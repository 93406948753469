<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <TextInput
        :default-value="inputValue"
        :valid-input="validName"
        :disabled="sameAsLastName"
        :valid-format="validFormat"
        input-name="birthLastName"
        :format-error="componentTexts.formatError"
        :submitted="submit"
        :question="componentTexts"
        ref="textInput"
        @update:textInput="setText"
      ></TextInput>
      <div class="row mb-3">
        <div class="d-none d-md-block pe-5 col-md-6"></div>
        <div class="col-12 col-md-6 mb-3 ps-5 justify-content-start text-start">
          <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                value=""
                name="birthLastNameCheckBox"
                :id="checkboxId"
                v-model="sameAsLastName"
                @change="forceLastNameValue()"
            />
            <label class="form-check-label" :for="checkboxId">{{ this.formTexts.infos_perso_souscription.label_meme_nom.text }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "hm-mpe-widgets/src/lib-components/components/Widgets/TextInput";
import { validateAlphabet } from "@/services/inputValidator";
import {mapState} from "vuex";

export default {
  name: "BirthLastName",
  components: { TextInput },
  props: {
    componentTexts: null,
    defaultValue: null,
    lastNameValue : null,
    checkboxId: null
  },
  data: function () {
    return {
      validName: false,
      submit: false,
      sameAsLastName: false,
      validFormat: false,
      inputValue: null,
    };
  },
  created() {
    this.inputValue = this.defaultValue;
  },
  methods: {
    setText(value) {
      this.inputValue = value;
    },
    updateLastName(value) {
      if (this.sameAsLastName){
        this.$refs.textInput.forceText(value)
      }
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      let validator = validateAlphabet(
        this.inputValue,
        this.validName,
        this.validFormat
      );
      this.validName = validator.validName;
      this.validFormat = validator.validFormat;
      return this.validName && this.validFormat;
    },
    forceLastNameValue(){
      if (this.sameAsLastName){
        this.$refs.textInput.forceText(this.lastNameValue)
      }else{
        this.$refs.textInput.forceText('')
      }
    }
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
  computed: mapState([`formTexts`]),
};
</script>

<style scoped></style>
