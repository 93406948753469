<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-8 mt-4">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h3 class="text-md-center title-page " v-html="viewTexts.title"></h3>
                <Civility
                  :component-texts="viewTexts.civilite"
                  :default-value="formPmPp.civility"
                  input-name="civility"
                  ref="civility"
                />
                <LastName
                  :component-texts="viewTexts.nom_famille"
                  :default-value="formPmPp.lastName"
                  ref="lastName"
                />
                <FirstName
                  :component-texts="viewTexts.prenom"
                  :default-value="formPmPp.firstName"
                  ref="firstName"
                />
                <DateInput
                  :component-texts="viewTexts.naissance"
                  input-name="birthDate"
                  :default-value="this.formPmPp.birthDate"
                  ref="birthDate"
                />
              </div>
            </transition>
            <ModalContact
              id="ineligibleSituationModal"
              ref="ineligibleSituationModal"
              :html-title="
                this.formTexts.modals.profil_aide_conseiller.text
              "
              content-text=""
              :confirmAction="false"
            />
            <Modal
              id="errorApiModal"
              ref="errorApiModal"
              title="Impossible de souscrire en ligne pour le moment"
              content-text=""
              :direct-show="!this.isApiContactAllRight"
              :confirmAction="false"
              :confirmRetour="false"
              :closable="false"
            />
          </div>
        </div>
      </div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import Civility from "@/components/FormPpPm/Civility";
import LastName from "@/components/FormPpPm/LastName";
import FirstName from "@/components/FormPpPm/FirstName";
import DateInput from "hm-mpe-widgets/src/lib-components/components/Widgets/DateInput";
import { isAgeValid } from "@/services/utils.js";
import ModalContact from "@/components/Navigation/ModalContact";
import Modal from "@/components/Navigation/Modal";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "Situation",
  components: {
    FooterNavigation,
    Encart,
    Civility,
    LastName,
    FirstName,
    DateInput,
    ModalContact,
    Modal,
  },
  data: function () {
    return {
      viewTexts: null,
      modalTitle: null,
      modalText: null,
    };
  },
  created() {
    wrapper.setTcVars({
      page_name : 'profil',
      page_cat1_name : 'besoins',
      page_cat2_name : 'situation',
    });
    this.$store.commit("setNextRoute", "InformationsPro");
    this.viewTexts = this.formTexts.eligibilite;
    reloadEvent('page');
  },
  mounted() {
    if (!this.contact.idProcess) {
      this.$store.dispatch("createContactProcessus");
    }
  },
  methods: {
    submitForm() {
      this.submit = true;
      let civility = this.$refs.civility.submitField();
      let lastName = this.$refs.lastName.submitField();
      let firstName = this.$refs.firstName.submitField();
      let birthDate = this.$refs.birthDate.submitField();
      if (civility && lastName && firstName && birthDate) {
        if (!isAgeValid(this.$refs.birthDate.inputValue, 50)) {
          this.modalTitle =
            "Votre profil nécessite les conseils d'un de nos conseillers";
          this.modalText = "Le parcours ne peux pas être poursuivi";
          this.$store.dispatch('closeOrOpenModal', {
            modalName: 'ModalWcb',
            action: true
          })
          
          wrapper.setTcVars({
            page_name : 'age-max',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        } else if (!isAgeValid(this.$refs.birthDate.inputValue, 18, false)) {
          this.modalTitle = "Vous devez avoir au moins 18 ans pour continuer";
          this.modalText = "Le parcours ne peux pas être poursuivi";
          this.$store.dispatch('closeOrOpenModal', {
            modalName: 'ModalWcb',
            action: true
          })
          
          wrapper.setTcVars({
            page_name : 'age-min',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        } else {
          this.formPmPp.civility = civility;
          this.formPmPp.lastName = lastName;
          this.formPmPp.firstName = firstName;
          this.formPmPp.birthDate = birthDate;
          this.$store.state.avancement[1] = true;
          this.nextRoute();
        }
      }
    },
    nextRoute() {
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
  },
  computed: mapState([`isApiContactAllRight`, `formTexts`, `formPmPp`, `contact`]),
};
</script>

<style scoped></style>
