<template>
  <div class="mb-2 pt-1 row beneficiaryForm" :class="getFormClass()">
    <div class="col-12">
      <p class="pt-4 benefTitle">{{ title }}</p>
      <a class="custom-link mt-2" v-if="index !== 0 && defaultBeneficiary == null" @click="deleteComp()">
        <BIconXCircle />
        Retirer enfant
      </a>
      <Civility
        :component-texts="viewText.civilite"
        :default-value="this.beneficiary.civility"
        :input-name="typeof index !== 'undefined' ? 'civility-children-'+index : 'conjoint-civility'"
        ref="civility"
      />
      <LastName
        :component-texts="viewText.nom_famille"
        :default-value="this.beneficiary.lastName"
        :input-name="typeof index !== 'undefined' ? 'lastname-children-'+index : 'lastname'"
        ref="lastName"
      />
      <FirstName
        :component-texts="viewText.prenom"
        :default-value="this.beneficiary.firstName"
        :input-name="typeof index !== 'undefined' ? 'firstname-children-'+index : 'firstname'"
        ref="firstName"
      />
      <DateInput
        :component-texts="viewText.naissance"
        :default-value="this.beneficiary.birthDate"
        :maxAge="maxAge"
        :minAge="minAge"
        :input-name="typeof index !== 'undefined' ? 'birthDate-children-'+index : 'birthDate'"
        ref="birthDate"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Civility from "@/components/FormPpPm/Civility";
import LastName from "@/components/FormPpPm/LastName";
import FirstName from "@/components/FormPpPm/FirstName";
import DateInput from "hm-mpe-widgets/src/lib-components/components/Widgets/DateInput";
import { BIconXCircle } from "bootstrap-icons-vue";

export default {
  name: "BeneficiaryForm",
  props: {
    title: String,
    index: {
      type: String,
      default: undefined
    },
    defaultBeneficiary: null,
    maxAge: null,
    minAge: null
  },
  components: {
    Civility,
    LastName,
    FirstName,
    DateInput,
    BIconXCircle,
  },
  data: function () {
    return {
      viewText: null,
      textBenef: null,
      beneficiary: {
        civility: null,
        firstName: null,
        lastName: null,
        birthDate: null,
      },
    };
  },
  created() {
    if (this.defaultBeneficiary) {
      this.beneficiary.civility = this.formPmPp.beneficiaries.partner.civility;
      this.beneficiary.firstName = this.formPmPp.beneficiaries.partner.firstName;
      this.beneficiary.lastName = this.formPmPp.beneficiaries.partner.lastName;
      this.beneficiary.birthDate = this.formPmPp.beneficiaries.partner.birthDate;
    }

    if (this.formPmPp.beneficiaries.children[this.index]) {
      this.beneficiary.civility = this.formPmPp.beneficiaries.children[this.index].civility;
      this.beneficiary.firstName = this.formPmPp.beneficiaries.children[this.index].firstName;
      this.beneficiary.lastName = this.formPmPp.beneficiaries.children[this.index].lastName;
      this.beneficiary.birthDate = this.formPmPp.beneficiaries.children[this.index].birthDate;
    }

    this.viewText = this.formTexts.eligibilite;
  },
  methods: {
    submitForm() {
      this.submit = true;
      let civility = this.$refs.civility.submitField();
      let lastName = this.$refs.lastName.submitField();
      let firstName = this.$refs.firstName.submitField();
      let birthDate = this.$refs.birthDate.submitField();

      if (civility && lastName && firstName && birthDate) {
        let res = {
          civility: civility,
          firstName: firstName,
          lastName: lastName,
          birthDate: birthDate,
        };
        return res;
      }
      return false;
    },
    deleteComp() {
      this.$emit("deleteComponent");
    },
    getFormClass(){
      return (typeof this.index !== 'undefined') ? 'beneficiary-'+ this.index : ''
    }
  },
  computed: mapState([`formTexts`, `formPmPp`]),
};
</script>

<style scoped></style>
