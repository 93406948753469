<template>
  <div class="select-offer-btn" :class="{ selected: isSelected }">
    <label class="form-check-label  btn rounded-pill" :for="'offer' + offer + cotisationStructure">
      <div class="check-input-container d-inline">
        <span class="custom-circle"></span>
        <img class="check-img" :src="require('@/assets/svg/Icon feather-check.svg')" v-if="isSelected" />
      </div>
  
      <input
        class=" d-none form-check-input"
        @click="onSelect($event)"
        type="radio"
        :name="name"
        :id="'offer' + offer + cotisationStructure"
        :value="offer"
      />
      {{ isSelected ? "Offre sélectionnée" : "Sélectionner cette offre" }}
      <img
        class="ms-3"
        style="height: 14px"
        :src="require('@/assets/svg/Icon feather-check.svg')"
        v-if="isSelected"
      />
      
    </label>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "OfferSelectButton",
  props: {
    defaultChoice: null,
    name: String,
    offer: String,
    cotisationStructure: {
      type: String,
      default : ''
    }
  },
  data: function () {
    return {
      picked: this.defaultChoice,
    };
  },
  methods: {
    onSelect(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    ...mapState([`cotisationSelected`]),
    isSelected() {
      if (this.cotisationStructure === ''){
        return this.defaultChoice === this.offer;
      }
      return this.cotisationStructure === this.cotisationSelected && this.defaultChoice === this.offer
    },
  },
};
</script>

<style scoped></style>
