<template>
    <div class="row justify-content-center align-items-center">
        <div class="col-12">
            <div class="mb-5">
                <div class="d-flex justify-content-center mb-3">
                    <img src="@/assets/svg/rejected-icon.svg" alt="rejected-icon"/>
                </div>
                <div class="row d-flex justify-content-center">
                    <p class="text-center mb-0" v-html="this.rejectedText"></p>
                    <div class="col-6">
                        <a class="btn btn-info rounded-pill change-button mt-3" @click="this.resetInfo()">
                            <span class="me-3 ms-2">Modifier mes informations</span>
                            <img :src="require('@/assets/svg/arrow-title-white.svg')" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import wrapper from '@/main';
import { reloadEvent } from '@/services/tagCo';

export default {
    name: "FailOdigo",
    components: {},
    data: function() {
        return {
            date: null,
            hour: null,
            callBackNow: null,
            rejectedText: '',
        }
    },
    created() {
        this.rejectedText = 'Votre demande est déjà enregistrée avec le numéro ' + this.callBack.phone + '.';
        wrapper.setTcVars({
            page_name : 'erreur',
            page_cat1_name : 'entrepreneurs',
            page_cat2_name : '',
        });
        reloadEvent('page');
    },
    methods: {
        resetInfo() {
            this.$store.dispatch('resetInfoOdigo')
        },
    },
    computed: mapState([`formTexts`, `callBack`]),
}
</script>
