<template>
  <div class="container" v-if="!this.isViewLoading">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 mt-4" v-if="true">
        <div class="row mb-5">
          <div class="col-12">
            <div>
              <h1 v-html="viewTexts.title"></h1>
              <DateInput
                  ref="dateClotureInput"
                  v-model="dateClotureInput"
                  :component-texts="input.dateCloture"
                  :submitted="submit"
                  :default-value="defaultDate"
              />

              <div class="row">
                <div class="col-md-6"></div>
                <div
                    class="col-md-6 col-sm-12 pb-4 form-check"
                    style="padding-left: 40px">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      id="attestationInput"
                      v-model="attestationInput"
                  />
                  <label class="form-check-label" for="attestationInput" v-html="input.attestation.text"></label>
                </div>
              </div>

              <div
                  v-if="submit && !attestationInput"
                  class="row justify-content-md-end">
                <div class="d-inline col-6 text-start form-error form-error-text">
                  Merci de cocher la case
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-else class="spinner-border" role="status"></div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import DateInput from "hm-mpe-widgets/src/lib-components/components/Widgets/DateInput.vue";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import moment from "moment";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "AttestationMadelin",
  components: {
    FooterNavigation,
    DateInput,
    Encart,
  },
  data: function () {
    return {
      viewTexts: null,
      title: null,
      input: null,
      dateClotureInput: null,
      attestationInput: null,
      submit: false,
      isMicroEntrepreneur: null,
      defaultDate: null,
    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    this.isMicroEntrepreneur =
        this.$store.state.souscription.profile_professionel.micro_entrepreneur;
    if(this.isMicroEntrepreneur == 'oui'){
      this.nextRoute();
    }

    wrapper.setTcVars({
      page_name : 'attestation-madelin',
      page_cat1_name : 'souscription',
    });

    if(this.souscription.attestation_madelin.date_cloture_exercice==''){
      this.defaultDate = new Date();
    this.defaultDate = moment(this.defaultDate)
        .endOf("year")
        .format("yyyy-MM-DD");
    }else{
      this.defaultDate = this.souscription.attestation_madelin.date_cloture_exercice;
    }
    

    this.viewTexts = this.formTexts.madelin_souscription;
    this.input = {
      dateCloture: this.viewTexts.date_cloture_exercice,
      attestation: {
        text: this.viewTexts.madelin_optin_cotisations.text,
        choices: [{ oui: "" }],
      },
    };
    this.dateClotureInput = this.souscription.attestation_madelin.date_cloture_exercice;
    this.attestationInput = this.souscription.attestation_madelin.optin_cotisations;
    reloadEvent('page');
  },
  methods: {
    validateField() {
      this.submit = true;
      this.dateClotureInput = this.$refs.dateClotureInput.submitField()
      return this.dateClotureInput !== "" && this.attestationInput
    },
    submitForm() {
      this.submit = true;
      if (this.validateField()) {
        this.souscription.attestation_madelin.date_cloture_exercice =
            this.dateClotureInput;
        this.souscription.attestation_madelin.optin_cotisations =
            this.attestationInput;
        this.nextRoute();
      }
    },
    nextRoute() {
      this.$router.push({ name: "InformationPersonnellesSouscription" });
    },
  },
  computed: mapState([`formTexts`, `souscription`, `isViewLoading`]),
};
</script>

<style scoped></style>
