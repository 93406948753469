<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-11 col-md-8" v-if="true">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1 v-html="title"></h1>
                <p v-html="note"></p>
                <RadioInput
                  ref="radioInput"
                  :default-choice="radioChoice"
                  v-model="radioChoice"
                  :question="radioQuestion"
                  input-name="ria"
                  name="ria"
                  :nextQuestion="false"
                  :submitted="submit"
                  :modelValue="null"
                />
              </div>
            </transition>
            <ModalContact
              id="riaIneligibleModal"
              ref="riaIneligibleModal"
              :htmlTitle=titleModal.text
              content-text=""
              :confirmAction="false"
            />
          </div>
        </div>
      </div>
      <div v-else class="spinner-border" role="status"></div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput.vue";
import ModalContact from "@/components/Navigation/ModalContact";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "ProfileProfessionnel",
  components: {
    FooterNavigation,
    Encart,
    RadioInput,
    ModalContact,
  },
  data: function () {
    return {
      title: null,
      note: "",
      radioQuestion: null,
      viewTexts: null,
      radioChoice : null,
      submit: false,
      titleModal: null,
    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    wrapper.setTcVars({
      page_name : 'ria',
      page_cat1_name : 'souscription',
    });
    this.viewTexts = this.formTexts.ria_souscription;
    this.title = this.viewTexts.title;
    this.titleModal = this.viewTexts.ria_modal_error_title;
    if ("text" in this.viewTexts.ria_sous_titre) {
      this.note = this.viewTexts.ria_sous_titre.text;
    }
    this.radioQuestion = {
      text: this.viewTexts.prise_en_charge_resiliation.text,
      choices: this.viewTexts.prise_en_charge_resiliation.choices,
    };

    this.radioChoice = this.$store.state.souscription.ria.prise_en_charge_resiliation

    reloadEvent('page');
  },
  methods: {
    validateField() {
      if (this.$refs.radioInput.picked) {
        return true;
      } else {
        return false;
      }
    },
    submitForm() {
      if (this.$refs.radioInput.picked == "oui") {
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        wrapper.setTcVars({
          page_name : 'ria',
          page_cat1_name : 'exclusion',
          page_cat2_name : '',
        });
        reloadEvent('page');
      } else {
        this.submit = true;
        if (this.validateField()) {
          this.$store.state.souscription.ria.prise_en_charge_resiliation = this.radioChoice;
          this.$store.dispatch('updateState')
          let dataContact;
          if(this.formPmPp.hasPhone && this.$refs.radioInput.picked === "oui") {
            dataContact = {
                "interaction": {
                    "objet": "Décrochage RIA",
                },
                "opportuniteRelationnelle": {
                    "objet": "Décrochage RIA",
                    "priorite":"93",
                },
            }
          } else {
            dataContact = {
                "interaction": {
                    "objet": "Décrochage RIA",
                },
            }
          }
          this.$store.dispatch("updatePm", dataContact);
          this.nextRoute();
        }
      }
    },
    nextRoute() {
      this.$router.push({ name: "InformationsBancaires" });
    },
  },
  computed: mapState([`formTexts`, `formPmPp`]),
};
</script>

<style scoped></style>
