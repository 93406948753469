<template>
  <div class="besoin-prevoyance">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-11 col-md-8">
          <h1 v-html="viewText.title"></h1>
          <div class="row justify-content-between salaries mb-5">
            <div
                v-for="choice in this.viewText.tranche_salaire.choices"
                :key="Object.keys(choice)"
                class="col-12 col-sm-4 mb-2 mb-sm-0">

              <div class="row text-center justify-content-md-center">
                <div class="col-9 col-sm-12 order-1 order-sm-0 text-start text-md-center">
                  <label class="form-check-label" :for="Object.keys(choice)">
                    <strong>{{ choice[Object.keys(choice)] }}</strong>
                  </label>
                </div>
                <div class="col-1 offset-sm-0 col-sm-12 order-0 order-sm-1">
                  <input
                      class="form-check-input p-0"
                      @change="updateSwiper"
                      v-model="selectSalary"
                      type="radio"
                      name="flexRadioDefault"
                      :id="Object.keys(choice)"
                      :value="Object.keys(choice)[0]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center" v-if="selectSalary.length > 0">
        <div class="col-12 mb-5">
          <h3 class=" title-page text-center">
            <p>{{ viewText.titre_carte.text }}</p>
          </h3>
          <h3 class="text-center">{{ viewText.sous_titre_carte.text }}</h3>
        </div>

        <div class="col-12 col-xl-10">
          <OffersCardSwiper>
            <template v-slot:swipeBody>
              <div class="swiper-slide" v-for="(product, index) in mpeProduits" :key="index">
                <OfferCard
                    :adviced-offer="this.advicedOffer"
                    class="offer-card"
                    :class="{ 'pt-3 mt-5 mt-md-5 pt-md-3 pt-lg-5 pt-xl-4': this.advicedOffer !== product.name }"
                    :salary-selected="selectSalary"
                    :offer-id="product.name">
                  <template v-slot:top-header>
                    <div class="px-4 pt-3 pb-2 bg-info2 text-white fw-bolder">
                      <div class="row align-items-center">
                        <div class="col-12 text-center">
                          <div class="ps-1 pe-1"><p>D’après vos besoins, nous vous conseillons ce niveau de protection</p></div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:header>
                    <div class="row">
                      <div class="col text-center py-4">
                         <img v-if="1 !== index" class="offre_img" :src="require('@/assets/svg/icone parapluis1.svg')"  />
                        <img v-else class="offre_img" :src="require('@/assets/svg/icone parapluis2.svg')" />
                      </div>
                    </div>
                  </template>
                  <template v-slot:body>
                    <div class="row justify-content-center">
                      <div class="col-11 pb-4">
                        <div class="row align-items-center">
                          <OfferCardGaranteePrice
                              :garantee="formTexts.devis.prevoyance_indemnite"
                              :price-text="product.ij + '€/ jour'"/>
                          <OfferCardGaranteePrice
                              :garantee="formTexts.devis.prevoyance_invalidite"
                              :price-text="numberWithSpaces(product.ij * 360) + ' €'"/>
                          <OfferCardGaranteePrice
                              :garantee="formTexts.devis.prevoyance_deces"
                              :price-text="numberWithSpaces(product.deces) + ' €'"/>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:footer>
                    <div class="row">
                      <div class="col-11 my-4 mx-auto text-center">
                        <OfferSelectButton
                            name="flexRadioDefaultOffer"
                            :default-choice="selectedOfferModel"
                            :offer="product.name"
                            :index="index"
                            @update:modelValue="itemSelected($event, index, product.name)"/>
                      </div>
                    </div>
                  </template>
                </OfferCard>
              </div>
            </template>
          </OffersCardSwiper>
        </div>
      </div>
    </div>

    <FooterNavigation
        :disabled-button-label="selectedOfferModel != null ? null : 'Veuillez sélectionner une offre'"
        @submitStep="submitForm"/>
    <Encart :component-texts="viewText.blocs_texte" />
  </div>

</template>

<script>
import OfferCard from "@/components/OfferCard";
import {mapState} from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import OfferCardGaranteePrice from "@/components/Offers/OfferCardGaranteePrice";
import OfferSelectButton from "@/components/Offers/OfferSelectButton";
import OffersCardSwiper from "@/components/Offers/OffersCardSwiper";
// import {pushTag} from "@/services/tagCo";
import templateHelpers from "@/services/templateHelpers";
import { reloadEvent } from '../../services/tagCo';
import wrapper from '../../main';

export default {
  name: "BesoinPrevoyance",
  components: {
    OfferCardGaranteePrice,
    OfferCard,
    FooterNavigation,
    Encart,
    OfferSelectButton,
    OffersCardSwiper,
  },
  data: function () {
    return {
      selectSalary: "",
      advicedOffer: "mpe002",
      selectedOfferModel: null,
      viewText: Object,
      mpeProduits: Array,
      salaryChoices: null,
      offers: null,
      showPopper: false,
    };
  },
  created() {
    wrapper.setTcVars({
      page_name : 'prevoyance',
      page_cat1_name : 'besoins',
      page_cat2_name : 'couverture',
    });
    this.$store.commit("setNextRoute", "CouvertureSante1");
    this.selectedOfferModel = this.selectedOfferPrevoyance;
    this.viewText = this.formTexts.vos_besoins_prevoyance;
    this.selectSalary = this.selectedSalary;
    this.mpeProduits = this.prevoyanceProducts.products;
    reloadEvent('page');
  },
  mounted() {
    this.updateSwiper();
  },
  methods: {
    ...templateHelpers,
    submitForm() {
      if (this.selectedOfferModel !== null) {
        this.$store.commit("setSelectedSalary", this.selectSalary);
        this.$store.commit("setSelectedOfferPrevoyance", this.selectedOfferModel);
        this.$router.push({ name: this.$store.getters.getNextRoute });
      }
    },
    itemSelected($event, index, offer) {
      this.selectedOfferModel = offer;
      // pushTag($event, "clic_detail-garantie-" + offer, "download", "contenu");
      // let event = {
      //   click_name: "clic_choisir-" + offer,
      //   click_type: 'action',
      //   composant: 'contenu'
      // };
      // reloadEvent('click', event)
    },
    updateSwiper() {
      if (this.selectSalary === "0_1600") {
        this.advicedOffer = "mpe001";
        if(this.mpeProduits.length>2){
          this.mpeProduits.pop();
        }
        if (this.selectedOfferModel === "mpe003") {
          this.selectedOfferModel = null;
        }
      } else if (this.mpeProduits.length === 2) {
        this.advicedOffer = "mpe002";
        this.mpeProduits.push(this.prevoyanceProducts.mpe003);
      }

      if(this.selectSalary === "1600_2500") {
        this.advicedOffer = "mpe002";
      }

      if(this.selectSalary === "2500") {
        this.advicedOffer = "mpe003";
      }
      this.setSwiper();
    },
    setSwiper(){
      let focus = 0;
      for(let i=0; i< this.mpeProduits.length; i++ ){
        if(this.mpeProduits[i].name == this.advicedOffer){
          focus=i;
        }
      }
      this.initSwiper(focus, 3)
    },
    numberWithSpaces(x) {
      x = x.toString();
      return x.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  computed: {
    ...mapState([`formTexts`, `selectedOfferPrevoyance`, `selectedSalary`, `prevoyanceProducts`])
  }
};
</script>
