<template>
  <div class="row justify-content-center question text-input">
    <div class="col-12 col-md-6 text-centered pt-2">
      <QuestionLabel :question="componentTexts" />
    </div>
    <div class="col-12 col-md-6 mb-3">
      <div class="input-group">
        <Select2
          class="form-control p-0 border-0 d-flex"
          :class="{'border-success ' : this.inputValue != null &&  this.inputValue != '' }"          v-model="inputValue"
          :options="this.codeNafList"
        />
        <i v-if="inputValue" class="m-2 bi bi-check-circle-fill validIcon"></i>
      </div>
      <div class="pt-1 col-12 text-start form-error form-error-text">
        <div v-if="!inputValue && submit" class="d-inline">Ce champ est obligatoire</div>
      </div>
    </div>
  </div>
</template>

<script>
import { validateRequiredInput } from "@/services/inputValidator";
import Select2 from "vue3-select2-component";
import { mapState } from "vuex";
import QuestionLabel from "hm-mpe-widgets/src/lib-components/components/Widgets/QuestionLabel";

export default {
  name: "CodeNaf",
  components: { Select2, QuestionLabel },
  props: {
    componentTexts: null,
    defaultValue: null,
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      validName: false,
      submit: false,
      inputValue: null,
    };
  },
  created() {
    this.inputValue = this.defaultValue;
      this.$store.dispatch("loadNafList");
  },
  methods: {
    setText(value) {
      this.inputValue = value;
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      let validator = validateRequiredInput(this.inputValue, this.validName);
      this.validName = validator.validName;
      return this.validName;
    },
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
  computed: mapState([`codeNafList`, `isLoading`]),
};
</script>

<style scoped></style>
