import { getPrevoyancePrice, totalSante } from '../templateHelpers';

export function buildDocumentData(state){

    let civility = state.formPmPp.civility === "mr" ? "M" : "Mme"

    let tarifSante = totalSante(state.tarification)
    let tarifPrevoyance = getPrevoyancePrice(state.tarification)

    return {
        'civility': civility,
        'lastName': state.formPmPp.lastName,
        'wcb_link': "https://rappel.harmonie-mutuelle.fr/entrepreneur",
        'price': state.tarification.offre.tarification.cotisationMensuelleGlobale,
        'tarifSante': tarifSante,
        'tarifPrevoyance': tarifPrevoyance,
        'email': state.formPmPp.email,
        'idHmDrive': state.documentsHMDrive,
        'code_sante': state.tarification.offre.beneficiaires[0].produits[0].code,
        'code_prevoyance': state.tarification.offre.beneficiaires[0].produits[1].code,
        'mailType': 'devis'
    }
}