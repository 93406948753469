import { createStore } from 'vuex'
import axios from "axios";
import { getNafs } from './services/nafs';
import state from "@/store/state";
import {buildInteractionOR, buildPMData, buildPPData} from "@/services/contactAPI/contactCallsBuilder";
import {
	getDevisInteraction, getInfoProInteraction, getSignatureInteraction
} from "@/services/contactAPI/interactionService";
import {getDevisOR, getSignatureOR} from "@/services/contactAPI/contactORService";
import mutations from "@/store/mutations";
import {filterCities, getCityFromZipCode} from "@/services/geoApiService";
import {getCall, postCall} from "@/services/apiService";
import {createPropData} from "@/services/souscrireAPI/souscrireCallBuilder";
import {getPostSignatureData, getPreSignatureData} from "@/services/mailService";
import {sleeper, getUpdateStateData} from "@/services/utils";
import { buildStudyData } from './services/etudeAPI/etudeCallBuilder';
import { buildDocumentData } from './services/documentAPI/documentCallBuilder';
import {buildAuthenticateData, buildCallbacksData} from "./services/odigoAPI/odigoCallBuilder";
import textHelper from "@/helpers/textHelper";
import soumissions from './services/soumissions';

// Create a new store instance.
export default createStore({
	state() {
		return state
	},
	// Synchronous
	mutations: mutations,
	// Asynchronous
	actions: {
		async getSiretInfo(context, siret) {
			return axios.get(process.env.VUE_APP_API_URL + '/hm-web-services/siret-api/' + siret).then((r) => {
				context.commit('setEtablissement', r.data)
				context.commit('setSiretInfo', true)
			}).catch((error) => {
				console.log(error)
				context.commit('setSouscrireEligibilitePM', true)
				context.commit('setSiretInfo', false)
			})
		},
		getFormTexts(context) {
			context.commit('setFormTexts', textHelper);
			context.commit('setPrevoyanceProducts');
		},
		loadNafList(context){
			context.commit('setCodeNafList', getNafs()["nafs"]);
		},
		getTarif(context, simData) {
			context.state.isViewLoading = true
			axios
				.post(process.env.VUE_APP_API_URL + '/hm-web-services/tarif', simData)
				.then(function (response) {
					let produits = response.data.offre.beneficiaires[0].produits
					let produitSante = produits[1]
					let produitPrevoyance = produits[0]
					let newProduits = [produitSante, produitPrevoyance]
					response.data.offre.beneficiaires[0].produits = newProduits
					context.commit('setTarification', response.data);
					context.commit('toggleLoadedTarif');
					context.state.isViewLoading = false
				}).catch(function () {
					context.commit('setTitleWcb', 'Nous n\'avons pas pu calculer votre tarif, vous pouvez réessayer plus tard ou contacter un conseiller.')
					context.dispatch('setClosableOdigoValue', false)
					context.dispatch('closeOrOpenModal', {
						modalName: 'ModalWcb',
						action: true
					})
					context.commit('setTarifError', true)
					context.state.isViewLoading = false
				})
		},
		createContactProcessus(context) {
			axios
				.get(process.env.VUE_APP_API_URL + '/hm-web-services/create-id-processus')
				.then(function (response) {
					context.state.isApiContactAllRight = true;
					context.commit('setContactProcess', response.data);
				}).catch(function(error){
				context.state.isApiContactAllRight = false;
				error instanceof Error;
				throw error;
			});
		},
		
		createContact(context, data) {
			return axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/create-pp', data).then(r => {return r});
		},
		async updateContactPM(context) {
			await context.dispatch('resetContactIfNoPhone')
			await context.dispatch('updateState')
			let apiContactData = buildInteractionOR(context.state, getSignatureInteraction(context.state), getSignatureOR(context.state))
			await postCall('/hm-web-services/update-pm-pp/' + context.state.contact.idProcess + '/pp', apiContactData)
			await postCall('/hm-web-services/create-pm', buildPMData(context.state, getInfoProInteraction(context.state)));
		},
		createParcoursSoumissions(context) {
			context.state.stateLoaded = false
			soumissions.post(process.env.VUE_APP_API_URL + '/api/parcours-soumissions', getUpdateStateData(context.state)).then(r => {
				context.commit('setIdReprise', r.data.uuid[0].value)
				context.commit('setUuid', r.data.uuid[0].value)
				context.state.stateLoaded = true
				context.dispatch('updateState');
			});
		},
		updateState(context) {
			soumissions.patch(process.env.VUE_APP_API_URL + '/api/parcours-soumissions/' + context.state.idReprise, getUpdateStateData(context.state)).then(() => {
				context.state.stateLoaded = true
			});
		},
		createEtude(context){
			let data = buildStudyData(context.state);
			if(!context.state.isViewLoading) {
				context.commit('toggleViewLoading')
			}
			return axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/create-study-v3/indiv', data).then((r) => {
				context.commit('setDocHm', r.data.documentsHMDrive)
			}).catch(() => {
				context.commit('setTitleWcb', 'Nous n\'avons pas pu vous envoyer le devis par email, vous pouvez réessayer plus tard ou contacter un conseiller.')
				context.dispatch('closeOrOpenModal', {
					modalName: 'ModalWcb',
					action: true
				})
			})
		},
		sendMail(context) {
			let data = buildDocumentData(context.state)
			axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/doc-mail/mpe', data).then(() => {
				context.dispatch('closeOrOpenModal', {
					modalName: 'ModalSendMail',
					action: true
				})
				context.commit('toggleViewLoading', false)
			}).catch(() => {
				context.commit('setTitleWcb', 'Nous n\'avons pas pu vous envoyer le devis par email, vous pouvez réessayer plus tard ou contacter un conseiller.')
				context.dispatch('closeOrOpenModal', {
					modalName: 'ModalWcb',
					action: true
				})
				context.commit('toggleViewLoading', false)
			})
		},
		souscrireEligibilitePP(context){

			return axios({
				method: 'get',
				url: process.env.VUE_APP_API_URL + '/hm-web-services/eligibilite-pp/' + context.state.contact.idProcess,
				headers: {
					'apikey': 'AHS8V5EUCA6M4WYVH75KASPXPSVH7CBLFL4NSPKNLNLS4ABGAYNUM62GL9LBULB4'
				},
				auth: {
					'username': 'MAESTRO_USER1',
					'password': 'TTG8Z3RU69WFE6F2G7'
				},
				data :  context.state.souscrire.eligible
			}).then((r) => {
				context.state.souscrire.eligible = r.data
			})

		

		},
		souscrireEligibilitePM(context){
			return axios({
				method: 'get',
				url: process.env.VUE_APP_API_URL +  '/hm-web-services/eligibilite-pm/' + context.state.formPmPp.siret ,
			}).then((r) => {
				context.state.souscrire.souscrireEligibilitePM = r.data
				if(r.data === false) {
					context.commit('setTitleWcb', 'Il semblerait que votre entreprise soit déjà cliente Harmonie Mutuelle. Veuillez prendre contact avec un conseiller.')
					context.commit('setDisableNext', true)
					context.dispatch('closeOrOpenModal', {
						modalName: 'ModalWcb',
						action: true
					})
				} else {
					context.commit('setDisableNext', false)
				}
			}).catch((error) => {
				console.log(error);
			})
		},
		resetContactIfNoPhone(context){
			console.log(context.state.formPmPp.hasPhone)
			if (!context.state.formPmPp.hasPhone){
				let apiContactData = buildPPData(context.state, getDevisInteraction(context.state), getDevisOR(context.state));
				return axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/update-pm-pp/' + context.state.contact.idProcess + '/pp', apiContactData)
			}
		},
		updateSouscrirePP(context, data){
			context.commit('toggleViewLoading');
			axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/souscrire-maj-pp/' + context.state.contact.idProcess, data).then(() => {
				context.commit('toggleViewLoading')
			})
		},
		uploadPJ(context){
			if (context.state.souscription.information_bancaire.fileData !== ""){
				return axios({
					method: 'post',
					url: 'https://rec.api.harmonie-mutuelle.fr/services/souscrire/v1/servicielle/adjonction-fichier-propositions/'+context.state.souscrire.idProp,
					headers: {
						'apikey': '67FFA750BCF971AF7438F507F2C1A317919F485C56009C8A50C80827D42A4B57',
						'Authorization': 'Basic V0VCX0hNX1VTRVIxOkVOVGFVdDN4azg4VlM1NXY2cg==',
						'Content-Type': 'application/json',
						'Cookie': 'JSESSIONID=CB060B29DE483B9F06A473DA5021F6A2'
					},
					data : context.state.souscription.information_bancaire.fileData
				});
			}else{
				return Promise.resolve('Success')
			}
		},
		resetIdProcess(context){

			context.commit('toggleViewLoading');
			return postCall('/hm-web-services/create-prop', createPropData(context.state, true)).then(sleeper(500)).then(r => {
				context.state.souscrire.idProp = r.data[0]
				return context.dispatch('uploadPJ').then(() => {
					return postCall('/hm-web-services/attach-prop-pm/' + context.state.souscrire.idProp, {}).then(() => {
						return getCall('/hm-web-services/edit-prop/' + context.state.souscrire.idProp).then((r) => {
							context.state.souscrire.documents = r.data
							let baDoc = context.state.souscrire.documents.find((d) => d.type === "BA_GENERE")
							context.state.souscrire.baLink = process.env.VUE_APP_API_URL + '/hm-web-services/telecharger-doc-prop/' + baDoc.id
							context.state.souscrire.baDoc = baDoc
							context.dispatch('createEtude').then(() => {
								return postCall('/hm-web-services/doc-mail-signature/mpe', getPreSignatureData(context.state)).then(() => {
									context.state.validProp = true
									context.dispatch('updateState').then(() => {
									context.commit('toggleViewLoading')
									});
								}).catch(() => {
									context.state.validProp = false
									context.dispatch('updateState').then(() => {
										context.commit('toggleViewLoading')
									});
								})
							})
						})
					})
				}).catch(() => {
					context.state.validProp = false
					context.dispatch('updateState').then(() => {
						context.commit('toggleViewLoading')
					});
				})
			}).catch(() => {
				context.commit('setTitleWcb', 'La souscription en ligne n\'est actuellement pas disponible, vous pouvez réessayer plus tard ou contacter un conseiller.')
				context.dispatch('setClosableOdigoValue', false)
				context.dispatch('closeOrOpenModal', {
					modalName: 'ModalWcb',
					action: true
				})

				context.commit('toggleViewLoading')
				context.state.validProp = false
					
				return false
			})
		},

		closeOrOpenModal(context, value) {
			context.commit('set' + value.modalName, value.action)
		},

		resetInfoOdigo(context) {
			context.commit('setSuccessOdigo', false)
			context.commit('setFailOdigo', false)
			context.commit('setClosableOdigo', true)
			context.commit('setModalWcb', false)
			context.commit('setTitleWcb', "Votre profil nécessite une prise en charge par l'un de nos conseillers experts")
		},

		acceptProposition(context){
			context.commit('toggleViewLoading')
			return getCall('/hm-web-services/accept-proposition/' + context.state.souscrire.idProp).then(() =>{
				return postCall('/hm-web-services/update-prop/' + context.state.souscrire.idProp, {'statut' : 'ACCEPTE'}).then(()=> {
					context.commit('toggleViewLoading')
				})
			})
		},

		sendPostSignatureMail(context){
			context.commit('toggleViewLoading')
			return postCall('/hm-web-services/doc-mail-signature/mpe', getPostSignatureData(context.state)).then(() =>{
				context.commit('toggleViewLoading')
			})
		},

		getOptins(context){
			context.commit('toggleViewLoading');

			axios.get(process.env.VUE_APP_API_URL + '/hm-web-services/consentements').then((r) => {
				context.state.isConsentementAllRight = true;
				context.commit('setOptins', r.data.mentionOptin);
				context.commit('toggleViewLoading')
			}).catch(function(error){
				context.state.isConsentementAllRight = false;
				error instanceof Error;
				throw error;
			});
		},
		getSimulationData(context, simCode){
			context.state.simulationLoaded = false;

			soumissions.get(process.env.VUE_APP_API_URL + '/api/parcours-soumissions/' + simCode).then((r) => {
				let cleanState = r.data
				context.commit('replaceState', cleanState);
				context.state.isViewLoading = false;

			})
		},
		completeCity(context, zipCode){
			getCityFromZipCode(zipCode).then((r) => {
				context.state.citiesList = filterCities(r.data)
			})
		},

		initSignature(context, phoneNumber){
			context.commit('toggleViewLoading')

			postCall('/hm-web-services/init-signature/'+ context.state.souscrire.idProp, {
				"telephone": phoneNumber,
				"email": context.state.formPmPp.email
			}).then((r)=> {
				context.state.souscrire.workflowNumber = r.data.workflowNumber
				context.commit('toggleViewLoading')
			}).catch(() => {
				context.commit('toggleViewLoading')
				context.commit('setTitleWcb', 'La signature électronique n\'est actuellement pas disponible, vous pouvez réessayer plus tard ou contacter un conseiller.')
				context.dispatch('setClosableOdigoValue', false)
				context.dispatch('closeOrOpenModal', {
					modalName: 'ModalWcb',
					action: true
				})
			})
		},
		updatePm(context, data) {
			return axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/update-pm-pp/' + context.state.contact.idProcess + '/pp', data)
		},

		getOdigoPhone(context, data){
			context.commit('setOdigoPhone', data)
		},
	
		getOdigoZipCode(context, data){
			context.commit('setOdigoZipCode', data)
		},
	
		getOdigoDate(context, data){
			context.commit('setOdigoDate', data)
		},
	
		getOdigoSlot(context, data){
			context.commit('setOdigoSlot', data)
		},

		setFailOdigoValue(context, data) {
			context.commit('setFailOdigo', data)
		},
		
		setSuccessOdigoValue(context, data) {
			context.commit('setSuccessOdigo', data)
		},

		setClosableOdigoValue(context, data) {
			context.commit('setClosableOdigo', data)
		},

		setTitleWcbValue(context, data) {
			context.commit('setTitleWcb', data)
		},

		async getOdigoToken(context) {
			let data = await buildAuthenticateData();
			return axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/odigo-token', data).then((r) => {
				context.commit('setOdigoToken', r.data.accessToken)
			})
		},

		async getOdigoCallback(context) {
			try {
				let data = await buildCallbacksData(context.state.callBack, context.state.contact.codeAction)
				let res = await axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/odigo-callback', data)
				if(res.status === 200 || res.status === 201 || res.status === 202) {
					context.commit('setSuccessOdigo', true)
				}
			} catch(error) {
				if(error.response.status === 400) {
					context.commit('setFailOdigo', true)
				}
			}
		},

		setModalWcb(context, data) {
			context.commit('setModalWcb', data);
		},
	},
	getters: {
		getNextRoute: (state) => {
			return state.nextRouteName
		}
	}
})
