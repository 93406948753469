<template>
   <div class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper justify-content-md-center">
      <slot name="swipeBody"></slot>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>

    <!-- If we need navigation buttons -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import {Navigation, Pagination} from "swiper";

export default {
  name: "OffersCardSwiper",
  props: {
    nbSlidesPerView: {
      type: Number,
      default: 3
    }
  },
  data: function () {
    return {
      modules: [Pagination, Navigation],
    }
  }
}
</script>

<style scoped>

</style>
