<template>
  <div class="container" v-if="!this.isViewLoading">
    <div class="row justify-content-center clauseBeneficiary">
      <div class="col-11 col-md-8">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1 v-html="viewTexts.title"></h1>
                <div class="row">
                  <RadioInput
                      v-model="clause"
                      :submitted="submit"
                      :question="viewTexts.onglets_clauses"
                      :default-choice="clause"
                      :name="'clause'"
                      :noLabel="true"
                      :input-col-size="7"
                  ></RadioInput>
                </div>
                <div class="row">
                  <div
                      v-if="clause == Object.keys(this.viewTexts.onglets_clauses.choices[0])[0]"
                      class="contentBorder p-4"
                      v-html="this.viewTexts.clause_type_texte.text"></div>
                  <div v-else class="row">
                    <div class="clause-libre-text"
                      v-html="this.viewTexts.clause_libre_texte.text"></div>
                    <div class="">
                      <div v-for="(component, index) in components" :key="index">
                       <strong><p  class="pt-4"> BÉNÉFICIAIRE {{index + 1}} </p></strong>
                       <div class="contentBorder p-4">
                       <BIconXCircle
                            v-if="components[index] && index !== 0"
                            @click="removeComponent(index)"
                        />
                        <component
                            :is="component"
                            :index="index"
                            :ref="'clauseBeneficiaryForm' + index"
                            :equalPart="equalPart"
                            @update:modelValue="updateForm"
                        ></component>
                      </div>
                      </div>
                    </div>
                   <div class=" d-flex justify-content-center  justify-content-md-start ">
                    <a class="btn btn-outline-info rounded-pill add-benef mt-4 mb-3 col-10 col-md-4" @click="addForm">
                      <BIconPlus class="type-bold h4 pt-1" />
                      {{ viewTexts.label_add_beneficiaire.text }}
                    </a>
                    </div>

                    <div class="text-center text-md-end default-text" v-html="viewTexts.clause_type_defaut.text" />
                  </div>
                </div>
                <div v-if="partErrore == true" ><p style="color: red">Attention, la répartition des part n'est pas correct, elle doit etre égale à 100</p></div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { markRaw } from "vue";
import { mapState } from "vuex";
import { BIconPlus } from "bootstrap-icons-vue";
import { BIconXCircle } from "bootstrap-icons-vue";
import ClauseBeneficiaryForm from "@/components/FormPpPm/ClauseBeneficiaryForm";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";

export default {
  name: "ClauseBeneficiary",
  components: {
    ClauseBeneficiaryForm,
    FooterNavigation,
    Encart,
    RadioInput,
    BIconXCircle,
    BIconPlus,
  },
  data: function () {
    return {
      submit: false,
      clause: null,
      components: [markRaw(ClauseBeneficiaryForm)],
      partErrore: false,
      equalPart: "false",
    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    this.$store.commit("setNextRoute", "InformationsBancaires");
    this.viewTexts = this.formTexts.clause_beneficiaire_souscription;
    this.clause = this.formPmPp.clauseType
        ? this.formPmPp.clauseType
        : Object.keys(this.viewTexts.onglets_clauses.choices[0])[0];
    if(this.formPmPp.partBeneficiaries[0] != null){
      this.loadForms(true);
    }else{
      this.loadForms(false);
    }

    if (this.formPmPp.partBeneficiaries.length == 0) {
      this.formPmPp.partBeneficiaries.push({ max: 100, val: 0, equalPart: false });
    }
  },
  methods: {
    updateForm(val){
      this.equalPart=val;
    },
    loadForms(stated) {
      if (this.formPmPp.partBeneficiaries.length > 1) {
        for (let i = 1; i <= this.formPmPp.partBeneficiaries.length-1; i++) {

            if(!stated){
              this.addForm();
            }else{
              this.components.push(markRaw(ClauseBeneficiaryForm));
            }

          }

      }
    },
    addForm() {
      var maxValue = 0;
      var total = 0;

      this.components.forEach((e, i) => {
        total += this.formPmPp.partBeneficiaries[i].val;
      });

      maxValue = 100 - total;

      this.formPmPp.partBeneficiaries.push({ max: maxValue, val: maxValue });
      if(this.formPmPp.partBeneficiaries[0].equalPart == true){
        let rank = 100/this.formPmPp.partBeneficiaries.length;
        for(let i=0; i <= this.formPmPp.partBeneficiaries.length -1; i++){
           this.formPmPp.partBeneficiaries[i].max = rank;
          this.formPmPp.partBeneficiaries[i].val = rank;
        }
      }
      this.components.push(markRaw(ClauseBeneficiaryForm));
    },
    itemSelected(itemKey) {
      this.clause = itemKey;
      if (!this.clause) {
        this.components = [markRaw(ClauseBeneficiaryForm)];
      }
    },
    removeComponent(index) {
      this.components.splice(index - 1, 1);
      this.formPmPp.partBeneficiaries.splice(index, 1);
      this.formPmPp.partBeneficiaries.forEach((e, i) => {
        this.formPmPp.partBeneficiaries[i].max = 100;
      });
    },
    updatePart(val) {
      var totalValue = 0;

      this.components.forEach((e, i) => {
        totalValue += parseInt(this.formPmPp.partBeneficiaries[i].val);
      });

      var leftValue = 100 - totalValue;

      this.components.forEach((e, i) => {
        if (val.ref != i) {
          if (val.val == 0) {
            this.formPmPp.partBeneficiaries[i].max = leftValue;
          }
          if (this.formPmPp.partBeneficiaries[i].val > leftValue) {
            this.formPmPp.partBeneficiaries[i].val = 0;
          }
        }
      });


    },
    submitForm() {
      this.submit = true;
      let isValid = true;
      this.partErrore=false;
      if (this.clause !== Object.keys(this.viewTexts.onglets_clauses.choices[0])[0]) {
        this.formPmPp.clauseBeneficiaries = [];
        var tab = [];
        var total = 0;
        this.components.forEach((e, i) => {
          let res = this.$refs["clauseBeneficiaryForm" + i].submitForm();
          if (res) {
            this.formPmPp.clauseBeneficiaries.push(res);
            total+=Number(res.part);
            tab.push(res);
          } else {
            isValid = false;
          }
        });
        this.souscription.clause_beneficiaries.beneficiaries = tab;

       // var total = 0;
       /* for(let i=0; i<= this.formPmPp.partBeneficiaries.length -1; i++){
          total += Number(this.formPmPp.partBeneficiaries[i].val);
        }*/

        if (total!=100 || this.formPmPp.partBeneficiaries[0].equalPart== true ){
          isValid=false;
          this.partErrore=true;
        }
      }else{
        this.souscription.clause_beneficiaries.beneficiaries = [];
      }
      this.formPmPp.clauseType = this.clause;

      if (isValid) {
        this.formPmPp.clauseType = this.clause;
        this.$store.state.avancementSouscription[2] = true;
        this.nextRoute();
      }
    },
    nextRoute() {
      this.$router.push({ name: "DateEffetContrat" });
    },
  },
  computed: mapState([`formTexts`, `formPmPp`, `isViewLoading`, `souscription`]),
};
</script>

<style scoped></style>
