<template>
  <div class="">
    <div class="row">
      <div class="col-11 mx-auto">
        <div class="border py-3 px-4">
          <div class="row">
            <div class="text-center">
              <i :class="'bi bi-'+iconClass"></i>
            </div>
            <div class="col-12" v-html="textKey.text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElementToPrepare",
  props: {
    textKey : Object,
    iconClass: String
  }
}
</script>

<style scoped>

</style>
