<template>
  <div class="price-section text-center">
    <div class="price-container">
      <span class="price" :class="{blue : bluePrice}">{{ price }}€</span><span class="asterisk">&#42;</span><span class="price-period">/mois</span>
    </div>
    <div class="tarif-label">
      <span class="asterisk">&#42;</span><span class="annual-price">Tarif sur l'année en cours</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonthPrice",
  props: {
    price: String,
    bluePrice : {
      type : Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
