<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div
            v-if="this.$route.path.includes('souscription')"
            id="nav"
            class="row justify-content-center mt-4 mb-4"
        >
          <div class="col-lg-11 col-lg-9 ">
            <div class="row justify-content-center gx-0 gx-md-2">
              <NavLink
                step-index="1"
                :complet="this.$store.state.avancementSouscription[1]"
                link-col-size="col-md-2"
                route-name="InformationsComplementaires"
                :link-text="formTexts.fil_ariane.souscription_infos.text"
              />

              <NavLink
                step-index="2"
                :complet="this.$store.state.avancementSouscription[2]"
                link-col-size="col-md-2"
                route-name="ClauseBeneficiary"
                :link-text="
                  formTexts.fil_ariane.souscription_clauses.text
                "
              />

              <NavLink
                step-index="3"
                :complet="this.$store.state.avancementSouscription[3]"
                route-name="DateEffetInfosBancaires"
                link-col-size="col-md-2"
                :link-text="
                  formTexts.fil_ariane
                    .souscription_date_effet_infos_bancaires.text
                "
              />

              <NavLink
                step-index="4"
                :complet="this.$store.state.avancementSouscription[4]"
                link-col-size="col-md-2 "
                route-name="Relecture"
                :link-text="
                  formTexts.fil_ariane.souscription_relecture.text
                "
              />

              <NavLink
                step-index="5"
                :complet="this.$store.state.avancementSouscription[5]"
                link-col-size="col-md-2"
                route-name="SignatureElectronique"
                :link-text="
                  formTexts.fil_ariane.souscription_signature.text
                "
              />
            </div>
          </div>
        </div>

        <div
            v-else
            id="nav"
            class="row justify-content-center mt-4"
        >
          <div class="col-lg-8">
            <div class="row gx-0 gx-md-2">
              <NavLink
                step-index="1"
                :complet="completed[1]"
                route-name="Situation"
                :link-text="formTexts.fil_ariane.besoin_situation.text"
              />

              <NavLink
                step-index="2"
                :complet="completed[2]"
                route-name="Besoins"
                :link-text="formTexts.fil_ariane.besoin_besoins.text"
              />

              <NavLink
                step-index="3"
                :complet="completed[3]"
                route-name="InfosPersos"
                :link-text="formTexts.fil_ariane.besoin_informations.text"
              />

              <NavLink
                step-index="4"
                :complet="completed[4]"
                route-name="Devis"
                :link-text="formTexts.fil_ariane.besoin_devis.text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
  <!-- <Contact /> -->

  <div style="cursor: pointer">
    <Contact />
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavLink from "@/components/Navigation/NavLink";
import Contact from "@/components/Navigation/Contact";
import {overrideBrowserBackLink} from "@/services/WizardHelperService";

export default {
  name: "Wizard",
  components: { NavLink, Contact },
  data: function () {
    return {
      linkTexts: Object,
      completed: null,
      completedSouscription: null
    };
  },
  created() {
    this.completed=this.$store.state.avancement;
    this.completedSouscription=this.$store.state.avancementSouscription;

    if (process.env.NODE_ENV === 'production' && ['DateEffetContrat', 'DateEffetInfosBancaires', 'InformationsBancaires', 'SignatureElectronique', 'ClauseBeneficiary', 'Relecture', 'RIA'].includes(this.$route.name)){
      this.$router.push({ name: 'InformationPersonnellesSouscription' });
    }
    if ( this.contact.idProcess === null && ['Situation', 'VotreSituation', 'InformationsPro', 'Besoins', 'Prevoyance', 'Prevoyance', 'CouvertureSante1', 'CouvertureSante2', 'InfosPersos', 'InformationsPersonnelles', 'InformationsPersonnellesComplete', 'Devis' ].includes(this.$route.name)){
      this.$router.push({ name: 'Situation' });
    }

    overrideBrowserBackLink(this.$route, this.$router);
  },
  computed: mapState([`formTexts`, `contact`]),
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-leave-to {
  display: none;
}
</style>
