<template>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xl-9">
      <OffersCardSwiper>
        <template v-slot:swipeBody>
          <div class="swiper-slide " v-for="(offer) in offresTexts" :key="offer.product">
            <OfferCard :advicedOffer="selectedOfferModel" :offer-id="offer.product"
                       :class="{ 'pt-3 mt-5 pt-md-5 mt-md-5 mt-lg-5 pt-lg-5 mt-xl-5 pt-xl-4': this.selectedOfferModel !== offer.product && this.$route.name === 'Devis' }">
              <template v-if="devisDeck == true" v-slot:top-header>
                <div class="px-4 pt-3 pb-2 bg-info2 text-white fw-bolder">
                  <div class="row align-items-center">
                    <div class="col-12 text-center">
                          <div><p>D’après vos besoins, nous vous conseillons ce niveau de protection</p></div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:header>
                <div class="row justify-content-center">
                  <div class="col-12">
                    <h4 class="offer-title text-center pt-4 mb-0">{{ offer.name }}</h4>
                  </div>
                  <div class="col-11">
                    <div v-if="!devisDeck" class="offer-description text-center mt-2 mb-3" v-html="offer.description"></div>
                    <div v-else>
                      <MonthPrice v-if="!offer.loading" :price="totalSante(offer.price)" :blue-price="selectedOfferModel === offer.product" />
                      <Loading v-else :active="true" :is-full-page="false"/>

                      <div class="row justify-content-center">
                        <div class="col-10">
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:body>
                <div class="row">
                  <div class="col-12">
                    <div class="accordion accordion-flush" :id="'accordionFlushExample' + offer.level">
                      <AccordionItem
                          :offer="offer"
                          icon-class="heart"
                          garantee="soins_courant"
                          sub-garantee-first="medicaux"
                          sub-garantee-last="medecine_complementaire"
                      />
                      <AccordionItem
                          :offer="offer"
                          icon-class="hospital"
                          garantee="hospitalisation"
                          sub-garantee-first="operation"
                          sub-garantee-last="sejour_chambre"
                      />
                      <AccordionItem
                          :offer="offer"
                          icon-class="hospital"
                          garantee="optique"
                          sub-garantee-first="lunettes"
                          sub-garantee-last="lentilles"
                      />
                      <AccordionItem
                          :offer="offer"
                          icon-class="hospital"
                          garantee="dentaire"
                          sub-garantee-first="couronne"
                          sub-garantee-last="orthodonthie"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:footer>
                <div class="row">
                  <div class="col-11 my-3 mx-auto text-center">
                    <OfferSelectButton
                        :class="{'tarif-not-ready' : offer.loading}"
                        :offer="offer.product"
                        :cotisation-structure="cotisationStructure"
                        :ref="'flexRadioDefaultOffer'+ offer.product + '_' + cotisationStructure"
                        :name="'flexRadioDefaultOffer'+ cotisationStructure"
                        :default-choice="selectedOfferSante"
                        @update:modelValue="itemSelected(offer.product, offer)"
                    />
                  </div>
                  <div class="col-12 text-center mb-4">
                    <a :href="getPdfUrl(offer.productPdfName)"
                       class="dl-garantee" target="_blank" @click="eventTagCo(offer)" >Détail des garanties</a>
                  </div>
                </div>
              </template>
            </OfferCard>
          </div>
        </template>
      </OffersCardSwiper>

      <Modal
          :id="'InfoDevisModal'+ cotisationStructure"
          :ref="'InfoDevisModal'+ cotisationStructure"
          title="Vous êtes sur le point de modifier votre besoin"
          content-text=""
          :change-offer="newOffer"
          :confirmAction="true"
          @confirmAction="forceItemSelected"/>
    </div>
  </div>
</template>

<script>
import OffersCardSwiper from "@/components/Offers/OffersCardSwiper";
import OfferSelectButton from "@/components/Offers/OfferSelectButton";
import OfferCard from "@/components/OfferCard";
import { mapState } from "vuex";
import AccordionItem from "@/components/Offers/AccordionItem";
import MonthPrice from "@/components/Devis/MonthPrice";
import Modal from "@/components/Navigation/Modal";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import {getTarifData} from "@/services/tarifService";
import templateHelpers from "@/services/templateHelpers";
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "OffersSanteCardsDeck",
  components: {
    OffersCardSwiper,
    OfferSelectButton,
    OfferCard,
    AccordionItem,
    MonthPrice,
    Modal,
    Loading
  },
  props: {
    devisDeck: {
      type: Boolean,
      default: false,
    },
    cotisationStructure: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      selectedOfferModel: null,
      offresTexts: Array,
      tempIndex: null,
      tempOffer: null,
      newOffer: {},
    };
  },
  mounted() {
    this.offresTexts = [
      {
        product: "mpe112",
        level: 1,
        price: null,
        loading: false,
        name: this.formTexts.offres.economique_malin_name.text,
        description: this.formTexts.offres.economique_malin_description.text,
        productPdfName: 'TESAMPE112_20220101',
      },
      {
        product: "mpe110",
        level: 1,
        price: null,
        loading: false,
        name: this.formTexts.offres.economique_name.text,
        description: this.formTexts.offres.economique_description.text,
        productPdfName: 'TESAMPE110_20220101',
      },
      {
        product: "mpe220",
        level: 2,
        price: null,
        loading: false,
        name: this.formTexts.offres.confort_name.text,
        description: this.formTexts.offres.confrt_description.text,
        productPdfName: 'TESAMPE220_20220101',
      },
      {
        product: "mpeFF0",
        level: 3,
        price: null,
        loading: false,
        name: this.formTexts.offres.optimal_offer_name.text,
        description: this.formTexts.offres.optimal_description.text,
        productPdfName: 'TESAMPEFF0_20220101',
      },
    ];

    this.selectedOfferModel = this.selectedOfferSante;

    if (this.selectedOfferPrevoyance === "mpe001") {
      this.offresTexts.pop();
    } else {
      this.offresTexts.shift();
    }

    if (this.devisDeck){
      this.offresTexts.forEach((e) => {
        e.loading = true;
        axios.post(process.env.VUE_APP_API_URL + '/hm-web-services/tarif', getTarifData(this.$store.state, e.product, this.cotisationStructure))
            .then((r) => {
              e.price = r.data;
              e.loading = false;
            })
      });
    }

  },
  methods: {
    ...templateHelpers,
    forceItemSelected() {
      this.itemSelected(this.tempIndex, this.tempOffer, true);
    },
    getPdfUrl(offer) {
      return 'https://' + window.location.hostname + '/sites/default/files/pdfs_mpe/' + offer + '.pdf'
    },
    itemSelected(index, offer, force = false) {
      if (this.selectedOfferSante !== offer.product || (this.cotisationStructure !== '' && this.cotisationStructure !== this.cotisationSelected) ) {
        if (this.selectedOfferSante == null || this.$route.name == "CouvertureSante2") {
          force = true;
        }
        if (!force) {
          this.tempOffer = offer;
          this.tempIndex = index;
          this.newOffer = {
            subtitle:
                "En choisissant l'offre <a class=\"blue\">" +
                offer.name +
                '</a> vous confirmez que votre besoin est : ',
            description: offer.description,
            price: this.totalSante(offer.price)
          };

          this.$refs['InfoDevisModal'+ this.cotisationStructure].showModal();
        } else {

          this.selectedOfferModel = index;
          this.$store.commit("setTarification", offer.price);
          this.$store.commit("setSelectedOfferSante", offer.product);
          this.$store.commit("setSelectedCotisation", this.cotisationStructure);
          this.$emit("update:modelValue", offer.product);
          let event = {
            click_name: 'clic_choisir-'+ offer.name,
            click_type: 'action',
            composant: 'contenu'
          };
          reloadEvent('click', event)
        }
      }
    },
    eventTagCo(offer){
      let event = {
        click_name: 'clic_detail-garantie'+ offer.name,
        click_type: 'download',
        composant: 'contenu'
      };
      reloadEvent('click', event)
    }
  },
  computed: mapState([`formTexts`, `selectedOfferPrevoyance`, `selectedOfferSante`, `cotisationSelected`]),
};
</script>
