<template>
    <div class="container">
        <div class="row">
            <div class="col-12">

                <div class="mb-5">
                    <div class="modal-header text-center pt-0 border-0">
                        <h5 class="modal-title w-100 fw-bolder" v-html="callBack.titleWcb"></h5>
                    </div>
                    <div class="m-5 modal-body body">
                        <div class="row">
                            <div class="col-md-7">
                                <strong>
                                    <p class="mainText mb-0" v-html="'Nos conseillers sont disponibles au'"></p>
                                </strong>
                                <p class="subText" v-html="'Service ouvert du Lundi au Vendredi de 8h30 à 18h30.'"></p>
                            </div>
                            <div class="col-md-5 d-flex align-items-center phone">
                                <strong><p v-html="'0 805 50 00 19'"></p></strong>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-if="callBack.failOdigo === false && callBack.successOdigo === false" class="row justify-content-center align-items-center">
                    <div class="col-12">
                        <div class="row col-12 justify-content-around">
                            <div class="col-md-5">
                                <PhoneNumber
                                    :component-texts="this.telQuestion"
                                    :display-column="true"
                                    :facultatif="false"
                                    :no-mandatory="false"
                                    ref="phone"
                                />
                            </div>

                            <div class="col-md-5">
                                <ZipCode
                                    :component-texts="this.zipCodeQuestion"
                                    :display-column="true"
                                    ref="zipCode"
                                />
                            </div>
                        </div>

                        <div class="row col-12 justify-content-around">
                            <div class="col-md-5 align-items-center">

                                <div class="col-12 text-centered pt-2 col-md-0">
                                    <QuestionLabel 
                                        :question="this.dateQuestion"
                                        :display-column="true"
                                    />
                                </div>

                                <Datepicker 
                                    v-model="date"
                                    :min-date="this.currentDate"
                                    :max-date="this.maxDate"
                                    :disabledWeekDays="[6, 0]"
                                    hideInputIcon
                                    :enableTimePicker="false"
                                    locale="fr-FR"
                                    :format="'dd/MM/yyyy'"
                                    :auto-apply="true"
                                    :state="true"
                                    :position="center"
                                    @update:modelValue="makeSlotChoices"
                                    hideOffsetDates
                                >
                                    <template #calendar-header="{ index, day }">
                                        <div :class="index === 5 || index === 6 ? 'red-color' : ''">
                                            {{ day }}
                                        </div>
                                    </template>
                                </Datepicker>
                            </div>

                            <div class="col-md-5">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <SelectInput
                                            :question="this.slotChoicesQuestion"
                                            :display-column="true"
                                            :input-col-size="12"
                                            v-model="this.slot"
                                            :submitted="this.submit"
                                            :valid-input="validValue"
                                            @update:modelValue="checkSlot"
                                            @click="resetValue"
                                            ref="slot"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row col-12 justify-content-around">
                            <div class="col-6"></div>
                            <div class="col-md-6">
                                <FooterNavigation
                                    :textRight="'Valider ma demande'"
                                    @submitStep="submitForm"
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div v-if="callBack.successOdigo === true">
                    <SuccessOdigo />
                </div>
                <div v-if="callBack.failOdigo === true">
                    <FailOdigo />
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import PhoneNumber from '../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/PhoneNumber.vue';
import ZipCode from '../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/ZipCode.vue';
import SelectInput from '../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/SelectInput.vue';
import QuestionLabel from '../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/QuestionLabel.vue';
import FooterNavigation from '../Navigation/FooterNavigation.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import SuccessOdigo from './SuccessOdigo.vue';
import FailOdigo from './FailOdigo.vue';

export default {
    name: "CallBack",
    components: { PhoneNumber, ZipCode, SelectInput, FooterNavigation, Datepicker, QuestionLabel, SuccessOdigo, FailOdigo },
    data: function () {
        return {
            slot: null,
            currentDate: null,
            dateSelected: null,
            currentHour: null,
            firstChoice: true,
            validValue: null,
            picker: null,
            maxDate: null,
            submit: null,
            date: null,
            dateQuestion: null,
            telQuestion: null,
            slotChoicesQuestion: null,
            zipCodeQuestion: null,
            failOdigo: false,
            successOdigo: false,
        }
    },
    created(){
        this.dateQuestion = {"text":"<p>Date du rappel</p>\r\n","type":"ckeditor"}
        this.telQuestion = {"text":"<p>Téléphone</p>\r\n","type":"ckeditor","helpLabel":"","formatError":"","tooltip":"","placeholder":""}
        this.slotChoicesQuestion = {text: '<p>Créneau</p>\r\n', choices: [{"9h": "9h - 10h"},{"10h": "10h - 11h"},{"11h": "11h - 12h"},{"12h": "12h - 13h"},{"13h": "13h - 14h"},{"14h": "14h - 15h"},{"15h": "15h - 16h"},{"16h": "16h - 17h"},{"17h": "17h - 18h"}], type: 'ckeditor', tooltip: ''}
        this.zipCodeQuestion = {"text":"<p>Code postal</p>\r\n","type":"ckeditor","helpLabel":"","formatError":"","tooltip":"","placeholder":""}
        this.currentHour = new Date().getHours();
        
        if(this.currentHour > 17) {
            this.currentDate = moment().add(1, 'days')._d;
        } else {
            this.currentDate = moment()._d;
        }
        this.dateSelected = this.currentDate
        this.date = this.currentDate

        this.maxDate = moment().add(14, 'days')._d;
        if(this.date.getDay() === 6) {
            this.date = moment().add(2, 'days')._d
        } else if (this.date.getDay() === 0) {
            this.date = moment().add(1, 'days')._d
        }
        this.$store.dispatch('getOdigoToken')

    },
    mounted() {
        this.makeSlotChoices()
        this.checkSlot()
    },
    methods: {
        checkSlot() {
            if(this.slot !== null && this.slot !== '') {
                this.validValue = true
            } else {
                this.validValue = null
            }
        },

        makeSlotChoices() {
            if(this.currentDate.toLocaleDateString() === this.date.toLocaleDateString() && this.currentHour > 8 && this.currentHour < 18) {
                let index;
                let currentChoicesSlot = this.slotChoicesQuestion.choices
                currentChoicesSlot.forEach(element => {
                    if(Object.values(element)[0].includes(this.currentHour.toString())) {
                        index = currentChoicesSlot.indexOf(element)
                    }
                });

                for(let i = 0 ; i <= index ; i++) {
                    currentChoicesSlot.shift()
                }

                if(Object.values(currentChoicesSlot)[0] !== "Au plus vite") {
                    currentChoicesSlot.splice(0, 0, {"0h": "Au plus vite"})
                }
                if(this.firstChoice) {
                    this.validValue = true
                    this.slot = '0h'
                    this.$refs.slot.forceSelect(this.slot)
                } else {
                    this.slot = null
                    this.validValue = false
                    this.$refs.slot.forceSelect(this.slot)
                }
                this.firstChoice = false
            } else {
                this.slotChoicesQuestion.choices = [{"9h": "9h - 10h"},{"10h": "10h - 11h"},{"11h": "11h - 12h"},{"12h": "12h - 13h"},{"13h": "13h - 14h"},{"14h": "14h - 15h"},{"15h": "15h - 16h"},{"16h": "16h - 17h"},{"17h": "17h - 18h"}]
                this.validValue = false
                this.slot = null
                this.$refs.slot.forceSelect(this.slot)
            }
        },

        resetValue(){
            let current =  new Date().getHours();
            if(this.currentHour !== current){
                this.currentHour = new Date().getHours();
                this.slotChoicesQuestion.choices.shift();
                this.makeSlotChoices();
            }
           
        },

        submitForm() {
            this.submit = true
            this.$store.dispatch('getOdigoPhone', this.$refs.phone.submitField())
            this.$store.dispatch('getOdigoZipCode', this.$refs.zipCode.submitField())
            this.$store.dispatch('getOdigoDate', this.date)
            this.$store.dispatch('getOdigoSlot', this.slot)
            this.$store.dispatch('getOdigoCallback')
            this.validValue = null
            this.submit = false
        }
    },
    computed: mapState([`callBack`]),
}
</script>

<style scoped>

.red-color{
    color: red;
}

</style>