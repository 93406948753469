import { createRouter, createWebHistory } from 'vue-router'
import Situation from "@/views/Besoin/Situation";
import VotreSituation from "@/views/Besoin/VotreSituation";
import InformationsPro from "@/views/Besoin/InformationsPro";
import Devis from "@/views/Besoin/Devis";
import Besoins from "@/views/Besoin/Besoins";
import InfosPerso from "@/views/Besoin/InfosPerso";
import InformationsPersonnelles from "@/views/Besoin/InformationsPersonnelles";
import InformationsPersonnellesComplete from "@/views/Besoin/InformationsPersonnellesComplete";
import BesoinPrevoyance from "@/views/Besoin/BesoinPrevoyance";
import ProtectionSante from "@/views/Besoin/ProtectionSante";
import ProtectionSanteBeneficiaire from "@/views/Besoin/ProtectionSanteBeneficiaire";
import NotFound from "@/views/NotFound.vue";
import Souscription from "@/views/Souscription/Souscription";
import Eligibilite from "@/views/Souscription/Eligibilite";
import ProfileProfessionnel from "@/views/Souscription/ProfileProfessionnel";
import ElementsAPreparer from "@/views/Souscription/ElementsAPreparer";
import AttestationMadelin from "@/views/Souscription/AttestationMadelin";
import InformationsProfessionnelles from "@/views/Souscription/InformationsProfessionnelles";
import InformationsComplementaires from "@/views/Souscription/InformationsComplementaires";
import InformationsPersonnellesSouscription from "@/views/Souscription/InformationsPersonnelles";
import InformationsBancaires from "@/views/Souscription/InformationsBancaires";
import DateEffetContrat from "@/views/Souscription/DateEffetContrat";
import Relecture from "@/views/Souscription/Relecture";
import ClauseBeneficiary from "@/views/Souscription/ClauseBeneficiary";
import SignatureElectronique from "@/views/Souscription/SignatureElectronique";
import DateEffetInfosBancaires from "@/views/Souscription/DateEffetInfosBancaires";
import RIA from "@/views/Souscription/RIA";
import DSS from "@/views/Souscription/DSS";
import Validation from "@/views/Souscription/Validation";

const routes = [
  { path: '/', redirect: '/votre-situation' },
  {
    path: '/votre-situation', name: 'Situation', component: Situation, redirect: {
      name: 'VotreSituation'
    }, children: [
      {
        path: 'situation',
        name: 'VotreSituation',
        component: VotreSituation
      },
      {
        path: 'informations',
        name: 'InformationsPro',
        component: InformationsPro
      },
    ]
  },
  {
    path: '/vos-besoins', name: 'Besoins', component: Besoins,
    redirect: {
      name: 'Prevoyance'
    }, children: [
      {
        path: 'prevoyance',
        name: 'Prevoyance',
        component: BesoinPrevoyance
      },
      {
        path: 'couverture-sante-1',
        name: 'CouvertureSante1',
        component: ProtectionSanteBeneficiaire
      },
      {
        path: 'couverture-sante-2',
        name: 'CouvertureSante2',
        component: ProtectionSante
      },
    ]
  },
  {
    path: '/vos-informations', name: 'InfosPersos', component: InfosPerso, redirect: {
      name: 'InformationsPersonnelles'
    }, children: [
      {
        path: 'informations-personnelles',
        name: 'InformationsPersonnelles',
        component: InformationsPersonnelles
      },
      {
        path: 'informations-personnelles-completes',
        name: 'InformationsPersonnellesComplete',
        component: InformationsPersonnellesComplete
      },
    ]
  },
  { path: '/votre-devis', name: 'Devis', component: Devis },
  {
    path: '/souscription/:simCode', name: 'Souscription', component: Souscription, redirect: { name: 'InformationsComplementaires'},
    children: [
      {
        path: 'informations-complementaires', name: 'InformationsComplementaires', redirect: { 'name': 'Eligibilite' }, component: InformationsComplementaires,
        children: [
          { path: 'eligibilite', name: 'Eligibilite', component: Eligibilite },
          { path: 'profil-professionnel', name: 'ProfileProfessionnel', component: ProfileProfessionnel },
          { path: 'dss', name: 'DSS', component: DSS },
          { path: 'elements-a-preparer', name: 'ElementsAPreparer', component: ElementsAPreparer },
          { path: 'attestation-madelin', name: 'AttestationMadelin', component: AttestationMadelin },
          { path: 'informations-personnelles', name: 'InformationPersonnellesSouscription', component: InformationsPersonnellesSouscription },
          { path: 'informations-professionnelles', name: 'InformationsProfessionnelles', component: InformationsProfessionnelles },
          ///souscription/informations-complementaires/informations-professionnelles
        ]
      },
      {
        path: 'date-effet-infos-bancaires',
        name: 'DateEffetInfosBancaires',
        redirect: { 'name': 'DateEffetContrat' },
        component: DateEffetInfosBancaires,
        children: [
          { path: 'date-effet-contrat', name: 'DateEffetContrat', component: DateEffetContrat },
          {
            path: 'ria',
            name: 'RIA',
            component: RIA
          },
          { path: 'informations-bancaires', name: 'InformationsBancaires', component: InformationsBancaires }
        ]
      },

      {
        path: 'signature-electronique',
        name: 'SignatureElectronique',
        component: SignatureElectronique
      },
      {
        path: 'clause-beneficiaire',
        name: 'ClauseBeneficiary',
        component: ClauseBeneficiary
      },
      { path: 'validation-souscription', name: 'ValidationSouscription', component: Validation, },
      { path: 'relecture', name: 'Relecture', component: Relecture },
    ]
  },

  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },

  // {
  //   path: '/',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior () {
    return {top: 0};
  }
});

export default router
