<template>
  <div class="devis">
    <Loading :active="this.isViewLoading"
             :is-full-page="true"/>

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-md-center">
            <h1 v-html="formTexts.devis.title"></h1>
          </div>
          <div class="col-12 mx-auto text-center mb-3">
            <h4 v-html="formTexts.devis.prevoyance_titre.text"></h4>
          </div>
          <div class="col-12 col-md-10 col-lg-6">
            <div class="row justify-content-around">
              <div class="col-12 order-1 order-md-0 col-md-6">
                <div class="row" >
                  <OfferCardGaranteePrice
                    :equals-columns="false"
                    :garantee="viewTexts.prevoyance_indemnite"
                    :price-text="selectedPrevoyance[0].ij + '€/ jour'"
                  />
                  <OfferCardGaranteePrice
                    :equals-columns="false"
                    :garantee="viewTexts.prevoyance_invalidite"
                    :price-text="numberWithSpaces(selectedPrevoyance[0].ij * 360) + '€'"
                  />
                  <OfferCardGaranteePrice
                    :equals-columns="false"
                    :garantee="viewTexts.prevoyance_deces"
                    :price-text="numberWithSpaces(selectedPrevoyance[0].deces) + '€'"
                  />
                </div>
              </div>
              <div class="col-12 order-0 order-md-1 col-md-6" v-if="!this.isViewLoading && !this.tarifError">
                <MonthPrice  :price="getPrevoyancePrice(this.tarification)" :blue-price="true" />
              </div>
            </div>
          </div>

          <div class="col-12">
            <hr />
          </div>

          <div class="col-12 col-lg-10 col-xl-8 structure-tarif mb-5 mt-4 pt-3">
            <div class="row ">
              <div class="col-12">
                <h4 class="text-center mb-4 pb-3" v-html="viewTexts.sante_titre.text"></h4>
              </div>
              <div class="col-12 col-md-6 flex-row d-flex">
                <p class="text-center col pt-1" v-html="viewTexts.sante_structure_tarifaire.text"></p>
                  <span class="col-1 p-1">
                <Popper arrow class="m-0 border-0">
                <img :src="require('@/assets/svg/Icon awesome-info-circle.svg')"
                   data-bs-toggle="tooltip" data-bs-placement="top" :title="viewTexts.sante_structure_tarifaire.tooltip.replace(/(<([^>]+)>)/gi, '')">
                <template #content>
                  <div class="tooltip-content" v-html="viewTexts.sante_structure_tarifaire.tooltip"></div>
                </template>
              </Popper>
          </span>
              </div>
              <div class="col-12 col-md-6">
                <div class="btn-group w-100 cotisation-btn-group" role="group" aria-label="Basic example">
                  <div class="nav nav-tabs mx-auto" id="nav-tab" role="tablist">
                    <button
                      type="button"
                      data-bs-toggle="tab"
                      data-bs-target="#age"
                      role="tab"
                      aria-controls="age"
                      aria-selected="true"
                      class="btn btn-primary age"
                      :class="{ selected: selectedCotisation === 'age' }"
                      @click="selectCotisation('age')"
                    >
                      {{ viewTexts.sante_structure_tarifaire.choices[0].age }}
                    </button>
                    <button
                      type="button"
                      data-bs-toggle="tab"
                      data-bs-target="#famille"
                      role="tab"
                      aria-controls="famille"
                      aria-selected="true"
                      class="btn btn-primary famille"
                      :class="{ selected: selectedCotisation === 'famille' }"
                      @click="selectCotisation('famille')"
                    >
                      {{ viewTexts.sante_structure_tarifaire.choices[1].famille }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid pb-5 mb-3">
        <div class="row justify-content-center">
          <div class="col-12 col-xl-10 col-xl-8">
            <div class="tab-content" id="myTabContent">
              <div :class="{'show active': selectedCotisation == 'age'}"  class="tab-pane fade" id="age" role="tabpanel" aria-labelledby="age-tab">
                <OffersSanteCardsDeck
                  ref="age"
                  :devis-deck="true"
                  cotisation-structure="age"
                  @update:modelValue="itemSelected"
                />
              </div>
              <div  :class="{'show active': selectedCotisation == 'famille'}" class="tab-pane fade" id="famille" role="tabpanel" aria-labelledby="famille-tab">
                <OffersSanteCardsDeck
                  ref="famille"
                  :devis-deck="true"
                  cotisation-structure="famille"
                  @update:modelValue="itemSelected"
                />
              </div>
            </div>
          </div>
        </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">
          <a class="default-link" @click="downloadIpid()" :href="getPdfUrl()" target="_blank">
            {{ formTexts.offre_garanties.pdf_tab_garantees.text }}
          </a>
          </div>
        </div>
      </div>

      <div class="container-fluid py-4 pt-5 pb-5 recap-container">
        <div class="row">
          <div class="col-12 col-md-8 col-lg-7 mx-auto">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <h4 v-html="viewTexts.recap_offre_titre.text"></h4>
              </div>
              <div class="col-lg-12">
                <div class="row justify-content-center">
                  <div class="col-10" v-if="!this.isViewLoading && !this.tarifError">
                    <PriceResume
                      :recap="true"
                      :total="getTotalTarif(this.tarification)"
                      :prevoyance="getPrevoyancePrice(this.tarification)"
                      :sante="totalSante(this.tarification)"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row justify-content-center">
                  <div v-show="this.tarification" class="col-10 py-4">
                    <DevisButton :loading="isViewLoading" />
                  </div>
                </div>
              </div>
              <div class="col-12 text-center">
                <a class="custom-link" @click="goToPrevoyance()">
                  <img :src="require('@/assets/svg/Icon feather-arrow-left.svg')" />
                  {{ viewTexts.modifier_simulation.text }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-10 col-md-12 text-white callback-row">
            <div class="row justify-content-center align-items-center py-4">
              <div class="col-12 col-md-5 text-center text-md-start">
                <span class="pe-2">
                  <img :src="require('@/assets/svg/arrow-title-white.svg')" />
                </span>
                <span class="callBack">
                  {{ viewTexts.callback_titre.text }}
                </span>
              </div>
              <div class="col-12 col-md-5 text-end py-3">
                <a
                  @mouseover="showHover = true"
                  @mouseleave="showHover = false"
                  class="btn btn-outline-light border border-3 rounded-pill px-4 py-2 callBackText"
                  @click="contactConseiller()"
                >
                  <img
                    v-show="!showHover"
                    class="pe-3 callBackImg"
                    :src="require('@/assets/svg/icon-callback.svg')"
                  />
                  <img
                    v-show="showHover"
                    class="pe-3 callBackImg"
                    :src="require('@/assets/svg/icon-callback-hover.svg')"
                  />
                  Rappel gratuit par un conseiller
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterNavigation />

      <Encart :component-texts="viewTexts.blocs_texte" />

      <div class="pb-5 mb-5"></div>
      <nav class="navbar fixed-bottom navbar-light bg-light">
        <div class="container d-block">
          <div class="row justify-content-between">
            <div class="col-12 col-md-5" v-if="!this.isViewLoading && !this.tarifError">
              <PriceResume

                :total="getTotalTarif(this.tarification)"
                :prevoyance="getPrevoyancePrice(this.tarification)"
                :sante="totalSante(this.tarification)"
              />
            </div>
            <div v-show="this.tarification" class="col-12 col-md-6 pt-2 pt-md-5">
              <DevisButton :loading="isViewLoading"/>
            </div>
          </div>
        </div>
      </nav>
  </div>

  <ModalSendMail id="modalSendedMail" ref="modalSendMail" :confirmAction="true"/>

<Modal
    id="errorApiModal"
    ref="errorApiModal"
    title="Impossible de souscrire en ligne pour le moment"
    content-text=""
    :direct-show="!this.isApiContactAllRight"
    :confirmAction="false"
    />

</template>

<script>
import { mapState } from "vuex";
import DevisButton from "@/components/Devis/DevisButton";
import PriceResume from "@/components/Devis/PriceResume";
import OfferCardGaranteePrice from "@/components/Offers/OfferCardGaranteePrice";
import MonthPrice from "@/components/Devis/MonthPrice";
import OffersSanteCardsDeck from "@/components/Offers/OffersSanteCardsDeck";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Modal from "@/components/Navigation/Modal";
import ModalSendMail from "@/components/Navigation/ModalSendMail";
import {getTarifData} from "@/services/tarifService";
import {getTarifOR} from "@/services/contactAPI/contactORService";
import {getInterfactionTarif} from "@/services/contactAPI/interactionService";
import {buildPPData} from "@/services/contactAPI/contactCallsBuilder";
import Loading from "vue-loading-overlay";
import templateHelpers from "@/services/templateHelpers";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
// import ModalContact from "@/components/Navigation/ModalContact";
import Popper from "vue3-popper";
import { reloadEvent } from '../../services/tagCo';
import wrapper from '../../main';


export default {
  name: "Devis",
  components: {
    MonthPrice,
    DevisButton,
    PriceResume,
    OfferCardGaranteePrice,
    OffersSanteCardsDeck,
    FooterNavigation,
    Modal,
    Loading,
    Encart,
    Popper,
    ModalSendMail
  },
  data: function () {
    return {
      selectedSalary: 1,
      viewTexts: Object,
      selectedOfferModel: null,
      selectedCotisation: "age",
      selectedPrevoyance: null,
      showHover: false,
      baseUrl: process.env.VUE_APP_API_URL,
    };
  },
  created() {
    this.$store.state.avancement[4] = true;
    this.$parent.$parent.$parent.hasSticky = true;

    if(this.formPmPp.beneficiaries.children.length > 2 || this.formPmPp.beneficiaries.hasPartner === true && this.formPmPp.beneficiaries.children.length > 1){
      this.selectedCotisation = 'famille';
      this.$store.state.cotisationSelected = 'famille';
    }

    // reloadTCContainer("resultat", "tarification");
    
    wrapper.setTcVars({
            page_name : 'resultat',
            page_cat1_name : 'tarification',
            page_cat2_name : '',
          });

    this.$store.commit("resetProductsTarif");

    this.selectedOfferModel = this.selectedOfferSante;

    this.selectedPrevoyance = this.prevoyanceProducts.products.filter((p) => {
      return p.name === this.selectedOfferPrevoyance;
    });

    this.$store.commit("setSelectedCotisation", this.selectedCotisation);

    this.$store.dispatch("getTarif", getTarifData(this.$store.state, this.selectedOfferSante, this.selectedCotisation));
    this.viewTexts = this.formTexts.devis;
    reloadEvent('page');
  },
  mounted(){
    
    this.initSwiper(null, 3);

    
  },
  unmounted() {
    this.$parent.$parent.$parent.hasSticky = false;
  },

  methods: {
    ...templateHelpers,
    itemSelected(index) {
      this.selectedOfferModel = index;
    },
    getPdfUrl() {
      return 'https://' + window.location.hostname + '/sites/default/files/pdfs_mpe/IPID-Ma-Protection-Entrepreneur.pdf'
    },
    selectCotisation(val) {
      // pushTag($event, 'clic_selectionner-'+val, "action", "contenu");
      let event = {
        click_name: 'clic_selectionner-'+val,
        click_type: 'action',
        composant: 'contenu'
      };
      reloadEvent('click', event)
      this.selectedCotisation = val;
      this.$store.state.cotisationSelected = val;
      this.$store.dispatch("getTarif", getTarifData(this.$store.state, this.selectedOfferSante, this.selectedCotisation));
    },
    goToPrevoyance() {
      // pushTag($event, "clic_modifier-simulation", "navigation", "contenu");
      let event = {
        click_name: "clic_modifier-simulation",
        click_type: 'navigation',
        composant: 'contenu'
      };
      reloadEvent('click', event)
      this.$router.push({ name: "Prevoyance" });
    },
    contactConseiller() {
      // pushTag($event ,"clic_contacter-un-conseiller", "action", "sticky");
      let event = {
        click_name: "clic_contacter-un-conseiller",
        click_type: 'action',
        composant: 'sticky'
      };
      reloadEvent('click', event)
      this.$refs.modalContact.showModal();
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    downloadIpid(){
      let event = {
        click_name: "clic_telecharger-ipid",
        click_type: 'download',
        composant: 'contenu'
      };
      reloadEvent('click', event)
    },
  },
  computed: {
    ...mapState([
      `isApiContactAllRight`, `formTexts`, `formPmPp`,
      `selectedOfferSante`, `selectedOfferPrevoyance`,
      `prevoyanceProducts`, `tarification`, `productsTarif`,
      `uuid`, `isViewLoading`, `tarifError`
    ]),
  },
  watch: {
    tarification() {
      let apiContactData = buildPPData(this.$store.state, getInterfactionTarif(this.$store.state), getTarifOR(this.$store.state));
      // reloadTCContainer('confirmation-devis', 'tarification')
      this.$store.dispatch('createContact', apiContactData)
      this.$store.dispatch('createParcoursSoumissions')
    },
    // tarifError(){
    //   this.$refs.errorTarif.showModal()
    // }
  }
};
</script>

<style scoped></style>
