<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <TextInput
        ref="textInput"
        :default-value="defaultValue"
        :valid-input="validName"
        :valid-format="true"
        :format-error="componentTexts.formatError"
        :submitted="submit"
        input-name="adresse"
        :question="componentTexts"
        :disabled="disabled"
        @update:textInput="setText"
      ></TextInput>
    </div>
  </div>
</template>

<script>
import TextInput from "hm-mpe-widgets/src/lib-components/components/Widgets/TextInput";
import { validateRequiredInput } from "@/services/inputValidator";

export default {
  name: "Address",
  components: { TextInput },
  props: {
    componentTexts: null,
    defaultValue: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      inputValue: null,
      validName: false,
      submit: false,
    };
  },
  created() {
    this.inputValue = this.defaultValue;
  },
  methods: {
    setText(value) {
      this.inputValue = value;
    },
    forceText(value) {
      this.$refs.textInput.forceText(value);
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      let validator = validateRequiredInput(this.inputValue, this.validName);
      this.validName = validator.validName;

      return this.validName;
    },
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
};
</script>

<style scoped></style>
