
export function getInterfactionTarif(state){
    return {
        "statut": "Fait",
        "codeAction": state.contact.codeAction ? state.contact.codeAction : 'code_action',
        "canal":"Web",
        "evtDeclencheur": "Vu sur internet",
        "objet": "Affichage tarif",
        "garantie": "Ma Protection Entrepreneur",
        "observation": state.selectedOfferSante + ' / ' + state.selectedOfferPrevoyance
    };
}

export function getDevisInteraction(state){
    return getInteraction("Devis envoyé", state)
}

export function getSouscriptionInteraction(state){
    return getInteraction("Initialisation souscription", state)
}

export function getInfoProInteraction(state){
    return getInteraction("Informations pro complétées", state)
}

export function getRIAInteraction(state){
    return getInteraction( "Décrochage RIA", state)
}

export function getSignatureInteraction(state){
    return getInteraction("Attente Signature électronique", state)
}

export function getValidationInteraction(state){
    return getInteraction("Souscription enregistrée", state)
}

function getInteraction(objetInterraction, state){
    return {
        "objet": objetInterraction,
        "codeAction": state.contact.codeAction ? state.contact.codeAction : 'code_action',
        "evtDeclencheur": "Vu sur internet",
    }
}
