<template>
  <div class="container" v-if="!this.isViewLoading">
    <div class="row justify-content-center">
      <div class="col-11 col-md-8" v-if="true">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1 v-html="title"></h1>
                <p v-html="subTitle"></p>
                <DateInput
                  ref="dateInput"
                  :default-value="defaultValue"
                  :submitted="submit"
                  :component-texts="questions.dateEffet"
                  :min-date="currentDate"
                  :max-date="maxDate"
                />
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div v-else class="spinner-border" role="status"></div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import DateInput from "hm-mpe-widgets/src/lib-components/components/Widgets/DateInput.vue";
import moment from "moment";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "DateEffetContrat",
  components: {
    FooterNavigation,
    Encart,
    DateInput,
  },
  data: function () {
    return {
      viewTexts: null,
      title: null,
      subTitle: null,
      dateInput: null,
      optCotiInput: null,
      questions: null,
      submit: false,
      currentDate: null,
      maxDate: null,
      defaultValue: '',
    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    wrapper.setTcVars({
      page_name : 'date-effet',
      page_cat1_name : 'souscription',
    });
    this.currentDate = moment(new Date()).format("yyyy-MM-DD");
    this.maxDate = moment(this.currentDate).add(4, "M").format("yyyy-MM-DD");

    if (this.souscription.date_effet_contrat !== '') {
      this.defaultValue = this.souscription.date_effet_contrat
    } else {
      this.defaultValue = this.currentDate
    }
    this.viewTexts = this.formTexts.date_effet_contrat_souscription;
    this.title = this.viewTexts.title;

    this.subTitle = this.viewTexts.date_effet_sous_titre.text;
    this.questions = {
      dateEffet: this.viewTexts.date_effet,
     /* optCoti: {
        text: this.viewTexts.optin_cotisations.text,
        choices: [{ oui: "" }],
      },*/
    };
    this.dateInput = this.$store.state.souscription.date_effet_contrat.date_effet;
    if(this.dateInput === "" || this.dateInput === false){
      this.dateInput = this.currentDate;
    }
    reloadEvent('page');
  },
  methods: {
    validateField() {
      this.dateInput = this.$refs.dateInput.submitField()
      return this.dateInput !== ""
    },
    submitForm() {
      this.submit = true;
      if (this.validateField()) {
        this.$store.commit('setDateEffet', this.$refs.dateInput.submitField());
        this.$store.dispatch('updateState')
        this.nextRoute();
      }
    },
    nextRoute() {
      this.$router.push({ name: "RIA" });
    },
  },
  computed: mapState([`formTexts`, `isViewLoading`, `souscription`]),
};
</script>

<style scoped></style>
