<template>
  <transition name="fade">
    <div>
      <RadioInput
        :submitted="submit"
        :question="componentTexts"
        :disabled="disabled"
        :default-choice="this.inputValue"
        :name="inputName ? inputName : uid"
        @update:modelValue="itemSelected"
      ></RadioInput>
    </div>
  </transition>
</template>

<script>
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";
import { uniqId } from "@/services/utils.js";

export default {
  name: `Civility`,
  components: { RadioInput },
  props: {
    componentTexts: null,
    defaultValue: null,
    inputName: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      uid: uniqId(),
      inputValue: null,
      validName: false,
      submit: false,
    };
  },
  created() {
    this.inputValue = this.defaultValue;
  },
  methods: {
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      return this.inputValue && this.inputValue.length != 0;
    },
    itemSelected(itemKey) {
      this.validName = true;
      this.inputValue = itemKey;
    },
  },
};
</script>

<style scoped></style>
