<template>
    <div class="row justify-content-center align-items-center">
        <div class="col-12">
            <div class="mb-5">
                <div class="d-flex justify-content-center mb-3">
                    <img src="@/assets/svg/succes-icon-48.svg" alt="succes-icon"/>
                </div>
                <div v-if="callBackNow === true">
                    <p class="text-center mb-0">Votre demande a bien été enregistrée.</p>
                    <p class="text-center">Un conseiller vous rappelle.</p>
                </div>
                <div v-else>
                    <p class="text-center mb-0">Votre demande a bien été enregistrée.</p>
                    <p class="text-center" v-html="this.callBackText"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import wrapper from '@/main';
import { reloadEvent } from '@/services/tagCo';
import { getMonth, getDay } from '@/services/utils';

export default {
    name: "SuccessOdigo",
    components: {},
    data: function() {
        return {
            date: null,
            hour: null,
            callBackNow: null,
            callBackText: '',
        }
    },
    created() {
        if(this.callBack.slot === "0h") {
            this.callBackNow = true
            wrapper.setTcVars({
                page_name : 'rappel-immediat',
                page_cat1_name : 'entrepreneurs',
                page_cat2_name : '',
            });
            reloadEvent('page');
        } else {
            let endCall;
            let day = getDay(this.callBack.date.getDay());
            let date = this.callBack.date.getDate().toString();
            let month = getMonth(this.callBack.date.getMonth());
            let year = this.callBack.date.getFullYear().toString();
            let callBackDate = day + ' ' + date + ' ' + month + ' ' + year;
            if(this.callBack.slot === "9h") {
                endCall = 10
            } else {
                endCall = parseInt(this.callBack.slot.substr(0,2)) + 1
            }
            this.callBackText = 'Un conseiller vous contactera le ' + callBackDate + ' entre ' + this.callBack.slot + ' et ' + endCall + 'h.'
            wrapper.setTcVars({
                page_name : 'rappel-differe',
                page_cat1_name : 'entrepreneurs',
                page_cat2_name : '',
            });
            reloadEvent('page');
        }
    },
    computed: mapState([`formTexts`, `callBack`]),
}
</script>
