<template>
  <div class="container" v-if="!this.isViewLoading">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-xl-8" v-if="true">
        <div class="row mb-5">
          <div class="col-12">
            <div>
              <h1 v-html="viewTexts.title"></h1>
              <Siret
                  ref="siret"
                  :input-group="true"
                  :question-text="
                  this.formTexts.information_entrepreneur.siret
                "
                  :default-value="this.formPmPp.siret"
                  :idProcess="idProcess"
                  :defaultWaitingSiret="this.defaultWaitingSiret"
                  @update:cantSub="showModal"
                  @siretInput="getSiretInfo($event)"
                  @waitingSiret="switchWaitingSiret"
                  />
              <RaisonSociale
                  :componentTexts="this.viewTexts.raison_sociale"
                  :default-value="raisonSociale"
                  ref="raisonSociale"
                  :disabled="disabledInputs"
                  :submitted="submit"
              />
              <FormeJuridique
                  :componentTexts="this.viewTexts.forme_juridique"
                  :default-value="this.formPmPp.formJuridique"
                  ref="formJuridique"
                  :submitted="submit"
                  class="pb-3"
              />
              <CodeNaf
                  :component-texts="this.viewTexts.code_naf"
                  :default-value="codeNaf"
                  ref="codeNaf"
                  :disabled="disabledInputs"
                  :submitted="submit"
              />
              <NbEmployees
                  :question-text="viewTexts.nb_employes"
                  :no-mandatory="true"
                  :optionalText="formTexts.optional.optional.text"
                  :default-value="employee"
                  @update:modelValue="setEmployee"
                  ref="nbEmployees"
              />
              <Address 
              class="pt-4"
                  :component-texts="this.viewTexts.adresse_siege"
                  :default-value="this.formPmPp.addressSiege"
                  :submitted="submit"
                  :disabled="disabledInputs"
                  ref="address"
              />
              <ZipCode
                  :component-texts="this.viewTexts.code_postal_siege"
                  :default-value="zipCode"
                  :submitted="submit"
                  @updateZipCode="completeCity"
                  :disabled="disabledInputs"
                  ref="zipCode"
              />
              <City
                  :component-texts="this.viewTexts.ville_siege"
                  :default-value="city"
                  :submitted="submit"
                  :cities="cities"
                  :disabled="disabledInputs"
                  ref="city"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="spinner-border" role="status"></div>
      <ModalContact
        id="ineligibleModal"
        ref="ineligibleModal"
        :html-title="this.formTexts.modals.profil_aide_conseiller.text"
        content-text=""
        :confirmAction="false"
      />

      <ModalContact
        id="eli"
        ref="eli"
        title="Votre profil nécessite une prise en charge par l’un de nos conseillers experts"
        content-text=""
        :confirmAction="true"
        :confirmRetour="false"
        :closable="true"
      />
    </div>
    <FooterNavigation
        :disabled-button-label="disabledSubmitBtnLabel"
        @submitStep="submitForm"
    />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Siret from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/Siret.vue";
import City from "@/components/FormPpPm/City";
import FormeJuridique from "@/components/FormPpPm/FormeJuridique";
import Address from "@/components/FormPpPm/Address";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import ZipCode from "@/components/FormPpPm/ZipCode";
import CodeNaf from "@/components/FormPpPm/CodeNaf";
import NbEmployees from "@/components/FormPpPm/NbEmployees";
import RaisonSociale from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/RaisonSociale";
import { getRaisonSociale } from "@/services/utils";
import {filterCities, getCityFromZipCode} from "@/services/geoApiService";
import ModalContact from "@/components/Navigation/ModalContact";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';


export default {
  name: "InformationsProfessionnelles",
  components: {
    FooterNavigation,
    Siret,
    City,
    FormeJuridique,
    Address,
    Encart,
    ZipCode,
    NbEmployees,
    CodeNaf,
    RaisonSociale,
    ModalContact,
  },
  data: function () {
    return {
      viewTexts: null,
      submit: false,
      cities: [],
      disabledInputs: false,
      siret: "",
      formJuridique: "",
      employee: null,
      city: "",
      disabledSubmitBtnLabel: null,
      address: "",
      zipCode: "",
      raisonSociale: "",
      codeNaf: "",
      idProcess: "",
      defaultWaitingSiret: false,
      addresses: [],
      dataAddress: [],
      lastDataAddress: [],
      selectedAddress: null,

    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    //this.address = this.formPmPp.address
    wrapper.setTcVars({
      page_name : 'informations-entreprise',
      page_cat1_name : 'souscription',
    });
    this.idProcess=this.$store.state.contact.idProcess;
    this.viewTexts = this.formTexts.informations_pro_souscription;
    this.employee = this.formPmPp.nbEmployees;
    if(this.formPmPp.waitingSiret){
      this.defaultWaitingSiret = true;
    }else{
      this.defaultWaitingSiret = false;
    }
    reloadEvent('page');
  },
  mounted() {
   
    this.completeFields(this.etablissement);
    
  },
  methods: {
    async getSiretInfo(siret) {
      this.$store.commit('setDisableNext', false)
      await this.$store.dispatch('getSiretInfo', siret)
      if(this.siretInfo) {
        this.completeFields(this.etablissement)
      }
    },
    validateField() {
      return true;
    },
    setEmployee(employee){
      this.employee = employee;
      this.formPmPp.nbEmployees = this.employee;
    },
    
    submitForm() {
      this.submit = true;

      let siret = this.$refs.siret.submitField();
     // let siretVerif = this.$refs.siret.validateField();
      let raisonSociale = this.$refs.raisonSociale.submitField();
      let formJuridique = this.$refs.formJuridique.submitField();
      let codeNaf = this.$refs.codeNaf.submitField();
      let zipCode = this.$refs.zipCode.submitField();
      let city = this.$refs.city.submitField();
      let address = this.$refs.address.submitField();
      let nbEmployees = this.$refs.nbEmployees.submitField();


      if (raisonSociale !== "" && zipCode !== "" && formJuridique !== "" && city !== "" && codeNaf !== "" && address !== "" && (siret !== "" || this.defaultWaitingSiret) && nbEmployees !== "") {
        this.formPmPp.citySiege = city
        this.formPmPp.zipCodeSiege = zipCode
        this.formPmPp.addressSiege = address;
        this.formPmPp.siret = this.$refs.siret.submitField();
        this.formPmPp.raisonSociale = raisonSociale;
        this.formPmPp.codeNaf = codeNaf
        this.formPmPp.formJuridique = formJuridique;
        this.formPmPp.nbEmployees = this.employee;
        // this.$store.state.avancementSouscription[1] = true;

        this.$store.commit('setEtablissement', this.etablissement);
        this.$store.dispatch('updateState');
        this.nextRoute();
      }
    },
    showModal(){
      this.$store.dispatch('closeOrOpenModal', {
        modalName: 'ModalWcb',
        action: true
      })
    },
    async completeFields(etablissement) {

      this.$store.state.etablissement = etablissement
      if (etablissement && (etablissement.siret !== this.formPmPp.siret || !this.formPmPp.raisonSociale)) {
        this.siret = etablissement.siret
        this.formPmPp.siret = this.siret

        // await this.$store.dispatch('souscrireEligibilitePM');
        if(this.$store.state.souscrire.souscrireEligibilitePM === false) {
          wrapper.setTcVars({
            page_name : 'eligibilite-pm',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        }
        // if(this.$store.state.souscrire.souscrireEligibilitePM){
          this.$refs.raisonSociale.forceText(
            getRaisonSociale(etablissement.uniteLegale)
        );

        this.$refs.codeNaf.setText(
            etablissement.uniteLegale.activitePrincipaleUniteLegale
        );
        this.$refs.zipCode.forceText(
            etablissement.adresseEtablissement.codePostalEtablissement
        );
        this.city = etablissement.adresseEtablissement.libelleCommuneEtablissement
        
          if (etablissement.adresseEtablissement.numeroVoieEtablissement &&
              etablissement.adresseEtablissement.libelleVoieEtablissement) {
            this.$refs.address.forceText(
                etablissement.adresseEtablissement.numeroVoieEtablissement +
                " " +
                etablissement.adresseEtablissement.libelleVoieEtablissement
            );

          if(etablissement.uniteLegale.trancheEffectifsUniteLegale != 'NN'){
            this.$refs.nbEmployees.forceText(etablissement.uniteLegale.trancheEffectifsUniteLegale);
            this.setEmployee(etablissement.uniteLegale.trancheEffectifsUniteLegale);
          }
          
          this.disabledSubmitBtnLabel = null;
          this.formPmPp.autiCompleteFields = true;
          
            // if (etablissement.adresseEtablissement.codePostalEtablissement !== "") {
            //   this.completeCity(this.etablissement.adresseEtablissement.codePostalEtablissement);
            // }
            this.disabledSubmitBtnLabel = null;
            this.formPmPp.autiCompleteFields = true;

          }
        
        // }else{
        //   this.$refs.eli.showModal();
       
        // }
      }else{

        this.disabledInputs = false;
        this.siret = this.formPmPp.siret
        this.codeNaf = this.formPmPp.codeNaf
        this.raisonSociale = this.formPmPp.raisonSociale
        this.address = this.formPmPp.addressSiege
        this.employee = this.formPmPp.nbEmployees
        this.zipCode = this.formPmPp.zipCodeSiege
        this.city = this.formPmPp.citySiege
        this.formJuridique = this.formPmPp.formJuridique;
        this.employee = this.formPmPp.nbEmployees;

        this.$refs.raisonSociale.forceText(this.raisonSociale);
        this.$refs.codeNaf.setText(this.codeNaf);
        this.$refs.zipCode.forceText(this.zipCode);
        this.$refs.city.setText(this.city);
        this.$refs.address.forceText(this.address);
        this.$refs.formJuridique.forceSelect(this.formJuridique)
        this.$refs.nbEmployees.setText(this.employee)
        this.formPmPp.autiCompleteFields = false;
      }
    },
    switchWaitingSiret(value) {
      if (value){
        this.siret = ""
        this.$refs.siret.setText("");
        this.formPmPp.waitingSiret = true
        this.defaultWaitingSiret = true;
      }else{
        this.formPmPp.waitingSiret = false
        this.defaultWaitingSiret = false;
      }
    },
    nextRoute() {
      this.$router.push({ name: "AttestationMadelin" });
    },
    getCurrentCity(cities) {
      let res = false;
      cities.forEach((e) => {
        if (
            this.etablissement.adresseEtablissement.libelleCommuneEtablissement.length != 0 &&
            this.etablissement.adresseEtablissement.libelleCommuneEtablissement == e.toUpperCase()
        ) {
          res = e.toUpperCase();
        }
      });
      if (!res) {
        return cities[0];
      } else {
        return res;
      }
    },
    completeCity(zipCode) {
      if(zipCode !== "") {

        getCityFromZipCode(zipCode).then((r) => {
          this.cities = filterCities(r.data);
          let city =
          this.cities.length > 1
          ? this.getCurrentCity(this.cities)
          : this.cities[0];
          this.$refs.city.setText(city);
        });
      }
    },
  },
  computed: mapState([`formTexts`, `formPmPp`, `etablissement`, `isViewLoading`, `stateLoaded`, `etablissementSiren`, `siretInfo`])
};
</script>

<style scoped></style>
