<template>
  <div class="col-12 my-2 garantee-price">
    <div class="align-items-center " :class="equalsColumns ? 'row' : 'flex-row d-flex justify-content-between'">
      <div class="text-start " :class="equalsColumns ? 'col' : 'me-3'">
        {{ garantee.text }}
      </div>
      <div class="" :class="equalsColumns ? 'col-sm col-lg' : 'align-self-center'" v-if="tooltip">
        <span>
          <Popper arrow class="m-0 border-0">
            <img :src="require('@/assets/svg/Icon awesome-info-circle.svg')"
                 data-bs-toggle="tooltip" data-bs-placement="top" :title="garantee.tooltip.replace(/(<([^>]+)>)/gi, '')">
            <template #content>
              <div class="tooltip-content" v-html="garantee.tooltip"></div>
            </template>
          </Popper>
        </span>
      </div>
      
      <div class="text-end col-auto ps-md-3" :class="equalsColumns ? 'col-sm col-lg' : 'ms-auto'"><strong>{{ priceText }}</strong></div>
    </div>
  </div>

</template>

<script>
import Popper from "vue3-popper";

export default {
  name: "OfferCardGaranteePrice",
  components: {Popper},
  props: {
    garantee : Object,
    priceText : String,
    equalsColumns : {
      type : Boolean,
      default: true
    },
    tooltip: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
