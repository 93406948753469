
import moment from "moment";

export function keepItemsFromArray(arrayToFilter, itemsToKeep) {
	return arrayToFilter.filter(function (obj) {
		return Object.getOwnPropertyNames(obj).some(function (r) {
			return itemsToKeep.includes(r)
		});
	});
}

export function removeItemsFromArray(arrayToFilter, itemsToRemove) {
	return arrayToFilter.filter(function (obj) {
		return Object.getOwnPropertyNames(obj).some(function (r) {
			return !itemsToRemove.includes(r)
		});
	});
}

export function getObjectLabelOfKey(choices, choiceProperty) {
	return choices.find(function (c) {
		if (Object.getOwnPropertyNames(c).includes(choiceProperty)) {
			return c;
		}
	})
}

export function isAgeValid(dateInput, age, lessThan = true) {
	var currentDate = moment(new Date()).format("yyyy-MM-DD");
	var inputed = moment(dateInput).format("yyyy-MM-DD");
	var res;
	if (lessThan) {
		res = moment(currentDate).subtract(age, "years") < moment(inputed);
	} else {
		res = moment(currentDate).subtract(age, "years") > moment(inputed);
	}
	return res;
}

export function uniqId() {
	const sec = Date.now() * 1000 + Math.random() * 1000;
	const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
	return `${id}${`${Math.trunc(Math.random() * 100000000)}`}`;
}

export function getWCBLink(formTextWcbLink, codeAction) {
	if (codeAction !== 'WEB_PRO_SEO_NA') {
		formTextWcbLink = formTextWcbLink + '?ca=' + codeAction
	}
	return formTextWcbLink
}

export function getNbEmployeesFromCode(code) {
	if (code === '00') return '0 salarié'
	if (code === '01') return '1 à 2 salariés'
	if (code === '02') return '3 à 5 salariés'
	if (code === '03') return '6 à 9 salariés'
	if (code === '11') return '10 à 19 salariés'
	if (code === '12') return '20 à 49 salariés'
	if (code === '21') return '50 à 99 salariés'
	if (code === '22') return '100 à 199 salariés'
	if (code === '31') return '200 à 249 salariés'
	if (code === '32') return '250 à 499 salariés'
	if (code === '41') return '500 à 999 salariés'
	if (code === '42') return '1000 à 1 999 salariés'
	if (code === '51') return '2000 à 4 999 salariés'
	if (code === '52') return '5000 salariés & +'
	//if (code === '53') return '10 000 salariés et plus'
}

export function getCodeFromTrancheEmployees(tranche) {
	if (tranche === '0 salarié') return '00'
	if (tranche === '1 à 2 salariés') return '01'
	if (tranche === '3 à 5 salariés') return '02'
	if (tranche === '6 à 9 salariés') return '03'
	if (tranche === '10 à 19 salariés') return '11'
	if (tranche === '20 à 49 salariés') return '12'
	if (tranche === '50 à 99 salariés') return '21'
	if (tranche === '100 à 199 salariés') return '22'
	if (tranche === '200 à 249 salariés') return '31'
	if (tranche === '250 à 499 salariés') return '32'
	if (tranche === '500 à 999 salariés') return '41'
	if (tranche === '1000 à 1 999 salariés') return '42'
	if (tranche === '2000 à 4 999 salariés') return '51'
	if (tranche === '5000 salariés & +') {

		return '52'
		/*if (tranche === '10 000 salariés et plus'){
			return '53'*/
	} else {
		return '00'
	}
}

export function getCodeFromNbEmployees(nbEmployees) {
	if (nbEmployees === 0) return "00"
	if (nbEmployees === 1 || nbEmployees === 2) return '01'
	if (nbEmployees >= 3 && nbEmployees <= 5) return '02'
	if (nbEmployees >= 6 && nbEmployees <= 9) return '03'
	if (nbEmployees >= 10 && nbEmployees <= 19) return '11'
	if (nbEmployees >= 20 && nbEmployees <= 49) return '12'
	if (nbEmployees >= 50 && nbEmployees <= 99) return '21'
	if (nbEmployees >= 100 && nbEmployees <= 199) return '22'
	if (nbEmployees >= 200 && nbEmployees <= 249) return '31'
	if (nbEmployees >= 250 && nbEmployees <= 499) return '32'
	if (nbEmployees >= 500 && nbEmployees <= 999) return '41'
	if (nbEmployees >= 1000 && nbEmployees <= 1999) return '42'
	if (nbEmployees >= 2000 && nbEmployees <= 4999) return '51'
	if (nbEmployees >= 5000) {
		return '52'
		/*if (nbEmployees >= 10000) {
			return '53'*/
	} else {
		return '00'
	}
}

export function getRaisonSociale(uniteLegale) {
	if (typeof uniteLegale === 'string' || uniteLegale instanceof String) return uniteLegale
	if (uniteLegale.denominationUniteLegale) return uniteLegale.denominationUniteLegale
	if (uniteLegale.denominationUsuelle1UniteLegale) return uniteLegale.denominationUsuelle1UniteLegale
	if (uniteLegale.denominationUsuelle2UniteLegale) return uniteLegale.denominationUsuelle2UniteLegale
	if (uniteLegale.denominationUsuelle3UniteLegale) return uniteLegale.denominationUsuelle3UniteLegale

	let nomLegal = null
	if (uniteLegale.nomUniteLegale) nomLegal = uniteLegale.nomUniteLegale
	if (uniteLegale.nomUsageUniteLegale) nomLegal = uniteLegale.nomUsageUniteLegale

	let prenomLegal = null
	if (uniteLegale.prenom1UniteLegale) prenomLegal = uniteLegale.prenom1UniteLegale
	if (uniteLegale.prenom2UniteLegale) prenomLegal = uniteLegale.prenom2UniteLegale
	if (uniteLegale.prenom3UniteLegale) prenomLegal = uniteLegale.prenom3UniteLegale
	if (uniteLegale.prenom4UniteLegale) prenomLegal = uniteLegale.prenom4UniteLegale
	if (uniteLegale.prenomUsuelUniteLegale) prenomLegal = uniteLegale.prenomUsuelUniteLegale

	if (nomLegal && prenomLegal) return nomLegal + prenomLegal

	//return "Votre entreprise";
}


export function numberWithSpaces(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function getStatusFromKey(statusKey) {
	if (statusKey === 'artisan') {
		return 'Artisan (y compris micro-entrepreneur)'
	} else if (statusKey === 'commercant') {
		return 'Commerçant (y compris micro-entrepreneur)'
	} else {
		return "Conjoint collaborateur d'artisan ou commerçant"
	}
}

export function getGerantFromData(status, gerant){
	if(gerant == "non" || status == "Conjoint collaborateur d'artisan ou commerçant"){
		return "Non"
	}else{
		return "Oui"
	}
}

export function getStatutValue(status, collab){
	switch (status) {
		case "artisan":
			if(collab == "oui"){
				return "Conjoint collaborateur d'artisan ou commerçant";
			}else{
				return "Artisan / Commerçant";
			}		
		case "professionnel_liberal":
			if(collab == "oui"){
				return "Conjoint collaborateur de Professionnel libéral / Freelance";
			}else{
				return "Professionnel libéral / Freelance";
			}	
		case "autre":
			return "autre";
	}
	return status;
}

export function setStatutValue(status){
	switch (status) {
		case "Conjoint collaborateur d'artisan ou commerçant":
		case "Artisan / Commerçant":
			return "artisan";
				
		case  "Conjoint collaborateur de Professionnel libéral / Freelance":
		case  "Professionnel libéral / Freelance":
			return "professionnel_liberal";
	}
	return status
}

export function sleeper(ms) {
	return function (x) {
		return new Promise(resolve => setTimeout(() => resolve(x), ms));
	};
}

export function getMonth(numMonth) {
	let month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
	return month[numMonth];
}

export function getDay(numDay) {
	let day = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
	return day[numDay];
}

export function getUpdateStateData(state){
	console.log(state)
	return {
		'name' : state.formPmPp.lastName,
		'firstname' : state.formPmPp.firstName,
		'parcours_type' : 'mpe',
		'saved_data': state
	};
}