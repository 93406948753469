<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-6 text-centered pt-2">
      <label class="form-question">{{ componentTexts.text }}</label>
    </div>

    <div @click="searchFile" class="col-12 col-md-6 mb-3 ">
      <div
          class=""
          style="border-style: dashed; border-color: #D9D9D9"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
      >
        <input
            type="file"
            id="assetsFieldHandle"
            v-show="false"
            :v-model="file"
            class="bg-secondary w-px h-px opacity-0 overflow-hidden absolute"
            ref="rib"
            accept=".pdf,.jpg,.jpeg,.png,.PDF,.JPG,.JPEG,.PNG"
            @change="handleFileUpload( $event )"
        />
        <div class="uploadIcon pt-5 d-flex justify-content-center">
          <BIconCloudUpload class="pb-2 uploadIconParameter" />
        </div>
        <label class="d-flex justify-content-center">
          <div v-if="this.file ">
            <p>{{ this.file.name }}</p>
          </div>
          <div v-else>
            <p style="color:#A5A5A5">Déposer votre fichier ici (jpg, pdf)</p>
          </div>
        </label>
      </div>
      <div class="col-12 text-start form-error form-error-text">
        <div v-if="validInput==false && submit" class="d-inline">
          Ce champ est obligatoire
        </div>
        <div v-if="validInput && !validFormat && submitted" class="d-inline">
          Fichiers acceptés : PDF, JPG, JPEG, PNG
        </div>
      </div>
    </div>
  </div>
  <Modal
      id="fileFormatError"
      ref="fileFormatError"
      title="Une erreur s'est produite"
      content-text="Le format du fichier est incorrecte"
      :confirmAction="false"
  />
</template>

<script>
import { BIconCloudUpload } from "bootstrap-icons-vue";
import Modal from "@/components/Navigation/Modal";

export default {
  name: "Rib",
  components: {
    BIconCloudUpload, Modal
  },
  props: {
    componentTexts: null,
    submitted: Boolean,
    inputName: null,
  },
  data: function () {
    return {
      inputValue: null,
      validName: false,
      submit: false,
      validFormat: true,
      validInput: true,
      file: null,
      fileData: null,
      fileName: null,
    };
  },
  created() {
    this.submit = this.submitted
  },
  methods: {
    setText(value) {
      this.inputValue = value.toUpperCase();
    },
    searchFile(){
      document.getElementById('assetsFieldHandle').click();
    },
    handleFileUpload( $event ){
      this.file = $event.target.files[0];
      this.validInput=true;

      let that = this;
      var reader = new FileReader();
      reader.onload = (function() {
        return function (e) {
          // that.fileType = that.file.type
          that.fileData = JSON.stringify({
            "nom": that.file.name.toLowerCase(),
            "type": "PJ",
            "modeRemiseEnum": "WEB",
            "data": window.btoa(e.target.result)
          });
        };
      })(this.file);
      reader.readAsBinaryString(this.file)
    },
    validateField() {
      this.validInput = !!this.file
      this.validFormat = ["application/pdf", "image/jpg", "image/jpeg", "image/png", "application/PDF", "image/JPG", "image/JPEG", "image/PNG"].includes(this.file.type) || ["application/pdf", "image/jpg", "image/jpeg", "image/png", "application/PDF", "image/JPG", "image/JPEG", "image/PNG"].includes(this.file[0].type)
      return this.validInput && this.validFormat
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-success")) {
        event.currentTarget.classList.add("bg-success");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove("bg-success");
    },
    drop(event) {
      event.currentTarget.classList.remove("bg-success");
      event.preventDefault();
      this.$refs.rib.files = event.dataTransfer.files;
      this.file = event.dataTransfer.files;
      this.handleFileUpload(event[0]);
    },
    submitField() {
      this.submit = true;
      if (!this.validateField()) {
        return false;
      }else{
        this.$store.state.souscription.information_bancaire.fileData = this.fileData
        return true;
      }
    },
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
};
</script>
