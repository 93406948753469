import { getPrevoyancePrice, totalSante } from './templateHelpers';

export function getPreSignatureData(state){

    let civility = state.formPmPp.civility === "mr" ? "M" : "Mme"

    let tarifSante = totalSante(state.tarification)
    let tarifPrevoyance = getPrevoyancePrice(state.tarification)

    let baDoc = state.souscrire.documents.find((d) => d.type === "BA_GENERE")

    return {
        'ba_link' : window.location.protocol + '//'+ window.location.host  + '/hm-web-services/telecharger-doc-prop/' + baDoc.id,
        'ba_name' : state.souscrire.baDoc.nom,
        'lien_reprise': window.location.protocol + '//'+ window.location.host  + '/souscription/' + state.uuid,
        'civility': civility,
        'lastName': state.formPmPp.lastName,
        'wcb_link': "https://rappel.harmonie-mutuelle.fr/entrepreneur",
        'price': state.tarification.offre.tarification.cotisationMensuelleGlobale,
        'tarifSante': tarifSante,
        'tarifPrevoyance': tarifPrevoyance,
        'email': state.formPmPp.email,
        'idHmDrive': state.documentsHMDrive,
        'code_sante': state.tarification.offre.beneficiaires[0].produits[0].code,
        'code_prevoyance': state.tarification.offre.beneficiaires[0].produits[1].code,
        'mailType': 'pre-signature'
    }
}


export function getPostSignatureData(state){
    const dateEffetSplit = state.souscription.date_effet_contrat.split('-');

    let civility = state.formPmPp.civility === "mr" ? "M" : "Mme"

    let tarifSante = totalSante(state.tarification)
    let tarifPrevoyance = getPrevoyancePrice(state.tarification)

    let baDoc = state.souscrire.documents.find((d) => d.type === "BA_GENERE")
    
    return {
        'ba_link' : window.location.protocol + '//'+ window.location.host  + '/hm-web-services/telecharger-doc-prop/' + baDoc.id,
        'ba_name' : state.souscrire.ba_signe.nom,
        'lien_reprise': window.location.protocol + '//'+ window.location.host  + '/souscription/' + state.uuid,
        'date_effet': dateEffetSplit[2]+'/'+dateEffetSplit[1] + '/' + dateEffetSplit[0],
        'civility': civility,
        'lastName': state.formPmPp.lastName,
        'wcb_link': "https://rappel.harmonie-mutuelle.fr/entrepreneur",
        'price': state.tarification.offre.tarification.cotisationMensuelleGlobale,
        'tarifSante': tarifSante,
        'tarifPrevoyance': tarifPrevoyance,
        'email': state.formPmPp.email,
        'idHmDrive': state.documentsHMDrive,
        'code_sante': state.tarification.offre.beneficiaires[0].produits[0].code,
        'code_prevoyance': state.tarification.offre.beneficiaires[0].produits[1].code,
        'mailType': 'post-signature'
    }
}
