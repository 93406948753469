<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="id"
    :ref="id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="id"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-center border-0">
          <h5 class="modal-title w-100 fw-bolder" id="staticBackdropLabel">
            {{ this.title }}
          </h5>
          <button v-if="closable" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="changeOffer" class="row justify-content-center">
            <div class="col-10">
              <p v-html="changeOffer.subtitle"></p>
              <b><div v-html="changeOffer.description"></div></b>
              <div class="row p-3 div-price-container">
                <div class="col-lg-6 col-12 align-self-center">
                  <b>Le tarif correspondant à cette offre est :</b>
                </div>
                <div class="col-lg-6 col-12 align-self-center justify-content-end">
                  <MonthPrice :price="changeOffer.price" :blue-price="true" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row justify-content-center">
            <div class="col-10">
              <p>{{ this.contentText }}</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center modal-footer footer-navigation border-0">
          <button v-if="confirmRetour" type="button" class="btn back-button ms-2" data-bs-dismiss="modal">
            <img :src="require('@/assets/svg/Icon feather-arrow-left.svg')" />
            Fermer
          </button>
          <button
            v-if="confirmAction"
            type="button"
            class="btn btn-info rounded-pill"
            data-bs-dismiss="modal"
            @click="confirm"
          >
            Continuer
            <img :src="require('@/assets/svg/arrow-title-white.svg')" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");
import MonthPrice from "@/components/Devis/MonthPrice";

export default {
  name: "Modal",
  props: {
    id: String,
    title: String,
    contentText: String,
    closable: {
      type: Boolean,
      default: true
    },
    confirmAction: Boolean,
    confirmRetour: {
      type: Boolean,
      default: true
    },
    changeOffer: null,
    directShow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MonthPrice,
  },
  data: function(){
    return {
      myModal: null
    }
  },
  mounted() {
    if (this.directShow){
      this.showModal()
    }
  },
  methods: {
    showModal() {
      this.myModal = new bootstrap.Modal(document.getElementById(this.id), {
        keyboard: false,
      });
      this.myModal.show();
    },
    confirm() {
      this.$emit("confirmAction", true);
    },
  },
};
</script>

<style scoped></style>
