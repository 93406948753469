<template>
    <div>
        <SelectInput
         :valid-input="validName"
         v-model="this.reference"
         :defaultChoice="defaultValue"
         :submitted="submit"
         input-name="regimeObligatoire"
         ref="regimeObligatoire"
         :question="componentTexts"
         @update:modelValue="itemSelected" 
         ></SelectInput>
    </div>
</template>

<script>
import { mapState } from "vuex";
import SelectInput from "hm-mpe-widgets/src/lib-components/components/Widgets/SelectInput";

export default {
    name: "RegimeOnligatoire",
    components: { SelectInput },
    date: function () {
        return {
            validName: false,
            submit: false,
            inputValue: null,
            reference: null,
        };
    },
    props: {
        componentTexts: null,
        regime: null,
        defaultValue: null,
        personne: null,
    },
    created() {
        if(this.personne == "conjoint"){
            this.reference= this.formPmPp.beneficiaries.partner['regimeObligatoire']
        }else{
            this.reference= this.formPmPp.beneficiaries.children[this.personne].regimeObligatoire
        }
        if(this.regime){
            this.validName = true;
        } else {
            this.validName = false;
        }
    },
    methods: {
        submitField() {
            this.submit = true;
            this.validName = true;
            return this.regime;
        },
        itemSelected(itemKey) {
            this.validName = true;
            this.inputValue = itemKey;
            if(this.personne == "conjoint"){
            this.formPmPp.beneficiaries.partner['regimeObligatoire'] = this.reference
        }else{
            this.formPmPp.beneficiaries.children[this.personne].regimeObligatoire = this.reference
        }
            this.$emit("update:modelValue", itemKey);
        },
        forceSelect(itemKey) {
            this.inputValue = itemKey
            this.$refs.regimeObligatoire.forceSelect(itemKey)
        }
    },
 
    computed: mapState([`formTexts`, `formPmPp`, `etablissement`]),
};
</script>

<style scoped>
</style>
