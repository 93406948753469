export default {
	setProfile(state, profil) {
		state.profil = profil;
	},
	setNextRoute(state, name) {
		state.nextRouteName = name;
	},
	setFormTexts(state, data) {
		state.formTexts = data
		state.loadedTexts = true;
	},
	toggleTextLoader(state){
		state.loadedTexts = !state.loadedTexts
	},
	setSelectedSalary(state, data){
		state.selectedSalary = data;
	},
	setSelectedOfferPrevoyance(state, data){
		state.selectedOfferPrevoyance = data;
	},
	setSelectedOfferSante(state, data) {
		state.selectedOfferSante = data;
	},
	setCodeNafList(state, data){
		state.codeNafList = data;
	},
	toggleLoader(state) {
		state.isLoading = !state.isLoading;
	},
	setTarification(state, data){
		state.tarification = data;
	},
	setPrevoyanceProducts(state){
		state.prevoyanceProducts.mpe003 = {
			name: "mpe003",
			ij: state.formTexts.offres_produits.prevoyance_ij_mpe003.text,
			deces: state.formTexts.offres_produits.prevoyance_deces_mpe003.text,
		};

		state.prevoyanceProducts.products = [
			{
				name: "mpe001",
				ij: state.formTexts.offres_produits.prevoyance_ij_mpe001.text,
				deces: state.formTexts.offres_produits.prevoyance_deces_mpe001.text,
			},
			{
				name: "mpe002",
				ij: state.formTexts.offres_produits.prevoyance_ij_mpe002.text,
				deces: state.formTexts.offres_produits.prevoyance_deces_mpe002.text,
			},
			state.prevoyanceProducts.mpe003,
		];
	},
	resetProductsTarif(state){
		state.productsTarif = [];
	},
	addProductTarif(state, data){
		state.productsTarif.push(data);
		if (state.productsTarif.length === 6){
			// state.loadedPrices = true;
		}
	},
	toggleLoadedTarif(state){
		state.loadedTarification = !state.loadedTarification;
	},
	setContactProcess(state, data){
		state.contact.idProcess = data;
	},
	setCodeAction(state, data){
		state.contact.codeAction = data
	},
	setSelectedCotisation(state, data){
		state.cotisationSelected = data
	},
	toggleViewLoading(state){
		state.isViewLoading = !state.isViewLoading
	},
	setModalContact(state, data) {
		state.modalContact = data
	},
	setModalSendMail(state, data) {
		state.modalSendMail = data
	},
	setModalWcb(state, data) {
		state.modalWcb = data
	},
	setOptins(state, data){
		state.optins = data
	},
	setFormPmPpOptins(state, data){
		state.formPmPp.optins = data
	},
	setUuid(state, data){
		state.uuid = data;
	},
	setEtablissement(state, data){
		state.etablissement = data
	},
	setDateEffet(state, data){
		state.souscription.date_effet_contrat = data
	},
	setChildren(state, data){
		state.formPmPp.beneficiaries.children = data;
	},
	resetRattachementChoices(state){
		state.formPmPp.rattachementChoices = []
	},
	removeRattachementChoices(state, num){
		let choices = state.formPmPp.rattachementChoices;
		let index = choices.findIndex(obj => {
			return num in obj
		});
		choices.splice(index, 1);
		state.formPmPp.rattachementChoices = choices;

	},
	addRattachementChoices(state, num){
		let choices = state.formPmPp.rattachementChoices;
		let index = choices.findIndex(obj => {
			return num in obj
		})
		let numSecuSelectOption = {};
		numSecuSelectOption[num] = num
		if (index !== -1){
			choices[index] = numSecuSelectOption
		}else{
			choices.push(numSecuSelectOption)
		}

		state.formPmPp.rattachementChoices = []
		state.formPmPp.rattachementChoices = choices


		let otherIndex = state.formPmPp.rattachementChoices.findIndex(obj => {
			return 'autre' in obj
		});
		if (otherIndex === -1){
			state.formPmPp.rattachementChoices.push({'autre': 'Autre'})
		}else{
			state.formPmPp.rattachementChoices.push(state.formPmPp.rattachementChoices.splice(otherIndex, 1)[0]);

		}
	},
	replaceState(state, data){
		state = Object.assign(state, JSON.parse(data)) // newState is the object from App.vue
		state.simulationLoaded = true
	},
	replaceTarifBeneficiaires(state, benefsList){
		state.tarification.offre.beneficiaires = benefsList
	},
	setTarifError(state, data){
		state.tarifError = data
	},
	setProfilePro(state,data){
		state.souscription.profile_professionel = data
	},
	setDocHm(state, data) {
		state.documentsHMDrive = data
	},
	setDisableNext(state, data) {
		state.disableNext = data
	},
	setEtablissementSiren(state, data) {
		state.etablissementSiren = data
	},
	setSouscrireEligibilitePM(state, data) {
		state.souscrire.souscrireEligibilitePM = data
	},
	setSiretInfo(state, data) {
		state.siretInfo = data
	},
	setOdigoToken(state, data) {
		state.callBack.odigoToken = data
	},
	setOdigoPhone(state, data) {
        state.callBack.phone = data
    },
    setOdigoZipCode(state, data) {
        state.callBack.zipCode = data
    },
    setOdigoDate(state, data) {
        state.callBack.date = data
    },
    setOdigoSlot(state, data) {
        state.callBack.slot = data
    },
	setFailOdigo(state, data) {
		state.callBack.failOdigo = data
	},
	setSuccessOdigo(state, data) {
		state.callBack.successOdigo = data
	},
	setClosableOdigo(state, data) {
		state.callBack.closableOdigo = data
	},
	setTitleWcb(state, data) {
		state.callBack.titleWcb = data
	},
	setIdReprise(state, data) {
		state.idReprise = data
	}
}
