
export function validateRequiredInput(inputValue, requiredInput){
	if (inputValue && !inputValue.empty){
		requiredInput = true;
	}else{
		requiredInput = false;
	}
	return {
		validName : requiredInput,
	};
}

export function validateZipCode(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue,requiredInput, validFormat, /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/);
}

export function validateSiret(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue, requiredInput, validFormat, /^[0-9]{14}$/);
}

export function validateNumCaisse(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue, requiredInput, validFormat, /^[0-9]{3}$/);
}

export function validateAlphabet(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue,requiredInput, validFormat, /^([^0-9]*)$/);
}

export function validateEmail(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue,requiredInput, validFormat, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/); // eslint-disable-line
}

export function validatePhone(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue,requiredInput, validFormat, /^(?:(?:\+|00)33|0)\s*[1-7](?:[\s.-]*\d{2}){4}$/);
}

export function validateNumber(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue,requiredInput, validFormat, /^\d+$/);
}

export function validateDate(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue, requiredInput, validFormat, /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/);
}

export function validateIban(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue, requiredInput, validFormat, /^[a-zA-Z]{2}[0-9]{25}$/);
}

export function validateBic(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue, requiredInput, validFormat, /^[a-zA-Z]{4}FR([a-zA-Z0-9]{2}|[a-zA-Z0-9]{5})$/);
}

export function validateNumSecu(inputValue, requiredInput, validFormat){
	return validateStringInput(inputValue, requiredInput, validFormat, /^(?<sexe>[123478])(?<annee>[0-9]{2})(?<mois>0[1-9]|1[0-2]|[2-3][0-9]|4[0-2]|[5-9][0-9])(?<departement>[0][1-9]|2[AB]|[1-9][0-9])(?<numcommune>[0-9]{3})(?<numacte>00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(?<clef>0[1-9]|[1-8][0-9]|9[0-7])?$/)
}

export function validateStringInput(inputValue, requiredInput, validFormat, regex){

	if (inputValue && !inputValue.empty){
		requiredInput = true;
		validFormat = regex.test(inputValue);

	}else if (!inputValue) {
		requiredInput = false;
		validFormat = true;
	}
	return {
		validName : requiredInput,
		validFormat : validFormat
	};
}
