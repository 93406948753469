<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-8">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1
                  v-html="
                    viewTexts.title.replace('{{ prenom }}', formPmPp.firstName)
                  "
                ></h1>
                <Status
                  :question-text="viewTexts.statut"
                  :default-value="this.status"
                  @update:modelValue="setStatus"
                  ref="status"
                />
                
                <RadioInput
                v-if="status != 'autre'"
                  :question="ccollaboChoice"
                  v-model="this.cCollabo"
                  :default-choice="this.cCollabo"
                  :name="'ccollabo'"
                  ref="ccollabo"
                />
                
                <RadioInput
                  v-if="cCollabo === 'non'"
                  :question="microChoice"
                  v-model="this.mEntrepreneur"
                  :default-choice="this.mEntrepreneur"
                  :name="'micro'"
                  ref="micro"
                />
                <Owner
                  v-if="(status === 'artisan' || status === 'commercant') && cCollabo === 'non' && mEntrepreneur === 'non'"
                  :question-text="viewTexts.gerant"
                  :default-value="owner"
                  @update:modelValue="setOwner"
                  ref="owner"
                />
                <RadioInput
                  v-if="(status === 'freelance' || status === 'professionnel_liberal') && (cCollabo==='oui' || mEntrepreneur==='non')"
                  :question="caisseSSiChoice"
                  v-model="this.caissSSi"
                  :default-choice="this.caissSSi"
                  :name="'CaissSSi'"
                  ref="CaissSSi"
                />
                <Siret
                  :input-group="true"
                  :no-mandatory="true"
                  :question-text="viewTexts.siret"
                  :default-value="formPmPp.siret"
                  :submitted="submit"
                  :optionalText="formTexts.optional.optional.text"
                  :defaultWaitingSiret="this.defaultWaitingSiret"
                  :base-url="this.baseUrlSiret"
                  ref="siret"
                  @confirmSiret="forceSubmit"
                  @siretDetails="completeFields"
                  @waitingSiret="switchWaitingSiret"
                />
                <NbEmployees
                  :question-text="viewTexts.nb_employes"
                  :default-value="this.employee"
                  :no-mandatory="true"
                  :optionalText="formTexts.optional.optional.text"
                  @update:modelValue="setEmployee"
                  ref="nbEmployees"
                />
              </div>
            </transition>
            <ModalContact
              id="ineligibleModal"
              ref="ineligibleModal"
              :html-title="this.formTexts.modals.profil_aide_conseiller.text"
              content-text=""
              :confirmAction="false"
            />
            <ModalContact
              id="eli"
              ref="eli"
              title="Votre profil nécessite une prise en charge par l’un de nos conseillers experts"
              content-text=""
              :confirmAction="true"
              :confirmRetour="false"
              :closable="false"
            />
          </div>
        </div>
      </div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import {getStatutValue, setStatutValue} from "@/services/utils";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import Status from "@/components/FormPpPm/Status";
import Owner from "@/components/FormPpPm/Owner";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";
import Siret from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/Siret.vue";
import NbEmployees from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/NbEmployees.vue";
import ModalContact from "@/components/Navigation/ModalContact";
// import { reloadTCContainer } from "@/services/tagCo";
import { reloadEvent } from '../../services/tagCo';
import wrapper from '../../main';

export default {
  name: "InformationsPro",
  components: {
    FooterNavigation,
    Encart,
    Status,
    Owner,
    Siret,
    NbEmployees,
    ModalContact,
    RadioInput,
  },
  data: function () {
    return {
      viewTexts: null,
      modalTitle: null,
      modalText: null,
      status: null,
      cCollabo: null,
      mEntrepreneur: null,
      caissSSi: null,
      employee: null,
      siret: null,
      owner: null,
      nbEmployees: null,
      defaultWaitingSiret: false,
      baseUrlSiret: process.env.VUE_APP_API_URL,
    };
  },
  created() {
    // this.baseUrlSiret = process.env.VUE_APP_API_URL
    console.log("status " + this.status);
    wrapper.setTcVars({
      page_name : 'profil-professionnel',
      page_cat1_name : 'besoins',
      page_cat2_name : 'situation',
    });
    this.$store.commit("setNextRoute", "Prevoyance");
    this.employee = this.formPmPp.nbEmployees;
    this.viewTexts = this.formTexts.information_entrepreneur;
    this.status = setStatutValue(this.formPmPp.status);
    
    this.caisseSSiChoice= {
      "text": "<p>Avez-vous une caisse de retraite SSI ?</p>\r\n",
      "choices": [
        {
          "oui": "Oui"
        },
        {
          "non": "Non"
        }
      ],
      "type": "ckeditor",
      "tooltip": ""
    },
    this.microChoice= {
      "text": "<p>Êtes-vous  micro entrepreneur ?</p>\r\n",
      "choices": [
        {
          "oui": "Oui"
        },
        {
          "non": "Non"
        }
      ],
      "type": "ckeditor",
      "tooltip": ""
    },
    this.ccollaboChoice= {
      "text": "<p>Êtes-vous un conjoint collaborateur ?</p>\r\n",
      "choices": [
        {
          "oui": "Oui"
        },
        {
          "non": "Non"
        }
      ],
      "type": "ckeditor",
      "tooltip": ""
    },
    console.log("status " + this.status);
    this.siret = this.formPmPp.siret;
    if (this.formPmPp.owner === "oui") {
      this.owner = "oui";
    } else if (this.formPmPp.owner === "non") {
      this.owner = "non";
    }
    if(this.formPmPp.waitingSiret){
      this.defaultWaitingSiret = true;
    }else{
      this.defaultWaitingSiret = false;
    }
    this.cCollabo = this.formPmPp.cCollabo;
    this.mEntrepreneur = this.formPmPp.mEntrepreneur;
    this.caissSSi = this.formPmPp.caissSSi;
    reloadEvent('page');
  },
  methods: {
    setStatus(status) {
      console.log(status);
    //  this.status = '';
    if(this.cCollabo != null){
      this.$refs.ccollabo.forceSelect(null);
    }
      this.cCollabo =null
      this.mEntrepreneur = null
      this.caissSSi = null
      this.owner = null
      this.status = status;
     
    },
    setCCollabo(collab) {
      this.cCollabo = collab;
    },
    setMEntrepreneur(mEntre) {
      this.mEntrepreneur = mEntre;
    },
    setCaissSSi(caissSSi) {
      this.caissSSi = caissSSi;
    },
    setOwner(owner) {
      this.owner = owner;
    },
    // switchWaitingSiret(value) {
    //   if (value){
    //     this.siret = ""
    //     this.$refs.siret.setText("");
    //     this.formPmPp.waitingSiret = true
    //   }else{
    //     this.formPmPp.waitingSiret = false
    //   }
     
    // },

    async completeFields(etablissement) {
      if (etablissement) {
        this.$store.state.etablissement = etablissement
        if(etablissement.uniteLegale.trancheEffectifsUniteLegale != 'NN')
          this.formPmPp.siret = etablissement.siret
          await this.$store.dispatch('souscrireEligibilitePM');
          if(this.$store.state.souscrire.souscrireEligibilitePM){
            this.$refs.nbEmployees.forceText(etablissement.uniteLegale.trancheEffectifsUniteLegale);
            this.setEmployee(etablissement.uniteLegale.trancheEffectifsUniteLegale);
        // this.$refs.nbEmployees.setText(getNbEmployeesFromCode(data.uniteLegale.trancheEffectifsUniteLegale));
          }  
      }
    },
    setEmployee(employee){
      this.employee = employee;
      this.formPmPp.nbEmployees = this.employee;
    },
    async submitForm() {
      console.log("status " + this.status);
      this.submit = true;
     // this.status = this.$refs.status.submitField();

      if (this.status == "autre" || this.caissSSi == 'non') {
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        
        wrapper.setTcVars({
          page_name : 'statut',
          page_cat1_name : 'exclusion',
          page_cat2_name : '',
        });
        reloadEvent('page');
        return false;
      } 
        if(this.owner==null){
          this.owner = ''
        } 
          

        this.siret = await this.$refs.siret.submitField();
    //    this.nbEmployees = this.$refs.nbEmployees.submitField();
        this.formPmPp.siret = this.siret
        
        if (this.status) {

      
          if(this.owner){
            this.formPmPp.owner = this.owner;
            this.formPmPp.status = this.status;
            this.formPmPp.nbEmployees = this.employee;
            this.nextRoute();
          }

          
          this.formPmPp.cCollabo = this.cCollabo;
          this.formPmPp.mEntrepreneur = this.mEntrepreneur;
          this.formPmPp.caissSSi = this.caissSSi;
          console.log(this.formPmPp.cCollabo + this.formPmPp.mEntrepreneur + this.formPmPp.caissSSi + "rer")

          this.formPmPp.status=getStatutValue(this.status, this.cCollabo);
            this.formPmPp.nbEmployees = this.employee;
            reloadEvent('page');
            this.nextRoute();
         

        }
      
    },
    nextRoute() {
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
  },
  computed: mapState([`formTexts`, `formPmPp`]),
};
</script>

<style scoped></style>
