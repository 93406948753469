<template>
  <div class="container">
    <div class="row justify-content-center gx-3">
      <div class="col-12 col-lg-8 mb-5">
        <h1 v-html="viewTexts.title"></h1>
      </div>
    </div>
  </div>
  <div class="container-fluid">

    <div class="row justify-content-center prepare-elements">
      <div class="col-12 col-lg-10 col-xl-8">

          <OffersCardSwiper :nb-slides-per-view="4">
            <template v-slot:swipeBody>
              <div class="swiper-slide" >
             
                <ElementToPrepare icon-class="briefcase" :text-key="viewTexts.informations_entreprise"/>
           
              </div>
              <div class="swiper-slide" >
         
                <ElementToPrepare icon-class="people-fill" :text-key="viewTexts.informations_beneficiaires"/>
            
              </div>
              <div class="swiper-slide" >
             
                <ElementToPrepare icon-class="file-earmark" :text-key="viewTexts.informations_bancaires"/>
            
              </div>
              <div class="swiper-slide" >
         
                <ElementToPrepare icon-class="phone" :text-key="viewTexts.signature_electronique"/>
        
              </div>
              
            </template>
          </OffersCardSwiper>

          <div class="col-12 mt-4 text-end font-weight-bolder" v-html="viewTexts.label_suivant.text"></div>
      </div>
    </div>

    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>

</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import ElementToPrepare from "@/components/ElementToPrepare";
import OffersCardSwiper from "@/components/Offers/OffersCardSwiper";
import templateHelpers from "@/services/templateHelpers";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';


export default {
  name: "ElementsAPreparer",
  components: {
    ElementToPrepare,
    FooterNavigation,
    Encart,
    OffersCardSwiper,
  },
  data: function () {
    return {
      viewTexts: null,
    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    wrapper.setTcVars({
      page_name : 'elements-a-preparer',
      page_cat1_name : 'souscription',
      page_cat2_name : '',
    });

    this.viewTexts = this.formTexts.elements_prepare_souscription;
    reloadEvent('page');
  },
  mounted(){
    this.setSwiper();
    
  },
  methods: {
    ...templateHelpers,
    setSwiper(){
      this.initSwiper(null, 4);
    },
    swapComp(arg) {
      this.$store.commit("switchComponent", arg);
    },
    submitForm() {
      this.$router.push({ name: "InformationsProfessionnelles" });
    },
  },
  computed: mapState([`loadTexts`, `formTexts`]),
};
</script>
