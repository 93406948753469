<template>
  <div class="row">
    <div class="col-12 py-4 px-0 form-check" :data-cy-user="refName">
      <Civility
          :component-texts="this.formTexts.eligibilite.civilite"
          :default-value="benefObject.civility"
          :ref="'civility'+refName"
          :disabled="mainBenef"
      />
      <FirstName
          :component-texts="this.formTexts.eligibilite.prenom"
          :default-value="benefObject.firstName"
          v-model="firstName"
          :ref="'firstName-'+refName"
          :disabled="mainBenef"
      />
      <LastName
          :component-texts="this.formTexts.eligibilite.nom_famille"
          :default-value="benefObject.lastName"
          v-model="lastName"
          @update:modelValue="updateBirthLastName"
          :ref="'lastName-'+refName"
          :disabled="mainBenef"
      />

      <DateInput
          :component-texts="this.formTexts.eligibilite.naissance"
          :disabled="true"
          :default-value="benefObject.birthDate"
          :ref="'dateInput-'+refName"
      />

      <BirthLastName
          :component-texts="this.formTexts.clause_beneficiaire_souscription.nom_naissance"
          :last-name-value="lastName"
          :default-value="birthLastName"
          data-cy-input="birthName"
          :checkbox-id="'BirthLastName' + refName"
          :ref="'birthLastName-'+refName"/>


      <Departement v-if="mainBenef"
                   :component-texts="this.birthDepartement"
                   :default-value="this.formPmPp.birthDepartement"
                   :ref="'birthDepartement-' + refName"
      />



      <BirthCity
          :component-texts="this.formTexts.clause_beneficiaire_souscription.comune_naissance"
          data-cy-input="birthCity"
          :default-value="birthCity"
          :ref="'birthCity-'+refName"/>

      <Country v-if="mainBenef"
               :component-texts="this.birthCountry"
               :default-value="this.formPmPp.birthCountry!= ''  ?  this.formPmPp.birthCountry : 'France'"
               :ref="'birthCountry-'+refName"
      />

      <div v-if="mainBenef">

        <Address
            :component-texts="this.formTexts.relecture_souscription.relecture_prevoyance_souscripteur_adresse"
            :default-value="this.formPmPp.address"
            :ref="'address'+ refName"
            :disabled="mainBenef"
        />
        <ZipCode
            :component-texts="this.formTexts.informations_personnelles.code_postal"
            :default-value="this.formPmPp.zipCode"
            :disabled="true"
            @updateZipCode="completeCity"
            ref="zipCode"
        />
        <City
            :component-texts="this.formTexts.informations_personnelles.ville"
            :default-value="this.formPmPp.city"
            :disabled="true"
            :cities="citiesList"
            ref="city"
        />
        <PhoneNumber
            :component-texts="this.formTexts.informations_personnelles.telephone"
            :default-value="this.formPmPp.phone"
            :no-mandatory="false"
            :ref="'phone-' + refName"
            :disabled="mainBenef && this.formPmPp.phoneNumber !== ''"
        />
        <Email
            :component-texts="viewTexts.email"
            :default-value="this.formPmPp.email"
            :ref="'email-'+refName"
            :disabled="true"
        />
      </div>

      <RattachementSecu v-if="!conjointBenef && !mainBenef"
                        :defaut-value="this.rattachementChoice"
                        :index-ref="'child-'+refName"
                        :index="childIndex"
                        :ref="'child-rattachement-'+refName"
                        :question="formTexts.infos_perso_souscription.label_rattachement_secu"
                        @selectedValue="itemSelected"
      />

      <div v-if="conjointBenef || mainBenef">
        <NbSecuriteSociale
            :component-texts="this.formTexts.relecture_souscription.relecture_sante_souscripteur_secu"
            :ref="'nbSecuriteSociale-'+ refName"
            data-cy-input="secu"
            :default-value="nbSecuriteSociale"
            :birthDate="this.birthDate"
            @changeNumSecu="updateNumsSecuListConjoint"
        />
        <NbCaisseRegimeObligatoire
            :component-texts="this.formTexts.infos_perso_souscription.label_num_caisse_regime"
            ref="nbCaisseRegimeObligatoire-conjoint"
            data-cy-input="numCaisse"
            :default-value="getNbCaisseRegime()"
        />
      </div>
      <div v-if="conjointBenef">
        <RegimeObligatoire
            :componentTexts="this.regimeListe"
            :default-value="this.formPmPp.beneficiaries.partner['regimeObligatoire']"
            :regime="this.formPmPp.beneficiaries.partner['regimeObligatoire']"
            personne="conjoint"
            ref="regimeObligatoire"
            @update:modelValue="itemSelectedRegimeObligatoire"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Civility from "@/components/FormPpPm/Civility";
import DateInput from "hm-mpe-widgets/src/lib-components/components/Widgets/DateInput";
import BirthLastName from "@/components/FormPpPm/BirthLastName";
import BirthCity from "@/components/FormPpPm/BirthCity";
import LastName from "@/components/FormPpPm/LastName";
import FirstName from "@/components/FormPpPm/FirstName";
import RattachementSecu from "@/components/FormPpPm/RattachementSecu";
import {mapState} from "vuex";
import NbSecuriteSociale from "hm-mpe-widgets/src/lib-components/components/FormPmPp/NbSecuriteSociale.vue";
import NbCaisseRegimeObligatoire from "@/components/FormPpPm/NbCaisseRegimeObligatoire";
import ZipCode from "@/components/FormPpPm/ZipCode";
import Departement from "@/components/FormPpPm/Departement";
import Country from "@/components/FormPpPm/Country";
import City from "@/components/FormPpPm/City";
import PhoneNumber from "hm-mpe-widgets/src/lib-components/components/FormPmPp/PhoneNumber.vue";
import Email from "@/components/FormPpPm/Email";
import Address from "@/components/FormPpPm/Address";
import templateHelpers from "@/services/templateHelpers";
import RegimeObligatoire from "@/components/FormPpPm/RegimeObligatoire";

export default {
  name: "ProfilProBeneficiaireForm",
  components: {
    Civility,
    DateInput,
    BirthLastName,
    BirthCity,
    Address,
    LastName,
    FirstName,
    ZipCode,
    City,
    Email,
    PhoneNumber,
    RattachementSecu,
    NbSecuriteSociale,
    NbCaisseRegimeObligatoire,
    Departement,
    Country,
    RegimeObligatoire,
    
    
  },
  emits: ["selectedValue"],
  props: {
    refName: null,
    benefObject: null,
    benefType: null,
    conjointBenef: {
      type: Boolean,
      default: false
    },
    mainBenef: {
      type: Boolean,
      default: false
    },
    childIndex: null
  },
  data: function(){
    return {
      numerosSecu: [],
      rattachementChoice: null,
      lastName: null,
      firstName: null,
      birthLastName : null,
      birthCity : null,
      nbSecuriteSociale : null,
      nbCaisseRegimeObligatoire : null,
      regimeObligatoire: null,
      email : null,
      birthDepartement: null,
      phone : null,
      birthCountry: null,
      addresses: [],
      dataAddress: [],
      lastDataAddress: [],
      regimeListe: {
        text: "Regime obligatoire",
        choices:[
          { 'Régime général' : "Régime général" },
          { 'Régime local' : "Régime local" },
        ],
      },
      birthDate: null,
      selectedAddress: null,
    }
  },
  created() {
    if(!this.conjointBenef && !this.mainBenef && this.formPmPp.beneficiaries.hasChildren == true){

      if('rattachement' in this.formPmPp.beneficiaries.children[this.childIndex]){
        this.rattachementChoice= {
          choice: this.formPmPp.beneficiaries.children[this.childIndex]['rattachement'],
          nbSecuriteSociale: this.formPmPp.beneficiaries.children[this.childIndex]['nbSecuriteSociale'],
          nbCaisseRegimeObligatoire:  this.formPmPp.beneficiaries.children[this.childIndex]['nbCaisseRegimeObligatoire']
        }
      }else{
        this.rattachementChoice= {
          choice:'',
          nbSecuriteSociale:'',
          nbCaisseRegimeObligatoire:'',
          regimeObligatoire:''
        }
      }
      if(this.formPmPp.nbSecuriteSociale){
        this.addNumSecu("", "", this.formPmPp.nbSecuriteSociale)
      }
      if(this.formPmPp.beneficiaries.partner.nbSecuriteSociale && this.formPmPp.beneficiaries.partner.nbSecuriteSociale != ''){
        this.addNumSecu("", "", this.formPmPp.beneficiaries.partner.nbSecuriteSociale)
      }
    }

    this.viewTexts = this.formTexts.informations_personnelles;
    this.birthDepartement={"text": "Code postal de naissance"};
    this.birthCountry={"text": "Pays de naissance"};
    this.lastName = this.benefObject.lastName

    if (this.mainBenef){
      this.birthLastName = this.formPmPp.birthLastName
      this.birthCity = this.formPmPp.birthCity
      this.nbSecuriteSociale = this.formPmPp.nbSecuriteSociale
      this.nbCaisseRegimeObligatoire = this.formPmPp.nbCaisseRegimeObligatoire
      this.birthDate = (this.formPmPp.birthDate).slice(2,4)
      console.log(this.birthDate)
    }
    
    if (this.conjointBenef){
      this.birthLastName = this.formPmPp.beneficiaries.partner.birthLastName
      this.birthCity = 'birthCity' in this.formPmPp.beneficiaries.partner ? this.formPmPp.beneficiaries.partner.birthCity : ''
      this.nbSecuriteSociale = 'nbSecuriteSociale' in this.formPmPp.beneficiaries.partner ? this.formPmPp.beneficiaries.partner.nbSecuriteSociale : ''
      this.nbCaisseRegimeObligatoire = 'nbCaisseRegimeObligatoire' in this.formPmPp.beneficiaries.partner ? this.formPmPp.beneficiaries.partner.nbCaisseRegimeObligatoire : ''
      this.regimeObligatoire = 'regimeObligatoire' in this.formPmPp.beneficiaries.partner ? this.formPmPp.beneficiaries.partner['regimeObligatoire'] : ''
      this.birthDate = (this.formPmPp.beneficiaries.partner.birthDate).slice(2,4)
    }
    
    if (!this.mainBenef && !this.conjointBenef){
      this.birthLastName = 'birthLastName' in this.formPmPp.beneficiaries.children[this.childIndex] ? this.formPmPp.beneficiaries.children[this.childIndex].birthLastName : ''
      this.birthCity = 'birthCity' in this.formPmPp.beneficiaries.children[this.childIndex] ? this.formPmPp.beneficiaries.children[this.childIndex].birthCity : ''
      this.rattachementChoice.choice = 'rattachement' in this.formPmPp.beneficiaries.children[this.childIndex] ? this.formPmPp.beneficiaries.children[this.childIndex].rattachement : ''
      this.birthDate = (this.formPmPp.beneficiaries.children[this.childIndex].birthDate).slice(2,4)
    }

    if (this.formPmPp.zipCode && this.mainBenef){
      this.$store.dispatch('completeCity', this.formPmPp.zipCode)
    }


  },
  mounted(){
    this.$refs['dateInput-'+this.refName].validateField();
    if (this.formPmPp.beneficiaries.partner.regimeObligatoire && this.conjointBenef) {
      this.$refs.regimeObligatoire.forceSelect(this.regimeObligatoire)
    }
  },
  beforeUnmount(){
    this.$store.commit('resetRattachementChoices');
  },
  methods: {
    ...templateHelpers,
    getNbCaisseRegime() {
      if (this.mainBenef) {
        if (this.formPmPp.nbCaisseRegimeObligatoire !== '') {
          return this.formPmPp.nbCaisseRegimeObligatoire
        } else if (this.benefObject.zipCode) {
          this.formPmPp.nbCaisseRegimeObligatoire = this.benefObject.zipCode.toString().substring(0, 2) + '1';
          return this.benefObject.zipCode.toString().substring(0, 2) + '1'
        }
      }

      if (this.conjointBenef && this.formPmPp.beneficiaries.partner.nbCaisseRegimeObligatoire !== undefined ){
        return this.formPmPp.beneficiaries.partner.nbCaisseRegimeObligatoire
      }else{
        return this.formPmPp.nbCaisseRegimeObligatoire
      }

    },
    itemSelectedRegimeObligatoire(value) {
      this.rattachementChoice.regimeObligatoire = value
      this.$emit('selectedValue', this.rattachementChoice)
    },
    itemSelected(value){
      this.rattachementChoice.choice = value.choice;
      this.rattachementChoice.nbSecuriteSociale = value.nbSecuriteSociale;
      this.rattachementChoice.nbCaisseRegimeObligatoire = value.nbCaisseRegimeObligatoire;
      this.rattachementChoice.regimeObligatoire = value.regimeObligatoire;
    },
    updateNumsSecuListConjoint(value){
    
      if(this.conjointBenef ){
        if (value != this.formPmPp.beneficiaries.partner['nbSecuriteSociale'] && this.formPmPp.beneficiaries.partner['nbSecuriteSociale'] != "" && this.formPmPp.beneficiaries.partner['nbSecuriteSociale'] != undefined ){
          this.$store.commit('removeRattachementChoices',  this.formPmPp.beneficiaries.partner['nbSecuriteSociale'] )
        }
        this.formPmPp.beneficiaries.partner['nbSecuriteSociale'] = value;
      }else{
        if(value != this.formPmPp.nbSecuriteSociale && this.formPmPp.nbSecuriteSociale != "" && this.formPmPp.nbSecuriteSociale != undefined ){
          this.$store.commit('removeRattachementChoices',  this.formPmPp.nbSecuriteSociale )
        }
        this.formPmPp.nbSecuriteSociale = value
      }
      let partner = this.formPmPp.beneficiaries.partner
      this.addNumSecu(partner.lastName, partner.firstName, value)
    },
    addNumSecu(lastname, firstname, num){
      this.$store.commit('addRattachementChoices', num)
    },
    completeCity(value){
      this.completeCityList(this.$store, value)
    },
    submitForm(){
      let validSubmit = false;

      this.birthLastName = this.$refs['birthLastName-'+this.refName].submitField()
      this.birthCity = this.$refs['birthCity-'+this.refName].submitField()

      this.lastName = this.$refs['lastName-'+this.refName].submitField();
      this.firstName = this.$refs['firstName-'+this.refName].submitField();

      if (this.mainBenef || this.conjointBenef){
        this.nbCaisseRegimeObligatoire = this.$refs['nbCaisseRegimeObligatoire-conjoint'].submitField()
        this.nbSecuriteSociale = this.$refs['nbSecuriteSociale-'+this.refName].submitField()
      }

      if (this.mainBenef){
        this.birthDepartement=this.$refs['birthDepartement-'+this.refName].inputValue;
        this.email = this.$refs['email-'+this.refName].submitField()
        this.birthCountry=this.$refs['birthCountry-'+this.refName].submitField();
        // this.$store.state.formPmPp.hasPhone = true;

        this.phone = this.$refs['phone-'+this.refName].submitField()
        validSubmit = (this.birthLastName && this.birthCity && this.email && this.nbCaisseRegimeObligatoire && this.nbSecuriteSociale && this.phone)
      }

      if (this.conjointBenef){
        this.regimeObligatoire = this.$refs['regimeObligatoire'].submitField();
        validSubmit = (this.birthLastName && this.birthCity && this.nbCaisseRegimeObligatoire && this.nbSecuriteSociale && this.regimeObligatoire)
      }

      if (!this.mainBenef && !this.conjointBenef){
        let rattachementChoiceVerif = this.$refs['child-rattachement-'+this.refName].submitField()
        validSubmit = (this.birthLastName && this.birthCity && rattachementChoiceVerif)
      }

      if (this.mainBenef && validSubmit){
        this.formPmPp.lastName = this.lastName
        this.formPmPp.firstName = this.firstName
        this.formPmPp.birthLastName = this.birthLastName
        this.formPmPp.birthCity = this.birthCity
        this.formPmPp.birthDepartement = this.birthDepartement
        this.formPmPp.birthCountry = this.birthCountry
        this.formPmPp.nbSecuriteSociale = this.nbSecuriteSociale
        this.formPmPp.nbCaisseRegimeObligatoire = this.nbCaisseRegimeObligatoire
        this.formPmPp.email = this.email
        this.formPmPp.phone = this.phone
      }

      if (this.conjointBenef && validSubmit) {
        this.formPmPp.beneficiaries.partner['birthLastName'] = this.birthLastName
        this.formPmPp.beneficiaries.partner['birthCity'] = this.birthCity
        this.formPmPp.beneficiaries.partner['nbSecuriteSociale'] = this.nbSecuriteSociale
        switch(this.regimeObligatoire){
          case "RG":
          this.formPmPp.beneficiaries.partner['regimeObligatoire'] = "Régime général";
            break;
          case "RL" : 
          this.formPmPp.beneficiaries.partner['regimeObligatoire'] = "Régime local";
            break;
        }
        this.formPmPp.beneficiaries.partner['nbCaisseRegimeObligatoire'] = this.nbCaisseRegimeObligatoire
      }

      if (!this.mainBenef && !this.conjointBenef && validSubmit){
        this.formPmPp.beneficiaries.children[this.childIndex]['birthLastName'] = this.birthLastName
        this.formPmPp.beneficiaries.children[this.childIndex]['birthCity'] = this.birthCity
        if(this.rattachementChoice.choice !== 'autre'){
          this.formPmPp.beneficiaries.children[this.childIndex]['rattachement'] = this.rattachementChoice.choice
        }else{
          this.formPmPp.beneficiaries.children[this.childIndex]['rattachement'] = this.rattachementChoice.choice
          this.formPmPp.beneficiaries.children[this.childIndex]['nbSecuriteSociale'] = this.rattachementChoice.nbSecuriteSociale
          this.formPmPp.beneficiaries.children[this.childIndex]['nbCaisseRegimeObligatoire'] = this.rattachementChoice.nbCaisseRegimeObligatoire
        }
      }

      return validSubmit
    },
    updateBirthLastName(){
      this.$refs['birthLastName-'+this.refName].updateLastName(this.lastName)
    }
  },
  watch: {
    citiesList(){
      if (this.mainBenef && 'city' in this.$refs){
          this.$refs.city.setCity(this.formPmPp.city.toUpperCase())
      }
    },
  },
  computed: mapState([`formTexts`, `formPmPp`, `citiesList`]),
}
</script>
