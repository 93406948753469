<template>
  <div id="IPCS-questions" class="container">
    <transition name="fade">
      <div class="row justify-content-center mt-4">
        <div class="col-11 col-lg-8 mt-4">
          <Civility
            :component-texts="viewTexts.infos.civilite"
            :default-value="formPmPp.civility"
            ref="civility"
          />
          <LastName
            :component-texts="viewTexts.infos.nom_famille"
            :default-value="formPmPp.lastName"
            ref="lastName"
          />
          <FirstName
            :component-texts="viewTexts.infos.prenom"
            :default-value="formPmPp.firstName"
            ref="firstName"
          />

          <DateInput
            :component-texts="viewTexts.infos.naissance"
            :default-value="formPmPp.birthDate"
            ref="birthDate"
            disabled
          />
          <Address 
            :component-texts="viewTexts.rue" 
            :default-value="formPmPp.address"
            ref="address" />
          <ZipCode
            :component-texts="viewTexts.code_postal"
            :default-value="formPmPp.zipCode"
            @updateZipCode="completeCity"
            ref="zipCode"
          />
          <City :component-texts="viewTexts.ville"
                :cities="cities"
                :default-value="formPmPp.city"
                ref="city" />
          <PhoneNumber
              :component-texts="viewTexts.telephone"
              :default-value="formPmPp.phoneNumber"
              :no-mandatory="true"
            ref="phone"
          />
          <div v-html="viewTexts.telephone.helpLabel"></div>
          <Email
            @focusout="checkMails()"
            :component-texts="viewTexts.email"
            :default-value="formPmPp.email"
            :redBorder="!identicalMails"
            ref="email"
          />
          <div class="row"></div>
          <Email
            @focusout="checkMails()"
            @change="checkMails()"
            :default-value="formPmPp.confirmEmail"
            :component-texts="viewTexts.confirmation_email"
            :redBorder="!identicalMails"
            ref="emailConfirmation"
          />
          <div class="row">
            <div class="col-6"></div>
            <div
              v-if="!identicalMails"
              class="pb-3 d-flex col-6 justify-content-start form-error form-error-text"
            >
              Les adresses emails ne correspondent pas
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-6 ps-4 form-check">
              <input class="form-check-input" type="checkbox" v-model="confirmEmail" id="confirmEmail" />
              <label v-html="viewTexts.email_optin.text" class="form-check-label" for="confirmEmail">
              </label>
            </div>
          </div>
          <div v-if="submit && !confirmEmail" class="d-inline col-12 text-start form-error form-error-text">
            Merci de cocher la case
          </div>
          <p>{{ optins.libelle }}</p>
          <RadioInput
            ref="sms_agreement"
            :question="viewTexts.optiin_sms"
            :default-choice="smsAgreement"
            :submitted="false"
            :name="'sms_agreement'"
            v-model="smsAgreement"
          />
          <RadioInput
            ref="email_agreement"
            :question="viewTexts.optiin_email"
            :default-choice="emailAgreement"
            :submitted="false"
            :name="'email_agreement'"
            v-model="emailAgreement"
          />
          <div
            v-if="submit && (smsAgreement == null || emailAgreement == null)"
            class="d-inline col-12 text-start form-error form-error-text"
          >
            Merci de cocher les options
          </div>
        </div>
      </div>
    </transition>
  </div>
  <FooterNavigation @submitStep="submitForm" />
  <Encart :component-texts="viewTexts.blocs_texte" />
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import Civility from "@/components/FormPpPm/Civility.vue";
import LastName from "@/components/FormPpPm/LastName.vue";
import FirstName from "@/components/FormPpPm/FirstName.vue";
import DateInput from "hm-mpe-widgets/src/lib-components/components/Widgets/DateInput.vue";
import Email from "@/components/FormPpPm/Email.vue";
import PhoneNumber from "hm-mpe-widgets/src/lib-components/components/FormPmPp/PhoneNumber.vue";
import Address from "@/components/FormPpPm/Address";
import ZipCode from "@/components/FormPpPm/ZipCode.vue";
import City from "@/components/FormPpPm/City.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput.vue";
import {filterCities, getCityFromZipCode} from "@/services/geoApiService";
import {buildPPData} from "@/services/contactAPI/contactCallsBuilder";
import {getInterfactionTarif} from "@/services/contactAPI/interactionService";
import {getTarifOR} from "@/services/contactAPI/contactORService";

export default {
  name: "InformationsPersonnellesComplete",
  components: {
    FooterNavigation,
    Encart,
    Civility,
    LastName,
    FirstName,
    DateInput,
    Email,
    Address,
    ZipCode,
    City,
    PhoneNumber,
    RadioInput,
  },
  data: function () {
    return {
      submit: false,
      smsAgreement: null,
      emailAgreement: null,
      identicalMails: true,
      confirmEmail: false,
      addresses: [],
      dataAddress: [],
      cities: [],
      selectedAddress: null,
    };
  },
  created() {
    this.$store.commit("setNextRoute", "Devis");
    this.viewTexts = this.formTexts.informations_personnelles;
    this.viewTexts.infos = this.formTexts.eligibilite;
    if (this.formPmPp.address != "") {
      this.confirmEmail = true;
      if (this.formPmPp.smsAgreement == "oui") {
        this.smsAgreement = Object.keys(this.viewTexts.optiin_sms.choices[0])[0];
      } else {
        this.smsAgreement = Object.keys(this.viewTexts.optiin_sms.choices[1])[0];
      }
      if (this.formPmPp.emailAgreement == "oui") {
        this.emailAgreement = Object.keys(this.viewTexts.optiin_email.choices[0])[0];
      } else {
        this.emailAgreement = Object.keys(this.viewTexts.optiin_email.choices[1])[0];
      }
    }

     if(this.formPmPp.zipCode.length != 0){
      this.completeCity(this.formPmPp.zipCode);
    }

    if (!this.optins){
      this.$store.dispatch('getOptins');
    }

  },
  methods: {
    submitForm() {
      this.submit = true;
      let civility = this.$refs.civility.submitField();
      let lastName = this.$refs.lastName.submitField();
      let firstName = this.$refs.firstName.submitField();
      let birthDate = this.$refs.birthDate.submitField();
      let address = this.$refs.address.submitField();
      let zipCode = this.$refs.zipCode.submitField();
      let city = this.$refs.city.submitField();
      let phone = this.$refs.phone.submitField();
      let email = this.$refs.email.submitField();
      let emailConfirmation = this.$refs.emailConfirmation.submitField();

      if (
        civility &&
        lastName &&
        firstName &&
        birthDate &&
        address &&
        zipCode &&
        city &&
        email &&
        emailConfirmation &&
        this.checkMails() &&
        this.confirmEmail &&
        this.smsAgreement != null &&
        this.emailAgreement != null
      ) {
        this.formPmPp.civility = civility;
        this.formPmPp.lastName = lastName;
        this.formPmPp.firstName = firstName;
        this.formPmPp.birthDate = birthDate;
        this.formPmPp.address = address;
        this.formPmPp.city = city;
        this.formPmPp.zipCode = zipCode;
        this.formPmPp.phone = phone;
        this.formPmPp.email = email;
        this.formPmPp.confirmEmail = emailConfirmation;
        this.formPmPp.smsAgreement = this.$refs.sms_agreement.picked;
        this.formPmPp.emailAgreement = this.$refs.email_agreement.picked;

        this.saveConsentements();
        this.$store.state.avancement[3] = true;
        this.nextRoute();
      }
    },
    saveConsentements(){
      let smsOptin = this.optins.typeCanaux.find((t) => {
        return t.libelle === 'SMS'
      });
      let emailOptin = this.optins.typeCanaux.find((t) => {
        return t.libelle === 'Email'
      });
      let formPmPpOptins = {
        "ficheId": this.optins.id,
        "canaux": [
          {
            "typeCanalId": smsOptin.id,
            "flagAcceptation": this.smsAgreement === 'oui'
          },
          {
            "typeCanalId": emailOptin.id,
            "flagAcceptation": this.emailAgreement === 'oui'
          }
        ]
      };
      this.$store.commit('setFormPmPpOptins', formPmPpOptins);
    },
    checkMails() {
      if (this.$refs.email && this.$refs.emailConfirmation) {
        if (this.$refs.email.inputValue == null || this.$refs.emailConfirmation.inputValue == null) {
          this.identicalMails = true;
          return true;
        }
        if (this.$refs.email.submitField() != this.$refs.emailConfirmation.submitField()) {
          this.identicalMails = false;
          return false;
        } else {
          this.identicalMails = true;
          return true;
        }
      }
      return false;
    },
    nextRoute() {
      let apiContactData = buildPPData(this.$store.state, getInterfactionTarif(this.$store.state), getTarifOR(this.$store.state));
      this.$store.dispatch('createContact', apiContactData)
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
    completeCity(zipCode){
      getCityFromZipCode(zipCode).then((r) => {
        this.cities = filterCities(r.data)
      })
    }
  },
  computed: mapState([`formTexts`, `formPmPp`, `optins`]),
};
</script>

<style lang="scss" scoped></style>
