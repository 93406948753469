<template>
  <div class="container" >
    <div class="row justify-content-center">
      <div class="col-11 col-md-8">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1 class="text-md-center" style="margin-bottom: 40px" v-html="viewTexts.title"></h1>
                <p class="text-md-center pb-md-2" v-html="viewTexts.signature_sous_titre.text"></p>
                <PhoneNumber
                    ref="phoneNumber"
                    v-model="phoneInput"
                    :no-mandatory="false"
                    :question="viewTexts.signature_telephone"
                    :submitted="submit"
                    :defaultValue="defaultPhone"
                    :component-texts="viewTexts.signature_telephone"
                />
                <div class="row">
                <div class="col-6"></div>
                <div class="col-6" v-if="!mobileValid"> <p class="form-error form-error-text"> Vous devez rentrer un numéro de téléphone portable </p></div>

                </div>

                <div class="d-flex row justify-content-end">
                   <div class="d-flex col-md-6">
                     </div>
                  <div class="col-md-6 form-check pb-2" style="padding-left: 40px">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        ref="boxCondition"
                    />

                    <label class="form-check-label pb-2 condition" for="flexCheckDefault">
                      <p v-html="viewTexts.signature_optin.text"></p>
                    </label>
                    <div class="row">
                      <div
                        v-if="this.checkBox === false"
                        class="d-inline col-md-10 text-start form-error form-error-text">
                        Merci de cocher la case
                      </div>
                    </div>
                  </div>

                  <div class="d-flex col-md-6">
                  </div>

                  <div class="d-flex col-md-6 align-self-end pb-3">

                  <button
                      class="btn btn-info2 rounded-pill ps-3 pe-3 p-2 btnSendCode"
                      style="color: white"
                      name="sendCode"
                      @click="sendSmsCode"
                  >
                    Recevoir le code par SMS
                  </button>
                </div>
                </div>

                <TextInput
                    v-if="smsCode"
                    v-model="codeInput"
                    :valid-input="validName"
                    :valid-format="validFormat"
                    :submitted="submit"
                    :question="this.questions2"
                    @update:textInput="updateCodeSms"
                    ref="codeInput"
                />
                <div class="col-6" v-if="!smsValid"> <p class="form-error form-error-text"> Ce code n'est pas valide !</p></div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <FooterNavigation @submitStep="submitForm" />
      <Encart :component-texts="viewTexts.blocs_texte" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import PhoneNumber from "hm-mpe-widgets/src/lib-components/components/FormPmPp/PhoneNumber.vue";
import templateHelpers from "@/services/templateHelpers";
import TextInput from "hm-mpe-widgets/src/lib-components/components/Widgets/TextInput.vue";
import {postCall} from "@/services/apiService";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "ProfileProfessionnel",
  components: {
    FooterNavigation,
    Encart,
    TextInput,
    PhoneNumber,
  },
  data: function () {
    return {
      mobileValid: {
        type: Boolean,
        default: true
      },
      viewTexts: null,
      phoneInput: null,
      defaultPhone: null,
      agreementInput: null,
      codeInput: null,
      smsCode: false,
      submit: false,
      msgErreurCode :"Ce code n'est plus valide",
      smsValid: true,
      questions2 : null,
      validFormat: false,
      validName: false,
      checkBox: null,
    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    wrapper.setTcVars({
      page_name : 'signature',
      page_cat1_name : 'souscription',
    });
    this.viewTexts = this.formTexts.signature_souscription;
    this.questions2 = {
      text: this.viewTexts.signature_code_sms.text,
      type: "text",
    }

    this.defaultPhone = this.isAMobile(this.formPmPp.phone) ? this.formPmPp.phone : ""

    let dataContact;
    if(this.formPmPp.hasPhone) {
      dataContact = {
          "interaction": {
              "objet": "Attente Signature électronique",
          },
          "opportuniteRelationnelle": {
              "objet": "Attente Signature électronique",
              "priorite":"92",
          },
      }
    } else {
      dataContact = {
          "interaction": {
              "objet": "Attente Signature électronique",
          },
      }
    }
    this.$store.dispatch("updatePm", dataContact);
    reloadEvent('page');
  },
  methods: {
    ...templateHelpers,
    updateCodeSms(value){
      this.codeInput = value
      if(Number(value) && value.length === 6 ){
        this.validFormat = 'true'
        this.validName = 'true'
      }
    },
    validateField() {
      return this.codeInput
    },
    sendSmsCode(){
      if(this.isAMobile(this.$refs.phoneNumber.inputValue)){
        this.formPmPp.phoneNumber = this.$refs.phoneNumber.inputValue;
        this.mobileValid=true;
        if(this.$refs.boxCondition.checked){
          this.smsCode = true
          this.checkBox = true
          this.$store.dispatch('initSignature', this.$refs.phoneNumber.inputValue);
          let event = {
            click_name: "clic_recevoir-code",
            click_type: 'action',
            composant: 'contenu'
          };
          reloadEvent('click', event)
        } else {
          this.checkBox = false
        }
      }else{
        this.mobileValid=false;
      }

    },
    submitForm() {
      this.submit = true;
      if (this.validateField()) {

      this.$store.commit('toggleViewLoading')
      let that = this

			postCall('/hm-web-services/sens-sms/'+ this.souscrire.idProp, {
				"workflowNumber": that.$store.state.souscrire.workflowNumber,
				"userOTPValue": that.codeInput
			}).then((r)=> {
        that.souscrire.ba_signe = r.data
        that.$store.commit('toggleViewLoading')
        this.smsValid = true
        this.nextRoute();
      }).catch(() => {
        this.$store.commit('toggleViewLoading')
        that.smsValid = false
        this.$store.dispatch('setClosableOdigoValue', false)
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
      })
        let event = {
        click_name: "clic_signer-contrat",
        click_type: 'action',
        composant: 'contenu'
      };
      reloadEvent('click', event)
        this.$store.state.souscription.signature_electronique.signature_telephone =
            this.$refs.phoneNumber.inputValue;
        this.$store.state.souscription.signature_electronique.signature_optin =
            this.agreementInput;
      }
    },
    nextRoute() {
      this.$router.push({ name: "ValidationSouscription" });
    },
  },
  computed: mapState([`formTexts`, `isViewLoading`, `souscrire`, `formPmPp`]),
};
</script>

<style scoped></style>
