import axios from "axios";

export function getCityFromZipCode(zipCode){
	return axios.get('https://api-adresse.data.gouv.fr/search/?q='+zipCode).catch((error) => console.log(error))
}

export function filterCities(cities){
	let newCities = cities.features.map((f) => {
		return f.properties.city.toUpperCase();
	})
	return [...new Set(newCities)];
}
