<template>
  <div class="row justify-content-center devis-buttons">
    <div class="col-6 text-center">
      <a class="w-100 btn rounded-pill btn-outline-dark d-flex justify-content-between sendDevis btnDevis" @click="recevoirDevis()">
        <span class="">Recevoir mon devis</span> <i class="bi bi-envelope d-flex align-items-center"></i> </a>
    </div>
    <div v-if="api" class="col-6">
      <a class="sub w-100 btn rounded-pill btn-info2 d-flex justify-content-between btnDevis" @click="goToSouscription()">Souscrire en ligne  <img :src="require('@/assets/svg/arrow-title-white.svg')" /></a>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import templateHelpers from "@/services/templateHelpers";
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "DevisButton",
  data: function(){
    return {
      sendedModalText: null,
      api: null,
      mailSendedNotif: false,
    }
  },
  props:{
    loading: {
      type: Boolean,
      default: false,
    }
  },
  components: {  },
  created() {
    this.api = this.isApiContactAllRight;
  },
  methods: {
    ...templateHelpers,
    recevoirDevis(){
      this.mailSendedNotif = true;
      // this.$emits('isLoading', true)

      this.$store.dispatch('createEtude').then(() => {
        this.$store.dispatch('sendMail')
			});

      let dataContact;
      if(this.formPmPp.hasPhone) {
        dataContact = {
            "interaction": {
                "objet": "Devis envoyé",
            },
            "opportuniteRelationnelle": {
                "objet": "Devis envoyé",
                "priorite":"85",
            },
        }
      } else {
        dataContact = {
            "interaction": {
                "objet": "Devis envoyé",
            }
        }
      }
      this.$store.dispatch("updatePm", dataContact);
      let event = {
        click_name: "clic_recevoir-mon-devis",
        click_type: 'action',
        composant: 'contenu'
      };
      reloadEvent('click', event)
      
    }, 
    goToSouscription(){
      let event = {
        click_name: "clic_souscrire-en-ligne",
        click_type: 'action',
        composant: 'contenu'
      };
      reloadEvent('click', event)
      this.$router.push({ name: "Souscription", params: {simCode : this.idReprise} });
    }
  },
  computed: {...mapState([`isApiContactAllRight`, `formPmPp`, `formTexts`, `tarification`, `idReprise`, `uuid`, `sendedMail`, `tarifError`, `selectedOfferSante`, `selectedOfferPrevoyance`, `cotisationSelected`, `contact`, `modalSendMail`, `isConsentementAllRight`])},
  watch: {
   loading (){
    if(!this.loading && this.mailSendedNotif) {
      this.mailSendedNotif=false;
    }
   },
  }
}
</script>
