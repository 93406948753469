<template>
  <transition name="fade">
    <div>
      <RadioInput
          :question="questionText"
          :submitted="submit"
          :default-choice="this.inputValue"
          @update:modelValue="itemSelected"
      />
    </div>
  </transition>
</template>

<script>
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";

export default {
  name: "DebitPeriod",
  components: { RadioInput },
  data: function () {
    return {
      validName: false,
      submit: false,
      inputValue: null,
    };
  },
  props: {
    questionText: null,
    defaultValue: null,
  },
  created() {
    this.inputValue = this.defaultValue;
  },
  methods: {
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        this.validName = true;
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      return this.inputValue && this.inputValue.length > 0;
    },
    itemSelected(itemKey) {
      this.validName = itemKey.length != 0 ? true : false;
      this.inputValue = itemKey;
    },
  },
};
</script>

<style scoped></style>
