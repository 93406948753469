import { get } from "@/services/httpService.js";
import axios from "axios";


export function getCall(url){
    return axios.get(process.env.VUE_APP_API_URL + url)
}

export function postCall(url, data){
    return axios.post(process.env.VUE_APP_API_URL + url, data)
}

export function getSiretDetail(siret){
    return get('/hm-web-serrvices/siret-api/' + siret);
}
