<template>
  <div class="container protectionSanteBeneficiaire">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-8">
        <div class="row mb-5 justify-content-center">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1 class="text-md-center" v-html="this.viewTexts.title"></h1>
                <div class="">
                  <RadioInput
                    v-model="inputValuePartner"
                    :submitted="submit"
                    :question="viewTexts.question_conjoint"
                    :default-choice="defaultValuePartner"
                    :name="'conjoint'"
                    @update:modelValue="itemSelectedPartner"
                  />
                  <BeneficiaryForm
                    :title="conjointTitle"
                    v-if="inputValuePartner"
                    :default-beneficiary="this.beneficiaryPartner"
                    ref="beneficiaryFormPartner"
                  />
                  <div v-if="inputValuePartner" style="margin-bottom: 7rem"></div>
                </div>
                <div class="">
                  <RadioInput
                    v-model="inputValueChildren"
                    :submitted="submit"
                    :name="'enfant'"
                    :question="viewTexts.question_enfant"
                    :default-choice="defaultValueChildren"
                    @update:modelValue="itemSelectedChildren"
                  />
                  <component
                    v-for="(component, index) in components"
                    :key="index"
                    v-show="inputValueChildren"
                    :index="index"
                    :title="enfantTitle"
                    :is="component"
                    :ref="'beneficiaryFormChild' + index"
                    :maxAge="27"
                    :minAge="0"
                    @deleteComponent="deleteComp(index)"
                  ></component>
                  <a v-show="inputValueChildren" v-if="this.nbForm >= this.maxChildren" class="btn btn-link" disabled
                    >Maximum de 5 enfants atteint.</a
                  >
                  <a v-show="inputValueChildren" v-else class="btn btn-outline-info col-md-3 pe-4 rounded-pill add-children" @click="addForm">
                    <BIconPlus class="type-bold h4 pt-1" />
                    {{ viewTexts.ajouter_enfant_btn.text }}
                  </a>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterNavigation @submitStep="submitForm" />
  <Encart :component-texts="viewTexts.blocs_texte" />
</template>

<script>
import { markRaw } from "vue";
import { mapState } from "vuex";
import { BIconPlus } from "bootstrap-icons-vue";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import BeneficiaryForm from "@/components/FormPpPm/BeneficiaryForm";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";
// import { reloadTCContainer } from "@/services/tagCo";
import { reloadEvent } from '../../services/tagCo';
import wrapper from '../../main';

export default {
  name: "ProtectionSanteBeneficiaire",
  components: {
    BeneficiaryForm,
    FooterNavigation,
    Encart,
    RadioInput,
    BIconPlus,
  },
  data: function () {
    return {
      maxChildren: 5,
      nbForm: 0,
      submit: false,
      viewTexts: Object,
      inputValuePartner: false,
      inputValueChildren: false,
      defaultValuePartner: "",
      defaultValueChildren: "",
      components: [markRaw(BeneficiaryForm)],
      beneficiaryPartner: false,
      conjointTitle: null,
      enfantTitle: null,
    };
  },
  created() {
    // reloadTCContainer("beneficiaires", "besoins", "couverture");
    wrapper.setTcVars({
      page_name : 'beneficiaires',
      page_cat1_name : 'besoins',
      page_cat2_name : 'couverture',
    });
    
    this.$store.commit("setNextRoute", "CouvertureSante2");
    this.viewTexts = this.formTexts.vos_besoins_sante_1;

    this.conjointTitle = this.viewTexts.precision_infos_conjoint.text;
    this.enfantTitle = this.viewTexts.precision_infos_enfant.text;

    this.loadForms();

    this.inputValuePartner = this.formPmPp.beneficiaries.hasPartner;
    this.inputValueChildren = this.formPmPp.beneficiaries.hasChildren;

    if (this.inputValuePartner) {
      this.defaultValuePartner = Object.keys(this.viewTexts.question_conjoint.choices[0])[0];
    } else {
      this.defaultValuePartner = Object.keys(this.viewTexts.question_conjoint.choices[1])[0];
    }

    if (this.inputValueChildren) {
      this.defaultValueChildren = Object.keys(this.viewTexts.question_enfant.choices[0])[0];
    } else {
      this.defaultValueChildren = Object.keys(this.viewTexts.question_enfant.choices[1])[0];
    }
    reloadEvent('page');
  },
  methods: {
    loadForms() {
      if (this.formPmPp.beneficiaries.partner) {
        this.beneficiaryPartner = true;
      }
      if (this.formPmPp.beneficiaries.children.length > 0) {
        for (let i = 1; i < this.formPmPp.beneficiaries.children.length; i++) {
          if (this.formPmPp.beneficiaries.children[i]) {
            this.addForm();
          }
        }
      }
    },
    addForm() {
      if (this.nbForm < this.maxChildren) {
        this.components.push(markRaw(BeneficiaryForm));
        this.nbForm++;
      }
    },
    deleteComp(index) {
      delete this.components[index];
      this.nbForm--;
    },
    itemSelectedPartner(itemKey) {
      this.inputValuePartner =
        Object.keys(this.viewTexts.question_conjoint.choices[1])[0] == itemKey ? false : true;
    },
    itemSelectedChildren(itemKey) {
      this.inputValueChildren =
        Object.keys(this.viewTexts.question_enfant.choices[1])[0] == itemKey ? false : true;
      this.loadForms();
    },
    submitForm() {
      this.submit = true;
      let isValid = true;

      this.formPmPp.beneficiaries.hasPartner = this.inputValuePartner;
      this.formPmPp.beneficiaries.hasChildren = this.inputValueChildren;
      if (this.inputValuePartner) {
        let res = this.$refs.beneficiaryFormPartner.submitForm();
        if (res) {
          this.formPmPp.beneficiaries.partner = res;
        } else {
          isValid = false;
        }
      } else {
        this.formPmPp.beneficiaries.partner = "";
      }
      if (this.inputValueChildren) {
        this.formPmPp.beneficiaries.children = [];
        this.components.forEach((e, i) => {
          let res = this.$refs["beneficiaryFormChild" + i].submitForm();
          if (res) {
            this.formPmPp.beneficiaries.children.push(res);
          } else {
            isValid = false;
          }
        });
      } else {
        this.formPmPp.beneficiaries.children = [];
      }
      if (isValid) {
        this.nextRoute();
      }
    },
    nextRoute() {
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
  },
  computed: mapState([`formTexts`, `formPmPp`]),
};
</script>

<style scoped></style>
