<template>
  <div class="container" v-if="!this.isViewLoading">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-xl-8" v-if="true">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1 v-html="title"></h1>

                <RadioInput
                ref="question1"
                :question="questions.question1"
                name="arret"
                :default-choice="question1Choice"
                :submitted="submit"
              ></RadioInput>

              <RadioInput
                ref="question2"
                :question="questions.question2"
                name="complementaire"
                :default-choice="question2Choice"
                :submitted="submit"
              ></RadioInput>

              </div>
            </transition>
            <ModalContact
              id="eligibiliteIneligibleModalContact"
              ref="eligibiliteIneligibleModalContact"
              title="Votre profile nécessite une prise en charge par l'un de nos conseilliers experts"
              content-text=""
              :confirmAction="false"
            />
            <ModalContact
            v-if="this.$store.state.souscrire.eligible == false"
              id="eli"
              ref="eli"
              title="Votre profile nécessite une prise en charge par l'un de nos conseilliers experts"
              content-text=""
              :confirmAction="true"
              :confirmRetour="false"
              :closable="false"
            />
            <Modal
              id="eligibiliteIneligibleModal"
              ref="eligibiliteIneligibleModal"
              :title="titleModal"
              :content-text="textModal"
            />
          </div>
        </div>
      </div>
      <div v-else class="spinner-border" role="status"></div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";
import Modal from "@/components/Navigation/Modal";
import ModalContact from "@/components/Navigation/ModalContact";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "Eligibilite",
  components: {
    FooterNavigation,
    Encart,
    RadioInput,
    Modal,
    ModalContact,
  },
  data: function () {
    return {
      viewTexts: null,
      title: null,
      questions: null,
      itemRefs: [],
      submit: false,
      titleModal: "",
      textModal: "",
      question1Choice : null,
      question2Choice : null,
      eliContactModalTitle : null
    };
  },
  created() {
    //reloadTCContainer("contrats", "souscription", "eligibilite");

    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }

    wrapper.setTcVars({
      page_name : 'contrat',
      page_cat1_name : 'souscription',
      page_cat2_name : 'eligibilite',
    });

    this.viewTexts = this.formTexts.eligibilite_souscription;
    this.title = this.viewTexts.title;
    this.questions = {
      question1: {
        text: this.viewTexts.contrat_arret_travail.text,
        choices: this.viewTexts.contrat_arret_travail.choices,
      },
      question2: {
        text: this.viewTexts.contrat_complementaire.text,
        choices: this.viewTexts.contrat_complementaire.choices,
      },
    };

    this.question1Choice = this.$store.state.souscription.eligibilite.contrat_arret_travail
    this.question2Choice = this.$store.state.souscription.eligibilite.contrat_complementaire

    let dataContact;
    if(this.formPmPp.hasPhone) {
      dataContact = {
          "interaction": {
              "objet": "Initialisation souscription",
          },
          "opportuniteRelationnelle": {
              "objet": "Initialisation souscription",
              "priorite":"91",
          },
      }
    } else {
      dataContact = {
          "interaction": {
              "objet": "Initialisation souscription",
          },
      }
    }
    this.$store.dispatch("updatePm", dataContact);

    reloadEvent('page');
  },
  async mounted() {
    await this.getEli();
    wrapper.setTcVars({
      page_name : 'eligibilite-pp',
      page_cat1_name : 'exclusion',
      page_cat2_name : '',
    });
    reloadEvent('page');
  },
  methods: {
    async getEli(){
      await this.$store.dispatch('souscrireEligibilitePP');
      if (this.$store.state.souscrire.eligible == false ){
        this.$store.dispatch('setClosableOdigoValue', false)
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        
    }
      return;
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
    validateField() {
      if (this.$refs.question1.picked && this.$refs.question2.picked) {
        return true;
      }
      return false;
    },
    submitForm() {
      if (this.$refs.question1.picked == "oui") {
        wrapper.setTcVars({
          page_name : 'contrat-prevoyance',
          page_cat1_name : 'exclusion',
          page_cat2_name : '',
        });
        reloadEvent('page');
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        return;
      }
      if (this.$refs.question2.picked == "oui") {
        wrapper.setTcVars({
          page_name : 'contrat-sante',
          page_cat1_name : 'exclusion',
          page_cat2_name : '',
        });
        reloadEvent('page');
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        return;
      }
      this.submit = true;
      if (this.validateField()) {
        this.$store.state.souscription.eligibilite.contrat_arret_travail = this.$refs.question1.picked;
        this.$store.state.souscription.eligibilite.contrat_complementaire = this.$refs.question2.picked;

        this.$store.dispatch('updateState')
        this.nextRoute();
      }
    },
    nextRoute() {
      this.$router.push({ name: "ProfileProfessionnel" });
    },
  },
  computed: mapState([`formTexts`, `isViewLoading`, `formPmPp`]),
};
</script>

<style scoped></style>
