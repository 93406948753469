<template>

  <Loading :active="!this.loadedTexts"
           :is-full-page="true"/>

  <div v-if="this.loadedTexts">
    <Wizard/>

    <div class="footer container-fluid bg-dark py-3">
      <div class="row">
        <div class="col-12" style="padding-top:1rem; padding-bottom:1rem">
          <div class="container footer-links">
            <a class="link-light" style="color: white" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              {{  formTexts.footer.protection_donnees_label.text }}
            </a>
            <a :href="formTexts.footer.mentions_legales.link" target="_blank" class="ps-3 link-light" style="color: white"> {{ formTexts.footer.mentions_legales.text }}</a>
            <div class="collapse" style="padding-top: 1rem" id="collapseExample" v-html="formTexts.footer.protection_donnees_text.text"></div>
          </div>
          <div class="container legal-texts">
            <div class="legal-text">
              <h4>
                Taux de redistribution : 81,9%
              </h4>
              <p>
                Le taux de redistribution est le ratio entre le montant des prestations versées pour le remboursement et l’indemnisation des frais occasionnés par une maladie, une maternité ou un accident et le montant des cotisations ou primes afférentes à ces garanties représente la part des cotisations ou primes collectées, hors taxes, par l’organisme assureur au titre de l’ensemble des garanties couvrant le remboursement ou l’indemnisation des frais précités, qui est utilisée pour le versement des prestations correspondant à ces garanties.
              </p>
            </div>
            <div class="legal-text">
              <h4>
                Frais de gestion : 17,33%
              </h4>
              <p>
                Les frais de gestion correspondent au ratio entre le montant total des frais de gestion au titre du remboursement et de l’indemnisation des frais occasionnés par une maladie, une maternité ou un accident et le montant des cotisations ou primes afférentes à ces garanties représente la part des cotisations ou primes collectées, hors taxes, par l’organisme assureur au titre de l’ensemble des garanties couvrant le remboursement ou l’indemnisation des frais précités, qui est utilisée pour le financement des frais de gestion. Ces frais de gestion recouvrent l’ensemble des sommes engagées pour concevoir les contrats, les commercialiser (dont le réseau commercial, le marketing, les commissions des intermédiaires), les souscrire (dont l’encaissement des cotisations, la gestion des résiliations, le suivi comptable et juridique) et les gérer (dont le remboursement, la gestion du tiers payant, l’information client, l’assistance, les services, les prestations complémentaires), c’est-à-dire accomplir toutes les tâches incombant à l’organisme assureur dans le respect des garanties contractuelles.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div v-show="hasSticky" style="padding-top: 8em"></div>

  </div>

</template>

<script>
import Wizard from './components/Wizard.vue'
import {mapState} from "vuex";
import Loading from 'vue-loading-overlay';

export default {
  name: 'App',
  components: {
    Wizard, Loading
  },
  data: function () {
    return {
      hasSticky: false,
    };
  },
  created() {
    this.saveCodeAction();
    this.$store.dispatch("getFormTexts");
    
  },
  methods: {
    saveCodeAction(){
      const urlParams = new URLSearchParams(window.location.search);
      this.$store.commit('setCodeAction', urlParams.has('ca') ? urlParams.get('ca') : "WEB_PRO_SEO_NA")
    }
  },
  computed: mapState([`loadedTexts`, `formTexts`]),
}
</script>

<style lang="scss">
@import "~bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "assets/scss/app";

</style>
