<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <TextInput
        :default-value="defaultValue"
        :valid-input="validName"
        :valid-format="true"
        :format-error="componentTexts.formatError"
        :submitted="submit"
        :question="componentTexts"
        @update:textInput="setText"
      ></TextInput>
    </div>
  </div>
</template>

<script>
import TextInput from "hm-mpe-widgets/src/lib-components/components/Widgets/TextInput";
import {validateNumCaisse} from "@/services/inputValidator";

export default {
  name: "NbCaisseRegimeObligatoire",
  components: { TextInput },
  props: {
    componentTexts: null,
    defaultValue: null
  },
  data: function () {
    return {
      inputValue: "",
      validName: false,
      submit: false,
    };
  },
  created() {
     this.inputValue = this.defaultValue
  },
  methods: {
    setText(value) {
      this.inputValue = value;
      this.$emit('changeCodeCaisse', this.inputValue)
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        //this.$store.state.formPmPp.nbSecuriteSociale = this.inputValue;
        return this.inputValue
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      let validator = validateNumCaisse(this.inputValue, this.validName);
      this.validName = validator.validName;

      return this.validName;
    },
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
};
</script>

<style scoped></style>
