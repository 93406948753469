<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <TextInput
        :default-value="defaultValue"
        :valid-input="validName"
        :valid-format="validFormat"
        :format-error="componentTexts.formatError"
        :submitted="submit"
        input-name="nbSecuriteSociale"
        :question="componentTexts"
        @update:textInput="setText"
      ></TextInput>
    </div>
  </div>
</template>

<script>
import TextInput from "hm-mpe-widgets/src/lib-components/components/Widgets/TextInput";
import {validateNumSecu} from "@/services/inputValidator";

export default {
  name: "NbSecuriteSociale",
  components: { TextInput },
  props: {
    componentTexts: null,
    defaultValue: null,
  },
  data: function () {
    return {
      inputValue: "",
      validName: false,
      validFormat : false,
      submit: false,
    };
  },
  created() {
    this.inputValue = this.defaultValue
  },
  methods: {
    setText(value) {
      this.inputValue = value;
      if (this.validateField()) {
        this.$emit('changeNumSecu', this.inputValue)
      }
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      let validator = validateNumSecu(this.inputValue, this.validName, this.validFormat);
      this.validName = validator.validName;
      this.validFormat = validator.validFormat;

      return this.validName && this.validFormat;
    },
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
};
</script>

<style scoped></style>
