<template>
  <div class="container-fluid relecture">
    <div class="row justify-content-center">
      <div class="col-11 col-md-8 mt-4">
        <div class="row mb-5">
          <div class="col-12">
            <div>
              <h1 v-html="title"></h1>
              <div class="row">
                <div class="col-9">
                  <h3 class="pb-3 title-page " v-html="textFields.title"></h3>
                  <p v-html="textFields.text"></p>
                </div>
                <div class="col-3" style="padding-right: 0px">
                  <div class="tarif ps-3 pt-3">
                    <div class="">
                      <p class="d-inline-block prix mb-1">
                        {{ getTotalTarif(this.tarification) }} €
                      </p>
                      <p class="d-inline-block align-items-end ps-2 pe-0">
                        TTC /mois
                      </p>
                    </div>
                    <div class="d-flex justify-content-start">
                      <p style="margin-bottom: 0px">
                        {{ tarif.couvertureP }}
                        {{ getPrevoyancePrice(this.tarification) }} €
                      </p>
                    </div>
                    <div class="d-flex justify-content-start">
                      <p>
                        {{ tarif.couvertureS }}
                        {{ totalSante(this.tarification) }} €
                      </p>
                    </div>
                    <div class="d-flex justify-content-start pb-2">
                      <p>
                        Soit {{ getAnnualTotalTarif(this.tarification) }} € TTC
                        /an
                      </p>
                    </div>
                  </div>
                  <p style="font-size: 12px">{{ tarif.valid }}</p>
                </div>
              </div>

              <h3 class="pb-2 title-page">{{ textFields.contrat.title.text }}</h3>

              <div class="beneficiaire row ps-3 pb-3 pt-4">
                <div class="col-6">
                  <p>
                    <span class="fw-bold"
                      >{{ this.viewTexts.relecture_contrat_type_offre.text }} :
                    </span>
                    Ma protection Entrepreneur
                  </p>
                  <p>
                    <span class="fw-bold"
                      >{{ textFields.contrat.structureTarifaire.text }} : </span
                    >{{
                      this.tarification.offre.tarification
                        .structureCotisation === "Adulte/Enfant"
                        ? "Par âge"
                        : "Famille"
                    }}
                  </p>
                  <span
                    class="d-inline-block fw-bold"
                    v-html="viewTexts.relecture_contrat_date_effet.text"
                  >
                  </span>
                  <p class="d-inline-block fw-bold ps-1 pe-1"> :</p>
                  <span class="d-inline-block"> {{
                    formatDate(this.souscription.date_effet_contrat)
                  }}</span>
                  <p>
                    <span class="fw-bold">{{ this.viewTexts.relecture_contrat_periodicite_paiement.text }} :</span>
                    {{ this.souscription.information_bancaire.periodicite_prelevement }}
                  </p>
                  <p>
                    <span class="fw-bold">{{ this.viewTexts.relecture_contrat_date_prelevement.text }} :</span>
                    Le {{ this.souscription.information_bancaire.date_prelevement }} de chaque mois
                  </p>
                </div>
                <div class="col-6" >
                  <p v-html="viewTexts.relecture_contrat_check_infos.text"></p>
                  <div class="col pt-3 ps-4">
                    <a
                      class="downloadIconColor"
                      @click="downloadEvent"
                      :href="this.souscrire.baLink">
                      <BIconCloudDownload class="downloadIconColor downloadIconSize" />
                      {{ this.viewTexts.relecture_contrat_download_contrat.text }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="backgroundSecoundary row justify-content-center pt-5 pb-5">
      <div class="col-11 col-md-8 mt-4">
        <div class="row">
          <div class="col-12">
            <div>
              <h3
                v-html="textFields.prevoyance.souscripteur.title.text"
                class="bigText pb-4 title-page"></h3>

              <h3
                class="pb-2 title-page"
                v-html="textFields.prevoyance.souscripteur.souscripteur.text"></h3>

              <div class="beneficiaire ps-3 pb-3 pt-4">
                <div class="ps-3 pb-3">
                  <p class="fw-bold d-inline">
                    {{ textFields.prevoyance.souscripteur.identite }} :
                  </p>
                  <p class="d-inline">
                    {{ getCivilityByValue(formPmPp.civility) }} {{ formPmPp.lastName }}
                    {{ formPmPp.firstName }}
                  </p>
                </div>
                <div class="ps-3 pb-3">
                  <p class="fw-bold d-inline">
                    {{ textFields.prevoyance.souscripteur.naissance }} :
                  </p>
                  <p class="d-inline">{{ dateFRFormat(formPmPp.birthDate) }}</p>
                </div>
                <div class="ps-3 pb-3">
                  <p class="fw-bold d-inline">
                    {{ textFields.prevoyance.souscripteur.adresse }} :
                  </p>
                  <p class="d-inline">{{ formPmPp.address }}, {{formPmPp.zipCode}} {{formPmPp.city}} </p>
                </div>
              </div>

              <h3 v-if="this.formPmPp.clauseType == 'libre'" class="pt-3 pb-2 ps-2 title-page">
                {{ textFields.prevoyance.clauseBeneficiaire.title.text }}
              </h3>

              <div v-if="this.formPmPp.clauseType == 'type'" class="beneficiaire ps-3 pb-3 pt-4">
                <p v-html="textFields.prevoyance.clauseBeneficiaire.text.text"></p>
              </div>

              <div v-if="this.formPmPp.clauseType == 'libre'">
                <div
                  v-for="beneficiaire in this.formPmPp.clauseBeneficiaries"
                  :key="beneficiaire"
                  class="pb-3 pt-4">
                  <div class="beneficiaire ps-3 pb-3 pt-4">
                    <div class="ps-3 pe-2 pb-0">
                      <p>{{ getClauseBeneficiaireText(beneficiaire)}} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid relecture">
    <div class="row justify-content-center">
      <div class="col-11 col-md-8 mt-4">
        <div class="row mb-5">
          <div class="col-12">
            <div>
              <h3 class="bigText pb-4 title-page">
                {{ textFields.sante.souscripteur.title.text }}
              </h3>
              <h3 class="title-page ">{{ textFields.sante.souscripteur.text.text }}</h3>
              <Beneficiaire subject="souscripteur"></Beneficiaire>

              <h3 class="title-page"  v-if="this.formPmPp.beneficiaries.hasPartner || this.formPmPp.beneficiaries.hasChildren">{{ textFields.sante.beneficiaire.text.text }}</h3>
              <Beneficiaire v-if="this.formPmPp.beneficiaries.hasPartner" subject="conjoint"></Beneficiaire>

              <Beneficiaire v-if="this.formPmPp.beneficiaries.hasChildren" subject="children"></Beneficiaire>

              <div>
                <input
                  class="form-check-input col-1"
                  type="checkbox"
                  id="boxOne"
                  ref="boxOne"
                  :checked="this.formPmPp.relectureOptinsOne"/>
                <label
                  v-html="this.viewTexts.relecture_format_papier.text"
                  for="boxOne"
                  class="ps-1 form-check-label col-11">
                </label>
              </div>
              <div class="row">
                <div
                  v-if="!this.checkOne && submit"
                  class="d-inline col-md-6 text-start form-error form-error-text">
                  Merci de cocher la case
                </div>
              </div>
              <div>
                <input
                  class="form-check-input col-1"
                  type="checkbox"
                  id="boxTwo"
                  ref="boxTwo"
                  :checked="this.formPmPp.relectureOptinsTwo"
                />
                <label
                  v-html="this.viewTexts.relecture_optin_adhesion.text"
                  for="boxTwo"
                  class="ps-1 form-check-label col-11"
                >
                </label>
              </div>
              <div class="row">
                <div
                  v-if="!this.checkTwo && submit"
                  class="d-inline col-md-6 text-start form-error form-error-text">
                  Merci de cocher la case
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterNavigation @submitStep="submitForm" />
  <Encart :component-texts="viewTexts.blocs_texte" />
</template>

<script>
import { mapState } from "vuex";
import { BIconCloudDownload } from "bootstrap-icons-vue";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import Beneficiaire from "@/components/Beneficiaire.vue";
import templateHelpers from "@/services/templateHelpers";
import moment from "moment";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "Relecture",
  components: {
    FooterNavigation,
    Encart,
    Beneficiaire,
    BIconCloudDownload,
  },
  data: function () {
    return {
      title: null,
      viewTexts: null,
      textFields: null,
      submit: false,
      radios: null,
      tarif: null,
      baLink : null,
      baDoc: null
    };
  },
  created() {


if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    wrapper.setTcVars({
      page_name : 'relecture',
      page_cat1_name : 'souscription',
    });

    this.tarif = {
      couvertureP: this.formTexts.fil_ariane.besoin_couverture_prevoyance.text,
      couvertureS: this.formTexts.fil_ariane.besoin_couverture_sante.text,
      valid: this.formTexts.devis.prevoyance_label_tarif.text,
    };
    this.viewTexts = this.formTexts.relecture_souscription;
    this.title = this.viewTexts.title;
    // TODO - inutile de créer un objet intermédiaire pour les textes qui proviennent de Drupal !
    this.textFields = {
      title: this.viewTexts.relecture_offre_title.text,
      text: this.viewTexts.relecture_offre_text.text,
      contrat: {
        title: this.viewTexts.relecture_contrat_title,
        structureTarifaire:
          this.viewTexts.relecture_contrat_structure_tarifaire,
      },
      prevoyance: {
        souscripteur: {
          title: this.viewTexts.relecture_prevoyance_title,
          souscripteur: this.viewTexts.relecture_prevoyance_souscripteur,
          identite:
            this.viewTexts.relecture_prevoyance_souscripteur_identite
              .text,
          naissance:
            this.viewTexts.relecture_prevoyance_souscripteur_naissance
              .text,
          adresse:
            this.viewTexts.relecture_prevoyance_souscripteur_adresse.text,
        },
        clauseBeneficiaire: {
          title: this.viewTexts.relecture_prevoyance_beneficiaire_title,
          text: this.viewTexts.relecture_prevoyance_beneficiaire_text,
        },
      },
      sante: {
        souscripteur: {
          title: this.viewTexts.relecture_sante_title,
          text: this.viewTexts.relecture_sante_souscripteur,
          identite:
            this.viewTexts.relecture_prevoyance_souscripteur_identite,
          naissance:
            this.viewTexts.relecture_prevoyance_souscripteur_naissance,
          secu: this.viewTexts.relecture_sante_souscripteur_secu,
          codeCaisse:
            this.formTexts.infos_perso_souscription.label_num_caisse_regime.text,
          adresse:
            this.viewTexts.relecture_prevoyance_souscripteur_adresse,
        },
        beneficiaire: {
          text: this.viewTexts.relecture_sante_beneficiaire,
          //composant bénéfe ici
        },
      },
    };

    this.baLink = this.souscrire.baLink
    this.baDoc = this.souscrire.baDoc
    reloadEvent('page');
  },
  methods: {
    ...templateHelpers,
    validateField() {
      this.checkOne = true;
      this.checkTwo = true;
      if (this.$refs.boxOne.checked && this.$refs.boxTwo.checked) {
        return true;
      } else {
        this.checkOne = this.$refs.boxOne.checked;
        this.checkTwo = this.$refs.boxTwo.checked;
        return false;
      }
    },
    formatDate(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    submitForm() {
      this.submit = true;

      if (this.validateField()) {
        this.formPmPp.relectureOptinsOne = this.checkOne;
        this.formPmPp.relectureOptinsTwo = this.checkTwo;
        this.$store.state.avancementSouscription[4] = true;
        this.nextRoute();
      }
    },
    nextRoute() {
      this.$router.push({ name: "SignatureElectronique" });
    },
    downloadEvent() {
      //pushTag("clic_telecharger-mon-contrat", "download", "contenu");
      let event = {
        click_name: "clic_telecharger-mon-contrat",
        click_type: 'download',
        composant: 'contenu'
      };
      reloadEvent('click', event)
    },
  },
  computed: mapState([
    `souscription`,
    `formTexts`,
    `tarification`,
    `isViewLoading`,
    `contact`,
    `uuid`,
    `formPmPp`,
    `souscrire`,
  ]),
};
</script>

<style scoped></style>
