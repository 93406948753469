<template>
  <div v-if="this.simulationLoaded">
    <router-view/>
  </div>
  <Loading :active="this.isViewLoading"
           :is-full-page="true"/>
</template>

<script>
import {mapState} from "vuex";
import Loading from "vue-loading-overlay";

export default {
  name: "Souscription",
  components: {Loading},
  created() {
    this.$store.commit('setUuid', this.$route.params.simCode)
    this.$store.dispatch('getSimulationData', this.$route.params.simCode)
  },
  computed: { ...mapState([`contact`, `isViewLoading`, `tarification`, `simulationLoaded`])}
};
</script>
