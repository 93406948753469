<template>
  <transition name="fade">
    <div>
      <SelectInput
      ref="textInput"
      :defaultChoice="inputValue"
      @update:modelValue="itemSelected"
      :submitted="submit"
      :question="questionText"
      :validInput="validName"
      :validFormat="validFormat"/>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import SelectInput from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/SelectInput.vue";

export default {
  name: "NbEmployees",
  components: { SelectInput },
  data: function () {
    return {
      validName: false,
      submit: false,
      validFormat: false,
      inputValue: null,
      questionText: null,
    };
  },
  props: {
    defaultValue: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    noMandatory: {
      type: Boolean,
      default: false,
    },
    nbType: {
      type: String,
      default: 'number'
    }
  },
  created() {
    if(this.defaultValue != ""){
      this.inputValue = this.defaultValue;
    }
    if (this.validateField()) {
        this.validName = true;
    }

    if(this.inputValue != null && this.inputValue != ''){

      this.validName = true;
    }
    this.questionText = {
      text : this.formTexts.information_entrepreneur.nb_employes.text,
      choices: [
        {
          "00": "0 salarié"
        },
        {
          "01": "1 à 2 salariés"
        },
        {
          "02": "3 à 5 salariés"
        },
        {
          "03": "6 à 9 salariés"
        },
        {
          "11": "10 à 19 salariés"
        },
        {
          "12": "20 à 49 salariés"
        },
        {
          "21": "50 à 99 salariés"
        },
        {
          "22": "100 à 199 salariés"
        },
        {
          "31": "200 à 249 salariés"
        },
        {
          "32": "250 à 499 salariés"
        },
        {
          "41": "500 à 999 salariés"
        },
        {
          "42": "1000 à 1 999 salariés"
        },
        {
          "51": "2000 à 4 999 salariés"
        },
        {
          "52": "5000 salariés & +"
        },

      ]
    }
  },
  methods: {
    setText(value) {
      this.inputValue = value;
    },
    forceText(value) {
      //this.validName = true;
     // this.inputValue = value;
     this.itemSelected(value);
      this.$refs.textInput.forceSelect(value);
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        this.itemSelected(this.inputValue);
        this.validName = true;
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      if(this.inputValue === null || this.inputValue === undefined || this.inputValue == ""){
        return false;
      }
      return true;
    /*  if (this.inputValue && this.inputValue.length > 0) {
        this.validName = validateRequiredInput(this.inputValue, this.validName)
        this.validFormat = true
        if (this.nbType === 'number'){
          let validator = validateNumber(this.inputValue, this.validName, this.validFormat)
          this.validName = validator.validName;
          this.validFormat = validator.validFormat;
        }

        return this.validName && this.validFormat;
      }else{
        if (this.noMandatory){
          return true
        }
      }
        //optionnal field
      return true;*/
    },
    itemSelected(itemKey) {
      if(itemKey!="" && itemKey!=null){
        this.validName = true;
      }else{
        this.validName = false;
      }
      
      this.inputValue = itemKey;
      this.$emit("update:modelValue", itemKey);
    },
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
   computed: {
    ...mapState([`formTexts`]),
  }
};
</script>

<style scoped></style>
