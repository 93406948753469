<template>
  <SelectInput data-cy-input="rattachement"
      :valid-input="validName"
      :default-choice="this.rattachementChoice.choice"
      :question="rattachementQuestion"
      :submitted="submit"
      :choices="this.formPmPp.rattachementChoices"
      @update:modelValue="itemSelectedChoice"
      class="pb-3"/>

   <NbSecuriteSociale
      v-if="this.rattachementChoice.choice === 'autre'"
      :component-texts="this.formTexts.infos_perso_souscription.label_securite_sociale"
      :ref="'nbSecuriteSociale-child-'+ indexRef "
      :default-value="this.rattachementChoice.nbSecuriteSociale"
      :birthDate="birthDate"
      @changeNumSecu="itemSelectedSecu"/>

  <RegimeObligatoire
    v-if="this.rattachementChoice.choice === 'autre'"
      :componentTexts="this.regimeListeChild"
      :default-value="this.formPmPp.beneficiaries.children[this.index].regimeObligatoire"
      :regime="this.rattachementChoice.regimeObligatoire"
      :personne="index"
      ref="regimeObligatoire"
      @update:modelValue="itemSelectedRegimeObligatoire"
  />
</template>

<script>
import SelectInput from "hm-mpe-widgets/src/lib-components/components/Widgets/SelectInput";
import {mapState} from "vuex";
import NbSecuriteSociale from "@/components/FormPpPm/NbSecuriteSociale";
import RegimeObligatoire from "@/components/FormPpPm/RegimeObligatoire";

export default {
  name: "RattachementSecu",
  components: {SelectInput, NbSecuriteSociale, RegimeObligatoire},
  emits: ["selectedValue"],

  props: {
    question : null,
    indexRef : null,
    defautValue: null,
    index: null,
  },
  data: function(){
    return {
      secu : null,
      rattachementQuestion: null,
      rattachementChoice: null,
      codeCaisse: null,
      submit : false,
      validName : false,
      numSecu: null,
      birthDate: null,
      regimeListeChild: {
        text: "Regime obligatoire",
        choices:[
          { RG:"Régime général" },
          { RL:"Régime local" }
        ],
      },

    }
  },
  created() {
    //console.log(this.formPmPp.rattachementChoices)
    this.rattachementChoice= {
      choice:'',
      nbSecuriteSociale:'',
      nbCaisseRegimeObligatoire:'',
      regimeObligatoire: ''
    }
    this.birthDate = this.formPmPp.beneficiaries.children[this.index].birthDate;
    this.rattachementQuestion = {...this.question, ...{ 'choices' : this.formPmPp.rattachementChoices}}
    this.rattachementChoice.choice = this.defautValue.choice
    this.rattachementChoice.nbSecuriteSociale = this.defautValue.nbSecuriteSociale
    this.rattachementChoice.nbCaisseRegimeObligatoire = this.defautValue.nbCaisseRegimeObligatoire
    this.rattachementChoice.regimeObligatoire = this.defautValue.regimeObligatoire

    this.secu = this.defautValue

    // if(this.formPmPp.beneficiaries.children[this.index].rattachementChoice){
    //         this.validName = true;
    //     }
  },
  methods: {
    getNbCaisseRegime() {
      if (this.formPmPp.beneficiaries.hasChildren && this.formPmPp.beneficiaries.children[this.index].nbCaisseRegimeObligatoire !== undefined ){
        return this.formPmPp.beneficiaries.children[this.index].nbCaisseRegimeObligatoire
      }else{
        this.rattachementChoice.nbCaisseRegimeObligatoire = this.formPmPp.nbCaisseRegimeObligatoire
        return this.formPmPp.nbCaisseRegimeObligatoire
      }
    },
    itemSelectedChoice(value){
      if(value!=""){
        this.validName = true;
      }else{
        this.validName=false;
      }
      this.rattachementChoice.choice = value
      this.itemSelected();
    },
    itemSelectedSecu(value){
      this.rattachementChoice.nbSecuriteSociale = value
      this.itemSelected();
    },
    itemSelectedCaisse(value){
      this.rattachementChoice.nbCaisseRegimeObligatoire = value
      this.itemSelected();
    },
    itemSelectedRegimeObligatoire(value){
      this.rattachementChoice.regimeObligatoire = value
      this.itemSelected();
    },
    itemSelected(){

      this.$emit('selectedValue', this.rattachementChoice)

    },

    submitField(){
      this.submit = true
      this.formPmPp.beneficiaries.children[this.index].rattachementChoice = this.rattachementChoice;
      if (this.rattachementChoice.choice && this.rattachementChoice.choice === 'autre'){
        let numSecu = this.$refs['nbSecuriteSociale-child-'+ this.indexRef].submitField()
        this.validName = !!numSecu
        return numSecu
      }

      this.validName = !!this.rattachementChoice.choice
      return this.rattachementChoice.choice
    }
  },
  computed: mapState([`formTexts`, `formPmPp`]),
}
</script>
