<template>
  <div class="mb-4">
    <RadioInput
      :submitted="submit"
      :question="questionText"
      :default-choice="this.inputValue"
      :name="'owner'"
      @update:modelValue="itemSelected"
    ></RadioInput>
  </div>
</template>

<script>
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";

export default {
  name: "Owner",
  components: { RadioInput },
  props: {
    questionText: null,
    defaultValue: null,
  },
  data: function () {
    return {
      inputValue: null,
      validName: false,
      submit: false,
    };
  },
  created() {
    this.inputValue = this.defaultValue;
  },
  methods: {
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      return this.inputValue && this.inputValue.length != 0;
    },
    itemSelected(itemKey) {
      this.validName = true;
      this.inputValue = itemKey;
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style scoped></style>
