<template>
  <div class="accordion-item garantee-accordion">
    <span class="accordion-header" :id="'flush-headingOne' + this.garantee">
      <button
        class="accordion-button collapsed d-inline-flex px-3"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#' + 'flush-collapseOne' + this.garantee"
        aria-expanded="false"
        :aria-controls="'flush-collapseOne' + this.garantee"
      >
        <div class="d-inline">{{ garanteesText[this.garantee + "_label"]["text"] }}</div>
        <div class="d-inline float-end offer-levels">
          <img
            v-for="i in offer.level"
            :key="i"
            class="d-inline"
            :src="require('@/assets/svg/Icon feather-plus-1.svg')"
          />
          <img
            v-for="i in 3 - offer.level"
            :key="i"
            class="d-inline"
            :src="require('@/assets/svg/Icon feather-plus-1-gray.svg')"
          />
        </div>
      </button>
    </span>
    <div
      :id="'flush-collapseOne' + this.garantee"
      class="accordion-collapse collapse"
      :aria-labelledby="'flush-headingOne' + this.garantee"
      :data-bs-parent="'#' + 'accordionFlushExample' + offer.level"
    >
      <div class="accordion-body p-0">
        <div class="row">
          <div class="col">
            <AccordionItemGarantee
              :iconClass="iconClass"
              :depense="depenseFirstGarantee"
              :sub-garantee-label="subGaranteeFirstLabel"
              :sub-garantee-body="subGaranteeFirstBody"
              :sub-garantee-reste="subGaranteeFirstReste"
              :sub-garantee-tooltip="subGaranteeFirstTooltip"
              :charge="this.chargeFirst"
            />
            <AccordionItemGarantee
              :iconClass="iconClass"
              :depense="depenseLastGarantee"
              :sub-garantee-label="subGaranteeLastLabel"
              :sub-garantee-body="subGaranteeLastBody"
              :sub-garantee-reste="subGaranteeLastReste"
              :sub-garantee-tooltip="subGaranteeLastTooltip"
              :charge="this.chargeLast"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AccordionItemGarantee from "@/components/Offers/AccordionItemGarantee";

export default {
  name: "AccordionItem",
  components: { AccordionItemGarantee },
  props: {
    offer: Object,
    garantee: String,
    subGaranteeFirst: String,
    subGaranteeLast: String,
    iconClass: String,
    HM_Charge: String,
  },
  data: function () {
    return {
      depenseFirstGarantee: String,
      depenseLastGarantee: String,
      subGaranteeFirstLabel: String,
      subGaranteeFirstBody: String,
      subGaranteeLastLabel: String,
      subGaranteeLastBody: String,
      resteAChargeTexts: Object,
      garanteesText: Object,
      garanteeTooltip: Object,
      subGaranteeFirstReste: String,
      subGaranteeLastReste: String,
      subGaranteeFirstTooltip: String,
      subGaranteeLastTooltip: String,
      chargeFirst: String,
      chargeLast: String,
      priseEnCharge: Object
    };
  },
  created() {

    this.priseEnCharge = this.formTexts.prise_en_charge;
    
    this.resteAChargeTexts = this.formTexts.reste_a_charge;

    this.garanteesText = this.formTexts.offre_garanties;

    if(this.offer.name=="Économique")
    {
      this.offerName = "economique"
    }
    if(this.offer.name=="Économique budget malin")
    {
      this.offerName = "economique_budget_malin"
    }
    if(this.offer.name=="Confort")
    {
      this.offerName = "confort"
    }
    if(this.offer.name=="Optimal")
    {
      this.offerName = "optimal"
    }
   
    if("mpe112" == this.offer.product)
    {
      this.chargeFirst = eval("this.formTexts.prise_en_charge." + this.garantee + "_1_" +this.offer.product+"_economique_budget_malin." + this.garantee + "_1_" +this.offer.product+"_economique_budget_malin")
      this.chargeLast = eval("this.formTexts.prise_en_charge." + this.garantee + "_2_" +this.offer.product+"_economique_budget_malin."  + this.garantee + "_2_" +this.offer.product+"_economique_budget_malin")

    }
    if("mpe110"== this.offer.product)
    {
      this.chargeFirst = eval("this.formTexts.prise_en_charge."  + this.garantee  + "_1_" +this.offer.product+"_economique." +  this.garantee  + "_1_" +this.offer.product+"_economique")
      this.chargeLast = eval("this.formTexts.prise_en_charge."  + this.garantee  + "_2_" +this.offer.product+"_economique."  + this.garantee  + "_2_" +this.offer.product+"_economique")

    }
    if("mpe220" == this.offer.product)
    {
      this.chargeFirst = eval("this.formTexts.prise_en_charge." + this.garantee  + "_1_" +this.offer.product+"_confort." + this.garantee  + "_1_" +this.offer.product+"_confort")
      this.chargeLast = eval("this.formTexts.prise_en_charge."  + this.garantee  + "_2_" +this.offer.product+"_confort."  + this.garantee  + "_2_" +this.offer.product+"_confort")

    }
    if("mpeFF0" == this.offer.product)
    {
      this.chargeFirst = eval("this.formTexts.prise_en_charge."  + this.garantee  + "_1_" + this.offer.product+"_optimal."  + this.garantee  + "_1_" +this.offer.product+"_optimal")
      this.chargeLast = eval("this.formTexts.prise_en_charge."  + this.garantee  + "_2_" + this.offer.product+"_optimal." + this.garantee  + "_2_" +this.offer.product+"_optimal")

    }


    this.depenseFirstGarantee =
      this.garanteesText[this.garantee + "_" + this.subGaranteeFirst + "_depense"]["text"];
    this.depenseLastGarantee =
      this.garanteesText[this.garantee + "_" + this.subGaranteeLast + "_depense"]["text"];

    this.subGaranteeFirstLabel =
      this.garanteesText[this.garantee + "_" + this.subGaranteeFirst + "_label"]["text"];
    this.subGaranteeFirstBody =
      this.garanteesText[this.garantee + "_" + this.subGaranteeFirst + "_body"]["text"];
    this.subGaranteeFirstTooltip =
    this.garanteesText[this.garantee + "_" + this.subGaranteeFirst + "_tooltip"]["text"] + this.priseEnCharge[this.garantee + "_1_" + this.offer.product + "_" + this.offerName] ["text"];

    this.subGaranteeLastLabel =
      this.garanteesText[this.garantee + "_" + this.subGaranteeLast + "_label"]["text"];
    this.subGaranteeLastBody =
      this.garanteesText[this.garantee + "_" + this.subGaranteeLast + "_body"]["text"];
    this.subGaranteeLastTooltip =
    this.garanteesText[this.garantee + "_" + this.subGaranteeLast + "_tooltip"]["text"] + this.priseEnCharge[this.garantee + "_2_" + this.offer.product + "_" + this.offerName] ["text"];

    this.subGaranteeFirstReste =
      this.resteAChargeTexts[
        this.garantee + "_" + this.subGaranteeFirst + "_" + this.offer.product + ""
      ]["text"];
    this.subGaranteeLastReste =
      this.resteAChargeTexts[
        this.garantee + "_" + this.subGaranteeLast + "_" + this.offer.product + ""
      ]["text"];
  },
  computed: mapState([`formTexts`]),
};
</script>

<style scoped></style>
