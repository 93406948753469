<template>
  <div class="row justify-content-center">
    <div class="col-8 mt-5">
      <div class="row mb-5">
        <div class="col-12">
          <h1>Oops, la page que vous cherchez ne semble pas exister ....</h1>
        </div>
      </div>
    </div>
  </div>
</template>
