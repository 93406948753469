<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <Civility
        :component-texts="viewTexts.civilite"
        :default-value="this.beneficiary.civility"
        ref="civility"
      />
      <LastName
        :component-texts="viewTexts.nom_famille"
        :default-value="this.beneficiary.lastName"
        v-model="lastName"
        ref="lastName"
      />
      <FirstName
        :component-texts="viewTexts.prenom"
        :default-value="this.beneficiary.firstName"
        ref="firstName"
      />
      <DateInput
        :component-texts="viewTexts.naissance"
        :default-value="this.beneficiary.birthDate"
        ref="birthDate"
      />
      <div class="row mb-3">
        <div class="d-none d-md-block pe-5 col-md-6"></div>
        <div class="col-12 col-md-5 mb-3 ps-5 justify-content-start text-start">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="this.beneficiary.aliveChoice"
              id="aliveChoice"
              ref="aliveChoice"
            />
            <label class="form-check-label" for="aliveChoice">
              Vivant(e) ou représenté(e)
            </label>
          </div>
        </div>
      </div>

      <BirthLastName
        :component-texts="viewTexts.souscription.nom_naissance"
        :default-value="this.beneficiary.birthLastName"
        :lastNameValue="lastName"
        ref="birthLastName"
      />
      <BirthCity
        :component-texts="viewTexts.souscription.comune_naissance"
        :default-value="this.beneficiary.birthCity"
        ref="birthCity"
      />
      <Address
        :component-texts="this.formTexts.informations_personnelles.rue"
        :default-value="this.beneficiary.address"
        ref="address"
      />
      <ZipCode
        :component-texts="this.formTexts.informations_personnelles.code_postal"
        :default-value="this.beneficiary.zipCode"
        @updateZipCode="completeCity"
        ref="zipCode"
      />
      <City
        :component-texts="this.formTexts.informations_personnelles.ville"
        :cities="cities"
        :default-value="this.beneficiary.city"
        ref="city"
      />
      <PriorityOrder
        :component-texts="viewTexts.souscription.ordre_priorite"
        :default-value="this.beneficiary.priorityOrder"
        ref="priorityOrder"
      />
      <Part
        max-value="100"
        :component-texts="viewTexts.souscription.part"
        :default-value="this.beneficiary.part"
        :equalPart="equalPart"
        :index="index"
        @update:modelValue="updatePart"
        ref="part"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Civility from "@/components/FormPpPm/Civility";
import LastName from "@/components/FormPpPm/LastName";
import FirstName from "@/components/FormPpPm/FirstName";
import City from "@/components/FormPpPm/City";
import ZipCode from "@/components/FormPpPm/ZipCode";
import Address from "@/components/FormPpPm/Address";
import DateInput from "hm-mpe-widgets/src/lib-components/components/Widgets/DateInput";
import BirthLastName from "@/components/FormPpPm/BirthLastName";
import BirthCity from "@/components/FormPpPm/BirthCity";
import PriorityOrder from "@/components/FormPpPm/PriorityOrder";
import Part from "@/components/FormPpPm/Part";
import { filterCities, getCityFromZipCode } from "@/services/geoApiService";


export default {
  name: "ClauseBeneficiaryForm",
  props: {
    index: null,
  },
  components: {
    Civility,
    LastName,
    FirstName,
    DateInput,
    BirthLastName,
    BirthCity,
    PriorityOrder,
    Part,
    City,
    ZipCode,
    Address,

  },
  data: function () {
    return {
      viewTexts: null,
      equalPart: null,
      lastName: null,
      cities: [],
      addresses: [],
      dataAddress: [],
      lastDataAddress: [],
      beneficiary: {
        civility: null,
        firstName: null,
        lastName: null,
        birthDate: null,
        aliveChoice: null,
        birthLastName: null,
        birthCity: null,
        address: null,
        zipCode: null,
        city: null,
        priorityOrder: null,
        part: null,
      },
    };
  },
  created() {
    if (this.formPmPp.clauseBeneficiaries[this.index]) {
      this.lastName = this.formPmPp.clauseBeneficiaries[this.index].lastName;
      this.beneficiary.civility =
        this.formPmPp.clauseBeneficiaries[this.index].civility;
      this.beneficiary.firstName =
        this.formPmPp.clauseBeneficiaries[this.index].firstName;
      this.beneficiary.lastName =
        this.formPmPp.clauseBeneficiaries[this.index].lastName;
      this.beneficiary.birthDate =
        this.formPmPp.clauseBeneficiaries[this.index].birthDate;
      this.beneficiary.aliveChoice =
        this.formPmPp.clauseBeneficiaries[this.index].aliveChoice;
      this.beneficiary.birthLastName =
        this.formPmPp.clauseBeneficiaries[this.index].birthLastName;
      this.beneficiary.birthCity =
        this.formPmPp.clauseBeneficiaries[this.index].birthCity;
      this.beneficiary.priorityOrder =
        this.formPmPp.clauseBeneficiaries[this.index].priorityOrder;
        
        this.beneficiary.address =
        this.formPmPp.clauseBeneficiaries[this.index].address;
        this.$refs.address.forceText(this.formPmPp.clauseBeneficiaries[this.index].address);
        this.beneficiary.zipCode =
        this.formPmPp.clauseBeneficiaries[this.index].zipCode;
        this.beneficiary.city =
        this.formPmPp.clauseBeneficiaries[this.index].city;
        this.completeCity( this.beneficiary.zipCode);
      this.beneficiary.part = this.formPmPp.partBeneficiaries[this.index].val;
    }
    this.viewTexts = this.formTexts.eligibilite;
    this.viewTexts.souscription =
      this.formTexts.clause_beneficiaire_souscription;
  },
  methods: {
    updatePart(val) {
      this.equalPart = this.formPmPp.partBeneficiaries[0].equalPart;

      this.formPmPp.partBeneficiaries[this.index].val = val;
      /*let data = {
        val: val,
        ref: this.index,
        equalPart: this.equalPart
      };*/
      //this.$emit("update:modelValue", data);
    },
    completeCity(zipCode) {
      getCityFromZipCode(zipCode).then((r) => {
        this.cities = filterCities(r.data);
      });
    },
  
    submitForm() {
      this.submit = true;
      let civility = this.$refs.civility.submitField();
      let lastName = this.$refs.lastName.submitField();
      let firstName = this.$refs.firstName.submitField();
      let birthDate = this.$refs.birthDate.submitField();
      let aliveChoice = this.$refs.aliveChoice.checked;
      let birthLastName = this.$refs.birthLastName.submitField();
      let birthCity = this.$refs.birthCity.submitField();
      let address = this.$refs.address.submitField();
      let zipCode = this.$refs.zipCode.submitField();
      let city = this.$refs.city.submitField();
      let priorityOrder = this.$refs.priorityOrder.submitField();
      let part = this.$refs.part.submitField();
      if (
        civility &&
        lastName &&
        firstName &&
        birthDate &&
        birthLastName &&
        birthCity &&
        address &&
        zipCode &&
        city &&
        priorityOrder
      ) {
        let res = {
          civility: civility,
          firstName: firstName,
          lastName: lastName,
          birthDate: birthDate,
          aliveChoice: aliveChoice,
          birthLastName: birthLastName,
          birthCity: birthCity,
          address: address,
          zipCode: zipCode,
          city: city,
          priorityOrder: priorityOrder,
          part: part,
        };
        return res;
      }
      return false;
    },
  },
  computed: mapState([`formTexts`, `formPmPp`]),
};
</script>

<style scoped></style>
