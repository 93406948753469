<template>
  <div class="container-fluid informations-personnelles-souscription" v-if="!this.isViewLoading">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-6 mt-4">
        <div class="row mb-5">
          <div class="col-12">
            <div>
              <h3 class="title-page " v-html="viewTexts.title"></h3>
              <h3>{{ viewTexts.encart_profil.text }}</h3>
              <div class="col-12 profile justify-content-center p-5">
                <div class="">
                  <ProfilProBeneficiaireForm :ref-name="'-main-benef-'" ref="mainBenef" :main-benef="true"
                    :benef-object="formPmPp" />
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="this.formPmPp.beneficiaries.hasPartner || this.formPmPp.beneficiaries.hasChildren" class="backgroundSecoundary container-fluid informations-personnelles-souscription">
    <div class=" row justify-content-center">
      <div class="col-11 col-lg-6 mt-4">
        <div class="row mb-5">
          <div class="col-12">
            <div>

              <div v-if="this.formPmPp.beneficiaries.hasPartner || this.formPmPp.beneficiaries.hasChildren">
                <p class="pt-5 titreBenef">{{ viewTexts.titre_beneficiares.text }}</p>
                <div v-if="this.formPmPp.beneficiaries.hasPartner">
                  <h3 class=" mt-5 mb-3">{{ viewTexts.encart_conjoint.text }}</h3>
                  <div class="col-12 profile justify-content-center p-5">
                    <div class="row">
                      <div class="col-12 p-4 form-check">
                        <ProfilProBeneficiaireForm :ref-name="'-conjoint-'" ref="conjointForm" :conjoint-benef="true"
                          :benef-object="formPmPp.beneficiaries.partner" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="this.formPmPp.beneficiaries.hasChildren">
                  <h3 class=" mt-5 mb-3">{{ viewTexts.encart_enfants.text }}</h3>
                  <div class="col-12  profile justify-content-center p-5 mb-3" :key="index"
                    v-for="(child, index) in this.formPmPp.beneficiaries.children ">
                    <ProfilProBeneficiaireForm :ref-name="'-child-' + index" :ref="'childBenef' + index"
                      :benef-object="child" :child-index="index" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterNavigation @submitStep="submitForm" />
  <Encart :component-texts="viewTexts.blocs_texte" />
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import ProfilProBeneficiaireForm from "@/components/Souscription/ProfilProBeneficiaireForm";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "InformationsPersonnelles",
  components: {
    FooterNavigation,
    Encart,
    ProfilProBeneficiaireForm
  },
  methods: {
    submitForm() {
      this.submit = true;
      let validForms = [];

      let mainBenef = this.$refs.mainBenef.submitForm();

      validForms.push(!!mainBenef)

      if (this.formPmPp.beneficiaries.hasPartner) {
        let conjointForm = this.$refs.conjointForm.submitForm();
        validForms.push(!!conjointForm)
      }

      if (this.formPmPp.beneficiaries.hasChildren) {
        let that = this
        let childrenFormsResult = [];
        this.formPmPp.beneficiaries.children.forEach(function (child, i) {
          childrenFormsResult.push(that.$refs['childBenef' + i].submitForm())
        })

        validForms.push(childrenFormsResult.every((c) => !!c))
      }

      console.log(validForms)
      if (validForms.every((f) => !!f)) {
        this.$store.state.avancementSouscription[1] = true;
        this.$store.dispatch('updateState');

        this.$store.dispatch('updateContactPM')
        this.nextRoute();
      }
    },

    nextRoute() {
      this.$router.push({ name: "ClauseBeneficiary" });
    },
  },
  data: function () {
    return {
      submit: false,
      smsAgreement: null,
      emailAgreement: null,
      identicalMails: true,
      confirmEmail: false,
      numerosSecu: [],
      mainLastName: null
    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    wrapper.setTcVars({
      page_name : 'informations-complementaires',
      page_cat1_name : 'souscription',
    });
    this.$store.commit("setNextRoute", "Devis");
    this.beneficiaries = this.$store.state.formPmPp.beneficiaries;
    this.viewTexts = this.formTexts.infos_perso_souscription;
    this.mainLastName = this.formPmPp.lastName
    reloadEvent('page');
  },
  computed: mapState([`formTexts`, `formPmPp`, `isViewLoading`]),
};
</script>
