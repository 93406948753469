<template>
  <div class="bg-light p-3 mb-2">
    <span class="garanteeTitle"
      ><strong><i :class="'bi bi-' + iconClass"></i> {{ subGaranteeLabel }}</strong></span>

    <div class="row">
      <div class="col-12">
        <div class="garanteeBody" v-html="subGaranteeBody.replace('{{ depense }}', depense)"></div>
      </div>
    </div>

    <div class="row text-center">
      <div class="col depense">
        <span class="d-block"><strong>Dépense engagée</strong></span>
        <span class="d-block amount"><strong>{{ depense }} €</strong></span>
      </div>
      <div class="col reste">
        <span class="d-block"><strong>Reste à charge</strong></span>
        <span class="d-block amount"><strong>{{ subGaranteeReste.replace('.', ',') }} €</strong></span>
      </div>
    </div>

    <div class="accordion accordion-tooltip" id="accordionTooltip">
      <div class="accordion-item">
        <h3 class="accordion-header title-page" id="headingOne">
          <button
            class="accordion-button text-end px-3 py-1"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + uid"
            aria-expanded="true"
            :aria-controls="'collapse' + uid">
            <img :src="require('@/assets/svg/Icon awesome-info-circle.svg')" />
          </button>
        </h3>
        <div
          :id="'collapse' + uid"
          class="accordion-collapse collapse inline-block"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body py-0 px-2 pb-0" v-html="subGaranteeTooltip"></div>
          <div class="accordion-body py-0 px-2 pb-0" v-html="charge"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqId } from "@/services/utils.js";

export default {
  name: "AccordionItemGarantee",
  props: {
    subGaranteeLabel: String,
    subGaranteeBody: String,
    depense: String,
    subGaranteeReste: String,
    subGaranteeTooltip: String,
    iconClass: String,
    charge: String,
  },
  data: function () {
    return {
      uid: null,
    };
  },
  created() {
    this.uid = uniqId();
  },
};
</script>
