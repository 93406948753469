<template>
  <div class="row justify-content-center question text-input">
    <div class="col-12 col-md-6 text-centered pt-2">
      <QuestionLabel :question="componentTexts" :no-mandatory="noMandatory" :optionalText="textIn" />
    </div>
    <div class="col-12 col-md-6 mb-3">
      <div class="input-group">
        <Select2
          class="form-control p-0 border-0 d-flex"
          :class="{'border-success' : this.inputValue != null &&  this.inputValue != '' }"
          :disabled="(this.cities && !this.cities.length > 0) || this.disabled"
          v-model="inputValue"
          ref="selectList"
          :options="this.cities"
          @change="changeCity($event)"
        />
        <i v-if="inputValue" class="m-2 bi bi-check-circle-fill validIcon"></i>
      </div>
      <div class="pt-1 col-12 text-start form-error form-error-text">
        <div v-if="!inputValue && submit" class="d-inline">Ce champ est obligatoire</div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
//import { validateAlphabet } from "@/services/inputValidator";
import QuestionLabel from "hm-mpe-widgets/src/lib-components/components/Widgets/QuestionLabel";


export default {
  name: "City",
  components: { Select2, QuestionLabel },
  props: {
    componentTexts: null,
    defaultValue: null,
    cities: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noMandatory: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      inputValue: null,
      validName: false,
      submit: false,
      validFormat: false,
    };
  },
  created() {
    this.inputValue = this.defaultValue;
  },
  methods: {
    setText(value) {
      this.inputValue = value;
    },
    setCity(city) {
      this.inputValue = city;
      // this.$refs.selectList.setValue(city)
    },
    changeCity(event){
      this.inputValue=event.target.value;
      this.validateField();
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
     /* let validator = validateAlphabet(this.inputValue, this.validName, this.validFormat);
      this.validName = validator.validName;
      this.validFormat = validator.validFormat;*/

      this.validName = true;
      this.validFormat = true;

      return this.validName && this.validFormat;
    },
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
};
</script>

<style scoped></style>
