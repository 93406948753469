<template>
  <transition name="fade">
    <div>
      <SelectInput
        :valid-input="validName"
        v-model="inputValue"
        :defaultChoice="inputValue"
        :submitted="submit"
        :question="componentTexts"
        input-name="formJuridique"
        @update:modelValue="itemSelected"
      ></SelectInput>

    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import SelectInput from "hm-mpe-widgets/src/lib-components/components/Widgets/SelectInput";

export default {
  name: "FormeJuridique",
  components: { SelectInput },
  data: function () {
    return {
      validName: false,
      submit: false,
      inputValue: null,
    };
  },
  props: {
    componentTexts: null,
    defaultValue: null,
  },
  created() {
    this.inputValue = this.defaultValue;
    if (this.validateField()) {
        this.validName = true;
    }
  },
  methods: {
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        this.validName = true;
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      //optionnal field
      if(this.inputValue === null || this.inputValue === undefined || this.inputValue == ""){
        return false;
      }
      return true;
      //return this.inputValue && this.inputValue.length > 0;
    },
    itemSelected(itemKey) {
      this.validName = true;
      this.inputValue = itemKey;
    },
    forceSelect(itemKey){
      this.inputValue = itemKey
    }
  },
  watch: {
    inputValue() {
      if (this.validateField()) {
        this.validName = true;
    }else{
      this.validName = false;
    }
    },
  },
  computed: mapState([`formTexts`, `formPmPp`, `etablissement`]),
};
</script>

<style scoped></style>
