export function getNafs() {
    return {
        "nafs": [
            {id: "01.11Z", text: "01.11Z - Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses"},
            {id: "01.12Z", text: "01.12Z - Culture du riz"},
            {id: "01.13Z", text: "01.13Z - Culture de légumes, de melons, de racines et de tubercules"},
            {id: "01.14Z", text: "01.14Z - Culture de la canne à sucre"},
            {id: "01.15Z", text: "01.15Z - Culture du tabac"},
            {id: "01.16Z", text: "01.16Z - Culture de plantes à fibres"},
            {id: "01.19Z", text: "01.19Z - Autres cultures non permanentes"},
            {id: "01.21Z", text: "01.21Z - Culture de la vigne"},
            {id: "01.22Z", text: "01.22Z - Culture de fruits tropicaux et subtropicaux"},
            {id: "01.23Z", text: "01.23Z - Culture d'agrumes"},
            {id: "01.24Z", text: "01.24Z - Culture de fruits à pépins et à noyau"},
            {id: "01.25Z", text: "01.25Z - Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque"},
            {id: "01.26Z", text: "01.26Z - Culture de fruits oléagineux"},
            {id: "01.27Z", text: "01.27Z - Culture de plantes à boissons"},
            {id: "01.28Z", text: "01.28Z - Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques"},
            {id: "01.29Z", text: "01.29Z - Autres cultures permanentes"},
            {id: "01.30Z", text: "01.30Z - Reproduction de plantes"},
            {id: "01.41Z", text: "01.41Z - Élevage de vaches laitières"},
            {id: "01.42Z", text: "01.42Z - Élevage d'autres bovins et de buffles"},
            {id: "01.43Z", text: "01.43Z - Élevage de chevaux et d'autres équidés"},
            {id: "01.44Z", text: "01.44Z - Élevage de chameaux et d'autres camélidés"},
            {id: "01.45Z", text: "01.45Z - Élevage d'ovins et de caprins"},
            {id: "01.46Z", text: "01.46Z - Élevage de porcins"},
            {id: "01.47Z", text: "01.47Z - Élevage de volailles"},
            {id: "01.49Z", text: "01.49Z - Élevage d'autres animaux"},
            {id: "01.50Z", text: "01.50Z - Culture et élevage associés"},
            {id: "01.61Z", text: "01.61Z - Activités de soutien aux cultures"},
            {id: "01.62Z", text: "01.62Z - Activités de soutien à la production animale"},
            {id: "01.63Z", text: "01.63Z - Traitement primaire des récoltes"},
            {id: "01.64Z", text: "01.64Z - Traitement des semences"},
            {id: "01.70Z", text: "01.70Z - Chasse, piégeage et services annexes"},
            {id: "02.10Z", text: "02.10Z - Sylviculture et autres activités forestières"},
            {id: "02.20Z", text: "02.20Z - Exploitation forestière"},
            {id: "02.30Z", text: "02.30Z - Récolte de produits forestiers non ligneux poussant à l'état sauvage"},
            {id: "02.40Z", text: "02.40Z - Services de soutien à l'exploitation forestière"},
            {id: "03.11Z", text: "03.11Z - Pêche en mer"},
            {id: "03.12Z", text: "03.12Z - Pêche en eau douce"},
            {id: "03.21Z", text: "03.21Z - Aquaculture en mer"},
            {id: "03.22Z", text: "03.22Z - Aquaculture en eau douce"},
            {id: "05.10Z", text: "05.10Z - Extraction de houille"},
            {id: "05.20Z", text: "05.20Z - Extraction de lignite"},
            {id: "06.10Z", text: "06.10Z - Extraction de pétrole brut"},
            {id: "06.20Z", text: "06.20Z - Extraction de gaz naturel"},
            {id: "07.10Z", text: "07.10Z - Extraction de minerais de fer"},
            {id: "07.21Z", text: "07.21Z - Extraction de minerais d'uranium et de thorium"},
            {id: "07.29Z", text: "07.29Z - Extraction d'autres minerais de métaux non ferreux"},
            {id: "08.11Z", text: "08.11Z - Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise"},
            {id: "08.12Z", text: "08.12Z - Exploitation de gravières et sablières, extraction d'argiles et de kaolin"},
            {id: "08.91Z", text: "08.91Z - Extraction des minéraux chimiques et d'engrais minéraux"},
            {id: "08.92Z", text: "08.92Z - Extraction de tourbe"},
            {id: "08.93Z", text: "08.93Z - Production de sel"},
            {id: "08.99Z", text: "08.99Z - Autres activités extractives n.c.a."},
            {id: "09.10Z", text: "09.10Z - Activités de soutien à l'extraction d'hydrocarbures"},
            {id: "09.90Z", text: "09.90Z - Activités de soutien aux autres industries extractives"},
            {id: "10.11Z", text: "10.11Z - Transformation et conservation de la viande de boucherie"},
            {id: "10.12Z", text: "10.12Z - Transformation et conservation de la viande de volaille"},
            {id: "10.13A", text: "10.13A - Préparation industrielle de produits à base de viande"},
            {id: "10.13B", text: "10.13B - Charcuterie"},
            {id: "10.20Z", text: "10.20Z - Transformation et conservation de poisson, de crustacés et de mollusques"},
            {id: "10.31Z", text: "10.31Z - Transformation et conservation de pommes de terre"},
            {id: "10.32Z", text: "10.32Z - Préparation de jus de fruits et légumes"},
            {id: "10.39A", text: "10.39A - Autre transformation et conservation de légumes"},
            {id: "10.39B", text: "10.39B - Transformation et conservation de fruits"},
            {id: "10.41A", text: "10.41A - Fabrication d'huiles et graisses brutes"},
            {id: "10.41B", text: "10.41B - Fabrication d'huiles et graisses raffinées"},
            {id: "10.42Z", text: "10.42Z - Fabrication de margarine et graisses comestibles similaires"},
            {id: "10.51A", text: "10.51A - Fabrication de lait liquide et de produits frais"},
            {id: "10.51B", text: "10.51B - Fabrication de beurre"},
            {id: "10.51C", text: "10.51C - Fabrication de fromage"},
            {id: "10.51D", text: "10.51D - Fabrication d'autres produits laitiers"},
            {id: "10.52Z", text: "10.52Z - Fabrication de glaces et sorbets"},
            {id: "10.61A", text: "10.61A - Meunerie"},
            {id: "10.61B", text: "10.61B - Autres activités du travail des grains"},
            {id: "10.62Z", text: "10.62Z - Fabrication de produits amylacés"},
            {id: "10.71A", text: "10.71A - Fabrication industrielle de pain et de pâtisserie fraîche"},
            {id: "10.71B", text: "10.71B - Cuisson de produits de boulangerie"},
            {id: "10.71C", text: "10.71C - Boulangerie et boulangerie-pâtisserie"},
            {id: "10.71D", text: "10.71D - Pâtisserie"},
            {id: "10.72Z", text: "10.72Z - Fabrication de biscuits, biscottes et pâtisseries de conservation"},
            {id: "10.73Z", text: "10.73Z - Fabrication de pâtes alimentaires"},
            {id: "10.81Z", text: "10.81Z - Fabrication de sucre"},
            {id: "10.82Z", text: "10.82Z - Fabrication de cacao, chocolat et de produits de confiserie"},
            {id: "10.83Z", text: "10.83Z - Transformation du thé et du café"},
            {id: "10.84Z", text: "10.84Z - Fabrication de condiments et assaisonnements"},
            {id: "10.85Z", text: "10.85Z - Fabrication de plats préparés"},
            {id: "10.86Z", text: "10.86Z - Fabrication d'aliments homogénéisés et diététiques"},
            {id: "10.89Z", text: "10.89Z - Fabrication d'autres produits alimentaires n.c.a."},
            {id: "10.91Z", text: "10.91Z - Fabrication d'aliments pour animaux de ferme"},
            {id: "10.92Z", text: "10.92Z - Fabrication d'aliments pour animaux de compagnie"},
            {id: "11.01Z", text: "11.01Z - Production de boissons alcooliques distillées"},
            {id: "11.02A", text: "11.02A - Fabrication de vins effervescents"},
            {id: "11.02B", text: "11.02B - Vinification"},
            {id: "11.03Z", text: "11.03Z - Fabrication de cidre et de vins de fruits"},
            {id: "11.04Z", text: "11.04Z - Production d'autres boissons fermentées non distillées"},
            {id: "11.05Z", text: "11.05Z - Fabrication de bière"},
            {id: "11.06Z", text: "11.06Z - Fabrication de malt"},
            {id: "11.07A", text: "11.07A - Industrie des eaux de table"},
            {id: "11.07B", text: "11.07B - Production de boissons rafraîchissantes"},
            {id: "12.00Z", text: "12.00Z - Fabrication de produits à base de tabac"},
            {id: "13.10Z", text: "13.10Z - Préparation de fibres textiles et filature"},
            {id: "13.20Z", text: "13.20Z - Tissage"},
            {id: "13.30Z", text: "13.30Z - Ennoblissement textile"},
            {id: "13.91Z", text: "13.91Z - Fabrication d'étoffes à mailles"},
            {id: "13.92Z", text: "13.92Z - Fabrication d'articles textiles, sauf habillement"},
            {id: "13.93Z", text: "13.93Z - Fabrication de tapis et moquettes"},
            {id: "13.94Z", text: "13.94Z - Fabrication de ficelles, cordes et filets"},
            {id: "13.95Z", text: "13.95Z - Fabrication de non-tissés, sauf habillement"},
            {id: "13.96Z", text: "13.96Z - Fabrication d'autres textiles techniques et industriels"},
            {id: "13.99Z", text: "13.99Z - Fabrication d'autres textiles n.c.a."},
            {id: "14.11Z", text: "14.11Z - Fabrication de vêtements en cuir"},
            {id: "14.12Z", text: "14.12Z - Fabrication de vêtements de travail"},
            {id: "14.13Z", text: "14.13Z - Fabrication de vêtements de dessus"},
            {id: "14.14Z", text: "14.14Z - Fabrication de vêtements de dessous"},
            {id: "14.19Z", text: "14.19Z - Fabrication d'autres vêtements et accessoires"},
            {id: "14.20Z", text: "14.20Z - Fabrication d'articles en fourrure"},
            {id: "14.31Z", text: "14.31Z - Fabrication d'articles chaussants à mailles"},
            {id: "14.39Z", text: "14.39Z - Fabrication d'autres articles à mailles"},
            {id: "15.11Z", text: "15.11Z - Apprêt et tannage des cuirs ; préparation et teinture des fourrures"},
            {id: "15.12Z", text: "15.12Z - Fabrication d'articles de voyage, de maroquinerie et de sellerie"},
            {id: "15.20Z", text: "15.20Z - Fabrication de chaussures"},
            {id: "16.10A", text: "16.10A - Sciage et rabotage du bois, hors imprégnation"},
            {id: "16.10B", text: "16.10B - Imprégnation du bois"},
            {id: "16.21Z", text: "16.21Z - Fabrication de placage et de panneaux de bois"},
            {id: "16.22Z", text: "16.22Z - Fabrication de parquets assemblés"},
            {id: "16.23Z", text: "16.23Z - Fabrication de charpentes et d'autres menuiseries"},
            {id: "16.24Z", text: "16.24Z - Fabrication d'emballages en bois"},
            {id: "16.29Z", text: "16.29Z - Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie"},
            {id: "17.11Z", text: "17.11Z - Fabrication de pâte à papier"},
            {id: "17.12Z", text: "17.12Z - Fabrication de papier et de carton"},
            {id: "17.21A", text: "17.21A - Fabrication de carton ondulé"},
            {id: "17.21B", text: "17.21B - Fabrication de cartonnages"},
            {id: "17.21C", text: "17.21C - Fabrication d'emballages en papier"},
            {id: "17.22Z", text: "17.22Z - Fabrication d'articles en papier à usage sanitaire ou domestique"},
            {id: "17.23Z", text: "17.23Z - Fabrication d'articles de papeterie"},
            {id: "17.24Z", text: "17.24Z - Fabrication de papiers peints"},
            {id: "17.29Z", text: "17.29Z - Fabrication d'autres articles en papier ou en carton"},
            {id: "18.11Z", text: "18.11Z - Imprimerie de journaux"},
            {id: "18.12Z", text: "18.12Z - Autre imprimerie (labeur)"},
            {id: "18.13Z", text: "18.13Z - Activités de pré-presse"},
            {id: "18.14Z", text: "18.14Z - Reliure et activités connexes"},
            {id: "18.20Z", text: "18.20Z - Reproduction d'enregistrements"},
            {id: "19.10Z", text: "19.10Z - Cokéfaction"},
            {id: "19.20Z", text: "19.20Z - Raffinage du pétrole"},
            {id: "20.11Z", text: "20.11Z - Fabrication de gaz industriels"},
            {id: "20.12Z", text: "20.12Z - Fabrication de colorants et de pigments"},
            {id: "20.13A", text: "20.13A - Enrichissement et retraitement de matières nucléaires"},
            {id: "20.13B", text: "20.13B - Fabrication d'autres produits chimiques inorganiques de base n.c.a."},
            {id: "20.14Z", text: "20.14Z - Fabrication d'autres produits chimiques organiques de base"},
            {id: "20.15Z", text: "20.15Z - Fabrication de produits azotés et d'engrais"},
            {id: "20.16Z", text: "20.16Z - Fabrication de matières plastiques de base"},
            {id: "20.17Z", text: "20.17Z - Fabrication de caoutchouc synthétique"},
            {id: "20.20Z", text: "20.20Z - Fabrication de pesticides et d'autres produits agrochimiques"},
            {id: "20.30Z", text: "20.30Z - Fabrication de peintures, vernis, encres et mastics"},
            {id: "20.41Z", text: "20.41Z - Fabrication de savons, détergents et produits d'entretien"},
            {id: "20.42Z", text: "20.42Z - Fabrication de parfums et de produits pour la toilette"},
            {id: "20.51Z", text: "20.51Z - Fabrication de produits explosifs"},
            {id: "20.52Z", text: "20.52Z - Fabrication de colles"},
            {id: "20.53Z", text: "20.53Z - Fabrication d'huiles essentielles"},
            {id: "20.59Z", text: "20.59Z - Fabrication d'autres produits chimiques n.c.a."},
            {id: "20.60Z", text: "20.60Z - Fabrication de fibres artificielles ou synthétiques"},
            {id: "21.10Z", text: "21.10Z - Fabrication de produits pharmaceutiques de base"},
            {id: "21.20Z", text: "21.20Z - Fabrication de préparations pharmaceutiques"},
            {id: "22.11Z", text: "22.11Z - Fabrication et rechapage de pneumatiques"},
            {id: "22.19Z", text: "22.19Z - Fabrication d'autres articles en caoutchouc"},
            {id: "22.21Z", text: "22.21Z - Fabrication de plaques, feuilles, tubes et profilés en matières plastiques"},
            {id: "22.22Z", text: "22.22Z - Fabrication d'emballages en matières plastiques"},
            {id: "22.23Z", text: "22.23Z - Fabrication d'éléments en matières plastiques pour la construction"},
            {id: "22.29A", text: "22.29A - Fabrication de pièces techniques à base de matières plastiques"},
            {id: "22.29B", text: "22.29B - Fabrication de produits de consommation courante en matières plastiques"},
            {id: "23.11Z", text: "23.11Z - Fabrication de verre plat"},
            {id: "23.12Z", text: "23.12Z - Façonnage et transformation du verre plat"},
            {id: "23.13Z", text: "23.13Z - Fabrication de verre creux"},
            {id: "23.14Z", text: "23.14Z - Fabrication de fibres de verre"},
            {id: "23.19Z", text: "23.19Z - Fabrication et façonnage d'autres articles en verre, y compris verre technique"},
            {id: "23.20Z", text: "23.20Z - Fabrication de produits réfractaires"},
            {id: "23.31Z", text: "23.31Z - Fabrication de carreaux en céramique"},
            {id: "23.32Z", text: "23.32Z - Fabrication de briques, tuiles et produits de construction, en terre cuite"},
            {id: "23.41Z", text: "23.41Z - Fabrication d'articles céramiques à usage domestique ou ornemental"},
            {id: "23.42Z", text: "23.42Z - Fabrication d'appareils sanitaires en céramique"},
            {id: "23.43Z", text: "23.43Z - Fabrication d'isolateurs et pièces isolantes en céramique"},
            {id: "23.44Z", text: "23.44Z - Fabrication d'autres produits céramiques à usage technique"},
            {id: "23.49Z", text: "23.49Z - Fabrication d'autres produits céramiques"},
            {id: "23.51Z", text: "23.51Z - Fabrication de ciment"},
            {id: "23.52Z", text: "23.52Z - Fabrication de chaux et plâtre"},
            {id: "23.61Z", text: "23.61Z - Fabrication d'éléments en béton pour la construction"},
            {id: "23.62Z", text: "23.62Z - Fabrication d'éléments en plâtre pour la construction"},
            {id: "23.63Z", text: "23.63Z - Fabrication de béton prêt à l'emploi"},
            {id: "23.64Z", text: "23.64Z - Fabrication de mortiers et bétons secs"},
            {id: "23.65Z", text: "23.65Z - Fabrication d'ouvrages en fibre-ciment"},
            {id: "23.69Z", text: "23.69Z - Fabrication d'autres ouvrages en béton, en ciment ou en plâtre"},
            {id: "23.70Z", text: "23.70Z - Taille, façonnage et finissage de pierres"},
            {id: "23.91Z", text: "23.91Z - Fabrication de produits abrasifs"},
            {id: "23.99Z", text: "23.99Z - Fabrication d'autres produits minéraux non métalliques n.c.a."},
            {id: "24.10Z", text: "24.10Z - Sidérurgie"},
            {id: "24.20Z", text: "24.20Z - Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier"},
            {id: "24.31Z", text: "24.31Z - Étirage à froid de barres"},
            {id: "24.32Z", text: "24.32Z - Laminage à froid de feuillards"},
            {id: "24.33Z", text: "24.33Z - Profilage à froid par formage ou pliage"},
            {id: "24.34Z", text: "24.34Z - Tréfilage à froid"},
            {id: "24.41Z", text: "24.41Z - Production de métaux précieux"},
            {id: "24.42Z", text: "24.42Z - Métallurgie de l'aluminium"},
            {id: "24.43Z", text: "24.43Z - Métallurgie du plomb, du zinc ou de l'étain"},
            {id: "24.44Z", text: "24.44Z - Métallurgie du cuivre"},
            {id: "24.45Z", text: "24.45Z - Métallurgie des autres métaux non ferreux"},
            {id: "24.46Z", text: "24.46Z - Élaboration et transformation de matières nucléaires"},
            {id: "24.51Z", text: "24.51Z - Fonderie de fonte"},
            {id: "24.52Z", text: "24.52Z - Fonderie d'acier"},
            {id: "24.53Z", text: "24.53Z - Fonderie de métaux légers"},
            {id: "24.54Z", text: "24.54Z - Fonderie d'autres métaux non ferreux"},
            {id: "25.11Z", text: "25.11Z - Fabrication de structures métalliques et de parties de structures"},
            {id: "25.12Z", text: "25.12Z - Fabrication de portes et fenêtres en métal"},
            {id: "25.21Z", text: "25.21Z - Fabrication de radiateurs et de chaudières pour le chauffage central"},
            {id: "25.29Z", text: "25.29Z - Fabrication d'autres réservoirs, citernes et conteneurs métalliques"},
            {id: "25.30Z", text: "25.30Z - Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central"},
            {id: "25.40Z", text: "25.40Z - Fabrication d'armes et de munitions"},
            {id: "25.50A", text: "25.50A - Forge, estampage, matriçage ; métallurgie des poudres"},
            {id: "25.50B", text: "25.50B - Découpage, emboutissage"},
            {id: "25.61Z", text: "25.61Z - Traitement et revêtement des métaux"},
            {id: "25.62A", text: "25.62A - Décolletage"},
            {id: "25.62B", text: "25.62B - Mécanique industrielle"},
            {id: "25.71Z", text: "25.71Z - Fabrication de coutellerie"},
            {id: "25.72Z", text: "25.72Z - Fabrication de serrures et de ferrures"},
            {id: "25.73A", text: "25.73A - Fabrication de moules et modèles"},
            {id: "25.73B", text: "25.73B - Fabrication d'autres outillages"},
            {id: "25.91Z", text: "25.91Z - Fabrication de fûts et emballages métalliques similaires"},
            {id: "25.92Z", text: "25.92Z - Fabrication d'emballages métalliques légers"},
            {id: "25.93Z", text: "25.93Z - Fabrication d'articles en fils métalliques, de chaînes et de ressorts"},
            {id: "25.94Z", text: "25.94Z - Fabrication de vis et de boulons"},
            {id: "25.99A", text: "25.99A - Fabrication d'articles métalliques ménagers"},
            {id: "25.99B", text: "25.99B - Fabrication d'autres articles métalliques"},
            {id: "26.11Z", text: "26.11Z - Fabrication de composants électroniques"},
            {id: "26.12Z", text: "26.12Z - Fabrication de cartes électroniques assemblées"},
            {id: "26.20Z", text: "26.20Z - Fabrication d'ordinateurs et d'équipements périphériques"},
            {id: "26.30Z", text: "26.30Z - Fabrication d'équipements de communication"},
            {id: "26.40Z", text: "26.40Z - Fabrication de produits électroniques grand public"},
            {id: "26.51A", text: "26.51A - Fabrication d'équipements d'aide à la navigation"},
            {id: "26.51B", text: "26.51B - Fabrication d'instrumentation scientifique et technique"},
            {id: "26.52Z", text: "26.52Z - Horlogerie"},
            {id: "26.60Z", text: "26.60Z - Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques"},
            {id: "26.70Z", text: "26.70Z - Fabrication de matériels optique et photographique"},
            {id: "26.80Z", text: "26.80Z - Fabrication de supports magnétiques et optiques"},
            {id: "27.11Z", text: "27.11Z - Fabrication de moteurs, génératrices et transformateurs électriques"},
            {id: "27.12Z", text: "27.12Z - Fabrication de matériel de distribution et de commande électrique"},
            {id: "27.20Z", text: "27.20Z - Fabrication de piles et d'accumulateurs électriques"},
            {id: "27.31Z", text: "27.31Z - Fabrication de câbles de fibres optiques"},
            {id: "27.32Z", text: "27.32Z - Fabrication d'autres fils et câbles électroniques ou électriques"},
            {id: "27.33Z", text: "27.33Z - Fabrication de matériel d'installation électrique"},
            {id: "27.40Z", text: "27.40Z - Fabrication d'appareils d'éclairage électrique"},
            {id: "27.51Z", text: "27.51Z - Fabrication d'appareils électroménagers"},
            {id: "27.52Z", text: "27.52Z - Fabrication d'appareils ménagers non électriques"},
            {id: "27.90Z", text: "27.90Z - Fabrication d'autres matériels électriques"},
            {id: "28.11Z", text: "28.11Z - Fabrication de moteurs et turbines, à l'exception des moteurs d'avions et de véhicules"},
            {id: "28.12Z", text: "28.12Z - Fabrication d'équipements hydrauliques et pneumatiques"},
            {id: "28.13Z", text: "28.13Z - Fabrication d'autres pompes et compresseurs"},
            {id: "28.14Z", text: "28.14Z - Fabrication d'autres articles de robinetterie"},
            {id: "28.15Z", text: "28.15Z - Fabrication d'engrenages et d'organes mécaniques de transmission"},
            {id: "28.21Z", text: "28.21Z - Fabrication de fours et brûleurs"},
            {id: "28.22Z", text: "28.22Z - Fabrication de matériel de levage et de manutention"},
            {id: "28.23Z", text: "28.23Z - Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)"},
            {id: "28.24Z", text: "28.24Z - Fabrication d'outillage portatif à moteur incorporé"},
            {id: "28.25Z", text: "28.25Z - Fabrication d'équipements aérauliques et frigorifiques industriels"},
            {id: "28.29A", text: "28.29A - Fabrication d'équipements d'emballage, de conditionnement et de pesage"},
            {id: "28.29B", text: "28.29B - Fabrication d'autres machines d'usage général"},
            {id: "28.30Z", text: "28.30Z - Fabrication de machines agricoles et forestières"},
            {id: "28.41Z", text: "28.41Z - Fabrication de machines-outils pour le travail des métaux"},
            {id: "28.49Z", text: "28.49Z - Fabrication d'autres machines-outils"},
            {id: "28.91Z", text: "28.91Z - Fabrication de machines pour la métallurgie"},
            {id: "28.92Z", text: "28.92Z - Fabrication de machines pour l'extraction ou la construction"},
            {id: "28.93Z", text: "28.93Z - Fabrication de machines pour l'industrie agro-alimentaire"},
            {id: "28.94Z", text: "28.94Z - Fabrication de machines pour les industries textiles"},
            {id: "28.95Z", text: "28.95Z - Fabrication de machines pour les industries du papier et du carton"},
            {id: "28.96Z", text: "28.96Z - Fabrication de machines pour le travail du caoutchouc ou des plastiques"},
            {id: "28.99A", text: "28.99A - Fabrication de machines d'imprimerie"},
            {id: "28.99B", text: "28.99B - Fabrication d'autres machines spécialisées"},
            {id: "29.10Z", text: "29.10Z - Construction de véhicules automobiles"},
            {id: "29.20Z", text: "29.20Z - Fabrication de carrosseries et remorques"},
            {id: "29.31Z", text: "29.31Z - Fabrication d'équipements électriques et électroniques automobiles"},
            {id: "29.32Z", text: "29.32Z - Fabrication d'autres équipements automobiles"},
            {id: "30.11Z", text: "30.11Z - Construction de navires et de structures flottantes"},
            {id: "30.12Z", text: "30.12Z - Construction de bateaux de plaisance"},
            {id: "30.20Z", text: "30.20Z - Construction de locomotives et d'autre matériel ferroviaire roulant"},
            {id: "30.30Z", text: "30.30Z - Construction aéronautique et spatiale"},
            {id: "30.40Z", text: "30.40Z - Construction de véhicules militaires de combat"},
            {id: "30.91Z", text: "30.91Z - Fabrication de motocycles"},
            {id: "30.92Z", text: "30.92Z - Fabrication de bicyclettes et de véhicules pour invalides"},
            {id: "30.99Z", text: "30.99Z - Fabrication d'autres équipements de transport n.c.a."},
            {id: "31.01Z", text: "31.01Z - Fabrication de meubles de bureau et de magasin"},
            {id: "31.02Z", text: "31.02Z - Fabrication de meubles de cuisine"},
            {id: "31.03Z", text: "31.03Z - Fabrication de matelas"},
            {id: "31.09A", text: "31.09A - Fabrication de sièges d'ameublement d'intérieur"},
            {id: "31.09B", text: "31.09B - Fabrication d'autres meubles et industries connexes de l'ameublement"},
            {id: "32.11Z", text: "32.11Z - Frappe de monnaie"},
            {id: "32.12Z", text: "32.12Z - Fabrication d'articles de joaillerie et bijouterie"},
            {id: "32.13Z", text: "32.13Z - Fabrication d'articles de bijouterie fantaisie et articles similaires"},
            {id: "32.20Z", text: "32.20Z - Fabrication d'instruments de musique"},
            {id: "32.30Z", text: "32.30Z - Fabrication d'articles de sport"},
            {id: "32.40Z", text: "32.40Z - Fabrication de jeux et jouets"},
            {id: "32.50A", text: "32.50A - Fabrication de matériel médico-chirurgical et dentaire"},
            {id: "32.50B", text: "32.50B - Fabrication de lunettes"},
            {id: "32.91Z", text: "32.91Z - Fabrication d'articles de brosserie"},
            {id: "32.99Z", text: "32.99Z - Autres activités manufacturières n.c.a."},
            {id: "33.11Z", text: "33.11Z - Réparation d'ouvrages en métaux"},
            {id: "33.12Z", text: "33.12Z - Réparation de machines et équipements mécaniques"},
            {id: "33.13Z", text: "33.13Z - Réparation de matériels électroniques et optiques"},
            {id: "33.14Z", text: "33.14Z - Réparation d'équipements électriques"},
            {id: "33.15Z", text: "33.15Z - Réparation et maintenance navale"},
            {id: "33.16Z", text: "33.16Z - Réparation et maintenance d'aéronefs et d'engins spatiaux"},
            {id: "33.17Z", text: "33.17Z - Réparation et maintenance d'autres équipements de transport"},
            {id: "33.19Z", text: "33.19Z - Réparation d'autres équipements"},
            {id: "33.20A", text: "33.20A - Installation de structures métalliques, chaudronnées et de tuyauterie"},
            {id: "33.20B", text: "33.20B - Installation de machines et équipements mécaniques"},
            {id: "33.20C", text: "33.20C - Conception d'ensemble et assemblage sur site industriel d'équipements de contrôle des processus industriels"},
            {id: "33.20D", text: "33.20D - Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels"},
            {id: "35.11Z", text: "35.11Z - Production d'électricité"},
            {id: "35.12Z", text: "35.12Z - Transport d'électricité"},
            {id: "35.13Z", text: "35.13Z - Distribution d'électricité"},
            {id: "35.14Z", text: "35.14Z - Commerce d'électricité"},
            {id: "35.21Z", text: "35.21Z - Production de combustibles gazeux"},
            {id: "35.22Z", text: "35.22Z - Distribution de combustibles gazeux par conduites"},
            {id: "35.23Z", text: "35.23Z - Commerce de combustibles gazeux par conduites"},
            {id: "35.30Z", text: "35.30Z - Production et distribution de vapeur et d'air conditionné"},
            {id: "36.00Z", text: "36.00Z - Captage, traitement et distribution d'eau"},
            {id: "37.00Z", text: "37.00Z - Collecte et traitement des eaux usées"},
            {id: "38.11Z", text: "38.11Z - Collecte des déchets non dangereux"},
            {id: "38.12Z", text: "38.12Z - Collecte des déchets dangereux"},
            {id: "38.21Z", text: "38.21Z - Traitement et élimination des déchets non dangereux"},
            {id: "38.22Z", text: "38.22Z - Traitement et élimination des déchets dangereux"},
            {id: "38.31Z", text: "38.31Z - Démantèlement d'épaves"},
            {id: "38.32Z", text: "38.32Z - Récupération de déchets triés"},
            {id: "39.00Z", text: "39.00Z - Dépollution et autres services de gestion des déchets"},
            {id: "41.10A", text: "41.10A - Promotion immobilière de logements"},
            {id: "41.10B", text: "41.10B - Promotion immobilière de bureaux"},
            {id: "41.10C", text: "41.10C - Promotion immobilière d'autres bâtiments"},
            {id: "41.10D", text: "41.10D - Supports juridiques de programmes"},
            {id: "41.20A", text: "41.20A - Construction de maisons individuelles"},
            {id: "41.20B", text: "41.20B - Construction d'autres bâtiments"},
            {id: "42.11Z", text: "42.11Z - Construction de routes et autoroutes"},
            {id: "42.12Z", text: "42.12Z - Construction de voies ferrées de surface et souterraines"},
            {id: "42.13A", text: "42.13A - Construction d'ouvrages d'art"},
            {id: "42.13B", text: "42.13B - Construction et entretien de tunnels"},
            {id: "42.21Z", text: "42.21Z - Construction de réseaux pour fluides"},
            {id: "42.22Z", text: "42.22Z - Construction de réseaux électriques et de télécommunications"},
            {id: "42.91Z", text: "42.91Z - Construction d'ouvrages maritimes et fluviaux"},
            {id: "42.99Z", text: "42.99Z - Construction d'autres ouvrages de génie civil n.c.a."},
            {id: "43.11Z", text: "43.11Z - Travaux de démolition"},
            {id: "43.12A", text: "43.12A - Travaux de terrassement courants et travaux préparatoires"},
            {id: "43.12B", text: "43.12B - Travaux de terrassement spécialisés ou de grande masse"},
            {id: "43.13Z", text: "43.13Z - Forages et sondages"},
            {id: "43.21A", text: "43.21A - Travaux d'installation électrique dans tous locaux"},
            {id: "43.21B", text: "43.21B - Travaux d'installation électrique sur la voie publique"},
            {id: "43.22A", text: "43.22A - Travaux d'installation d'eau et de gaz en tous locaux"},
            {id: "43.22B", text: "43.22B - Travaux d'installation d'équipements thermiques et de climatisation"},
            {id: "43.29A", text: "43.29A - Travaux d'isolation"},
            {id: "43.29B", text: "43.29B - Autres travaux d'installation n.c.a."},
            {id: "43.31Z", text: "43.31Z - Travaux de plâtrerie"},
            {id: "43.32A", text: "43.32A - Travaux de menuiserie bois et PVC"},
            {id: "43.32B", text: "43.32B - Travaux de menuiserie métallique et serrurerie"},
            {id: "43.32C", text: "43.32C - Agencement de lieux de vente"},
            {id: "43.33Z", text: "43.33Z - Travaux de revêtement des sols et des murs"},
            {id: "43.34Z", text: "43.34Z - Travaux de peinture et vitrerie"},
            {id: "43.39Z", text: "43.39Z - Autres travaux de finition"},
            {id: "43.91A", text: "43.91A - Travaux de charpente"},
            {id: "43.91B", text: "43.91B - Travaux de couverture par éléments"},
            {id: "43.99A", text: "43.99A - Travaux d'étanchéification"},
            {id: "43.99B", text: "43.99B - Travaux de montage de structures métalliques"},
            {id: "43.99C", text: "43.99C - Travaux de maçonnerie générale et gros œuvre de bâtiment"},
            {id: "43.99D", text: "43.99D - Autres travaux spécialisés de construction"},
            {id: "43.99E", text: "43.99E - Location avec opérateur de matériel de construction"},
            {id: "45.11Z", text: "45.11Z - Commerce de voitures et de véhicules automobiles légers"},
            {id: "45.19Z", text: "45.19Z - Commerce d'autres véhicules automobiles"},
            {id: "45.20A", text: "45.20A - Entretien et réparation de véhicules automobiles légers"},
            {id: "45.20B", text: "45.20B - Entretien et réparation d'autres véhicules automobiles"},
            {id: "45.31Z", text: "45.31Z - Commerce de gros d'équipements automobiles"},
            {id: "45.32Z", text: "45.32Z - Commerce de détail d'équipements automobiles"},
            {id: "45.40Z", text: "45.40Z - Commerce et réparation de motocycles"},
            {id: "46.11Z", text: "46.11Z - Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis"},
            {id: "46.12A", text: "46.12A - Centrales d'achat de carburant"},
            {id: "46.12B", text: "46.12B - Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques"},
            {id: "46.13Z", text: "46.13Z - Intermédiaires du commerce en bois et matériaux de construction"},
            {id: "46.14Z", text: "46.14Z - Intermédiaires du commerce en machines, équipements industriels, navires et avions"},
            {id: "46.15Z", text: "46.15Z - Intermédiaires du commerce en meubles, articles de ménage et quincaillerie"},
            {id: "46.16Z", text: "46.16Z - Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir"},
            {id: "46.17A", text: "46.17A - Centrales d'achat alimentaires"},
            {id: "46.17B", text: "46.17B - Autres intermédiaires du commerce en denrées, boissons et tabac"},
            {id: "46.18Z", text: "46.18Z - Intermédiaires spécialisés dans le commerce d'autres produits spécifiques"},
            {id: "46.19A", text: "46.19A - Centrales d'achat non alimentaires"},
            {id: "46.19B", text: "46.19B - Autres intermédiaires du commerce en produits divers"},
            {id: "46.21Z", text: "46.21Z - Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail"},
            {id: "46.22Z", text: "46.22Z - Commerce de gros (commerce interentreprises) de fleurs et plantes"},
            {id: "46.23Z", text: "46.23Z - Commerce de gros (commerce interentreprises) d'animaux vivants"},
            {id: "46.24Z", text: "46.24Z - Commerce de gros (commerce interentreprises) de cuirs et peaux"},
            {id: "46.31Z", text: "46.31Z - Commerce de gros (commerce interentreprises) de fruits et légumes"},
            {id: "46.32A", text: "46.32A - Commerce de gros (commerce interentreprises) de viandes de boucherie"},
            {id: "46.32B", text: "46.32B - Commerce de gros (commerce interentreprises) de produits à base de viande"},
            {id: "46.32C", text: "46.32C - Commerce de gros (commerce interentreprises) de volailles et gibier"},
            {id: "46.33Z", text: "46.33Z - Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles"},
            {id: "46.34Z", text: "46.34Z - Commerce de gros (commerce interentreprises) de boissons"},
            {id: "46.35Z", text: "46.35Z - Commerce de gros (commerce interentreprises) de produits à base de tabac"},
            {id: "46.36Z", text: "46.36Z - Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie"},
            {id: "46.37Z", text: "46.37Z - Commerce de gros (commerce interentreprises) de café, thé, cacao et épices"},
            {id: "46.38A", text: "46.38A - Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques"},
            {id: "46.38B", text: "46.38B - Commerce de gros (commerce interentreprises) alimentaire spécialisé divers"},
            {id: "46.39A", text: "46.39A - Commerce de gros (commerce interentreprises) de produits surgelés"},
            {id: "46.39B", text: "46.39B - Commerce de gros (commerce interentreprises) alimentaire non spécialisé"},
            {id: "46.41Z", text: "46.41Z - Commerce de gros (commerce interentreprises) de textiles"},
            {id: "46.42Z", text: "46.42Z - Commerce de gros (commerce interentreprises) d'habillement et de chaussures"},
            {id: "46.43Z", text: "46.43Z - Commerce de gros (commerce interentreprises) d'appareils électroménagers"},
            {id: "46.44Z", text: "46.44Z - Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien"},
            {id: "46.45Z", text: "46.45Z - Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté"},
            {id: "46.46Z", text: "46.46Z - Commerce de gros (commerce interentreprises) de produits pharmaceutiques"},
            {id: "46.47Z", text: "46.47Z - Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'éclairage"},
            {id: "46.48Z", text: "46.48Z - Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie"},
            {id: "46.49Z", text: "46.49Z - Commerce de gros (commerce interentreprises) d'autres biens domestiques"},
            {id: "46.51Z", text: "46.51Z - Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels"},
            {id: "46.52Z", text: "46.52Z - Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication"},
            {id: "46.61Z", text: "46.61Z - Commerce de gros (commerce interentreprises) de matériel agricole"},
            {id: "46.62Z", text: "46.62Z - Commerce de gros (commerce interentreprises) de machines-outils"},
            {id: "46.63Z", text: "46.63Z - Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil"},
            {id: "46.64Z", text: "46.64Z - Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement"},
            {id: "46.65Z", text: "46.65Z - Commerce de gros (commerce interentreprises) de mobilier de bureau"},
            {id: "46.66Z", text: "46.66Z - Commerce de gros (commerce interentreprises) d'autres machines et équipements de bureau"},
            {id: "46.69A", text: "46.69A - Commerce de gros (commerce interentreprises) de matériel électrique"},
            {id: "46.69B", text: "46.69B - Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers"},
            {id: "46.69C", text: "46.69C - Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services"},
            {id: "46.71Z", text: "46.71Z - Commerce de gros (commerce interentreprises) de combustibles et de produits annexes"},
            {id: "46.72Z", text: "46.72Z - Commerce de gros (commerce interentreprises) de minerais et métaux"},
            {id: "46.73A", text: "46.73A - Commerce de gros (commerce interentreprises) de bois et de matériaux de construction"},
            {id: "46.73B", text: "46.73B - Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration"},
            {id: "46.74A", text: "46.74A - Commerce de gros (commerce interentreprises) de quincaillerie"},
            {id: "46.74B", text: "46.74B - Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage"},
            {id: "46.75Z", text: "46.75Z - Commerce de gros (commerce interentreprises) de produits chimiques"},
            {id: "46.76Z", text: "46.76Z - Commerce de gros (commerce interentreprises) d'autres produits intermédiaires"},
            {id: "46.77Z", text: "46.77Z - Commerce de gros (commerce interentreprises) de déchets et débris"},
            {id: "46.90Z", text: "46.90Z - Commerce de gros (commerce interentreprises) non spécialisé"},
            {id: "47.11A", text: "47.11A - Commerce de détail de produits surgelés"},
            {id: "47.11B", text: "47.11B - Commerce d'alimentation générale"},
            {id: "47.11C", text: "47.11C - Supérettes"},
            {id: "47.11D", text: "47.11D - Supermarchés"},
            {id: "47.11E", text: "47.11E - Magasins multi-commerces"},
            {id: "47.11F", text: "47.11F - Hypermarchés"},
            {id: "47.19A", text: "47.19A - Grands magasins"},
            {id: "47.19B", text: "47.19B - Autres commerces de détail en magasin non spécialisé"},
            {id: "47.21Z", text: "47.21Z - Commerce de détail de fruits et légumes en magasin spécialisé"},
            {id: "47.22Z", text: "47.22Z - Commerce de détail de viandes et de produits à base de viande en magasin spécialisé"},
            {id: "47.23Z", text: "47.23Z - Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé"},
            {id: "47.24Z", text: "47.24Z - Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé"},
            {id: "47.25Z", text: "47.25Z - Commerce de détail de boissons en magasin spécialisé"},
            {id: "47.26Z", text: "47.26Z - Commerce de détail de produits à base de tabac en magasin spécialisé"},
            {id: "47.29Z", text: "47.29Z - Autres commerces de détail alimentaires en magasin spécialisé"},
            {id: "47.30Z", text: "47.30Z - Commerce de détail de carburants en magasin spécialisé"},
            {id: "47.41Z", text: "47.41Z - Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé"},
            {id: "47.42Z", text: "47.42Z - Commerce de détail de matériels de télécommunication en magasin spécialisé"},
            {id: "47.43Z", text: "47.43Z - Commerce de détail de matériels audio et vidéo en magasin spécialisé"},
            {id: "47.51Z", text: "47.51Z - Commerce de détail de textiles en magasin spécialisé"},
            {id: "47.52A", text: "47.52A - Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m²)"},
            {id: "47.52B", text: "47.52B - Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m² et plus)"},
            {id: "47.53Z", text: "47.53Z - Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé"},
            {id: "47.54Z", text: "47.54Z - Commerce de détail d'appareils électroménagers en magasin spécialisé"},
            {id: "47.59A", text: "47.59A - Commerce de détail de meubles"},
            {id: "47.59B", text: "47.59B - Commerce de détail d'autres équipements du foyer"},
            {id: "47.61Z", text: "47.61Z - Commerce de détail de livres en magasin spécialisé"},
            {id: "47.62Z", text: "47.62Z - Commerce de détail de journaux et papeterie en magasin spécialisé"},
            {id: "47.63Z", text: "47.63Z - Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé"},
            {id: "47.64Z", text: "47.64Z - Commerce de détail d'articles de sport en magasin spécialisé"},
            {id: "47.65Z", text: "47.65Z - Commerce de détail de jeux et jouets en magasin spécialisé"},
            {id: "47.71Z", text: "47.71Z - Commerce de détail d'habillement en magasin spécialisé"},
            {id: "47.72A", text: "47.72A - Commerce de détail de la chaussure"},
            {id: "47.72B", text: "47.72B - Commerce de détail de maroquinerie et d'articles de voyage"},
            {id: "47.73Z", text: "47.73Z - Commerce de détail de produits pharmaceutiques en magasin spécialisé"},
            {id: "47.74Z", text: "47.74Z - Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé"},
            {id: "47.75Z", text: "47.75Z - Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé"},
            {id: "47.76Z", text: "47.76Z - Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé"},
            {id: "47.77Z", text: "47.77Z - Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé"},
            {id: "47.78A", text: "47.78A - Commerces de détail d'optique"},
            {id: "47.78B", text: "47.78B - Commerces de détail de charbons et combustibles"},
            {id: "47.78C", text: "47.78C - Autres commerces de détail spécialisés divers"},
            {id: "47.79Z", text: "47.79Z - Commerce de détail de biens d'occasion en magasin"},
            {id: "47.81Z", text: "47.81Z - Commerce de détail alimentaire sur éventaires et marchés"},
            {id: "47.82Z", text: "47.82Z - Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés"},
            {id: "47.89Z", text: "47.89Z - Autres commerces de détail sur éventaires et marchés"},
            {id: "47.91A", text: "47.91A - Vente à distance sur catalogue général"},
            {id: "47.91B", text: "47.91B - Vente à distance sur catalogue spécialisé"},
            {id: "47.99A", text: "47.99A - Vente à domicile"},
            {id: "47.99B", text: "47.99B - Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés n.c.a."},
            {id: "49.10Z", text: "49.10Z - Transport ferroviaire interurbain de voyageurs"},
            {id: "49.20Z", text: "49.20Z - Transports ferroviaires de fret"},
            {id: "49.31Z", text: "49.31Z - Transports urbains et suburbains de voyageurs"},
            {id: "49.32Z", text: "49.32Z - Transports de voyageurs par taxis"},
            {id: "49.39A", text: "49.39A - Transports routiers réguliers de voyageurs"},
            {id: "49.39B", text: "49.39B - Autres transports routiers de voyageurs"},
            {id: "49.39C", text: "49.39C - Téléphériques et remontées mécaniques"},
            {id: "49.41A", text: "49.41A - Transports routiers de fret interurbains"},
            {id: "49.41B", text: "49.41B - Transports routiers de fret de proximité"},
            {id: "49.41C", text: "49.41C - Location de camions avec chauffeur"},
            {id: "49.42Z", text: "49.42Z - Services de déménagement"},
            {id: "49.50Z", text: "49.50Z - Transports par conduites"},
            {id: "50.10Z", text: "50.10Z - Transports maritimes et côtiers de passagers"},
            {id: "50.20Z", text: "50.20Z - Transports maritimes et côtiers de fret"},
            {id: "50.30Z", text: "50.30Z - Transports fluviaux de passagers"},
            {id: "50.40Z", text: "50.40Z - Transports fluviaux de fret"},
            {id: "51.10Z", text: "51.10Z - Transports aériens de passagers"},
            {id: "51.21Z", text: "51.21Z - Transports aériens de fret"},
            {id: "51.22Z", text: "51.22Z - Transports spatiaux"},
            {id: "52.10A", text: "52.10A - Entreposage et stockage frigorifique"},
            {id: "52.10B", text: "52.10B - Entreposage et stockage non frigorifique"},
            {id: "52.21Z", text: "52.21Z - Services auxiliaires des transports terrestres"},
            {id: "52.22Z", text: "52.22Z - Services auxiliaires des transports par eau"},
            {id: "52.23Z", text: "52.23Z - Services auxiliaires des transports aériens"},
            {id: "52.24A", text: "52.24A - Manutention portuaire"},
            {id: "52.24B", text: "52.24B - Manutention non portuaire"},
            {id: "52.29A", text: "52.29A - Messagerie, fret express"},
            {id: "52.29B", text: "52.29B - Affrètement et organisation des transports"},
            {id: "53.10Z", text: "53.10Z - Activités de poste dans le cadre d'une obligation de service universel"},
            {id: "53.20Z", text: "53.20Z - Autres activités de poste et de courrier"},
            {id: "55.10Z", text: "55.10Z - Hôtels et hébergement similaire"},
            {id: "55.20Z", text: "55.20Z - Hébergement touristique et autre hébergement de courte durée"},
            {id: "55.30Z", text: "55.30Z - Terrains de camping et parcs pour caravanes ou véhicules de loisirs"},
            {id: "55.90Z", text: "55.90Z - Autres hébergements"},
            {id: "56.10A", text: "56.10A - Restauration traditionnelle"},
            {id: "56.10B", text: "56.10B - Cafétérias et autres libres-services"},
            {id: "56.10C", text: "56.10C - Restauration de type rapide"},
            {id: "56.21Z", text: "56.21Z - Services des traiteurs"},
            {id: "56.29A", text: "56.29A - Restauration collective sous contrat"},
            {id: "56.29B", text: "56.29B - Autres services de restauration n.c.a."},
            {id: "56.30Z", text: "56.30Z - Débits de boissons"},
            {id: "58.11Z", text: "58.11Z - Édition de livres"},
            {id: "58.12Z", text: "58.12Z - Édition de répertoires et de fichiers d'adresses"},
            {id: "58.13Z", text: "58.13Z - Édition de journaux"},
            {id: "58.14Z", text: "58.14Z - Édition de revues et périodiques"},
            {id: "58.19Z", text: "58.19Z - Autres activités d'édition"},
            {id: "58.21Z", text: "58.21Z - Édition de jeux électroniques"},
            {id: "58.29A", text: "58.29A - Édition de logiciels système et de réseau"},
            {id: "58.29B", text: "58.29B - Édition de logiciels outils de développement et de langages"},
            {id: "58.29C", text: "58.29C - Édition de logiciels applicatifs"},
            {id: "59.11A", text: "59.11A - Production de films et de programmes pour la télévision"},
            {id: "59.11B", text: "59.11B - Production de films institutionnels et publicitaires"},
            {id: "59.11C", text: "59.11C - Production de films pour le cinéma"},
            {id: "59.12Z", text: "59.12Z - Post-production de films cinématographiques, de vidéo et de programmes de télévision"},
            {id: "59.13A", text: "59.13A - Distribution de films cinématographiques"},
            {id: "59.13B", text: "59.13B - Édition et distribution vidéo"},
            {id: "59.14Z", text: "59.14Z - Projection de films cinématographiques"},
            {id: "59.20Z", text: "59.20Z - Enregistrement sonore et édition musicale"},
            {id: "60.10Z", text: "60.10Z - Édition et diffusion de programmes radio"},
            {id: "60.20A", text: "60.20A - Édition de chaînes généralistes"},
            {id: "60.20B", text: "60.20B - Édition de chaînes thématiques"},
            {id: "61.10Z", text: "61.10Z - Télécommunications filaires"},
            {id: "61.20Z", text: "61.20Z - Télécommunications sans fil"},
            {id: "61.30Z", text: "61.30Z - Télécommunications par satellite"},
            {id: "61.90Z", text: "61.90Z - Autres activités de télécommunication"},
            {id: "62.01Z", text: "62.01Z - Programmation informatique"},
            {id: "62.02A", text: "62.02A - Conseil en systèmes et logiciels informatiques"},
            {id: "62.02B", text: "62.02B - Tierce maintenance de systèmes et d'applications informatiques"},
            {id: "62.03Z", text: "62.03Z - Gestion d'installations informatiques"},
            {id: "62.09Z", text: "62.09Z - Autres activités informatiques"},
            {id: "63.11Z", text: "63.11Z - Traitement de données, hébergement et activités connexes"},
            {id: "63.12Z", text: "63.12Z - Portails Internet"},
            {id: "63.91Z", text: "63.91Z - Activités des agences de presse"},
            {id: "63.99Z", text: "63.99Z - Autres services d'information n.c.a."},
            {id: "64.11Z", text: "64.11Z - Activités de banque centrale"},
            {id: "64.19Z", text: "64.19Z - Autres intermédiations monétaires"},
            {id: "64.20Z", text: "64.20Z - Activités des sociétés holding"},
            {id: "64.30Z", text: "64.30Z - Fonds de placement et entités financières similaires"},
            {id: "64.91Z", text: "64.91Z - Crédit-bail"},
            {id: "64.92Z", text: "64.92Z - Autre distribution de crédit"},
            {id: "64.99Z", text: "64.99Z - Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a."},
            {id: "65.11Z", text: "65.11Z - Assurance vie"},
            {id: "65.12Z", text: "65.12Z - Autres assurances"},
            {id: "65.20Z", text: "65.20Z - Réassurance"},
            {id: "65.30Z", text: "65.30Z - Caisses de retraite"},
            {id: "66.11Z", text: "66.11Z - Administration de marchés financiers"},
            {id: "66.12Z", text: "66.12Z - Courtage de valeurs mobilières et de marchandises"},
            {id: "66.19A", text: "66.19A - Supports juridiques de gestion de patrimoine mobilier"},
            {id: "66.19B", text: "66.19B - Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a."},
            {id: "66.21Z", text: "66.21Z - Évaluation des risques et dommages"},
            {id: "66.22Z", text: "66.22Z - Activités des agents et courtiers d'assurances"},
            {id: "66.29Z", text: "66.29Z - Autres activités auxiliaires d'assurance et de caisses de retraite"},
            {id: "66.30Z", text: "66.30Z - Gestion de fonds"},
            {id: "68.10Z", text: "68.10Z - Activités des marchands de biens immobiliers"},
            {id: "68.20A", text: "68.20A - Location de logements"},
            {id: "68.20B", text: "68.20B - Location de terrains et d'autres biens immobiliers"},
            {id: "68.31Z", text: "68.31Z - Agences immobilières"},
            {id: "68.32A", text: "68.32A - Administration d'immeubles et autres biens immobiliers"},
            {id: "68.32B", text: "68.32B - Supports juridiques de gestion de patrimoine immobilier"},
            {id: "69.10Z", text: "69.10Z - Activités juridiques"},
            {id: "69.20Z", text: "69.20Z - Activités comptables"},
            {id: "70.10Z", text: "70.10Z - Activités des sièges sociaux"},
            {id: "70.21Z", text: "70.21Z - Conseil en relations publiques et communication"},
            {id: "70.22Z", text: "70.22Z - Conseil pour les affaires et autres conseils de gestion"},
            {id: "71.11Z", text: "71.11Z - Activités d'architecture"},
            {id: "71.12A", text: "71.12A - Activité des géomètres"},
            {id: "71.12B", text: "71.12B - Ingénierie, études techniques"},
            {id: "71.20A", text: "71.20A - Contrôle technique automobile"},
            {id: "71.20B", text: "71.20B - Analyses, essais et inspections techniques"},
            {id: "72.11Z", text: "72.11Z - Recherche-développement en biotechnologie"},
            {id: "72.19Z", text: "72.19Z - Recherche-développement en autres sciences physiques et naturelles"},
            {id: "72.20Z", text: "72.20Z - Recherche-développement en sciences humaines et sociales"},
            {id: "73.11Z", text: "73.11Z - Activités des agences de publicité"},
            {id: "73.12Z", text: "73.12Z - Régie publicitaire de médias"},
            {id: "73.20Z", text: "73.20Z - Études de marché et sondages"},
            {id: "74.10Z", text: "74.10Z - Activités spécialisées de design"},
            {id: "74.20Z", text: "74.20Z - Activités photographiques"},
            {id: "74.30Z", text: "74.30Z - Traduction et interprétation"},
            {id: "74.90A", text: "74.90A - Activité des économistes de la construction"},
            {id: "74.90B", text: "74.90B - Activités spécialisées, scientifiques et techniques diverses"},
            {id: "75.00Z", text: "75.00Z - Activités vétérinaires"},
            {id: "77.11A", text: "77.11A - Location de courte durée de voitures et de véhicules automobiles légers"},
            {id: "77.11B", text: "77.11B - Location de longue durée de voitures et de véhicules automobiles légers"},
            {id: "77.12Z", text: "77.12Z - Location et location-bail de camions"},
            {id: "77.21Z", text: "77.21Z - Location et location-bail d'articles de loisirs et de sport"},
            {id: "77.22Z", text: "77.22Z - Location de vidéocassettes et disques vidéo"},
            {id: "77.29Z", text: "77.29Z - Location et location-bail d'autres biens personnels et domestiques"},
            {id: "77.31Z", text: "77.31Z - Location et location-bail de machines et équipements agricoles"},
            {id: "77.32Z", text: "77.32Z - Location et location-bail de machines et équipements pour la construction"},
            {id: "77.33Z", text: "77.33Z - Location et location-bail de machines de bureau et de matériel informatique"},
            {id: "77.34Z", text: "77.34Z - Location et location-bail de matériels de transport par eau"},
            {id: "77.35Z", text: "77.35Z - Location et location-bail de matériels de transport aérien"},
            {id: "77.39Z", text: "77.39Z - Location et location-bail d'autres machines, équipements et biens matériels n.c.a."},
            {id: "77.40Z", text: "77.40Z - Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright"},
            {id: "78.10Z", text: "78.10Z - Activités des agences de placement de main-d'œuvre"},
            {id: "78.20Z", text: "78.20Z - Activités des agences de travail temporaire"},
            {id: "78.30Z", text: "78.30Z - Autre mise à disposition de ressources humaines"},
            {id: "79.11Z", text: "79.11Z - Activités des agences de voyage"},
            {id: "79.12Z", text: "79.12Z - Activités des voyagistes"},
            {id: "79.90Z", text: "79.90Z - Autres services de réservation et activités connexes"},
            {id: "80.10Z", text: "80.10Z - Activités de sécurité privée"},
            {id: "80.20Z", text: "80.20Z - Activités liées aux systèmes de sécurité"},
            {id: "80.30Z", text: "80.30Z - Activités d'enquête"},
            {id: "81.10Z", text: "81.10Z - Activités combinées de soutien lié aux bâtiments"},
            {id: "81.21Z", text: "81.21Z - Nettoyage courant des bâtiments"},
            {id: "81.22Z", text: "81.22Z - Autres activités de nettoyage des bâtiments et nettoyage industriel"},
            {id: "81.29A", text: "81.29A - Désinfection, désinsectisation, dératisation"},
            {id: "81.29B", text: "81.29B - Autres activités de nettoyage n.c.a."},
            {id: "81.30Z", text: "81.30Z - Services d'aménagement paysager"},
            {id: "82.11Z", text: "82.11Z - Services administratifs combinés de bureau"},
            {id: "82.19Z", text: "82.19Z - Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau"},
            {id: "82.20Z", text: "82.20Z - Activités de centres d'appels"},
            {id: "82.30Z", text: "82.30Z - Organisation de foires, salons professionnels et congrès"},
            {id: "82.91Z", text: "82.91Z - Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle"},
            {id: "82.92Z", text: "82.92Z - Activités de conditionnement"},
            {id: "82.99Z", text: "82.99Z - Autres activités de soutien aux entreprises n.c.a."},
            {id: "84.11Z", text: "84.11Z - Administration publique générale"},
            {id: "84.12Z", text: "84.12Z - Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale"},
            {id: "84.13Z", text: "84.13Z - Administration publique (tutelle) des activités économiques"},
            {id: "84.21Z", text: "84.21Z - Affaires étrangères"},
            {id: "84.22Z", text: "84.22Z - Défense"},
            {id: "84.23Z", text: "84.23Z - Justice"},
            {id: "84.24Z", text: "84.24Z - Activités d'ordre public et de sécurité"},
            {id: "84.25Z", text: "84.25Z - Services du feu et de secours"},
            {id: "84.30A", text: "84.30A - Activités générales de sécurité sociale"},
            {id: "84.30B", text: "84.30B - Gestion des retraites complémentaires"},
            {id: "84.30C", text: "84.30C - Distribution sociale de revenus"},
            {id: "85.10Z", text: "85.10Z - Enseignement pré-primaire"},
            {id: "85.20Z", text: "85.20Z - Enseignement primaire"},
            {id: "85.31Z", text: "85.31Z - Enseignement secondaire général"},
            {id: "85.32Z", text: "85.32Z - Enseignement secondaire technique ou professionnel"},
            {id: "85.41Z", text: "85.41Z - Enseignement post-secondaire non supérieur"},
            {id: "85.42Z", text: "85.42Z - Enseignement supérieur"},
            {id: "85.51Z", text: "85.51Z - Enseignement de disciplines sportives et d'activités de loisirs"},
            {id: "85.52Z", text: "85.52Z - Enseignement culturel"},
            {id: "85.53Z", text: "85.53Z - Enseignement de la conduite"},
            {id: "85.59A", text: "85.59A - Formation continue d'adultes"},
            {id: "85.59B", text: "85.59B - Autres enseignements"},
            {id: "85.60Z", text: "85.60Z - Activités de soutien à l'enseignement"},
            {id: "86.10Z", text: "86.10Z - Activités hospitalières"},
            {id: "86.21Z", text: "86.21Z - Activité des médecins généralistes"},
            {id: "86.22A", text: "86.22A - Activités de radiodiagnostic et de radiothérapie"},
            {id: "86.22B", text: "86.22B - Activités chirurgicales"},
            {id: "86.22C", text: "86.22C - Autres activités des médecins spécialistes"},
            {id: "86.23Z", text: "86.23Z - Pratique dentaire"},
            {id: "86.90A", text: "86.90A - Ambulances"},
            {id: "86.90B", text: "86.90B - Laboratoires d'analyses médicales"},
            {id: "86.90C", text: "86.90C - Centres de collecte et banques d'organes"},
            {id: "86.90D", text: "86.90D - Activités des infirmiers et des sages-femmes"},
            {id: "86.90E", text: "86.90E - Activités des professionnels de la rééducation, de l'appareillage et des pédicures-podologues"},
            {id: "86.90F", text: "86.90F - Activités de santé humaine non classées ailleurs"},
            {id: "87.10A", text: "87.10A - Hébergement médicalisé pour personnes âgées"},
            {id: "87.10B", text: "87.10B - Hébergement médicalisé pour enfants handicapés"},
            {id: "87.10C", text: "87.10C - Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé"},
            {id: "87.20A", text: "87.20A - Hébergement social pour handicapés mentaux et malades mentaux"},
            {id: "87.20B", text: "87.20B - Hébergement social pour toxicomanes"},
            {id: "87.30A", text: "87.30A - Hébergement social pour personnes âgées"},
            {id: "87.30B", text: "87.30B - Hébergement social pour handicapés physiques"},
            {id: "87.90A", text: "87.90A - Hébergement social pour enfants en difficultés"},
            {id: "87.90B", text: "87.90B - Hébergement social pour adultes et familles en difficultés et autre hébergement social"},
            {id: "88.10A", text: "88.10A - Aide à domicile"},
            {id: "88.10B", text: "88.10B - Accueil ou accompagnement sans hébergement d'adultes handicapés ou de personnes âgées"},
            {id: "88.10C", text: "88.10C - Aide par le travail"},
            {id: "88.91A", text: "88.91A - Accueil de jeunes enfants"},
            {id: "88.91B", text: "88.91B - Accueil ou accompagnement sans hébergement d'enfants handicapés"},
            {id: "88.99A", text: "88.99A - Autre accueil ou accompagnement sans hébergement d'enfants et d'adolescents"},
            {id: "88.99B", text: "88.99B - Action sociale sans hébergement n.c.a."},
            {id: "90.01Z", text: "90.01Z - Arts du spectacle vivant"},
            {id: "90.02Z", text: "90.02Z - Activités de soutien au spectacle vivant"},
            {id: "90.03A", text: "90.03A - Création artistique relevant des arts plastiques"},
            {id: "90.03B", text: "90.03B - Autre création artistique"},
            {id: "90.04Z", text: "90.04Z - Gestion de salles de spectacles"},
            {id: "91.01Z", text: "91.01Z - Gestion des bibliothèques et des archives"},
            {id: "91.02Z", text: "91.02Z - Gestion des musées"},
            {id: "91.03Z", text: "91.03Z - Gestion des sites et monuments historiques et des attractions touristiques similaires"},
            {id: "91.04Z", text: "91.04Z - Gestion des jardins botaniques et zoologiques et des réserves naturelles"},
            {id: "92.00Z", text: "92.00Z - Organisation de jeux de hasard et d'argent"},
            {id: "93.11Z", text: "93.11Z - Gestion d'installations sportives"},
            {id: "93.12Z", text: "93.12Z - Activités de clubs de sports"},
            {id: "93.13Z", text: "93.13Z - Activités des centres de culture physique"},
            {id: "93.19Z", text: "93.19Z - Autres activités liées au sport"},
            {id: "93.21Z", text: "93.21Z - Activités des parcs d'attractions et parcs à thèmes"},
            {id: "93.29Z", text: "93.29Z - Autres activités récréatives et de loisirs"},
            {id: "94.11Z", text: "94.11Z - Activités des organisations patronales et consulaires"},
            {id: "94.12Z", text: "94.12Z - Activités des organisations professionnelles"},
            {id: "94.20Z", text: "94.20Z - Activités des syndicats de salariés"},
            {id: "94.91Z", text: "94.91Z - Activités des organisations religieuses"},
            {id: "94.92Z", text: "94.92Z - Activités des organisations politiques"},
            {id: "94.99Z", text: "94.99Z - Autres organisations fonctionnant par adhésion volontaire"},
            {id: "95.11Z", text: "95.11Z - Réparation d'ordinateurs et d'équipements périphériques"},
            {id: "95.12Z", text: "95.12Z - Réparation d'équipements de communication"},
            {id: "95.21Z", text: "95.21Z - Réparation de produits électroniques grand public"},
            {id: "95.22Z", text: "95.22Z - Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin"},
            {id: "95.23Z", text: "95.23Z - Réparation de chaussures et d'articles en cuir"},
            {id: "95.24Z", text: "95.24Z - Réparation de meubles et d'équipements du foyer"},
            {id: "95.25Z", text: "95.25Z - Réparation d'articles d'horlogerie et de bijouterie"},
            {id: "95.29Z", text: "95.29Z - Réparation d'autres biens personnels et domestiques"},
            {id: "96.01A", text: "96.01A - Blanchisserie-teinturerie de gros"},
            {id: "96.01B", text: "96.01B - Blanchisserie-teinturerie de détail"},
            {id: "96.02A", text: "96.02A - Coiffure"},
            {id: "96.02B", text: "96.02B - Soins de beauté"},
            {id: "96.03Z", text: "96.03Z - Services funéraires"},
            {id: "96.04Z", text: "96.04Z - Entretien corporel"},
            {id: "96.09Z", text: "96.09Z - Autres services personnels n.c.a."},
            {id: "97.00Z", text: "97.00Z - Activités des ménages en tant qu'employeurs de personnel domestique"},
            {id: "98.10Z", text: "98.10Z - Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre"},
            {id: "98.20Z", text: "98.20Z - Activités indifférenciées des ménages en tant que producteurs de services pour usage propre"},
            {id: "99.00Z", text: "99.00Z - Activités des organisations et organismes extraterritoriaux"}
        ]
    }
}