<template>
  <div class="row justify-content-center question">
    <div class="col-6 text-centered pt-2"></div>
    <div v-if="subject == 'souscripteur'">
      <div class="row beneficiaire mt-4 mb-4 pb-4">
        <div class="col align-items-center">
          <h3 class="benef_sub_title title-page ps-3 pb-3 pt-5">IDENTITÉ</h3>
          <div class="ps-3">
            <strong
              ><p v-html="beneficiairesText.nom" class="d-inline"></p>
              <p class="d-inline"> :</p></strong
            >
            <p class="d-inline-block ms-1">
              {{ getCivilityByValue(formPmPp.civility) }} {{ formPmPp.lastName }}
              {{ formPmPp.firstName }}
            </p>
          </div>
          <div class="ps-3">
            <strong
              ><p
                v-html="beneficiairesText.naissance"
                class="d-inline-block"
              ></p>
              <p class="d-inline"> :</p></strong
            >
            <p class="d-inline-block ms-1">
              {{ formatDate(formPmPp.birthDate) }}
            </p>
          </div>
          <div class="ps-3">
            <strong
              class="d-inline-block"
              v-html="beneficiairesText.nomNaissance"
            ></strong>
            <p class="d-inline fw-bold"> :</p>
            <p
              class="d-inline ms-1 d-inline-block"
              v-html="formPmPp.lastName"
            ></p>
          </div>
          <div class="ps-3">
            <strong
              class="d-inline-block"
              v-html="beneficiairesText.lieuNaissance"
            ></strong>
            <p class="d-inline fw-bold"> :</p>
            <p v-html="formPmPp.birthCity" class="d-inline-block ms-1"></p>
          </div>
          <div class="ps-3">
            <strong
              ><p v-html="beneficiairesText.address" class="d-inline"></p>
              <p class="d-inline"> :</p></strong
            >
            <p  class="d-inline ms-1">{{ formPmPp.address }}, {{formPmPp.zipCode}} {{formPmPp.city}} </p>
          </div>
        </div>
        <div class="col">
          <div v-if="this.souscripteur == 'true'">
            <h3 class="benef_sub_title title-page ps-3 pb-2 pt-5">CONTACT</h3>
            <div class="ps-3 pb-3">
              <strong
                ><p v-html="beneficiairesText.tel" class="d-inline"></p>
                <p class="d-inline fw-bold"> :</p></strong
              >
              <p v-html="formPmPp.phoneNumber" class="d-inline ms-1"></p>
            </div>
            <div class="ps-3 pb-3">
              <strong
                ><p v-html="beneficiairesText.email" class="d-inline"></p>
                <p class="d-inline fw-bold"> :</p>
              </strong>
              <p v-html="formPmPp.email" class="d-inline ms-1"></p>
            </div>
          </div>
          <h3 class="benef_sub_title title-page ps-3 pb-2 pt-5">SÉCURITÉ SOCIALE</h3>
          <div class="ps-3 pb-3">
            <strong
              ><p v-html="beneficiairesText.secu" class="d-inline"></p>
              <p class="d-inline fw-bold"> :</p></strong
            >
            <p v-html="formPmPp.nbSecuriteSociale" class="d-inline ms-1"></p>
          </div>
          <div class="ps-3 pb-3">
            <strong
              ><p v-html="beneficiairesText.codeCaisse" class="d-inline"></p>
              <p class="d-inline fw-bold"> :</p></strong
            >
            <p
              v-html="formPmPp.nbCaisseRegimeObligatoire"
              class="d-inline ms-1"
            ></p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="subject == 'conjoint'">
      <div class="row beneficiaire mt-4 mb-4 pb-4">
        <div class="col align-items-center">
          <h3 class="benef_sub_title title-page  ps-3 pb-3 pt-5">IDENTITÉ</h3>
          <div class="ps-3">
            <strong
              ><p v-html="beneficiairesText.nom" class="d-inline"></p>
              <p class="d-inline"> :</p></strong
            >
            <p class="d-inline-block ms-1">
              {{ getCivilityByValue(beneficiaires.partner.civility) }}
              {{ beneficiaires.partner.lastName }}
              {{ beneficiaires.partner.firstName }}
            </p>
          </div>
          <div class="ps-3">
            <strong
              ><p
                v-html="beneficiairesText.naissance"
                class="d-inline-block"
              ></p>
              <p class="d-inline"> :</p></strong
            >
            <p class="d-inline-block ms-1">
              {{ formatDate(beneficiaires.partner.birthDate) }}
            </p>
          </div>
          <div class="ps-3">
            <strong
              class="d-inline-block"
              v-html="beneficiairesText.nomNaissance"
            ></strong>
            <p class="d-inline fw-bold"> :</p>
            <p
              class="d-inline ms-1 d-inline-block"
              v-html="beneficiaires.partner.lastName"
            ></p>
          </div>
          <div class="ps-3">
            <strong
              class="d-inline-block"
              v-html="beneficiairesText.lieuNaissance"
            ></strong>
            <p class="d-inline fw-bold"> :</p>
            <p
              v-html="beneficiaires.partner.birthCity"
              class="d-inline-block ms-1"
            ></p>
          </div>
        </div>
        <div class="col">
          <div v-if="this.souscripteur == 'true'">
            <h3 class="benef_sub_title title-page ps-3 pb-2 pt-5">CONTACT</h3>
            <div class="ps-3 pb-3">
              <strong
                ><p v-html="beneficiairesText.tel" class="d-inline"></p>
                <p class="d-inline fw-bold"> :</p></strong
              >
              <p
                v-html="beneficiaires.partner.phoneNumber"
                class="d-inline ms-1"
              ></p>
            </div>
            <div class="ps-3 pb-3">
              <strong
                ><p v-html="beneficiairesText.email" class="d-inline"></p>
                <p class="d-inline fw-bold"> :</p>
              </strong>
              <p v-html="beneficiaires.partner.email" class="d-inline ms-1"></p>
            </div>
          </div>
          <h3 class="benef_sub_title ps-3 pb-2 pt-5 title-page ">SÉCURITÉ SOCIALE</h3>
          <div class="ps-3 pb-3">
            <strong
              ><p v-html="beneficiairesText.secu" class="d-inline"></p>
              <p class="d-inline fw-bold"> :</p></strong
            >
            <p
              v-html="beneficiaires.partner.nbSecuriteSociale"
              class="d-inline ms-1"
            ></p>
          </div>
          <div class="ps-3 pb-3">
            <strong
              ><p v-html="beneficiairesText.codeCaisse" class="d-inline"></p>
              <p class="d-inline fw-bold"> :</p></strong
            >
            <p
              v-html="beneficiaires.partner.nbCaisseRegimeObligatoire"
              class="d-inline ms-1"
            ></p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="subject == 'children'">
      <div
        v-for="(beneficiaire, index) in beneficiaires.children"
        :key="'beneficiaire-' + index"
        class="row beneficiaire mt-4 mb-4 pb-4"
      >
        <div class="col align-items-center">
          <h3 class="benef_sub_title title-page ps-3 pb-3 pt-5">IDENTITÉ</h3>
          <div class="ps-3">
            <strong
              ><p v-html="beneficiairesText.nom" class="d-inline"></p>
              <p class="d-inline"> :</p></strong
            >
            <p class="d-inline-block ms-1">
              {{ beneficiaire.lastName + " " + beneficiaire.firstName }}
            </p>
          </div>
          <div class="ps-3">
            <strong
              ><p v-html="beneficiairesText.naissance" class="d-inline"></p>
              <p class="d-inline fw-bold"> :</p></strong
            >
            <p class="d-inline-block ms-1">
              {{ dateFRFormat(beneficiaire.birthDate) }}
            </p>
          </div>
          <div class="ps-3">
            <strong
              class="d-inline-block"
              v-html="beneficiairesText.nomNaissance"
            ></strong>
            <p class="d-inline fw-bold"> :</p>
            <p
              class="d-inline ms-1 d-inline-block"
              v-html="beneficiaire.lastName"
            ></p>
          </div>
          <div class="ps-3">
            <strong
              v-html="beneficiairesText.lieuNaissance"
              class="d-inline-block"
            ></strong
            ><strong><p class="d-inline fw-bold"> :</p></strong>
            <p v-html="beneficiaire.birthCity" class="d-inline ms-1"></p>
          </div>
        </div>
        <div class="col">
          <h3 class="benef_sub_title ps-3 pb-2 pt-5 title-page">SÉCURITÉ SOCIALE</h3>
          <div class="ps-3 pb-3">
            <strong
              ><p v-html="beneficiairesText.secu" class="d-inline"></p>
              <p class="d-inline fw-bold"> :</p></strong
            >
            <p v-if="beneficiaire.rattachementChoice.choice == 'autre'"
              v-html="beneficiaire.rattachementChoice.nbSecuriteSociale"
              class="d-inline ms-1"
            ></p>
            <p v-if="beneficiaire.rattachementChoice.choice != 'autre'"
              v-html="beneficiaire.rattachementChoice.choice"
              class="d-inline ms-1"
            ></p>
          </div>
          <div v-if="beneficiaire.rattachementChoice.choice == 'autre'" class="ps-3 pb-3">
            <strong
              ><p v-html="beneficiairesText.codeCaisse" class="d-inline"></p>
              <p class="d-inline fw-bold"> :</p></strong
            >
            <p
              v-html="beneficiaire.rattachementChoice.nbCaisseRegimeObligatoire"
              class="d-inline ms-1"
            ></p>
          </div>
          <div v-else class="ps-3 pb-3"> 
            <strong
              ><p v-html="beneficiairesText.codeCaisse" class="d-inline"></p>
              <p class="d-inline fw-bold"> :</p></strong
            >
            <p
              v-html="beneficiaires.partner.nbCaisseRegimeObligatoire"
              class="d-inline ms-1"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import templateHelpers from "@/services/templateHelpers";
import moment from "moment";

export default {
  name: "Beneficiaire",
  components: {},
  props: {
    subject: {
      type: String,
      default: "souscripteur",
    },
  },
  data: function () {
    return {
      beneficiaires: null,
      viewTexts: null,
    };
  },
  created() {
    this.viewTexts = this.formTexts.relecture_souscription;
    this.beneficiairesText = {
      nom: this.viewTexts.relecture_prevoyance_souscripteur_identite.text,
      naissance:
        this.viewTexts.relecture_prevoyance_souscripteur_naissance.text,
      nomNaissance:
        this.formTexts.clause_beneficiaire_souscription.nom_naissance
          .text,
      lieuNaissance:
        this.formTexts.clause_beneficiaire_souscription.comune_naissance
          .text,
      secu: this.viewTexts.relecture_sante_souscripteur_secu.text,
      codeCaisse:
        this.formTexts.infos_perso_souscription.label_num_caisse_regime.text,
      address:
        this.viewTexts.relecture_prevoyance_souscripteur_adresse.text,
      email: this.formTexts.informations_personnelles.telephone.text,
      tel: this.formTexts.informations_personnelles.email.text,
    };
    this.beneficiaires = this.$store.state.formPmPp.beneficiaries;
  },
  methods: {
    ...templateHelpers,
    formatDate(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
  },
  computed: mapState([`formTexts`, `formPmPp`]),
};
</script>


<style lang="scss">
</style>
