import '@babel/polyfill'
import 'mutationobserver-shim'
import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import router from './router'
import TC_Wrapper from 'vue-tag-commander';
import {isProduction} from "@/services/WizardHelperService";
import {detectDevice} from "./services/tagCo";

window.tc_vars = {
  env_work : isProduction() ? 'prod' : 'preprod',
  env_device : detectDevice(),
  env_template : "parcours-ma-protection-entrepreneur",
  env_url : window.location.host,
  env_ati_id : isProduction() ? 617978 : 617975,
  page_name : '',
  page_cat1_name : '',
  page_cat2_name : '',
  page_level_name : "parcours-ma-protection-entrepreneur",
  product_name : 'ma-protection-entrepreneur',
  product_id : 'mpe',
  product_type : 'sante',
  cart_id: '',
};

const wrapper = TC_Wrapper.getInstance()
// Set debug for development purpose if needed
wrapper.setDebug(true)
// Add TC Containers

let urlTagCoHeader;
let urlTagCoFooter;

if(isProduction()) {
  urlTagCoHeader = "//cdn.tagcommander.com/5013/tc_Parcoursdevente_header.js"
  urlTagCoFooter = "//cdn.tagcommander.com/5013/tc_Parcoursdevente_footer.js"
} else {
  urlTagCoHeader = "//cdn.tagcommander.com/5013/uat/tc_Parcoursdevente_header.js"
  urlTagCoFooter = "//cdn.tagcommander.com/5013/uat/tc_Parcoursdevente_footer.js"
}

Promise.all([
    wrapper.addContainer("container_head", urlTagCoHeader, "head"),
    wrapper.addContainer("container_body", urlTagCoFooter, "body"),
]).then(() => {
    const app = createApp(App).use(router);

    app.use(VueLoading);
    app.use(store);

    app.mount('#app');
})

export default wrapper;