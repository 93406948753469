<template>
  <div id="IPCS-questions" class="container">
    <transition name="fade">
      <div class="row justify-content-center mt-4">
        <div class="col-12 col-lg-10 col-xl-9">
          <h1 v-html="this.viewTexts.title"></h1>
          <div class="row">
            <div class="col-12 col-md-5">
              <div class="card custom-card">
                <div class="card-body">
                  <div class="row">
                    <h5 class="col-6 card-title ps-4 pt-4 pb-5">
                      VOTRE IDENTITÉ
                    </h5>
                    <h5 class="col-6 card-modify pe-4 pt-4 pb-5 d-flex justify-content-end">
                      <a href="#" @click="editIdentity()">
                        <img :src="require('@/assets/svg/Icon feather-edit.svg')" />
                        Modifier
                      </a>
                    </h5>
                  </div>
                  <div class="row pb-2">
                    <div class="col-7">
                      <p class="card-text ps-4">Civilité</p>
                      <p class="card-text ps-4">Nom</p>
                      <p class="card-text ps-4">Prénom</p>
                      <p class="card-text ps-4">Date de naissance</p>
                    </div>
                    <div class="col-5">
                      <p class="card-data pe-4 d-flex justify-content-end">{{ formPmPp.civility === "mr" ? "M" : "Mme" }}</p>
                      <p class="card-data pe-4 d-flex justify-content-end">{{ formPmPp.lastName }}</p>
                      <p class="card-data pe-4 d-flex justify-content-end">{{ formPmPp.firstName }}</p>
                      <p class="card-data pe-4 d-flex justify-content-end">{{ bDate }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-7 mt-3 mt-@md-0">
              <Address
                :component-texts="viewTexts.rue"
                :default-value="formPmPp.address"
                ref="address"
              />
              <ZipCode
                :component-texts="viewTexts.code_postal"
                :default-value="formPmPp.zipCode"
                @updateZipCode="completeCity"
                ref="zipCode"
              />
              <City
                :component-texts="viewTexts.ville"
                :cities="cities"
                :default-value="formPmPp.city"
                ref="city"
              />
              <PhoneNumber
                :component-texts="viewTexts.telephone"
                :default-value="formPmPp.phone"
                :no-mandatory="true"
                ref="phone"
              />
              <div v-html="viewTexts.telephone.helpLabel"></div>
              <Email
                @focusout="checkMails()"
                :component-texts="viewTexts.email"
                :default-value="formPmPp.email"
                input-name="email"
                :redBorder="!identicalMails"
                ref="email"
              />
              <div class="row"></div>
              <Email
                @focusout="checkMails()"
                @change="checkMails()"
                input-name="validation-email"
                :default-value="formPmPp.confirmEmail"
                :component-texts="viewTexts.confirmation_email"
                :redBorder="!identicalMails"
                ref="emailConfirmation"
              />
              <div class="row">
                <div class="col-6"></div>
                <div v-if="!identicalMails"
                  class="pb-3 d-flex col-6 justify-content-start form-error form-error-text">
                  Les adresses emails ne correspondent pas
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-6 form-check" style="padding-left: 40px">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="confirmEmail"
                    id="confirmEmail"
                  />
                  <label
                    v-html="viewTexts.email_optin.text"
                    class="form-check-label"
                    for="confirmEmail"
                    style="font-size: 0.8rem"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-6"></div>
                <div v-if="!confirmEmail && submit"
                  class="d-inline col-md-6 text-start form-error form-error-text">
                  Merci de cocher la case
                </div>
              </div>

              <div class="row">
                <div class="col-12" v-if="!this.isViewLoading">
                  <p>{{ optins.libelle }}</p>
                  <RadioInput
                    :question="viewTexts.optiin_sms"
                    :submitted="false"
                    :default-choice="smsAgreement"
                    :name="'sms_agreement'"
                    ref="sms_agreement"
                    v-model="smsAgreement"
                  />
                  <RadioInput
                    :question="viewTexts.optiin_email"
                    :submitted="false"
                    :default-choice="emailAgreement"
                    :name="'email_agreement'"
                    ref="email_agreement"
                    v-model="emailAgreement"
                  />
                  <div v-if="submit && (smsAgreement == null || emailAgreement == null)"
                    class="d-inline col-12 text-start form-error form-error-text">
                    Merci de cocher les options
                  </div>
                </div>
                <Loading :active="this.isViewLoading" :is-full-page="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <FooterNavigation @submitStep="submitForm" />
  <Encart :component-texts="viewTexts.blocs_texte" />
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import Email from "@/components/FormPpPm/Email.vue";
import PhoneNumber from "hm-mpe-widgets/src/lib-components/components/FormPmPp/PhoneNumber.vue";
import Address from "@/components/FormPpPm/Address";
import ZipCode from "@/components/FormPpPm/ZipCode.vue";
import City from "@/components/FormPpPm/City.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput.vue";
import moment from "moment";
import { filterCities, getCityFromZipCode } from "@/services/geoApiService";
import Loading from "vue-loading-overlay";
import { reloadEvent } from '../../services/tagCo';

import wrapper from '../../main';

export default {
  name: "InformationsPersonnelles",
  components: {
    FooterNavigation,
    Encart,
    Email,
    Address,
    ZipCode,
    City,
    PhoneNumber,
    RadioInput,
    Loading,
  },
  data: function () {
    return {
      submit: false,
      smsAgreement: null,
      emailAgreement: null,
      identicalMails: true,
      confirmEmail: false,
      bDate: null,
      cities: [],
      api: null,
      addresses: [],
      dataAddress: [],
      lastDataAddress: [],
      selectedAddress: null,
    };
  },
  created() {
    // reloadTCContainer("informations-personnelles", "besoins");
    wrapper.setTcVars({
            page_name : 'informations-personnelles',
            page_cat1_name : 'besoins',
            page_cat2_name : '',
          });
    
    this.$store.commit("setNextRoute", "Devis");
    reloadEvent('page');

    this.viewTexts = this.formTexts.informations_personnelles;
    this.viewTexts.infos = this.formTexts.eligibilite;
    this.api = this.isConsentementAllRight;
    if (this.api) {
      if (this.formPmPp.address != "") {
        this.confirmEmail = true;
        if (this.formPmPp.smsAgreement == "oui") {
          this.smsAgreement = Object.keys(
            this.viewTexts.optiin_sms.choices[0]
          )[0];
        } else {
          this.smsAgreement = Object.keys(
            this.viewTexts.optiin_sms.choices[1]
          )[0];
        }
        if (this.formPmPp.emailAgreement == "oui") {
          this.emailAgreement = Object.keys(
            this.viewTexts.optiin_email.choices[0]
          )[0];
        } else {
          this.emailAgreement = Object.keys(
            this.viewTexts.optiin_email.choices[1]
          )[0];
        }
      }
    } else {
      this.smsAgreement = {
        title: "SMS",
        choices: {
          oui: "Oui",
          non: "Non",
        },
      };
      this.emailAgreement = {
        title: "Email",
        choices: {
          oui: "Oui",
          non: "Non",
        },
      };
    }

    if (this.formPmPp.zipCode.length != 0) {
      this.completeCity(this.formPmPp.zipCode);
    }

    if (!this.optins) {
      this.$store.dispatch("getOptins");
    }

    this.bDate = moment(this.formPmPp.birthDate).format("DD/MM/YYYY");
  },
  methods: {
    submitForm() {
      this.submit = true;
      let address = this.$refs.address.submitField();
      let zipCode = this.$refs.zipCode.submitField();
      let city = this.$refs.city.submitField();
      let phone = this.$refs.phone.submitField();
      let email = this.$refs.email.submitField();
      let emailConfirmation = this.$refs.emailConfirmation.submitField();

      if (
        address &&
        zipCode &&
        city &&
        email &&
        emailConfirmation &&
        this.checkMails() &&
        this.confirmEmail  &&
        this.smsAgreement != null &&
        this.emailAgreement != null
      ) {
        this.formPmPp.address = address;
        this.formPmPp.city = city;
        this.formPmPp.zipCode = zipCode;
        if (phone){
          this.formPmPp.hasPhone = true;
        }
        this.formPmPp.phone = phone;
        this.formPmPp.email = email;
        this.formPmPp.confirmEmail = emailConfirmation;
        this.formPmPp.smsAgreement = this.$refs.sms_agreement.picked;
        this.formPmPp.emailAgreement = this.$refs.email_agreement.picked;

        this.saveConsentements();
        this.$store.state.avancement[3] = true;
        this.nextRoute();
      }
    },
    saveConsentements() {
      let smsOptin = this.optins.typeCanaux.find((t) => {
        return t.libelle === "SMS";
      });
      let emailOptin = this.optins.typeCanaux.find((t) => {
        return t.libelle === "Email";
      });
      let formPmPpOptins = {
        ficheId: this.optins.id,
        canaux: [
          {
            typeCanalId: smsOptin.id,
            flagAcceptation: this.smsAgreement === "oui",
          },
          {
            typeCanalId: emailOptin.id,
            flagAcceptation: this.emailAgreement === "oui",
          },
        ],
      };
      this.$store.commit("setFormPmPpOptins", formPmPpOptins);
    },
    checkMails() {
      if (this.$refs.email && this.$refs.emailConfirmation) {
        if (
          this.$refs.email.inputValue == null ||
          this.$refs.emailConfirmation.inputValue == null
        ) {
          this.identicalMails = true;
          return true;
        }
        if (
          this.$refs.email.submitField() !=
          this.$refs.emailConfirmation.submitField()
        ) {
          this.identicalMails = false;
          return false;
        } else {
          this.identicalMails = true;
          return true;
        }
      }
      return false;
    },
    editIdentity() {
      // pushTag($event, "clic_modifier", "action", "profil");
      let event = {
        click_name: "clic_modifier",
        click_type: 'action',
        composant: 'profil'
      };
      reloadEvent('click', event)
      this.$router.push({
        name: "InformationsPersonnellesComplete",
      });
    },
    nextRoute() {
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
    completeCity(zipCode) {
      getCityFromZipCode(zipCode).then((r) => {
        this.cities = filterCities(r.data);
      });
    },
  },
  computed: mapState([
    `isConsentementAllRight`,
    `formTexts`,
    `formPmPp`,
    `isViewLoading`,
    `optins`,
  ]),
};
</script>

<style lang="scss" scoped></style>
