<template>
  <RangeInput
    v-model="inputValue"
    :question="componentTexts"
    :max-value="this.maxValue"
    :index="index"
    :min-value="1"
    @update:modelValue="rangeSelected"
  />
  <div class="row">
  <div class="d-none d-md-block pe-5 col-md-6"></div>
  <div class="col-12 col-md-6 mb-3 ps-2 justify-content-start text-start">
            <input
              class="form-check-input ms-1"
              type="checkbox"
              :value="this.formPmPp.partBeneficiaries[0].equalPart"
              :checked="this.formPmPp.partBeneficiaries[0].equalPart"
              v-model="this.formPmPp.partBeneficiaries[0].equalPart"

              id="flexCheckDefault"
              @change="changeEqualPart()"
            />
            <label class="form-check-label ps-2" >
              Égaliser les parts
            </label>
          </div>
          </div>
</template>

<script>
import RangeInput from "@/components/Widgets/RangeInput";
import { validateNumber } from "@/services/inputValidator";
import { mapState } from "vuex";

export default {
  name: "Part",
  components: { RangeInput },
  props: {
    componentTexts: null,
    defaultValue: null,
    maxValue: null,
    index: null,
    equalPart: {
      type: String,
      default: "false",
    }
  },
  data: function () {
    return {
      validName: false,
      submit: false,
      validFormat: false,
      inputValue: null,
      ep: null
    };
  },
  created() {
    this.ep= this.formPmPp.partBeneficiaries[0].equalPart;
    this.inputValue = this.defaultValue;
  },
  methods: {
    rangeSelected(value) {
      this.inputValue = value;
      this.$emit("update:modelValue", this.inputValue);
    },
    changeEqualPart(){
    
      if(this.ep == true){
        this.ep=false;
        
      }else{
        this.ep=true;
        let rank = 100/this.formPmPp.partBeneficiaries.length;
      for(let i=0; i <= this.formPmPp.partBeneficiaries.length -1; i++){
        this.formPmPp.partBeneficiaries[i].val = rank
      }
      }
      this.formPmPp.partBeneficiaries[0].equalPart = this.ep
     
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      let validator = validateNumber(
        this.inputValue,
        this.validName,
        this.validFormat
      );
      this.validName = validator.validName;
      this.validFormat = validator.validFormat;
      return this.validName && this.validFormat;
    },
  },
  computed: mapState([`formTexts`, `formPmPp`]),
};
</script>

<style scoped></style>
