<template>
    <!-- Modal -->
    <div
      class="modal fade"
      :id="id"
      :ref="id"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      :aria-labelledby="id"
      aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" style="max-width: 700px">
            <div class="modal-content pt-3 pb-4">
                <div class="modal-header text-center pt-0 border-0">
                    <h5 class="modal-title w-100 fw-bolder" id="staticBackdropLabel">
                        Devis envoyé !
                    </h5>
                </div>
                <div class="m-5 modal-body body">
                    <div class="row">
                        <div class="col-12">
                            <p class="mainText mb-5">Votre devis a bien été envoyé à l'adresse email : <strong>{{ this.formPmPp.email}}</strong></p>
                            <p class="subText">
                                La réception peut prendre quelques minutes.<br>
                                Vous n'avez rien dans votre boite de réception ? Pensez à vérifier dans les indésirables.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center modal-footer footer-navigation border-0">
                    <button v-if="confirmRetour" @click="this.closeModal()" type="button" class="btn back-button ms-2" data-bs-dismiss="modal">
                        Fermer
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { reloadEvent } from '../../services/tagCo';
import wrapper from '../../main';

const bootstrap = require("bootstrap");

export default {
    name: "ModalSendMail",
    props: {
        id: String,
        confirmRetour: {
            type: Boolean,
            default: true
        },
        confirmAction: Boolean,
    },
    methods: {
        showModal() {
          this.myModal = new bootstrap.Modal(document.getElementById(this.id), {
            keyboard: false,
          });
          this.myModal.show();
        },
        confirm() {
          this.$emit("confirmAction", true);
          this.closeModal();
        },
        closeModal() {
          this.$store.dispatch('closeOrOpenModal', {
              modalName: 'ModalSendMail',
              action: false
          })
        },
    },
    computed: {
        ...mapState([`formTexts`, `modalSendMail`, `formPmPp`])
    },
    watch: {
        modalSendMail() {
            if(this.modalSendMail) {
                this.showModal()
            }
            wrapper.setTcVars({
                page_name: "confirmation-devis",
                page_cat1_name: "tarification",
                page_cat2_name: "",
            });
            reloadEvent("page");
        }
    },
}
</script>
