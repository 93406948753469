import {getNbEmployeesFromCode} from "@/services/utils";


export function buildPPData(state, interaction, OR){
    return getContactPPData(state.formPmPp, interaction, state.contact.idProcess, OR);
}

export function buildInteractionOR(state, interaction, OR){
    let contactData = {
        interaction : interaction,
    };
    if (state.formPmPp.phone){
        contactData['opportuniteRelationnelle'] = OR
    }
    return contactData
}

export function buildPMData(state, interaction){
    return getContactPMData(state, interaction, state.contact.idProcess);
}

export function getContactPPData(formDatas, interaction, idProcess, OR) {
    let contactPPData = {
        contactPP : getContactPP(formDatas),
        interaction : interaction,
        flagEntrepreneur : true,
        idProcessus: idProcess,
    };
    if (formDatas.phone){
        contactPPData['opportuniteRelationnelle'] = OR
    }
    return contactPPData
}

export function getContactPMData(formDatas, interaction, idProcess){
    let PMData = {
        contactPM : getContactPM(formDatas),
        flagEntrepreneur : true,
        flagRedirection: false,
        idProcessus: idProcess,
    }
    if (interaction){
        PMData['interaction'] = interaction
    }
    return PMData
}

export function getContactPP(state){
    let pp = {
        "nom": state.lastName,
        "prenom": state.firstName,
        "dateNaissance": state.birthDate,
        "civilite": state.civility === 'mr' ? 'M' : 'Mme',
        "adresse": {
            "voie": state.address,
            "complementAdresse": "",
            "batResidence": "",
            "lieuDit": "",
            "codePostal": state.zipCode,
            "commune": state.city,
            "pays": "France"
        },
        "email": {
            "type": "Personnelle",
            "adresse": state.email
        },
        "consentement": state.optins
    }

    if (state.phone) {
        var type = "Domicile";
        if(state.phone.charAt(1) == '6' || state.phone.charAt(1) == '7'){
            type = "Portable"
        } 
        pp["telephone"] = {
            "type": type,
            "numero": state.phone
        }
    }

    return pp;
}

export function getContactPM(state){

    var type = "Domicile";
        if(state.formPmPp.phone.charAt(1) == '6' || state.formPmPp.phone.charAt(1) == '7'){
            type = "Portable"
        } 
    console.log(getLibelleNaf(state.formPmPp.codeNaf, state.codeNafList));

    let contactPM = {
        "numeroTelPort": state.formPmPp.souscrirePhone,
        // "adresseMail":state.formPmPp.email,
        "raisonSociale": state.formPmPp.raisonSociale,
        "flagSiretEnCours": state.formPmPp.waitingSiret,
        "codeNaf": state.formPmPp.codeNaf.replace('.', ''),
        "formeJuridique" : getFormeJuridique(state.formPmPp.formJuridique),
        "libelleNaf": getLibelleNaf(state.formPmPp.codeNaf, state.codeNafList),
        "convCollIdcc": "QQQQ",
        "libelleConvColl": "A QUALIFIER",
        "trancheEffectifEtab": getNbEmployeesFromCode(state.formPmPp.nbEmployees),
        "trancheEffectifGroupe": getNbEmployeesFromCode(state.formPmPp.nbEmployees),
        "flagSiege": true,
        "telephonePM": {
            "type":type, 
            "numero": state.formPmPp.phone
        },
        "emailPM": {
            "adresse": state.formPmPp.email
        },
        "adressePM": {
            "voie": state.formPmPp.addressSiege,
            "codePostal": state.formPmPp.zipCodeSiege,
            "commune": state.formPmPp.citySiege,
            "pays": "France"
        }
    }
    if (!state.formPmPp.waitingSiret){
        contactPM['siret'] = state.formPmPp.siret
    }

    return contactPM;
}

function getLibelleNaf(codeNaf, nafList){
    let naf = nafList.find(n => n.id === codeNaf);
    return naf.text.replace(codeNaf + ' - ', '');
}

export function getFormeJuridique(key){
    let formeJurifiqueValues = {
        "artisan": "Artisan",
        "artisant_commercant": "Artisan-commerçant",
        "associe_gerant_societe": "Associé gérant de Société",
        "commercant": "Commerçant",
        "eurl": "SARL unipersonnelle",
        "sarl": "Soc. à responsabilité limit",
        "autre": "Autre pers. morale droit privé"
    };
    return formeJurifiqueValues[key];
}
