<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <TextInput
        :default-value="defaultValue"
        :valid-input="validName"
        :valid-format="validFormat"
        :format-error="componentTexts.formatError"
        :submitted="submit"
        :input-name="fieldName"
        :question="componentTexts"
        @update:textInput="setText"
      ></TextInput>
    </div>
  </div>
</template>

<script>
import TextInput from "hm-mpe-widgets/src/lib-components/components/Widgets/TextInput";
import { validateAlphabet } from "@/services/inputValidator";
import { uniqId } from "@/services/utils";

export default {
  name: "AccountOwner",
  components: { TextInput },
  props: {
    componentTexts: null,
    defaultValue: null,
    submitted: null
  },
  data: function () {
    return {
      validName: false,
      submit: false,
      validFormat: false,
      inputValue: null,
      fieldName: null,
    };
  },
  created() {
    this.fieldName = "accountOwner" + uniqId();
    this.inputValue = this.defaultValue;
    this.submit = this.submitted;
  },
  methods: {
    setText(value) {
      this.inputValue = value;
    },
    submitField() {
      this.submit = true;
      if (this.validateField()) {
        return this.inputValue;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateField() {
      let validator = validateAlphabet(this.inputValue, this.validName, this.validFormat);
      this.validName = validator.validName;
      this.validFormat = validator.validFormat;
      return this.validName && this.validFormat;
    },
  },
  watch: {
    inputValue() {
      this.validateField();
    },
  },
};
</script>

<style scoped></style>
