import $ from 'jquery/dist/jquery.slim.min';

export function logInDev(item){
   if (!isProduction()) {
        console.log(item);
    }
}

export function isProduction(){
    return (window.location.hostname === 'ma-protection-entrepreneur.harmonie-mutuelle.fr');
}

export function overrideBrowserBackLink($route, $router){
    if (window.history && window.history.pushState) {
        window.history.pushState({}, '', '/');
        let currentRouteName = $route.name
        $(window).on('popstate', function() {
            if (currentRouteName === 'Eligibilite'){
                $router.push({name : 'Eligibilite'})
            }
        });
    }
}
