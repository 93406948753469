<template>
  <div class="container" v-if="!this.isViewLoading">
    <div class="row justify-content-center validation">
      <div class="col-8 mt-4" v-if="true">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1 v-html="viewTexts.title"></h1>
                <p v-html="viewTexts.validation_sous_titre.text"></p>
                <a class="downloadIconColor"  :href="this.baLink">
                  <BIconCloudDownload class="downloadIconColor downloadIconSize"/>
                  Télécharger mon dossier d'adhésion
                </a>

                <p class="pt-5" v-html="viewTexts.validation_verification_dossier.text"></p>

                <h1 class="mb-4"><p>{{viewTexts.validation_apps.text}}</p></h1>
                <div class="row justify-content-md-start ">
                  <a class=" pt-3 col-md-3" :href="viewTexts.validation_app_store.link">
                    <img src="@/assets/Download_on_the_App_Store_Badge_FR_RGB_blk.svg" class="appStore"/>
                  </a>

                  <a class="col-md-3" :href="viewTexts.validation_google_play.link">
                    <img src="@/assets/google-play-badge.svg" class="googlePlay"/>
                  </a>
                </div>
                <br />
                <br />
                <div class="row pb-5 pb-md-0">
                  <img class="col-md-4 pb-3 ps-md-0 pb-md-0 proteger pe-5 pe-md-4 object-fit-scale" :src="viewTexts.validation_image.file" :alt="viewTexts.validation_image.text" />
                  <div class="col-md-8">
                    <h4 v-html="viewTexts.validation_protection_accidents.text"></h4>
                    <p v-html="viewTexts.validation_decouvrir_garantie.text"></p>

                    <a
                      class="back-button ms-2 pe-5"
                      :href="viewTexts.validation_voir_offre.link">
                      <span>{{ viewTexts.validation_voir_offre.text }}</span>
                    </a>

                    <a
                      class="btn btn-info2 rounded-pill mt-3 mt-md-0"
                      :href="viewTexts.validation_faire_devis.link">
                      <span>{{ viewTexts.validation_faire_devis.text }}</span>
                      <img
                        class="ps-3"
                        :src="require('@/assets/svg/arrow-title-white.svg')"
                      />
                    </a>
                  </div>
                </div>
                <div class="row pt-5">
                  <div class="col-md-4"></div>
                  <div class="col-md-8">
                    <a
                      class="btn btn-primary rounded-pill retour-btn"
                      :href="viewTexts.validation_retour_website.link">
                      <BIconArrowLeftShort class="backArrow" /><span>{{ viewTexts.validation_retour_website.text }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div v-else class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BIconCloudDownload, BIconArrowLeftShort } from "bootstrap-icons-vue";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "ProfileProfessionnel",
  components: { BIconCloudDownload, BIconArrowLeftShort },
  data: function () {
    return {
      viewTexts: null,
      verif: null,
      offer: null,
      baLink: null,
      baDoc: null
    };
  },
  created() {
        wrapper.setTcVars({
      page_name : 'validation',
      page_cat1_name : 'souscription',
    });
    this.viewTexts = this.formTexts.validation_souscription;
    if(!this.$store.state.avancementSouscription[5]){
    

    this.souscrire.baLink =  process.env.VUE_APP_API_URL + '/hm-web-services/telecharger-doc-prop/' + this.souscrire.ba_signe.id

    this.$store.dispatch("acceptProposition")
    this.$store.dispatch("sendPostSignatureMail")
    this.$store.state.avancementSouscription[5] = true;
    this.$store.dispatch('updateState') 
  }
    let dataContact;
    if(this.formPmPp.hasPhone) {
      dataContact = {
          "interaction": {
              "objet": "Souscription enregistrée",
          },
          "opportuniteRelationnelle": {
              "objet": "Souscription enregistrée",
              "statut": "Fait"
          },
      }
    } else {
      dataContact = {
          "interaction": {
              "objet": "Souscription enregistrée",
          },
      }
    }
    this.$store.dispatch("updatePm", dataContact);
    reloadEvent('page');
    this.$store.state.signed = true;
  },

  computed: mapState([`formTexts`, `isViewLoading`, `souscrire`, `formPmPp`]),

  watch: {
    isViewLoading(){
      if (this.souscrire.ba_signe){
        this.baLink =  process.env.VUE_APP_API_URL + '/hm-web-services/telecharger-doc-prop/' + this.souscrire.ba_signe.id
      }
    }
  },
};
</script>

<style scoped></style>
