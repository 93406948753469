<template>
  <div class="couverture-sante">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-11 col-lg-8 ">
          <h1 class="text-md-center" v-html="formTexts.vos_besoins_sante_2.title"></h1>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <OffersSanteCardsDeck @update:modelValue="itemSelected" />
        </div>
      </div>
    </div>
    <FooterNavigation
      :disabled-button-label="selectedOfferModel != null ? null : 'Veuillez sélectionner une offre'"
      @submitStep="submitForm"
    />
  </div>

</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import OffersSanteCardsDeck from "@/components/Offers/OffersSanteCardsDeck";
import templateHelpers from "@/services/templateHelpers";
import { reloadEvent } from '../../services/tagCo';
import wrapper from '../../main';

export default {
  name: "BesoinPrevoyance",
  components: {
    FooterNavigation,
    OffersSanteCardsDeck
  },
  data: function () {
    return {
      selectedOfferModel: null,
    };
  },
  created() {
    // reloadTCContainer("santé", "besoins", "couverture");
    wrapper.setTcVars({
            page_name : 'santé',
            page_cat1_name : 'besoins',
            page_cat2_name : 'couverture',
          });
    
    this.$store.commit("setNextRoute", "InformationsPersonnelles");
    this.selectedOfferModel = this.selectedOfferSante;
    reloadEvent('page');
  },
  mounted() {
    this.initSwiper(null, 3)
  },
  methods: {
    ...templateHelpers,
    itemSelected(product) {
      this.selectedOfferModel = product;
      // pushTag("clic_detail-garantie-" + product, "download", "contenu");
      // let event = {
      //   click_name: "clic_detail-garantie-" + product,
      //   click_type: 'download',
      //   composant: 'contenu'
      // };
      // reloadEvent('click', event)
    },
    submitForm() {
      if (this.selectedOfferModel !== null) {
        this.$store.commit("setSelectedOfferSante", this.selectedOfferModel);
        this.$store.state.avancement[2] = true;
        this.nextRoute();
      }
    },
    nextRoute() {
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
  },
  computed: mapState([`formTexts`, `selectedOfferSante`, `selectedOfferPrevoyance`]),
};
</script>

<style scoped></style>
