<template>
  <!-- Modal -->
  <div
      class="modal modal_contact fade"
      :id="id"
      :ref="id"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      :aria-labelledby="id"
      aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 700px">
      <div class="modal-content pt-3 pb-4">
        <div class="modal-header text-center border-0">
          <button
              v-if="closable"
              type="button d-flex justify-content-end"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
          ></button>
        </div>
        <div class="modal-header text-center pt-0 border-0">

          <h5 v-if="this.htmlTitle" class="modal-title w-100 fw-bolder" v-html="this.htmlTitle"></h5>
          <h5 v-if="this.title" class="modal-title w-100 fw-bolder">{{ this.title }}</h5>
        </div>
        <div class="d-flex justify-content-center m-2" v-if="this.contentText !== ''">
          {{ this.contentText }}
        </div>
        <div class="m-5 modal-body body">
          <div class="row">
            <div class="col-md-7">
              <strong>
                <p class="mainText mb-0">
                  {{ this.formTexts.wbc_modal.wcb_conseillers.text }}
                </p>
              </strong>
              <p class="subText">
                {{ this.formTexts.wbc_modal.wcb_ouverture.text }}
              </p>
            </div>
            <div class="col-md-5 d-flex align-items-center phone">
              <strong><p>{{ this.formTexts.wbc_modal.wcb_phone.text }}</p></strong>
            </div>
          </div>
        </div>
        <div
            class="
            d-flex
            justify-content-center
            modal-footer
            footer-navigation
            border-0
          "
        >
          <button
              v-if="confirmRetour"
              type="button"
              class="btn back-button ms-2"
              data-bs-dismiss="modal"
          >
            <img :src="require('@/assets/svg/Icon feather-arrow-left.svg')" />
            {{ this.formTexts.wbc_modal.wcb_cancel_btn.text }}
          </button>
          <a
              class="btn btn-info rounded-pill"
              data-bs-dismiss="modal"
              target="_blank"
              :href="this.wcbLink"
              @click="confirm">
            {{ this.formTexts.wbc_modal.wcb_callback_link.text }}
            <img :src="require('@/assets/svg/arrow-title-white.svg')" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import templateHelpers from "@/services/templateHelpers";
import { reloadEvent } from '../../services/tagCo';


const bootstrap = require("bootstrap");

export default {
  name: "ModalContact",
  props: {
    id: String,
    title: {
      type: String,
      default: null
    },
    htmlTitle: {
      type: String,
      default: null
    },
    changeOffer: null,
    confirmRetour: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: true
    },
    contentText: {
      type: String,
      default: ''
    }
  },
  components: {},
  data: function () {
    return {
      myModal: null,
      wcbLink: null,
    };
  },
  created() {
    if(this.modalContact){
      this.showModal()
    }
    this.wcbLink = this.formTexts.wbc_modal.wcb_callback_link.link
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('ca')){
      this.wcbLink = this.wcbLink + '?ca=' + urlParams.get('ca')
    }
  },
  methods: {
    ...templateHelpers,
    showModal() {
      this.myModal = new bootstrap.Modal(document.getElementById(this.id), {
        keyboard: false,
      });
      this.myModal.show();
    },
    confirm() {
      this.$emit("confirmAction", true);
      
      let event = {
        click_name: 'clic-contact-valide',
        click_type: 'action',
        composant: 'sticky'
      };
      reloadEvent('click', event)
      window.setTimeout(() => (
        window.open(this.wcbLink, '_blank').focus()
      ), '100');

       // pushTag($event, 'clic-contact-valide', 'action', 'sticky')
      
      if(!this.closable){
        this.myModal = new bootstrap.Modal(document.getElementById(this.id), {
          keyboard: false,
        });
        this.myModal.show();
      }
      
    },
  },
  computed: {
    ...mapState([`formTexts`, `modalContact`])
  },
  watch: {
    modalContact() {
      if(this.modalContact) {
        this.showModal()
      }
    }
  },

};
</script>

<style scoped></style>
