<template>
  <div class="container footer-navigation">
    <div class="row align-items-center py-5">
      <div class="col-auto me-auto">
        <div v-if="$route.name !== 'VotreSituation' && $route.name !== 'Eligibilite' && modalWcb !== true">
          <img :src="require('@/assets/svg/Icon feather-arrow-left.svg')"/>
          <a class="back-button ms-2" @click="back">{{ textLeft }}</a>
        </div>

      </div>
      <div class="col-auto" v-if="($route.name !== 'Devis' && !disableNext) || modalWcb === true">
        <a v-if="disabledButtonLabel != null" class="btn btn-link" disabled>{{ disabledButtonLabel }}</a>
        <a v-else class="btn btn-info rounded-pill" @click="submitStep()" data-cy="next-step">
          <span class="me-4 pe-4 ms-2">{{ textRight }}</span>
          <img :src="require('@/assets/svg/arrow-title-white.svg')" /></a>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: "FooterNavigation",
  components :{
  },
  props: {
    textLeft: {
      type: String,
      default: "Retour"
    },
    textRight: {
      type: String,
      default: "Suivant"
    },
    disabledButtonLabel: {
      type: String,
      default: null
    }
  },
  methods: {
    submitStep() {
      this.$emit("submitStep");
    },
    back(){
      this.$router.back();
    }
  },
  computed: mapState([`disableNext`, `modalWcb`])
}
</script>

<style scoped>

</style>
