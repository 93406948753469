import Swiper, {Navigation, Pagination} from "swiper";

export default {
	totalSante(tarification) {
		let somme = 0;

		tarification.offre.beneficiaires.forEach((e) => {
			let produitSante = e.produits.find((p) => {
				return !p.code.includes("MPEPR");
			})
			somme += parseFloat(produitSante.tarif);
		});

		return somme.toFixed(2).toString().replace(".", ",");
	},
	getPrevoyancePrice(tarification) {
		let res = tarification.offre.beneficiaires[0].produits.find((p) => {
			return p.code.includes("MPEPR");
		});
		return res.tarif.replace(".", ",");
	},
	getTotalTarif(tarification) {
		return tarification.offre.tarification.cotisationMensuelleGlobale.replace(".", ",");
	},
	getAnnualTotalTarif(tarification) {
		let totalYear = tarification.offre.tarification.cotisationMensuelleGlobale * 12;
		totalYear =  Math.round(totalYear * 100) / 100
		return totalYear.toString().replace(".", ",");
	},
	dateFRFormat(dateString){
		let date = new Date(dateString)
		return [
			padTo2Digits(date.getDate()),
			padTo2Digits(date.getMonth() + 1),
			date.getFullYear(),
		].join('/');
	},
	completeCityList(store, zipCode){
		store.dispatch('completeCity', zipCode)
	},
	initSwiper(focus, nbSlidesPerView){

		let nbToDisplay = 1;
		if(window.screen.width > 789){
			nbToDisplay = nbSlidesPerView;
		}

		const swiper = new Swiper('.swiper', {
			slidesPerView: nbToDisplay,
			modules: [ Navigation, Pagination ],
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
		if (focus){
			swiper.activeIndex = focus
		}
		if (swiper.length > 1){
			swiper.forEach((s) => s.navigation.update())
		}else{
			swiper.navigation.update()
		}
	},
	getWCBLink(formTextWcbLink, codeAction){
		if (codeAction !== 'WEB_PRO_SEO_NA'){
			formTextWcbLink = formTextWcbLink + '?ca=' + codeAction
		}
		return formTextWcbLink
	},
	getCivilityByValue(value){
		if(value=="mr" || value=="m"){
			return "M";
		}
		if(value=="mme" || value=="mll"){
			return "Mme";
		}else{
			return "M";
		}
	},
	isAMobile(phoneNumber){
		let two = phoneNumber.substring(0,2)
		if(two == '07' || two=='06'){
			return true;
		}else{
			return false;
		}
	},
	getClauseBeneficiaireText(beneficiaire){
		let clause = `${this.getCivilityByValue(beneficiaire.civility)} ${beneficiaire.lastName}`
		if (beneficiaire.birthLastName !== beneficiaire.lastName){
			clause += `né(e) ${beneficiaire.birthLastName}`
		}
		clause += ` ${beneficiaire.firstName} né le ${this.dateFRFormat(beneficiaire.birthDate)}, à ${beneficiaire.birthCity}`

		if (beneficiaire.aliveChoice){
			clause += ' vivant(e) ou représenté(e)'
		}

		clause += ` demeurant au ${beneficiaire.address}, ${beneficiaire.zipCode} ${beneficiaire.city}, à hauteur de ${beneficiaire.part}% en rang ${beneficiaire.priorityOrder}`

		return clause
	}

}

export function totalSante(tarification) {
	let somme = 0;

	tarification.offre.beneficiaires.forEach((e) => {
		let produitSante = e.produits.find((p) => {
			return !p.code.includes("MPEPR");
		})
		somme += parseFloat(produitSante.tarif);
	});

	return somme.toFixed(2).toString().replace(".", ",");
}

export function getPrevoyancePrice(tarification) {
	let res = tarification.offre.beneficiaires[0].produits.find((p) => {
		return p.code.includes("MPEPR");
	});
	return res.tarif.replace(".", ",");
}

function padTo2Digits(num) {
	return num.toString().padStart(2, '0');
}
