import {isProduction} from "@/services/WizardHelperService";

export function initTagCo(){
	if(typeof tC !== 'undefined') {
		// eslint-disable-next-line
		console.log(tC.internalvars);
	}
}

export function reloadEvent(type, data = {}) {
	if(type === 'click') {
		window.tC.event.click(this, data);
	} else if(type === 'page') {
		window.tC.event.page(this, data);
	}
}

export function reloadTCContainer(pageName, pageCat1 = null, pageCat2 = null, pageCat3 = null){
	window.tc_vars = {
		env_device : detectDevice(),
		env_work : isProduction() ? 'prod' : 'preprod',
		env_template : "parcours-ma-protection-entrepreneur",
		page_level_name : "parcours-ma-protection-entrepreneur",
		env_url : window.location.host,
		product_name : 'ma-protection-entrepreneur',
		product_id : 'mpe',
		product_type : 'sante',
		cart_id: 'TODO',
		page_name : pageName,
	};

	let objectToPush = [];
	if (pageCat3){
		objectToPush['page_cat3_name'] = pageCat3
	}

	if (pageCat2){
		objectToPush['page_cat2_name'] = pageCat2
	}
	if (pageCat1){
		objectToPush['page_cat1_name'] = pageCat1
	}

	window.tc_vars = {...window.tc_vars, ...objectToPush};

	if (!isProduction()){
		console.log(window.tc_vars);
	}
	if(typeof tC !== 'undefined') {
		// eslint-disable-next-line
		tC.container.reload({events: {page: [{},{}]}});
	}
}

export function pushTag($event, name, type, component){
	console.log($event);
	let tagToPush = {click_name: name, click_type: type};
	if (typeof component !== 'undefined'){
		tagToPush['composant'] = component
	}
	if (!isProduction()){
		console.log(tagToPush);
	}
	if(typeof tC !== 'undefined' && typeof ATTag !== 'undefined') {
		// eslint-disable-next-line no-undef
		tC.event.click($event, tagToPush);
	}
}

export function detectDevice(){
	var device = "desktop";

	var isMobile = detectMob();
	if (isMobile){
		device = "mobile";
	}

	const userAgent = navigator.userAgent.toLowerCase();
	const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

	if (isTablet){
		device = "tablet";
	}
	return device;
}

function detectMob() {
	const toMatch = [
		/Android/i,
		/webOS/i,
		/iPhone/i,
		/iPad/i,
		/iPod/i,
		/BlackBerry/i,
		/Windows Phone/i
	];

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
}
