<template>
  <div class="row justify-content-center">
    <div class="col-6 text-centered pt-2">
      <label v-html="question.text" class="form-question"></label>
    </div>
    <div class="col-6 mb-3">
      <div v-if="this.formPmPp.partBeneficiaries[0].equalPart==false" class="row">
        <div class="col-4">
          <input
            class="form-control custom-text p-1"
            type="number"
            v-model="value"
            :min="minValue"
            :max="maxValue"
          />
        </div>
        <div class="col-8 d-flex align-items-center">
          <input
            v-model="value"
            class="form-range"
            :class="borderClass()"
            type="range"
            :max="maxValue"
            :min="minValue"
          />
        </div>
      </div>
      <div v-if="this.formPmPp.partBeneficiaries[0].equalPart" class="row">
        <div class="col-4">
          <input
            class="form-control custom-text p-1"
            type="number"
            v-model="this.formPmPp.partBeneficiaries[index].val"
            disabled
          />
        </div>
        <div class="col-8 d-flex align-items-center">
          <input
            v-model="this.formPmPp.partBeneficiaries[index].val"
            class="form-range"
            :class="borderClass()"
            type="range"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TextInput",
  components: {},
  props: {
    question: Object,
    validInput: Boolean,
    validFormat: Boolean,
    submitted: Boolean,
    modelValue: null,
    defaultValue: null,
    maxValue: null,
    minValue: null,
    index: null
  },
  data: function () {
    return {
      inputChanged: false,
      value: null,
    };
  },
  created() {
    if(this.formPmPp.partBeneficiaries[0].equalPart == true){
      this.value = this.formPmPp.partBeneficiaries[0].val;
    }else{
      this.value = this.formPmPp.partBeneficiaries[this.index].val ? this.formPmPp.partBeneficiaries[this.index].val : this.minValue; 
    }
  },
  methods: {
    validityField() {
      return this.validFormat;
    },
    borderClass() {
      if (this.modelValue && this.validityField() && this.submitted) {
        return "greenBorder";
      } else if (this.modelValue && !this.validityField() && this.submitted) {
        return "redBorder";
      } else {
        return "";
      }
    },
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
  },
    computed: mapState([`formTexts`, `formPmPp`]),
};
</script>

<style lang="scss"></style>
