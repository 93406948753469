import {buildInfosComp, getPropData, replaceOffreBeneficiaires} from "@/services/souscrireAPI/souscrireFormBuilder";

export function createPropData(state){

	let infosComp = state.tarification.offre.infosComplementaire
	infosComp = {...infosComp, ...buildInfosComp(state)}

	let offre = state.tarification.offre;
	offre.infosComplementaire = infosComp
	offre.tarification.numGA = offre.tarification.numGroupeAssure
	offre.tarification.numContratColl = offre.tarification.numGroupe
	let infosBancairesState = state.souscription.information_bancaire

	let benefsList = replaceOffreBeneficiaires(state)

	offre.beneficiaires = benefsList

	offre.modalitePaiement.ribCotisation = getRibData(state.formPmPp, infosBancairesState.iban, infosBancairesState.bic, false)
	let mainBenef = offre.beneficiaires.find((b) => b.role === "Principale")
	mainBenef['ribPrestation'] = getRibData(state.formPmPp, infosBancairesState.iban, infosBancairesState.bic, true)
	offre.beneficiaires[0] = mainBenef
	offre.modalitePaiement.typePayeur = "Adhérent"
	
	if (state.souscription.information_bancaire.iban_pro !== ""){
		offre.beneficiaires[0] = mainBenef
		offre.modalitePaiement.typePayeur = "Entreprise"
		offre.modalitePaiement.ribCotisation = getRibData(state.formPmPp, infosBancairesState.iban_pro, infosBancairesState.bic_pro, false)
	}

	offre.modalitePaiement.jourPaiement = infosBancairesState.date_prelevement
	offre.modalitePaiement.periodicitePaiement = infosBancairesState.periodicite_prelevement === 'mensuelle' ? 'Mensuel' : 'Trimestriel'
	offre.modalitePaiement.modePaiement = "Prélèvement"

	let propData = getPropData(state);

	let propositionData = [{...propData, ...{'offre' : offre, 'codeAction' : state.contact.codeAction }}]
	return { proposition : propositionData, uuid : state.uuid }
}

function getRibData(formPmPp, iban, bic, isPresta){
	let ribData;
	if(!isPresta) {
		ribData = {
			raisonSociale : formPmPp.raisonSociale,
			civilite: formPmPp.civility,
			nom: formPmPp.lastName,
			prenom: formPmPp.firstName,
			iban : iban,
			bic : bic,
			numerodevoie : formPmPp.address,
			codePostal : formPmPp.zipCode,
			localite : formPmPp.city,
			pays: formPmPp.birthCountry,
			siret: formPmPp.siret
		}
	} else {
		ribData = {
			civilite: formPmPp.civility,
			nom: formPmPp.lastName,
			prenom: formPmPp.firstName,
			iban : iban,
			bic : bic,
			numerodevoie : formPmPp.address,
			codePostal : formPmPp.zipCode,
			localite : formPmPp.city,
			pays: formPmPp.birthCountry
		}
	}
	return ribData;
}

export function getUpdateProp(state){
	let benefObj = getBeneficiaireProps(state, false);
	return {...benefObj, ...{csp : "Artisan, commercant, chef ent", profession : state.souscription.profile_professionel.profession }};

}

function getBeneficiaireProps(state, isChild){
	let benef = state.formPmPp
	let profession = state.souscription.profile_professionel.profession
	let departement = benef.birthDepartement.substr(0,2)
	let benefProps = {
		nom : benef.lastName,
		prenom : benef.firstName,
		nomNaissance : benef.birthLastName,
		communeNaissance : benef.birthCity,
		departementNaissance : departement,
		paysNaissance : benef.birthCountry,
		profession: profession,
	};
	if (isChild){
		benefProps['numSs'] = benef.rattachement
	}else{
		benefProps['numSs'] = benef.nbSecuriteSociale
	}

	return benefProps;
}
