
export function getTarifData(storeState, santeLevel, cotisationStructure){
    let donneeTarifantes = buildDonneesTarifantes(storeState, santeLevel, cotisationStructure)
    donneeTarifantes.push(
        {
            "cle": "REGIME_OBLIGATOIRE",
            "dataType": "STRING",
            "valeur": storeState.formPmPp.beneficiaries.partner['regimeObligatoire'] != undefined ? storeState.formPmPp.beneficiaries.partner['regimeObligatoire'] : "RG" ,
        },
        {
            "cle": "STRUCTURE_COTISATION",
            "dataType": "STRING",
            "valeur": cotisationStructure === 'age' ? "STRUCTURE_ADULTE_ENFANT" : "STRUCTURE_UNIQUE"
        });

    let today = new Date()

    return {
        "codeOffre": "MPE",
        "dateEffet": today.toISOString(),
        // "dateEffet": "2023-05-01",
        "donneeTarifantes": donneeTarifantes
    }
}

export function buildDonneesTarifantes(storeState, santeLevel){
    let donneeTarifantes = []

    donneeTarifantes.push({
        "cle": "GERANT_MAJORITAIRE",
        "dataType": "BOOLEAN",
        "valeur": (storeState.formPmPp.owner === 'oui') || (storeState.formPmPp.status === "Conjoint collaborateur de Professionnel libéral / Freelance" || storeState.formPmPp.status === "Professionnel libéral / Freelance")
    });
    
    let prevoyanceLevel = storeState.selectedOfferPrevoyance.substr(storeState.selectedOfferPrevoyance.length - 1);
    donneeTarifantes.push({
        "cle": "CODE_NIVEAU_MPE_PREVOYANCE",
        "dataType": "STRING",
        "valeur": "PREVOYANCE_NIVEAU_"+prevoyanceLevel
    });

    donneeTarifantes.push({
        "cle": "CODE_NIVEAU_MPE_SANTE",
        "dataType": "STRING",
        "valeur": getCodeProduitSante(santeLevel)
    })

    donneeTarifantes.push({
        "cle": "BUDGET_MALIN",
        "dataType": "BOOLEAN",
        "valeur": ( (santeLevel === 'mpe110') && storeState.selectedOfferPrevoyance === 'mpe001')
    });

    donneeTarifantes.push(
        {
            "cle": "PROSPECT_DATE_NAISSANCE",
            "dataType": "DATE",
            "valeur": storeState.formPmPp.birthDate
        },
        {
            "cle": "CODE_POSTAL",
            "dataType": "STRING",
            "valeur": storeState.formPmPp.zipCode
        })


    donneeTarifantes = getBeneficiaresDonnees(donneeTarifantes, storeState.formPmPp.beneficiaries);

    return donneeTarifantes;
}

function getBeneficiaresDonnees(donneeTarifantes, beneficiaires){
    donneeTarifantes.push(getProtectionConjointBoolean(beneficiaires.hasPartner));

    if (beneficiaires.hasPartner){
        donneeTarifantes.push({
            "cle": "CONJOINT_DATE_NAISSANCE",
            "dataType": "DATE",
            "valeur": beneficiaires.partner.birthDate
        })
    }

    donneeTarifantes.push(getProtectionEnfantBoolean(beneficiaires.hasChildren))
    if (beneficiaires.hasChildren){
        donneeTarifantes.push({
            "cle": "NOMBRE_ENFANTS",
            "dataType": "NUMBER",
            "valeur": beneficiaires.children.length
        })
        beneficiaires.children.forEach((e) => {
            donneeTarifantes.push({
                "cle": "ENFANT_DATE_NAISSANCE",
                "dataType": "DATE",
                "valeur": e.birthDate
            })
        });
    }
    return donneeTarifantes
}

function getProtectionConjointBoolean(value){
    return {
        "cle": "PROTECTION_CONJOINT",
        "dataType": "BOOLEAN",
        "valeur": value
    }
}

function getProtectionEnfantBoolean(value){
    return {
        "cle": "PROTECTION_ENFANTS",
        "dataType": "BOOLEAN",
        "valeur": value
    }
}

export function getCodeProduitSante(santeLevel){
    let produitSante = 'NIVEAU_';
    if (santeLevel === 'mpe110' || santeLevel === 'mpe112'){
        produitSante += "ECONOMIQUE"
    }
    if (santeLevel === 'mpe220'){
        produitSante += "CONFORTABLE"
    }
    if (santeLevel === 'mpeFF0'){
        produitSante += "OPTIMAL"
    }
    return produitSante
}
