
export function getTarifOR(state){
    let observation = getPrevoyanceLabel(state.selectedOfferPrevoyance) + ' / ' + getSanteLabel(state.selectedOfferSante) + ' / Conjoint ' + (state.formPmPp.beneficiaries.hasPartner ? '(Oui)' : '(Non)')
    observation += state.formPmPp.beneficiaries.hasChildren ? ' / Enfant (' + state.formPmPp.beneficiaries.children.length + ')' : ''
    observation += '/' + state.formPmPp.siret
    return {
        // "statut":"A faire",
        "codeAction": state.contact.codeAction ? state.contact.codeAction  : 'code_action',
        "flagCallCenter": false,
        "objet": "Affichage tarif",
        // "canal":"Téléphone",
        "evtDeclencheur": "Vu sur internet",
        "observation": observation,
        "garantie": "Ma Protection Entrepreneur",
        "priorite":"80",
        // "motif":"Opportunite Relationnelle"
    }
}

function getPrevoyanceLabel(level){
    let label = "Prévoyance "
    if (level === 'mpe001') return label + '20'
    if (level === 'mpe002') return label + '35'
    return label + '50'
}

export function getSanteLabel(level){
    let label = "Santé "
    if (level === "mpe110") return label + "Économique budget malin"
    if (level === "mpe112") return label + "Économique"
    if (level === "mpe220") return label + "Confort"
    return label + "Optimal"
}

export function getDevisOR(state){
    return getOR("Devis envoyé", state, "85", null)
}

export function getSouscriptionOR(state){
    return getOR("Initialisation souscription", state, "91", null)
}

export function getRIAOR(state){
    return getOR("Attente Signature électronique", state, "93", null)
}

export function getSignatureOR(state){
    return getOR("Attente Signature électronique", state, "92", null)
}

export function getValidationOR(state){
    return getOR("Souscription enregistrée", state, null, "Fait")
}

function getOR(ORName, state, ORpriorite, ORstatut){
    let OR = {
        "objet": ORName,
        "flagCallCenter": false,
        "codeAction": state.contact.codeAction ? state.contact.codeAction  : 'code_action',
        "evtDeclencheur": "Vu sur internet",
    };

    if (ORpriorite){
        OR["priorite"] = ORpriorite
    }

    if (ORstatut){
        OR["statut"] = ORstatut
    }

    return OR;
}
