
export function buildStudyData(state){
    let beneficiaires = getBeneficiares(state);

    let civility = state.formPmPp.civility === "mr" ? "M" : "Mme";

    let phone = state.formPmPp.phoneNumber !== "" ? state.formPmPp.phoneNumber : "";
    
    return {
        "prospect": {
            "nom": state.formPmPp.lastName,
            "prenom": state.formPmPp.firstName,
            "civilite": civility,
            "numeroTelephone": phone,
            "email": state.formPmPp.email,
            "adresse": state.formPmPp.address,
            "codePostal": state.formPmPp.zipCode,
            "dateNaissance": state.formPmPp.birthDate,
            "localite": state.formPmPp.city
        },
        "tarification": {
            "offre": {
                "codeOffre": "MPE",
                "tarification": {
                    "cotisationMensuelleGlobale": parseInt(state.tarification.offre.tarification.cotisationMensuelleGlobale),
                },
                "beneficiaires": beneficiaires
            }
        },
        "idProcess": state.contact.idProcess
    }
}

function getBeneficiares(state) {
    let beneficiaires = [];
    state.tarification.offre.beneficiaires.forEach((benef) => {
        let produits = [];
        benef.produits.forEach((prod) => {
            produits.push({
                "code": prod.code,
                "tarif": prod.tarif,
                "specificites": {
                    "bpLibelle": prod.specificites.bpLibelle
                }
            })
        })
        beneficiaires.push({
            "role": benef.role,
            "quantite": benef.quantite,
            "produits": produits,
            "specificites": {
                "bpDateNaissance": benef.specificites.bpDateNaissance
            }
        })
    })
    return beneficiaires;
}