<template>
  <div class="container" v-if="!this.isViewLoading">
    <div class="row justify-content-center">
      <div class="row justify-content-center mb-5">
        <div class="col-12 col-md-10 col-xl-8">
          <div>
            <h1 v-html="title"></h1>

              <RadioInput
                ref="arret"
                v-model="arret"
                :default-choice="arret"
                :question="questions.arret"
                :name="'arret'"
                :submitted="submit"
              ></RadioInput>

              <RadioInput
                ref="arretPlus15"
                v-model="arretPlus15"
                :default-choice="arretPlus15"
                v-if="arret == 'non'"
                :question="questions.arretPlus15"
                :name="'arretPlus15'"
                :submitted="submit"
              ></RadioInput>

              <RadioInput
                ref="affectLong"
                v-if="arretPlus15 == 'non' && arret == 'non'"
                v-model="affectLong"
                :default-choice="affectLong"
                :question="questions.affectLong"
                :name="'affectLong'"
                :submitted="submit"
              ></RadioInput>

              <RadioInput
                ref="benefRent"
                v-if="
                  affectLong == 'non' && arretPlus15 == 'non' && arret == 'non'
                "
                v-model="benefRent"
                :question="questions.benefRent"
                :default-choice="benefRent"
                :name="'benefRent'"
                :submitted="submit"
              ></RadioInput>
            </div>
          <ModalContact
            id="DssineligibleModal"
            ref="DssineligibleModal"
            title="Votre profile nécessite une prise en charge par l'un de nos conseilliers experts"
            content-text=""
            :confirmAction="false"
          />
        </div>
      </div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import ModalContact from "@/components/Navigation/ModalContact";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "DSS",
  components: {
    RadioInput,
    FooterNavigation,
    Encart,
    ModalContact,
  },
  data: function () {
    return {
      viewTexts: null,
      title: null,
      questions: null,
      submit: false,
      arret: null,
      arretPlus15: null,
      affectLong: null,
      benefRent: null,
    };
  },
  created() {

    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }

    wrapper.setTcVars({
      page_name : 'dss',
      page_cat1_name : 'souscription',
      page_cat2_name : 'eligibilite',
    });


    this.viewTexts = this.formTexts.dss_souscription;
    this.title = this.viewTexts.title;
    this.questions = {
      arret: {
        text: this.viewTexts.arret_travail.text,
        choices: this.viewTexts.arret_travail.choices,
      },
      arretPlus15: {
        text: this.viewTexts.arret_travail_sup_15j.text,
        choices: this.viewTexts.arret_travail_sup_15j.choices,
      },
      affectLong: {
        text: this.viewTexts.beneficiaire_100_ald.text,
        choices: this.viewTexts.beneficiaire_100_ald.choices,
      },
      benefRent: {
        text: this.viewTexts.beneficiaire_invalidite.text,
        choices: this.viewTexts.beneficiaire_invalidite.choices,
      },
    };

    if(this.souscription.dss.non) {
      this.arret = "non"
      this.arretPlus15 = "non"
      this.affectLong = "non"
      this.benefRent = "non"
    }
    reloadEvent('page');
  },
  methods: {
    swapComp(arg) {
      this.$store.commit("switchComponent", arg);
    },
    validateField() {
      if (
        this.$refs.arret.picked &&
        this.$refs.arretPlus15.picked &&
        this.$refs.affectLong.picked &&
        this.$refs.benefRent.picked
      ) {
        return true;
      }
      return false;
    },
    submitForm() {
      this.submit = true;
      if ("oui" === this.$refs.arret.picked || "oui" === this.$refs.arretPlus15.picked || "oui" === this.$refs.affectLong.picked || "oui" === this.$refs.benefRent.picked) {
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        wrapper.setTcVars({
          page_name : 'dss',
          page_cat1_name : 'exclusion',
          page_cat2_name : '',
        });
        reloadEvent('page');
      } else {
        if (this.validateField()) {

          if (this.$refs.benefRent.picked === "non") {
            this.$store.state.souscription.dss.non = true;
          } else {
            this.$store.state.souscription.dss.non = false;
          }

          this.$store.dispatch('updateState')
          this.nextRoute();
        }
      }
    },
    nextRoute() {
      this.$router.push({ name: "ElementsAPreparer" });
    },
  },
  computed: mapState([`formTexts`, `isViewLoading`, `souscription`]),
};
</script>

<style lang="scss" scoped></style>
