<template>
  <div class="price-resume  pt-md-4">
    <div class="d-flex cotisation-mensuelle align-items-center">
      <div class="flex-fill boldy">Cotisation mensuelle</div>
      <div class="flex-fill text-end  boldy">{{ total }} €</div>
    </div>
    <div :class="{'d-none': !recap}" class="d-md-block d-md-flex cotisations">
      <div class="couvertures" >Couverture prévoyance</div>
      <div class="flex-grow-1 border-1 px-3 align-items-center">
        <div class="dotted-line w-100"></div>
      </div>
      <div class="text-end boldy">{{ prevoyance }} €</div>
    </div>
    <div :class="{'d-none': !recap}" class="d-md-block d-md-flex cotisations">
      <div class="couvertures" >Couverture santé</div>
      <div class="flex-grow-1 border-1 px-3 align-items-center">
        <div class="dotted-line w-100"></div>
      </div>
      <div class="text-end boldy">{{ sante }} €</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceResume",
  props: {
    recap: {
      type: Boolean,
      default: false,
    },
    prevoyance: String,
    sante: String,
    total: String,
  }
}
</script>
