import {getGerantFromData} from "@/services/utils";
import moment from "moment";
import templateHelpers from "@/services/templateHelpers";

export function buildInfosComp(state){
	const dateEffetSplit = state.souscription.date_effet_contrat.split('-');
	const dateExComptableSplit = state.souscription.attestation_madelin.date_cloture_exercice.split('-');
	let fieldBPDATECLOTURECOMPTABLE;
	if(state.souscription.profile_professionel.micro_entrepreneur === 'oui') {
		fieldBPDATECLOTURECOMPTABLE = null;
	} else {
		fieldBPDATECLOTURECOMPTABLE = dateExComptableSplit[2]+'/'+dateExComptableSplit[1] + '/' + dateExComptableSplit[0]
	}

	return {
		"fpDepartement": state.formPmPp.birthDepartement,
		"fpCSP": "wksHM/BVAL/bvalCSPAucun",
		"bpPayeur": "Adherent",
		"fpDateNaissance": state.formPmPp.birthDate,
		"fpLienFoyer": "wksHM/BVAL/bvalLienPrincipale",
		"bpCaisseRetraite": "Sécurité sociale des indépendants",
		"type": "Modification contrat existant",
		"bpAbattement": "",
		"bpCasAvenant": "N/A",
		"bpLibelleSouhaitSante": getLibelleSouhaiteSante(state.selectedOfferSante),
		"bpSegment": "Gérant TNS",
		"bpRemunerationMensuelle": getRemunerationMensuelle(state.selectedSalary),
		"salesStage": "Bulletin avenant",
		"tarificateur": "CAMELEON",
		"observationBA": "",
		"bpFamille": "Santé",
		"bpType": "Offre",
		"bpMarche": "Individuel",
		"fpFoyerId": "13683855",
		"bpFormule": "",
		"fpId": "DHC_17960520",
		"fpRO": "wksHM/BVAL/bvalRORegimeGeneral",
		"fpRole": "wksHM/BVAL/bvalRolePrincipale",
		"fpSegmentMarche": "wksHM/BVAL/bvalSegmentMarcheAucun",
		"bpPartPayeur": "Adhérent",
		"bpUserId": "",
		"cotisationCapitalOB": "",
		"bpMomentDeVie": "",
		"bpCodeSouhaitSante": getSanteLevel(state.selectedOfferSante),
		"bpChoixSante": getSanteLevel(state.selectedOfferSante),
		"dateAdhesion": dateEffetSplit[2]+'/'+dateEffetSplit[1] + '/' + dateEffetSplit[0],
		"fpAdresse1": state.formPmPp.address,
		"fpAdresse3": state.formPmPp.address,
		"bpCanal": "WEB",
		"prevFormule": "",
		"bpChoixPrevoyance": getPrevoyanceLevel(state.selectedOfferPrevoyance),
		"fpCivilite": "wksHM/BVAL/bvalCiviliteM",
		"fpPrenom": state.formPmPp.firstName,
		"fpNom": state.formPmPp.lastName,
		"bpGerant":getGerantFromData(state.formPmPp.status, state.formPmPp.owner),
		"fpCodePostal": state.formPmPp.zipCode,
		"bpActivite": state.formPmPp.status,
		"fpNumSS": state.formPmPp.nbSecuriteSociale,
		"bpFlagMaj": "0",
		"fpProfession": state.souscription.profile_professionel.profession,
		"bpLibelle": "Ma protection entrepreneur",
		"bpTypeCotisation": "",
		"bpPrincipalActif": "",
		"fpPays": "wksHM/BVAL/bvalPaysFR",
		"bpFlagDevoirConseilComplet": "",
		"bpGarantiePrecedenteReferencee": "N",
		"fpVille": state.formPmPp.city,
		"typeCotisationEdeo": "",
		"bpObjet": "Affaire nouvelle",
		"bpCodeDSS": "NON",
		"operation": "upsert",
		"bpLoiMadelin": state.souscription.profile_professionel.micro_entrepreneur === "oui" ? false : true,
		"BPDATECLOTURECOMPTABLE" : fieldBPDATECLOTURECOMPTABLE,
	}
}

function getRemunerationMensuelle(salaryChoice){
	if (salaryChoice === '0_1600'){
		return "Inférieur à 1600€"
	}else if (salaryChoice === '1600_2500'){
		return "Compris entre 1600 et 2500€"
	}else {
		return "Supérieur à 2500€"
	}
}

export function getPropData(state){

	const dateEffetSplit = state.souscription.date_effet_contrat.split('-');

	return {
		"login": "GRC_EAI_IDC",
		"idPPSous": "",
		"idFoyer": "",
		"dateEffet": dateEffetSplit[2]+'/'+dateEffetSplit[1] + '/' + dateEffetSplit[0],
		"idPanier": state.contact.idProcess, // ID Processus
		"idLigne": state.uuid, // uuid drupal
		"position": "1-1E1-196",
		"dossier": {
			"type": "SANTE / PREVOYANCE",
			"idRecommendation": null,
			"idRecueil": null
		},
		"infosTechnique": {
			"idOpportuniteGlobale": null
		},
		"idInteraction": "",
		"idPM": null,
		"statutCommercial": null,
		"etapeAdhesion": null,
		"canal": "WEB",
		"origine": null,
		"codeAction": null
	};
}

export function replaceOffreBeneficiaires(state){
	let tarifBeneficiares = state.tarification.offre.beneficiaires

	let formBeneficiaires = state.formPmPp.beneficiaries

	let benefsList = [];
	let mainBenef = tarifBeneficiares.find((b) => b.role === 'Principale')
	mainBenef = replaceBeneficiaireProps(state, mainBenef, state.formPmPp, false, true)

	benefsList.push(mainBenef);

	if (formBeneficiaires.hasPartner){
		let tarifConjoint = tarifBeneficiares.find((b) => b.role === 'Conjoint')
		tarifConjoint = replaceBeneficiaireProps(state, tarifConjoint, formBeneficiaires.partner, false, false)
		benefsList.push(tarifConjoint);
	}

	if (formBeneficiaires.hasChildren){
		let tarifChildren = tarifBeneficiares.filter((b) => b.role === 'Enfant')
		formBeneficiaires.children.forEach(function(child, i) {
			tarifChildren[i] = replaceBeneficiaireProps(state, tarifChildren[i], child, true, false)
			benefsList.push(tarifChildren[i]);
		})
	}


	return benefsList
}

function replaceBeneficiaireProps(state, benef, newProps, isChild, isMain){

	let specificites = benef.specificites;

	specificites['bpDateNaissance'] = newProps.birthDate

	if (isMain){
		specificites['bpNumeroCaisse'] = state.formPmPp.nbCaisseRegimeObligatoire
		specificites['bpNumeroSS'] = newProps.nbSecuriteSociale
		specificites['bpNomNaissance'] = newProps.birthLastName
		specificites['bpPaysNaissance'] = state.formPmPp.birthCountry
		specificites['bpCommuneNaissance'] = newProps.birthCity
		specificites['bpCodePostalNaissance'] = state.formPmPp.birthDepartement
		specificites['bpDepartementNaissance'] = state.formPmPp.birthDepartement.substring(0,2)

		specificites['bpCsp'] = "Artisan, commercant, chef ent"
		specificites['bpProfession'] = state.souscription.profile_professionel.profession

		specificites["bpAgeTarife"] = moment(state.souscription.date_effet_contrat.date_effet).diff(moment(state.formPmPp.birthDate), 'years')
		specificites['bpRegimeObligatoire'] = "Régime général"
		specificites['bpCodeGdRegime'] = '01'
		
	}else{
		specificites['bpNom'] = newProps.lastName
		specificites['bpPrenom'] = newProps.firstName
		specificites['bpCivilite'] = newProps.civility === 'mr' ? 'M' : 'Mme'
		specificites['bpCommuneNaissance'] = newProps.birthCity
		specificites['bpNomNaissance'] = newProps.birthLastName
		specificites['bpAdresseL1'] = state.formPmPp.address
		specificites['bpAdresseL2'] = ""
		specificites['bpAdresseL3'] = ""
		specificites['bpAdresseL4'] = state.formPmPp.city
		specificites['bpCodePostal'] = state.formPmPp.zipCode
		specificites['bpPays'] = state.formPmPp.birthCountry
		// specificites['bpRegimeObligatoire'] = newProps.
		// specificites['bpCodeGdRegime'] = 1
		
		if (isChild){
			specificites['bpNumeroCaisse'] = state.formPmPp.nbCaisseRegimeObligatoire
			if(newProps.rattachement === 'autre') {
				specificites['bpNumeroSS'] = newProps.rattachementChoice.nbSecuriteSociale
				specificites['bpRegimeObligatoire'] = getRegimeLabel(newProps.rattachementChoice.regimeObligatoire)
			} else {
				if(newProps.rattachement === state.formPmPp.nbSecuriteSociale) {
					specificites['bpRegimeObligatoire'] = "Régime général"
					specificites['bpNumeroSS'] = state.formPmPp.nbSecuriteSociale
				} else {
					specificites['bpRegimeObligatoire'] = getRegimeLabel(state.formPmPp.beneficiaries.partner.regimeObligatoire)
					specificites['bpNumeroSS'] = state.formPmPp.beneficiaries.partner.nbSecuriteSociale
				}
			}
			specificites['bpCodeGdRegime'] = '01'
		}else{
			specificites['bpNumeroSS'] = newProps.nbSecuriteSociale
			specificites['bpNumeroCaisse'] = newProps.nbCaisseRegimeObligatoire
			specificites['bpRegimeObligatoire'] = getRegimeLabel(newProps.regimeObligatoire)
			specificites['bpCodeGdRegime'] = '01'
		}

	}

	let clause = []
	if ( state.souscription.clause_beneficiaries.beneficiaries.length > 0 ){
		// specificites['BPCLAUSEBENEFICIAIREADHERENT'] = "Spécifique"
		clause['bpClauseBeneficiaireAdherent'] = "Spécifique"
		let clauseBeneficiaire = ''
		state.formPmPp.clauseBeneficiaries.forEach((b) => {
			clauseBeneficiaire += templateHelpers.getClauseBeneficiaireText(b)
		})
		clause['bpLibelleClauseSpecifique'] = clauseBeneficiaire
		// clause['BPLIBELLECLAUSESPECIFIQUE'] = clauseBeneficiaire
		benef['idPPSous'] = ""
	}else{
		clause['BPCLAUSEBENEFICIAIREADHERENT'] = "Type"
	}

	benef.produits = benef.produits.map((p)=>{
		if (p.code.includes("MPEPR")){
			p.specificites = {...clause, ...p.specificites}
		}
		return p
	})

	console.log(benef.produits)

	benef.specificites = specificites
	return benef
}

function getLibelleSouhaiteSante(level){
	if (level === "mpe112" || level === "mpe110") return "Prise en charge de l’ensemble de vos frais de santé pour un budget ajusté"
	if (level === "mpe220") return "Prise en charge renforcée de l'ensemble de vos frais de santé et améliorée en optique et dentaire"
	return "Prise en charge maximale de vos frais de santé qui sécurise vos restes à charge"
}
export function getSanteLevel(level){
	if (level === "mpe112") return "Économique budget malin"
	if (level === "mpe110") return "Économique"
	if (level === "mpe220") return "Confort"
	return "Optimal"
}

function getRegimeLabel(regimeKey){
	if (regimeKey === 'Régime général' || regimeKey === 'RG') return "Régime général"
	if (regimeKey === 'Régime local' || regimeKey === 'RL') return "Régime local"
	return "Régime général"
}

export function getPrevoyanceLevel(level){
	if (level === 'mpe001') return 'Niveau 1'
	if (level === 'mpe002') return 'Niveau 2'
	return 'Niveau 3'
}
