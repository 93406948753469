<template>
  <div class="contact">
    <a @click="showModal($event)">
        <img class=" callBackImg"
          :src="require('@/assets/svg/icon-callback.svg')"/>
    </a>
  </div>

  <ModalWCB 
    id="modalCallBack"
    ref="modalCallBack"
  />
</template>

<script>

import { mapState } from "vuex";
import { reloadEvent } from '../../services/tagCo';
import ModalWCB from "../WebCallBack/ModalWCB.vue";
// import {pushTag, reloadTCContainer} from "@/services/tagCo";

export default {
  name: "Contact",
  components: { ModalWCB },
  data: function () {
    return {

    };
  },
  methods: {
      showModal(){
        this.$store.dispatch('setTitleWcbValue', 'Contacter un conseiller Harmonie Mutuelle')
        this.$store.dispatch('setModalWcb', true)
        let event = {
          click_name: 'clic-contact',
          click_type: 'action',
          composant: 'sticky'
        };
        reloadEvent('click', event)
      }
  },
  computed: mapState([`formTexts`]),
};
</script>

