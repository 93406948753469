<template>
  <div class="offer-card" :class="{ 'adviced': advicedOffer === offerId }">
    <div class="row">
      <div class="col-11 mx-auto">
        <div class="top-header">
          <slot v-if="advicedOffer === offerId " name="top-header"></slot>
        </div>
        <div class="offer-card-container bg-white">
          <slot name="header"></slot>
          <slot name="sub-header"></slot>
          <slot name="body"></slot>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferCard",
  props: {
    advicedOffer : null,
    offerId : null,
    selectedOffer : null,
   
  }
}
</script>

<style scoped>

</style>
