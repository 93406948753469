<template>
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-12 col-lg-9">
        <div id="nav-2" class="row justify-content-center text-center">
          <router-link
            class="col-4 col-lg-3 p-3 sublink-container"
            v-slot="isExactActive"
            :to="{ name: 'Prevoyance' }"
          >
            <span class="link-default" :class="{ 'link-active': isExactActive }">
              {{ linkTexts.besoin_couverture_prevoyance.text }}
            </span>
          </router-link>

          <router-link
            class="col-4 col-lg-3 p-3 sublink-container"
            v-slot="isExactActive"
            data-name="CouvertureSante1"
            :to="{ name: 'CouvertureSante1' }"
          >
            <span class="link-default" :class="{ 'link-active': isExactActive }">
              {{ linkTexts.besoin_beneficiaire_sante.text }}
            </span>
          </router-link>

          <router-link
            class="col-4  p-3 sublink-container"
            v-slot="isExactActive"
            data-name="CouvertureSante2"
            :to="{ name: 'CouvertureSante2' }"
          >
            <span class="link-default" :class="{ 'link-active': isExactActive }">
              {{ linkTexts.besoin_couverture_sante.text }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <router-view/>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  name: "Besoins",
  data: function () {
    return {
      linkTexts: Object,
    };
  },
  created() {
    this.linkTexts = this.formTexts.fil_ariane;
  },
  computed: mapState([`formTexts`]),
};
</script>

<style scoped></style>
