<template>
  <div class="container" v-if="!this.isViewLoading">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-xl-8" v-if="true">
        <div class="row mb-5">
          <div class="col-12">
            <transition name="fade">
              <div>
                <h1 v-html="title"></h1>

                <Profession
                  :component-texts="viewTexts.profession"
                  :default-value="souscription.profile_professionel.profession"
                  ref="profession"
                  v-model="profession"
                />

                <!--<RadioInput
                  v-if="isMicroE()"
                  :submitted="submit"
                  :question="questions.question1"
                  :default-choice="questions.question1.defaultChoice"
                  :name="'microEntrepreneur'"
                  @update:modelValue="setMicroEntrepreneur"
                ></RadioInput>-->

                <RadioInput
                  :submitted="submit"
                  :ref="'mQuestion'"
                  :question="questions.question2"
                  :default-choice="questions.question2.defaultChoice"
                  :name="'mQuestion'"
                  @update:modelValue="set15m"
                ></RadioInput>

                <RadioInput
                  :submitted="submit"
                  ref="listedActivity"
                  :question="questions.question3"
                  :default-choice="questions.question3.defaultChoice"
                  :name="'listedActivity'"
                  @update:modelValue="setListedActivity"
                ></RadioInput>

                <div v-html="viewTexts.text_activite_listee.text"></div>
              </div>
            </transition>
            <ModalContact
              id="profilIneligibleModal"
              ref="profilIneligibleModal"
              title="Votre profile nécessite une prise en charge par l'un de nos conseilliers experts"
              content-text=""
              :confirmAction="false"
            />
          </div>
        </div>
      </div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput";
import Profession from "@/components/FormPpPm/Profession";
import ModalContact from "@/components/Navigation/ModalContact";
// import {getUpdateProp} from "@/services/souscrireAPI/souscrireCallBuilder";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "ProfileProfessionnel",
  components: {
    FooterNavigation,
    Encart,
    RadioInput,
    Profession,
    ModalContact,
  },
  data: function () {
    return {
      title: null,
      questions: null,
      submit: false,
      options: [],
      profession: null,
      status: null,
    };
  },
  created() {

    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }

    wrapper.setTcVars({
      page_name : 'profession',
      page_cat1_name : 'souscription',
      page_cat2_name : 'eligibilite',
    });


    this.status = this.$store.state.formPmPp.status;
    this.viewTexts = this.formTexts.profil_pro_souscription;
    this.title = this.viewTexts.title;
    this.questions = {
      question1: {
        text: this.viewTexts.micro_entrepreneur.text,
        choices: this.viewTexts.micro_entrepreneur.choices,
        defaultChoice:
          this.souscription.profile_professionel.micro_entrepreneur,
      },
      question2: {
        text: this.viewTexts.travail_15m.text,
        choices: this.viewTexts.travail_15m.choices,
        defaultChoice: this.souscription.profile_professionel.travail_15m,
      },
      question3: {
        text: this.viewTexts.activite_listee.text,
        choices: this.viewTexts.activite_listee.choices,
        defaultChoice: this.souscription.profile_professionel.activite_listee,
      },
    };
    this.options["question1"] = this.questions.question1.defaultChoice;
    this.options["question2"] = this.questions.question2.defaultChoice;
    this.options["question3"] = this.questions.question3.defaultChoice;


    reloadEvent('page');
  },
  methods: {
    isMicroE() {
      var owner = this.$store.state.formPmPp.owner;
      if (owner === "oui") {
        return true;
      } else {
        return false;
      }
    },
    setMicroEntrepreneur(itemKey) {
      this.options["question1"] = itemKey;
    },
    set15m(itemKey) {
      this.options["question2"] = itemKey;
    },
    setListedActivity(itemKey) {
      this.options["question3"] = itemKey;
    },
    validateField() {
      if (
        (this.isMicroE() &&
          this.options["question1"] && this.options["question2"] && this.options["question3"]) ||
        (this.options["question2"] && this.options["question3"])
      ) {
        return true;
      }
      return false;
    },
    submitForm() {
      if (this.$refs.listedActivity.picked === "oui" || this.$refs.mQuestion.picked === "oui") {
        wrapper.setTcVars({
          page_name : 'activite',
          page_cat1_name : 'exclusion',
          page_cat2_name : '',
        });
        reloadEvent('page');
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
      } else {
        this.submit = true;
        let profession = this.$refs.profession.submitField();
        if (this.validateField() && profession) {

          this.$store.commit('setProfilePro', {
            micro_entrepreneur : this.options["question1"],
            travail_15m : this.options["question2"],
            activite_listee : this.options["question3"],
            profession : profession
          });
          // this.$store.dispatch('updateSouscrirePP', getUpdateProp(this.$store.state))
          this.$store.dispatch('updateState')

          this.nextRoute();
        }
      }
    },
    nextRoute() {
      this.$router.push({ name: "DSS" });
    },
  },
  computed: mapState([`formTexts`, `souscription`, `isViewLoading`]),
};
</script>

<style scoped></style>
