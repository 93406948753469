<template>

  <router-link
      custom
      v-slot="{ href, navigate, isActive }"
      :to="{ name: routeName }">

    <a class="py-2 px-md-2 link-container" v-if="complet" :href="href" @click="navigate"
       :data-name="routeName"
        :class="[{'col-7 router-link-active px-2' : isActive, 'col px-0' : !isActive}, linkColSize]">
    <div class="row justify-content-center" :class="{'' : !isActive}">

      <div class="text-center col-lg-2 d-md-inline"
           :class="{'col-9 col-md-2' : !isActive, 'col-2' : isActive}">

        <span class="badge d-inline" ><i class="bi bi-check-circle-fill bg-badge-valid-default" :class="{ 'bg-badge-valid-active': isActive }" style="font-size: 22px"></i></span>
      </div>

      <div class="col-lg-10 pt-lg-0 text-start d-md-inline text-black"
           :class="{'d-none col col-md-10' : !isActive, 'col-10': isActive}">{{ linkText }}</div>
    </div>
    </a>

    <span class="py-2 px-md-2 link-container" v-if="!complet"
       :data-name="routeName"
       :class="[isActive ? 'col-7 router-link-active px-2' : 'col px-0', linkColSize, {'text-black' : complet} ]">
      <div class="row justify-content-center" :class="{'' : !isActive}">

        <div class="text-center col-lg-2 d-md-inline"
             :class="{'col-9 col-md-2' : !isActive, 'col-2' : isActive}">


          <span class="badge rounded-pill bg-badge-default "
                :class="{ 'bg-badge-active': isActive, 'ps-2 pe-2': stepIndex!=1 }">{{ stepIndex }}</span>
        </div>

        <div class="col-lg-10 pt-lg-0 text-start d-md-inline"
             :class="{'d-none col col-md-10 fw-bold' : !isActive, 'col-10': isActive}">{{ linkText }}</div>
      </div>
    </span>


  </router-link>

</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NavLink",
  props: {
    linkText: String,
    routeName: String,
    stepIndex: String,
    complet: {
      type: Boolean,
      default: false
    },
    linkColSize: {
      type: String,
      default: 'col-md-3'
    },
  },

    computed: mapState([`formTexts`]),
};
</script>

<style scoped>

</style>
