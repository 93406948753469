<template>
  <div class="container">
    <div class="row justify-content-center" id="IPCS-questions">
      <div class="col-11 col-md-8">
        <transition name="fade">
          <div class="mb-3">
            <h1 v-html="title"></h1>

            <p>{{ questions.noteTop }}</p>

            <RadioInput ref="personalAccountTransfer" v-model="personalAccountTransferChoice"
              :question="viewTexts.choix_versement" :submitted="submit"
              :default-choice="personalAccountTransferChoice" @update:modelValue="setPersonalAccountTransfer" />

            <div class="pt-4">
              <h3 v-if="personalAccountTransferChoice == 'oui'" class="pb-2 title-page " style="font-size: 18px"
                v-html="viewTexts.titre_compte_bancaire_seul.text" />
              <h3 v-if="personalAccountTransferChoice == 'non'" class="pb-2 title-page" style="font-size: 18px"
                v-html="viewTexts.titre_compte_bancaire_prestation.text" />
              <div class="borderRib">
                <div class="p-5 pt-5 pb-3">
                  <AccountOwner ref="accountOwner" :submitted="submit"
                    :component-texts="viewTexts.titulaire_compte" :default-value="
                      souscription.information_bancaire.titulaire_compte
                    " />

                  <Iban ref="iban1" :submitted="submit" :component-texts="viewTexts.iban" input-name="iban1"
                    :default-value="souscription.information_bancaire.iban" />
                  <Bic ref="bic1" :submitted="submit" :component-texts="viewTexts.bic" input-name="bic1"
                    :default-value="souscription.information_bancaire.bic" />
                </div>
              </div>
            </div>

            <div v-if="personalAccountTransferChoice == 'non'" class="mb-3">
              <h3 class="pt-5 title-page" v-html="viewTexts.titre_compte_bancaire_cotisations.text"
                style="font-size: 18px" />

              <div class="borderRib">
                <div class="p-5 pt-5 pb-3">

                  <AccountOwner ref="accountOwnerPro" :component-texts="viewTexts.titulaire_compte"
                    :submitted="submit" :default-value="
                      formPmPp.raisonSociale
                    " />

                  <Iban ref="iban2" :submitted="submit" :component-texts="viewTexts.iban"
                    :default-value="souscription.information_bancaire.iban_pro" />

                  <Bic ref="bic2" :submitted="submit" :component-texts="viewTexts.bic"
                    :default-value="souscription.information_bancaire.bic_pro" />

                  <Rib ref="rib" :submitted="submit" :component-texts="viewTexts.rib" />
                </div>
              </div>
            </div>

            <div class="pt-5">
              <DebitDate :question-text="viewTexts.date_prelevement" :default-value="debitDate" ref="debitDate" />

              <DebitPeriod :question-text="viewTexts.periodicite_prelevement" :default-value="debitPeriod"
                ref="debitPeriod" />
            </div>
          </div>
        </transition>
      
      </div>
    </div>
    <FooterNavigation @submitStep="submitForm" />
    <Encart :component-texts="viewTexts.blocs_texte" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Iban from "@/components/FormPpPm/Iban.vue";
import Bic from "@/components/FormPpPm/Bic.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput.vue";
import AccountOwner from "@/components/FormPpPm/AccountOwner.vue";
import DebitDate from "@/components/FormPpPm/DebitDate.vue";
import DebitPeriod from "@/components/FormPpPm/DebitPeriod.vue";
import FooterNavigation from "@/components/Navigation/FooterNavigation";
import Encart from "hm-mpe-widgets/src/lib-components/components/Encart.vue";
import Rib from "@/components/FormPpPm/Rib";
import wrapper from '../../main';
import { reloadEvent } from '../../services/tagCo';
//import ModalContact from "@/components/Navigation/ModalContact";

export default {
  name: "InformationsBancaires",
  components: {
    Iban,
    Bic,
    RadioInput,
    AccountOwner,
    DebitDate,
    DebitPeriod,
    FooterNavigation,
    Encart,
    Rib,
    
  },

  data: function () {
    return {
      viewTexts: null,
      personalAccountTransfer: null,
      accountOwner: null,
      accountOwnerPro: null,
      iban1: null,
      bic1: null,
      iban2: null,
      bic2: null,
      debitDate: null,
      debitPeriod: null,
      title: null,
      questions: null,
      submit: false,
      rib: null,
      personalAccountTransferChoice: "oui",
      modalErrorText: null,
    };
  },
  created() {
    if(this.$store.state.avancementSouscription[5]){
      this.$router.push({ name: "ValidationSouscription" });
    }
    wrapper.setTcVars({
      page_name: 'coordonnees-bancaires',
      page_cat1_name: 'souscription',
    });
    this.$store.state.validProp=null;
    this.viewTexts = this.formTexts.informations_bancaires_souscription;
    this.title = this.viewTexts.title;
    this.questions = {
      noteTop:
        "Nous avons besoin de ces informations pour le prélèvement de nos cotisations. Les cotisations seront payées par prélèvement automatique et un mandat SEPA à signer vous sera transmis après la souscription.",
      noteFoot:
        "Les prélèvement auront lieu à terme échu : vous paierez à la date choisie la cotisation du mois précédent. (Exemple : dans une même année, vous paierez la cotisation du mois de mars, au cours du mois d'avril).",
      pro: "Compte bancaire professionnel pour le prélèvement des cotisations",
    };

    this.modalErrorText = {
      "text": "<p>Une erreur est survenue lors du chargement de la page</p>\r\n",
      "type": "ckeditor"
    };

    if (this.souscription.information_bancaire.titulaire_compte.length == 0 &&
      this.formPmPp.firstName != "" && this.formPmPp.lastName != "") {
      this.souscription.information_bancaire.titulaire_compte =
        this.formPmPp.firstName + " " + this.formPmPp.lastName;
    }

    this.personalAccountTransfer = this.souscription.information_bancaire
      .choix_versement
      ? this.souscription.information_bancaire.choix_versement
      : Object.keys(this.viewTexts.choix_versement.choices[0])[0];

    this.debitDate = this.souscription.information_bancaire.date_prelevement
      ? this.souscription.information_bancaire.date_prelevement
      : this.tarification.offre.modalitePaiement.jourPaiement;

    this.debitPeriod = this.souscription.information_bancaire
      .periodicite_prelevement
      ? this.souscription.information_bancaire.periodicite_prelevement
      : (
        this.tarification.offre.modalitePaiement.periodicitePaiement + "le"
      ).toLowerCase();
    reloadEvent('page');
  },
  methods: {
    modalError() {
      this.$refs.errorProp.showModal();
    },
    setPersonalAccountTransfer(itemKey) {
      this.personalAccountTransfer = itemKey;
    },
    validateField() {
      let isValid = false;

      this.debitDate = this.$refs.debitDate.submitField();
      this.debitPeriod = this.$refs.debitPeriod.submitField();

      isValid = this.debitDate && this.debitPeriod;

      this.accountOwner = this.$refs.accountOwner.submitField();
      this.iban1 = this.$refs.iban1.submitField();
      this.bic1 = this.$refs.bic1.submitField();

      isValid = this.accountOwner && this.iban1 && this.bic1

      if (this.personalAccountTransferChoice === "non") {
        this.accountOwnerPro = this.$refs.accountOwnerPro.submitField();
        this.iban2 = this.$refs.iban2.submitField();
        this.bic2 = this.$refs.bic2.submitField();
        this.rib = this.$refs.rib.submitField();

        isValid = this.accountOwnerPro && this.iban2 && this.bic2 && this.rib
      }

      return isValid;
    },
    submitForm() {
      this.submit = true;
      if (this.validateField()) {
        this.souscription.information_bancaire.choix_versement = this.personalAccountTransfer;
        this.souscription.information_bancaire.titulaire_compte = this.accountOwner;
        this.souscription.information_bancaire.date_prelevement = this.debitDate;
        this.souscription.information_bancaire.periodicite_prelevement = this.debitPeriod;

        this.souscription.information_bancaire.iban = this.iban1;
        this.souscription.information_bancaire.bic = this.bic1;

        if (this.personalAccountTransferChoice === "non") {
          this.souscription.information_bancaire.titulaire_compte_pro = this.accountOwnerPro;
          this.souscription.information_bancaire.iban_pro = this.iban2;
          this.souscription.information_bancaire.bic_pro = this.bic2;
        }


      this.$store.dispatch("resetIdProcess");


        }
      },
      nextRoute() {
        this.$router.push({ name: "Relecture" });
      },
    },
    computed: mapState([`formTexts`, `formPmPp`, `tarification`, `souscription`, `isViewLoading`, `validProp`]),
    watch: {
      validProp() {
        if (this.validProp == true) {
          this.nextRoute();
        } else {
          if (this.validProp == false) {
           /* this.$store.dispatch('closeOrOpenModal', {
              modalName: 'ModalWcb',
              action: true
            })*/
          }
        }
      }
    },
  };
</script>

<style lang="scss" scoped>

</style>
